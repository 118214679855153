import React, {Fragment, useEffect, useState} from "react";
import {BrowserRouter, Redirect, useLocation} from "react-router-dom";
import {Route, Switch} from "react-router";
import * as Sentry from "@sentry/react";
import moment from "moment";
import "moment/locale/fr";

import TimesheetNotifier from "components/TimesheetContainer/Calendar/TimesheetNotifier";
import LoginContainer from "components/LoginContainer/LoginContainer";
import ResetPasswordContainer from "components/ResetPasswordContainer/ResetPasswordContainer";
import Overlay from "components/common/Overlay";
import MobileOverlay from "components/common/MobileOverlay";
import Loader from "components/Loader";
import GDPRContainer from "components/GDPRContainer/GDPRContainer";
import {changeOverlayContent} from "events/OverlayEvents";

import "./App.scss";
import {setAppLoading} from "actions/Common";
import {
    hasAcceptedBrowserNavOnMobile,
    isAuthenticated,
    isDocumentsManager,
    isMobileOrTablet,
    isSwitching,
} from "utils/Utils";
import {useDispatch, useSelector} from "react-redux";
import * as UserActions from "actions/User";
import BackendRoutes from "./BackendRoutes";
import FrontendRoutes from "./FrontendRoutes";
import ForgotPasswordContainer from "./ResetPasswordContainer/ForgotPasswordContainer";
import TutorialContainer from "./TutorialContainer/TutorialContainer";
import TutorialLink from "components/common/TutorialLink";
import PasswordAboutToExpirePopUp from "components/HomepageContainer/PasswordAboutToExpirePopUp";
import Error from "./common/Error";

const App = () => {
    const appLoading = useSelector((state) => state.common.appLoading);
    const [canManageDocuments, setCanManageDocuments] = useState(false);
    const me = useSelector((state) => state.user.me);
    const dispatch = useDispatch();
    const location = useLocation();

    const [mobileOverlayVisible, setMobileOverlayVisible] = useState(
        !hasAcceptedBrowserNavOnMobile()
    );

    if (process.env.NODE_ENV !== "development") {
        if (isAuthenticated()) {
            if (isSwitching()) {
                Sentry.setUser({impersonating: me.matricule});
            } else {
                Sentry.setUser({matricule: me.matricule});
            }
        }
    }

    useEffect(() => {
        const viewportMetaTag = document.querySelector('meta[name="viewport"]');
        if (isMobileOrTablet() && isAuthenticated() && mobileOverlayVisible) {
            viewportMetaTag.setAttribute(
                "content",
                "initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
            );
        } else {
            viewportMetaTag.setAttribute(
                "content",
                "width=device-width, initial-scale=1"
            );
        }
    }, []);

    useEffect(() => {
        if (
            isAuthenticated() &&
            !me.loaded &&
            window.location.pathname !== "/error" &&
            window.location.pathname !== "/maintenance"
        ) {
            dispatch(setAppLoading(true));
            dispatch(UserActions.fetchCurrentUser());
            // setLoading(false);
        }
        setCanManageDocuments(isDocumentsManager(me));
    }, [me]);

    useEffect(() => {
        if (me.type != "internal" && me.type != "interim") {
            if (
                isAuthenticated() &&
                window.location.pathname !== "/error" &&
                window.location.pathname !== "/account"
            ) {
                if (
                    me.passwordExpirationDate &&
                    moment(me.passwordExpirationDate).format("YYYY-MM-DD") <=
                    moment().add("1", "month").format("YYYY-MM-DD") &&
                    process.env.NODE_ENV !== "development"
                ) {
                    changeOverlayContent(
                        <PasswordAboutToExpirePopUp me={me}/>
                    );
                }
            }
        }
    }, [location, me]);

    function Routes() {
        if (appLoading) {
            return <div className="lds-ripple">
                <div></div>
                <div></div>
            </div>;
        } else if (isAuthenticated()) {
            return me.loaded && <RestrictedRoutes/>;
        } else {
            return <LoginRoute/>;
        }
    }

    function RestrictedRoutes() {
        return (
            <Switch>
                {(me.hasRole("ROLE_VALIDATION") || canManageDocuments) && (
                    <Route
                        path="/backend/*"
                        component={() => <BackendRoutes me={me}/>}
                    />
                )}
                <Route component={() => <FrontendRoutes me={me}/>}/>
            </Switch>
        );
    }

    function LoginRoute() {
        return (
            <Fragment>
                <Switch>
                    <Route path="/rgpd" component={() => <GDPRContainer/>}/>
                    <Route
                        path="/tutorial"
                        component={() => <TutorialContainer/>}
                    />

                    <Route
                        exact
                        path="/reset-password/:token"
                        component={() => <ResetPasswordContainer/>}
                    />
                    <Route
                        exact
                        path="/forgot-password/"
                        component={() => <ForgotPasswordContainer/>}
                    />
                    <Route path="/*">
                        <Redirect to="/login"/>
                        <Route
                            path="/login"
                            component={() => <LoginContainer/>}
                        />
                    </Route>
                </Switch>
                <TutorialLink/>
            </Fragment>
        );
    }

    return (
        <div
            id="app-container"
            style={{
                height:
                    isMobileOrTablet() &&
                    isAuthenticated() &&
                    mobileOverlayVisible &&
                    "100%",
            }}
            className={isAuthenticated() ? "" : "loging-page"}
        >
            <BrowserRouter>
                <Overlay/>

                {isMobileOrTablet() &&
                isAuthenticated() &&
                mobileOverlayVisible ? (
                    <MobileOverlay
                        setMobileOverlayVisible={setMobileOverlayVisible}
                    />
                ) : (
                    <Fragment>
                        <Loader/>
                        <Route
                            path="/error"
                            component={() => (
                                <Error label="Une erreur est survenue, veuillez recharger la page."/>
                            )}
                        />
                        <Route
                            path="/maintenance"
                            component={() => (
                                <Error
                                    label="Une maintenance est en cours, veuillez réessayer plus tard."
                                    btnLabel="Recharger"
                                    reloadTimeout={300000}
                                />
                            )}
                        />
                        <Routes/>
                        <TimesheetNotifier/>
                    </Fragment>
                )}

                {process.env.NODE_ENV === "development" && (
                    <div id="dev-indicator">DEV</div>
                )}
                {process.env.REACT_APP_BUILD_ENV === "Preprod" && (
                    <div id="preprod-indicator">Preprod</div>
                )}
            </BrowserRouter>
        </div>
    );
};

export default App;
