import React, { Fragment } from "react";
import { addSynchroneUrlToPictures } from "utils/Utils";

const RexView = (props) => {
    const post = props.post;

    const replaceInsertVideo = (content, iframeUrl) => {
        const regex = /<p>insert-video<\/p>/g;
        const iframe = `<iframe src="${iframeUrl}" title="${post.title}" id="bright-cove-player" allowfullscreen="" allow="encrypted-media" width="700" height="395"></iframe>`;
        let body = content.replace(regex, iframe);

        return body;
    };

    return (
        <Fragment>
            <h1>{post.title}</h1>
            <div
                dangerouslySetInnerHTML={{
                    __html: addSynchroneUrlToPictures(
                        replaceInsertVideo(post.content, post.iframeUrl)
                    ),
                }}
            ></div>
        </Fragment>
    );
};

export default RexView;
