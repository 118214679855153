import React from "react";
import "./Contact.scss";
import ContactLightbox from "components/ContactContainer/ContactLightbox";
import { changeOverlayContent } from "events/OverlayEvents";

const Contact = props => {
    const contact = props.contact;

    const handleClick = () => {
        changeOverlayContent(<ContactLightbox contact={contact} />);
    };

    return (
        <div className="contact" onClick={handleClick}>
            <img src={contact.picture} alt={contact.identity} />
            {contact.identity}
        </div>
    );
};

export default Contact;
