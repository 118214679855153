import React from "react";
import { useEffect } from "react";

import "./CheckBox.scss";

const CheckBox = (props) => {
    const { label, name, onClick, value } = props;

    return (
        <label htmlFor={name} className={name + "-container"} onClick={onClick}>
            <input
                className="checkbox"
                type="checkbox"
                name={name}
                // defaultChecked={value}
                checked={value}
                onChange={() => {
                    return;
                }}
            />
            <span className="checkmark"></span>
            <span className="checkbox-label">{label}</span>
        </label>
    );
};

export default CheckBox;
