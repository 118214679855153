import {MY_SYNCHRONE_URL} from "config/Url";

class InternalNews {
    title;
    url;
    imageUrl;
    htmlContent;

    constructor(json) {
        this.title = json.title;
        this.url = json.url;
        this.imageUrl = json.image_url;
        this.htmlContent = json.html_content;
    }

    get fullImageUrl () {
        return MY_SYNCHRONE_URL + this.imageUrl
    }
}

export default InternalNews;
