import { Schemas } from "middleware/Schemas";
import * as Params from "config/Parameters";

import { setLoading } from "actions/Common";
import { apiOptions } from "utils/Api";
import { buildUrl } from "utils/Utils";
import { getExpirableCache } from "utils/LocalStorage";

export const EXPAND_DAY = "EXPAND_DAY";
export const expandDay = (day, posX, posY) => ({
    type: EXPAND_DAY,
    day,
    posX,
    posY,
});

export const RESET_ERROR_MESSAGE = "RESET_ERROR_MESSAGE";
export const resetErrorMessage = () => ({
    type: RESET_ERROR_MESSAGE,
});

export const NOTIFY_ERROR = "NOTIFY_ERROR";
export const notifyError = (errorMessage) => ({
    type: NOTIFY_ERROR,
    errorMessage,
});

export const RESET_SUCCESS_MESSAGE = "RESET_SUCCESS_MESSAGE";
export const resetSuccessMessage = () => ({
    type: RESET_SUCCESS_MESSAGE,
});

export const NOTIFY_SUCCESS = "NOTIFY_SUCCESS";
export const notifySuccess = (successMessage) => ({
    type: NOTIFY_SUCCESS,
    successMessage,
});

export const SELECT_START_DAY = "SELECT_START_DAY";
export const selectStartDay = (day) => ({
    type: SELECT_START_DAY,
    day,
});

export const SELECT_END_DAY = "SELECT_END_DAY";
export const selectEndDay = (day) => ({
    type: SELECT_END_DAY,
    day,
});

export const SELECT_DAYS = "SELECT_DAYS";
export const selectDays = (days) => ({
    type: SELECT_DAYS,
    days,
});

export const HOVER_DAYS = "HOVER_DAYS";
export const hoverDays = (days) => ({
    type: HOVER_DAYS,
    days,
});

export const CLEAR_DAYS_SELECTION = "CLEAR_DAYS_SELECTION";
export const clearDaysSelection = () => ({
    type: CLEAR_DAYS_SELECTION,
});

export const CLEAR_DAYS_HOVERED_SELECTION = "CLEAR_DAYS_HOVERED_SELECTION";
export const clearDaysHoveredSelection = () => ({
    type: CLEAR_DAYS_HOVERED_SELECTION,
});

export const HOVER_EVENT = "HOVER_EVENT";
export const hoverEvent = (eventId) => ({
    type: HOVER_EVENT,
    eventId: eventId,
});

export const HOVER_OVERTIME = "HOVER_OVERTIME";
export const hoverOvertime = (overtimeId) => ({
    type: HOVER_OVERTIME,
    overtimeId: overtimeId,
});

export const HOVER_DAY = "HOVER_DAY";
export const hoverDay = (dayId) => ({
    type: HOVER_DAY,
    dayId: dayId,
});

export const MONTH_REQUEST = "MONTH_REQUEST";
export const MONTH_SUCCESS = "MONTH_SUCCESS";
export const MONTH_FAILURE = "MONTH_FAILURE";
export const fetchMonth = (month, year) => {
    return {
        // Types of actions to emit before and after
        types: [MONTH_REQUEST, MONTH_SUCCESS, MONTH_FAILURE],
        needLogin: true,
        // Perform the fetching:
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/month/${month}/${year}`,
                apiOptions("GET")
            );
        },
        // Arguments to inject in begin/end actions
        payload: { month, year },
        schema: Schemas.MONTH,
    };
};

export const PREVIOUS_MONTH_REQUEST = "PREVIOUS_MONTH_REQUEST";
export const PREVIOUS_MONTH_SUCCESS = "PREVIOUS_MONTH_SUCCESS";
export const PREVIOUS_MONTH_FAILURE = "PREVIOUS_MONTH_FAILURE";
export const fetchPreviousMonth = (month, year) => {
    return {
        // Types of actions to emit before and after
        types: [PREVIOUS_MONTH_REQUEST, PREVIOUS_MONTH_SUCCESS, PREVIOUS_MONTH_FAILURE],
        needLogin: true,
        // Perform the fetching:
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/month/${month}/${year}`,
                apiOptions("GET")
            );
        },
        // Arguments to inject in begin/end actions
        payload: { month, year },
        schema: Schemas.MONTH,
    };
};

export const DAYS_REQUEST = "DAYS_REQUEST";
export const DAYS_SUCCESS = "DAYS_SUCCESS";
export const DAYS_FAILURE = "DAYS_FAILURE";
export const fetchDays = (monthId) => {
    return {
        types: [DAYS_REQUEST, DAYS_SUCCESS, DAYS_FAILURE],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/day?maxResults=40&month=${monthId}`,
                apiOptions("GET")
            );
        },
        payload: { monthId },
        schema: Schemas.DAY_ARRAY,
    };
};

export const HALFDAYS_REQUEST = "HALFDAYS_REQUEST";
export const HALFDAYS_SUCCESS = "HALFDAYS_SUCCESS";
export const HALFDAYS_FAILURE = "HALFDAYS_FAILURE";
export const fetchHalfDays = (monthId) => {
    return {
        types: [HALFDAYS_REQUEST, HALFDAYS_SUCCESS, HALFDAYS_FAILURE],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/halfday?maxResults=100&month=${monthId}`,
                apiOptions("GET")
            );
        },
        payload: { monthId },
        schema: Schemas.HALFDAY_ARRAY,
    };
};

export const FETCH_EVENTS_REQUEST = "FETCH_EVENTS_REQUEST";
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";
export const FETCH_EVENTS_FAILURE = "FETCH_EVENTS_FAILURE";
export const fetchEvents = (monthId) => {
    return {
        types: [
            FETCH_EVENTS_REQUEST,
            FETCH_EVENTS_SUCCESS,
            FETCH_EVENTS_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/event?maxResults=100&month=${monthId}`,
                apiOptions("GET")
            );
        },
        payload: { monthId },
        schema: Schemas.EVENT_ARRAY,
    };
};

export const POST_EVENTS_REQUEST = "POST_EVENTS_REQUEST";
export const POST_EVENTS_SUCCESS = "POST_EVENTS_SUCCESS";
export const POST_EVENTS_FAILURE = "POST_EVENTS_FAILURE";
export const postEvent = (json) => {
    return {
        types: [POST_EVENTS_REQUEST, POST_EVENTS_SUCCESS, POST_EVENTS_FAILURE],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/event`,
                apiOptions("POST", json)
            );
        },
        payload: { json },
        schema: Schemas.EVENT_ARRAY,
    };
};

export const DELETE_EVENTS_REQUEST = "DELETE_EVENTS_REQUEST";
export const DELETE_EVENTS_SUCCESS = "DELETE_EVENTS_SUCCESS";
export const DELETE_EVENTS_FAILURE = "DELETE_EVENTS_FAILURE";
export const deleteEvent = (eventId) => {
    return {
        types: [
            DELETE_EVENTS_REQUEST,
            DELETE_EVENTS_SUCCESS,
            DELETE_EVENTS_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/event/${eventId}`,
                apiOptions("DELETE")
            );
        },
        payload: { eventId },
        schema: Schemas.EVENT_ARRAY,
    };
};

export const PATCH_EVENTS_REQUEST = "PATCH_EVENTS_REQUEST";
export const PATCH_EVENTS_SUCCESS = "PATCH_EVENTS_SUCCESS";
export const PATCH_EVENTS_FAILURE = "PATCH_EVENTS_FAILURE";
export const patchEvent = (eventId, json) => {
    return {
        types: [
            PATCH_EVENTS_REQUEST,
            PATCH_EVENTS_SUCCESS,
            PATCH_EVENTS_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/event/${eventId}`,
                apiOptions("PATCH", json)
            );
        },
        payload: { eventId, json },
        schema: Schemas.EVENT_ARRAY,
    };
};

export const CHECK_EVENT_BOUNDARIES_REQUEST = "CHECK_EVENT_BOUNDARIES_REQUEST";
export const CHECK_EVENT_BOUNDARIES_SUCCESS = "CHECK_EVENT_BOUNDARIES_SUCCESS";
export const CHECK_EVENT_BOUNDARIES_FAILURE = "CHECK_EVENT_BOUNDARIES_FAILURE";

export const checkEventBoundaries = (idOrDate) => {
    return {
        types: [
            CHECK_EVENT_BOUNDARIES_REQUEST,
            CHECK_EVENT_BOUNDARIES_SUCCESS,
            CHECK_EVENT_BOUNDARIES_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/event/check-boundaries/${idOrDate}`,
                apiOptions("GET")
            );
        },
        payload: { idOrDate },
        schema: Schemas.BOUNDING_EVENT_ARRAY,
    };
};

export const EVENTCODES_REQUEST = "EVENTCODES_REQUEST";
export const EVENTCODES_SUCCESS = "EVENTCODES_SUCCESS";
export const EVENTCODES_FAILURE = "EVENTCODES_FAILURE";

export const fetchEventCodes = (persist, startAt, endAt) => {
    let url;
    if (startAt && endAt) {
        url = `${Params.API_ROOT}timesheet/event-code/${startAt}/${endAt}?maxResults=1000`;
    } else {
        url = `${Params.API_ROOT}timesheet/event-code?maxResults=1000`;
    }
    return {
        types: [EVENTCODES_REQUEST, EVENTCODES_SUCCESS, EVENTCODES_FAILURE],
        needLogin: true,
        callAPI: () => {
            return fetch(url, apiOptions("GET"));
        },
        payload: { persist, startAt, endAt },
        schema: Schemas.EVENTCODE_ARRAY,
    };
};

export const fetchEntireMonth = (month, year) => {
    return (dispatch, getState) => {
        dispatch(setLoading(true));
        return dispatch(fetchMonth(month, year)).then(
            (data) => {
                let promises = [
                    dispatch(loadEventCodes()),
                    dispatch(loadOverTimeCodes()),
                ];
                return Promise.all(promises).then(() => {
                    dispatch(setLoading(false));
                });
            },
            (error) => {
                console.log(error);
                error.json().then((json) => {
                    let errorMessage = "Une erreur est survenue";
                    if (json.errors) {
                        errorMessage = Object.values(json.errors)[0][0];
                    } else if (json.message) {
                        errorMessage = json.message;
                    }
                    dispatch(notifyError(errorMessage));
                });
            }
        );
    };
};

export const POST_MONTH_REQUEST = "POST_MONTH_REQUEST";
export const POST_MONTH_SUCCESS = "POST_MONTH_SUCCESS";
export const POST_MONTH_FAILURE = "POST_MONTH_FAILURE";
export const postMonth = (month, year, json) => {
    return {
        types: [POST_EVENTS_REQUEST, POST_EVENTS_SUCCESS, POST_EVENTS_FAILURE],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/month/${month}/${year}`,
                apiOptions("POST", json)
            );
        },
        payload: { month, year, json },
        schema: Schemas.MONTH,
    };
};

export const FETCH_ABSENCE_HISTORY_REQUEST = "FETCH_ABSENCE_HISTORY_REQUEST";
export const FETCH_ABSENCE_HISTORY_SUCCESS = "FETCH_ABSENCE_HISTORY_SUCCESS";
export const FETCH_ABSENCE_HISTORY_FAILURE = "FETCH_ABSENCE_HISTORY_FAILURE";
export const fetchAbsenceHistory = () => {
    return {
        types: [
            FETCH_ABSENCE_HISTORY_REQUEST,
            FETCH_ABSENCE_HISTORY_SUCCESS,
            FETCH_ABSENCE_HISTORY_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/event/history/absence`,
                apiOptions("GET")
            );
        },
        payload: {},
        schema: Schemas.ABSENCE_HISTORY_ARRAY,
    };
};

export const LIST_OVERTIME_CODES_REQUEST = "LIST_OVERTIME_CODES_REQUEST";
export const LIST_OVERTIME_CODES_SUCCESS = "LIST_OVERTIME_CODES_SUCCESS";
export const LIST_OVERTIME_CODES_FAILURE = "LIST_OVERTIME_CODES_FAILURE";
export const listOvertimeCodes = (persist, startAt, endAt) => {
    let url;
    if (startAt && endAt) {
        url = `${Params.API_ROOT}timesheet/overtime-code/${startAt}/${endAt}?maxResults=100`;
    } else {
        url = `${Params.API_ROOT}timesheet/overtime-code?maxResults=100`;
    }

    return {
        types: [
            LIST_OVERTIME_CODES_REQUEST,
            LIST_OVERTIME_CODES_SUCCESS,
            LIST_OVERTIME_CODES_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(url, apiOptions("GET"));
        },
        payload: { persist, startAt, endAt },
        schema: Schemas.OVERTIMECODE_ARRAY,
    };
};

export const FETCH_OVERTIMES_REQUEST = "FETCH_OVERTIMES_REQUEST";
export const FETCH_OVERTIMES_SUCCESS = "FETCH_OVERTIMES_SUCCESS";
export const FETCH_OVERTIMES_FAILURE = "FETCH_OVERTIMES_FAILURE";
export const fetchOvertimes = (month, year) => {
    return {
        types: [
            FETCH_OVERTIMES_REQUEST,
            FETCH_OVERTIMES_SUCCESS,
            FETCH_OVERTIMES_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/overtime/${month}/${year}`,
                apiOptions("GET")
            );
        },
        payload: { month, year },
        schema: Schemas.OVERTIME_ARRAY,
    };
};

export const POST_OVERTIMES_REQUEST = "POST_OVERTIMES_REQUEST";
export const POST_OVERTIMES_SUCCESS = "POST_OVERTIMES_SUCCESS";
export const POST_OVERTIMES_FAILURE = "POST_OVERTIMES_FAILURE";
export const postOvertime = (json) => {
    return {
        types: [
            POST_OVERTIMES_REQUEST,
            POST_OVERTIMES_SUCCESS,
            POST_OVERTIMES_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/overtime`,
                apiOptions("POST", json)
            );
        },
        payload: { json },
        schema: Schemas.OVERTIME_ARRAY,
    };
};

export const DELETE_OVERTIMES_REQUEST = "DELETE_OVERTIMES_REQUEST";
export const DELETE_OVERTIMES_SUCCESS = "DELETE_OVERTIMES_SUCCESS";
export const DELETE_OVERTIMES_FAILURE = "DELETE_OVERTIMES_FAILURE";
export const deleteOvertime = (overtimeId) => {
    return {
        types: [
            DELETE_EVENTS_REQUEST,
            DELETE_EVENTS_SUCCESS,
            DELETE_EVENTS_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/overtime/${overtimeId}`,
                apiOptions("DELETE")
            );
        },
        payload: { overtimeId },
        schema: Schemas.OVERTIME_ARRAY,
    };
};

export const FETCH_LAST_3_ABSENCES_REQUEST = "FETCH_LAST_3_ABSENCES_REQUEST";
export const FETCH_LAST_3_ABSENCES_SUCCESS = "FETCH_LAST_3_ABSENCES_SUCCESS";
export const FETCH_LAST_3_ABSENCES_FAILURE = "FETCH_LAST_3_ABSENCES_FAILURE";
export const fetchLast3Absences = () => {
    return {
        types: [
            FETCH_LAST_3_ABSENCES_REQUEST,
            FETCH_LAST_3_ABSENCES_SUCCESS,
            FETCH_LAST_3_ABSENCES_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/event/history/last`,
                apiOptions("GET")
            );
        },
        payload: {},
        schema: Schemas.LAST_3_ABSENCES_ARRAY,
    };
};

export const DELETE_SPECIFIC_CRA_REQUEST = "DELETE_SPECIFIC_CRA_REQUEST";
export const DELETE_SPECIFIC_CRA_SUCCESS = "DELETE_SPECIFIC_CRA_SUCCESS";
export const DELETE_SPECIFIC_CRA_FAILURE = "DELETE_SPECIFIC_CRA_FAILURE";
export const deleteSpecificCra = (id) => {
    return {
        types: [
            DELETE_SPECIFIC_CRA_REQUEST,
            DELETE_SPECIFIC_CRA_SUCCESS,
            DELETE_SPECIFIC_CRA_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/month/file/${id}`,
                apiOptions("DELETE")
            );
        },
        payload: {},
        schema: Schemas.SPECIFIC_CRA,
    };
};

export const PATCH_SPECIFIC_CRA_REQUEST = "PATCH_SPECIFIC_CRA_REQUEST";
export const PATCH_SPECIFIC_CRA_SUCCESS = "PATCH_SPECIFIC_CRA_SUCCESS";
export const PATCH_SPECIFIC_CRA_FAILURE = "PATCH_SPECIFIC_CRA_FAILURE";
export const patchSpecificCra = (month, year, json) => {
    return {
        types: [
            PATCH_SPECIFIC_CRA_REQUEST,
            PATCH_SPECIFIC_CRA_SUCCESS,
            PATCH_SPECIFIC_CRA_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/month/${month}/${year}/files`,
                apiOptions("PATCH", json)
            );
        },
        payload: {},
        schema: Schemas.SPECIFIC_CRA,
    };
};

export const DOWNLOAD_CRA_REQUEST = "DOWNLOAD_CRA_REQUEST";
export const DOWNLOAD_CRA_SUCCESS = "DOWNLOAD_CRA_SUCCESS";
export const DOWNLOAD_CRA_FAILURE = "DOWNLOAD_CRA_FAILURE";
export const downloadCRA = (fileId) => {
    return {
        types: [
            DOWNLOAD_CRA_REQUEST,
            DOWNLOAD_CRA_SUCCESS,
            DOWNLOAD_CRA_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/month/file/${fileId}/request-download`,
                apiOptions("GET")
            );
        },
        schema: Schemas.FILE,
    };
};


export const REQUEST_EXTRA_ACTIVITIES_REQUEST = "REQUEST_EXTRA_ACTIVITIES_REQUEST";
export const REQUEST_EXTRA_ACTIVITIES_SUCCESS = "REQUEST_EXTRA_ACTIVITIES_SUCCESS";
export const REQUEST_EXTRA_ACTIVITIES_FAILURE = "REQUEST_EXTRA_ACTIVITIES_FAILURE";
export const requestExtraActivities = (month, year) => {
    return {
        types: [
            REQUEST_EXTRA_ACTIVITIES_REQUEST,
            REQUEST_EXTRA_ACTIVITIES_SUCCESS,
            REQUEST_EXTRA_ACTIVITIES_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/month/extra/${month}/${year}`,
                apiOptions("POST")
            );
        },
        payload: {},
        schema: Schemas.MONTH,
    };
}

export const REQUEST_EXTRA_ACTIVITIES_CONFIG_REQUEST = "REQUEST_EXTRA_ACTIVITIES_CONFIG_REQUEST";
export const REQUEST_EXTRA_ACTIVITIES_CONFIG_SUCCESS = "REQUEST_EXTRA_ACTIVITIES_CONFIG_SUCCESS";
export const REQUEST_EXTRA_ACTIVITIES_CONFIG_FAILURE = "REQUEST_EXTRA_ACTIVITIES_CONFIG_FAILURE";
export const requestExtraActivitiesConfig = (year, month, week) => {
    return {
        types: [
            REQUEST_EXTRA_ACTIVITIES_CONFIG_REQUEST,
            REQUEST_EXTRA_ACTIVITIES_CONFIG_SUCCESS,
            REQUEST_EXTRA_ACTIVITIES_CONFIG_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/extra-activity/${year}/${month}/${week}/config`,
                apiOptions("GET")
            );
        },
        payload: {},
        schema: Schemas.EXTRA_ACTIVITY_CONFIG,
    };
}

export const DOWNLOAD_CRA_TIMESHEET_REQUEST = "DOWNLOAD_CRA_TIMESHEET_REQUEST";
export const DOWNLOAD_CRA_TIMESHEET_SUCCESS = "DOWNLOAD_CRA_TIMESHEET_SUCCESS";
export const DOWNLOAD_CRA_TIMESHEET_FAILURE = "DOWNLOAD_CRA_TIMESHEET_FAILURE";
export const downloadCRATimesheet = (year, month, values) => {
    return {
        types: [
            DOWNLOAD_CRA_TIMESHEET_REQUEST,
            DOWNLOAD_CRA_TIMESHEET_SUCCESS,
            DOWNLOAD_CRA_TIMESHEET_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            let includeValues = false;
            if (values && values.codes?.indexOf("all") < 0) {
                includeValues = true;
            }
            return fetch(
                buildUrl(
                    `${Params.API_ROOT}timesheet/month/file/${year}/${month}/request-download`,
                    includeValues
                        ? values
                        : values.user
                        ? { user: values.user }
                        : ""
                ),
                apiOptions("GET")
            );
            // }
        },
        payload: { month, year, values },
        schema: Schemas.FILE,
    };
};

export const CLEAR_CALENDAR = "CLEAR_CALENDAR";
export const clearCalendar = () => {
    return {
        type: CLEAR_CALENDAR,
    };
};

export const CLEAR_EXTRA_ACTIVITY_WEEK = "CLEAR_EXTRA_ACTIVITY_WEEK";
export const clearExtraActivityWeek = () => {
    return {
        type: CLEAR_EXTRA_ACTIVITY_WEEK,
    };
};

export const ENTER_EDIT_MODE = "ENTER_EDIT_MODE";
export const enterEditMode = (event) => {
    return {
        type: ENTER_EDIT_MODE,
        event,
    };
};

export const LOCK_START = "LOCK_START";
export const lockStart = () => {
    return {
        type: LOCK_START,
    };
};

export const UNLOCK_START = "UNLOCK_START";
export const unlockStart = () => {
    return {
        type: UNLOCK_START,
    };
};

export const LOCK_END = "LOCK_END";
export const lockEnd = () => {
    return {
        type: LOCK_END,
    };
};

export const UNLOCK_END = "UNLOCK_END";
export const unlockEnd = () => {
    return {
        type: UNLOCK_END,
    };
};

export const LOAD_EVENT_CODES_FROM_LOCALSTORAGE =
    "LOAD_EVENT_CODES_FROM_LOCALSTORAGE";
export const loadEventCodes = () => {
    const eventCodes = getExpirableCache("eventCodes");
    if (!eventCodes) {
        return fetchEventCodes(true);
    } else {
        return {
            type: LOAD_EVENT_CODES_FROM_LOCALSTORAGE,
            eventCodes: eventCodes,
        };
    }
};

export const LOAD_OVERTIME_CODES_FROM_LOCALSTORAGE =
    "LOAD_OVERTIME_CODES_FROM_LOCALSTORAGE";
export const loadOverTimeCodes = () => {
    const overtimeCodes = getExpirableCache("overtimeCodes");
    if (!overtimeCodes) {
        return listOvertimeCodes(true);
    } else {
        return {
            type: LOAD_OVERTIME_CODES_FROM_LOCALSTORAGE,
            overtimeCodes: overtimeCodes,
        };
    }
};

export const FETCH_EXTRA_ACTIVITY_FOR_WEEK_REQUEST = "FETCH_EXTRA_ACTIVITY_FOR_WEEK_REQUEST";
export const FETCH_EXTRA_ACTIVITY_FOR_WEEK_SUCCESS = "FETCH_EXTRA_ACTIVITY_FOR_WEEK_SUCCESS";
export const FETCH_EXTRA_ACTIVITY_FOR_WEEK_FAILURE = "FETCH_EXTRA_ACTIVITY_FOR_WEEK_FAILURE";
export const fetchExtraActivityForWeek = (year, month, week) => {
    return {
        types: [
            FETCH_EXTRA_ACTIVITY_FOR_WEEK_REQUEST,
            FETCH_EXTRA_ACTIVITY_FOR_WEEK_SUCCESS,
            FETCH_EXTRA_ACTIVITY_FOR_WEEK_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/extra-activity/${year}/${month}/${week}`,
                apiOptions("GET")
            );
        },
        payload: {},
        schema: Schemas.EXTRA_ACTIVITY_WEEK,
    };
};

export const FETCH_MISSING_EXTRA_ACTIVITY_FOR_WEEK_REQUEST = "FETCH_MISSING_EXTRA_ACTIVITY_FOR_WEEK_REQUEST";
export const FETCH_MISSING_EXTRA_ACTIVITY_FOR_WEEK_SUCCESS = "FETCH_MISSING_EXTRA_ACTIVITY_FOR_WEEK_SUCCESS";
export const FETCH_MISSING_EXTRA_ACTIVITY_FOR_WEEK_FAILURE = "FETCH_MISSING_EXTRA_ACTIVITY_FOR_WEEK_FAILURE";
export const fetchMissingExtraActivityForWeek = (year, month, week) => {
    return {
        types: [
            FETCH_MISSING_EXTRA_ACTIVITY_FOR_WEEK_REQUEST,
            FETCH_MISSING_EXTRA_ACTIVITY_FOR_WEEK_SUCCESS,
            FETCH_MISSING_EXTRA_ACTIVITY_FOR_WEEK_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/extra-activity/${year}/${month}/${week}`,
                apiOptions("GET")
            );
        },
        payload: {},
        schema: Schemas.MISSING_EXTRA_ACTIVITY_WEEK,
    };
};

export const SEND_EXTRA_ACTIVITY_FOR_WEEK_REQUEST = "SEND_EXTRA_ACTIVITY_FOR_WEEK_REQUEST";
export const SEND_EXTRA_ACTIVITY_FOR_WEEK_SUCCESS = "SEND_EXTRA_ACTIVITY_FOR_WEEK_SUCCESS";
export const SEND_EXTRA_ACTIVITY_FOR_WEEK_FAILURE = "SEND_EXTRA_ACTIVITY_FOR_WEEK_FAILURE";
export const sendExtraActivityForWeek = (year, month, week, json, httpVerb) => {
    return {
        types: [
            SEND_EXTRA_ACTIVITY_FOR_WEEK_REQUEST,
            SEND_EXTRA_ACTIVITY_FOR_WEEK_SUCCESS,
            SEND_EXTRA_ACTIVITY_FOR_WEEK_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/extra-activity/${year}/${month}/${week}`,
                apiOptions(httpVerb, json)
            );
        },
        payload: {json},
        schema: Schemas.EXTRA_ACTIVITY_WEEK,
    }
}