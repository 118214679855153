import React from "react";
import moment from "moment";
import "moment/locale/fr";

import "./ActivityDaysBlock.scss";

const ActivityDaysBlock = (props) => {
    const { month, user } = props;

    return (
        <div className="activity-days-amount-block">
            {month.noPrestation ? (
                <div className="activity-days-count no-prestation">0</div>
            ) : (
                month.events &&
                Object.values(month.events).map((event, key) => {
                    if (event.days > 0) {
                        if (
                            event.type !== "absence" ||
                            (event.type === "absence" &&
                                user.type !== "contractor")
                        ) {
                            return (
                                <div key={key} className="activity-days-count">
                                    {event.days}
                                </div>
                            );
                        }
                    }
                })
            )}
        </div>
    );
};

export default ActivityDaysBlock;
