import React from "react";
import DocumentsList from "components/DocumentContainer/DocumentsList";

const DocumentContainer = () => {
    return (
        <div id="union-container" className="backend">
            <DocumentsList isEdit={true} />
        </div>
    );
};

export default DocumentContainer;
