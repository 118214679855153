import React, { useState, useEffect } from "react";
import "./ContactList.scss";
import ConsultantContact from "components/ContactContainer/ConsultantContact";
import * as ContactActions from "actions/Contact";
import { EntityRepository, CONTACT_REPOSITORY } from "store/EntityRepository";
import { useDispatch } from "react-redux";
import { setLoading } from "actions/Common";
import SearchBar from "./SearchBar";

const ConsultingTeamList = (props) => {
    const contactRepo = EntityRepository().getRepository(CONTACT_REPOSITORY);
    const [contacts, setContacts] = useState(contactRepo.findConsultingTeam());
    const [filter, setFilter] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ContactActions.fetchConsultingTeam()).then(() => {
            setContacts(contactRepo.findConsultingTeam(filter));
            dispatch(setLoading(false));
        });
    }, [filter]);

    return (
        <div>
            <SearchBar
                setFilter={setFilter}
                placeholder="Recherche par nom, agence"
            />
            <div className="contact-list">
                {contacts.map((contact, key) => {
                    return <ConsultantContact key={key} contact={contact} />;
                })}
            </div>
        </div>
    );
};

export default ConsultingTeamList;
