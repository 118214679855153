import React, { useState, useEffect } from "react";
import SocialCategory from "components/DocumentContainer/Social/SocialCategory";
import "./Section.scss";

const SocialSection = (props) => {
    const { section, isEdit, type } = props;
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    return (
        <div className="section-container">
            <div className="section-title">
                <div className="section-title-label">{section.label}</div>
            </div>
            {section.categories?.map((category, key) => {
                return (
                    <SocialCategory key={key} category={category} type={type} />
                );
            })}
        </div>
    );
};

export default SocialSection;
