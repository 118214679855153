import { combineReducers } from "redux";
import {
    OPEN_MANAGERS,
    CLOSE_MANAGERS,
    TEAM_PLANNING_SUCCESS,
} from "actions/TeamPlanning";
import User from "entities/User";

export const entities = (
    state = {
        managees: [],
    },
    action
) => {
    switch (action.type) {
        case TEAM_PLANNING_SUCCESS:
            let users = [];
            if (action.response.entities.user) {
                let listArray = Object.values(action.response.entities.user);
                listArray.forEach((element) => {
                    users.push(new User(element));
                });
            }
            return {
                ...state,
                managees: users,
            };

        default:
            return state;
    }
};

const openManagers = (state = [], action) => {
    const { type, matricules } = action;

    if (type === OPEN_MANAGERS) {
        let newState;
        for (let i = 0; i < matricules.length; i++) {
            const element = matricules[i];
            newState = [...state, element];
        }

        return newState;
    } else if (type === CLOSE_MANAGERS) {
        for (let i = 0; i < matricules.length; i++) {
            const element = matricules[i];
            let index = state.indexOf(element);
            if (index > -1) {
                state.splice(index, 1);
            }
        }

        return state;
    }

    return state;
};

export default combineReducers({
    openManagers,
    entities,
});
