import { combineReducers } from "redux";

import {
    CONTACT_SUCCESS,
    CONSULTING_TEAM_SUCCESS,
    MY_CONTACT_SUCCESS,
    RESET_CONTACT
} from "actions/Contact";

export const entities = (
    state = { contacts: {}, consultingTeam: {}, mycontacts: {} },
    action
) => {
    switch (action.type) {
        case CONTACT_SUCCESS:
        case CONSULTING_TEAM_SUCCESS:
        case MY_CONTACT_SUCCESS:
            return Object.assign({}, state, action.response.entities);
        case RESET_CONTACT:
            return Object.assign({}, state, {
                contacts: {},
                consultingTeam: {},
                mycontacts: {}
            });
        default:
            return state;
    }
};

export default combineReducers({
    entities
});
