import React from "react";
import "./DaysOfWeek.scss";

const DaysOfWeek = () => {
    return (
        <div className="days-of-week">
            <div className="day-of-week">Lun</div>
            <div className="day-of-week">Mar</div>
            <div className="day-of-week">Mer</div>
            <div className="day-of-week">Jeu</div>
            <div className="day-of-week">Ven</div>
            <div className="day-of-week">Sam</div>
            <div className="day-of-week">Dim</div>
        </div>
    );
};

export default DaysOfWeek;
