import { Schemas } from "middleware/Schemas";
import * as Params from "config/Parameters";

import { apiOptions } from "utils/Api";

export const POST_RESET_REQUEST = "POST_RESET_REQUEST";
export const POST_RESET_SUCCESS = "POST_RESET_SUCCESS";
export const POST_RESET_FAILURE = "POST_RESET_FAILURE";
export const resetPassword = (token, json) => {
    return {
        types: [POST_RESET_REQUEST, POST_RESET_SUCCESS, POST_RESET_FAILURE],
        needLogin: false,
        callAPI: () => {
            return fetch(
                `${Params.MY_SYNCHRONE_URL}/reset-password/` + token,
                apiOptions("POST", json)
            );
        },
        payload: { json },
        schema: Schemas.MESSAGE,
    };
};

export const POST_FORGOT_REQUEST = "POST_FORGOT_REQUEST";
export const POST_FORGOT_SUCCESS = "POST_FORGOT_SUCCESS";
export const POST_FORGOT_FAILURE = "POST_FORGOT_FAILURE";
export const forgotPassword = (json) => {
    return {
        types: [POST_FORGOT_REQUEST, POST_FORGOT_SUCCESS, POST_FORGOT_FAILURE],
        needLogin: false,
        callAPI: () => {
            return fetch(
                `${Params.MY_SYNCHRONE_URL}/forgot-password`,
                apiOptions("POST", json)
            );
        },
        payload: { json },
        schema: Schemas.MESSAGE,
    };
};

export const POST_CHANGE_PASSWORD_REQUEST = "POST_CHANGE_PASSWORD_REQUEST";
export const POST_CHANGE_PASSWORD_SUCCESS = "POST_CHANGE_PASSWORD_SUCCESS";
export const POST_CHANGE_PASSWORD_FAILURE = "POST_CHANGE_PASSWORD_FAILURE";
export const changePassword = (json) => {
    return {
        types: [
            POST_CHANGE_PASSWORD_REQUEST,
            POST_CHANGE_PASSWORD_SUCCESS,
            POST_CHANGE_PASSWORD_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}account/change-password`,
                apiOptions("PATCH", json)
            );
        },
        payload: { json },
        schema: Schemas.MESSAGE,
    };
};
