import React, {useState} from "react";
import "./Step2SendMonth.scss";

const Step2SendMonth = (props) => {
    const {satisfaction, setSatisfaction} = props;

    return <>
        <div className={"lb-confirm-send-header"}>BAROMETRE DE SATISFACTION</div>
        <div className="upper-satisfaction-container">
            <div className={"confirm-satisfaction"}>Êtes-vous satisfait de votre relation/collaboration avec votre Manager ?</div>
            <div className={"italic-label"}>Réponse anonyme</div>
        </div>

        <div className={"satisfaction-choices-container"}>
            <i
                className={"fal fa-laugh satisfaction-max satisfaction-choice" + (satisfaction === 3 ? " selected-satisfaction-choice" : "")}
                title={"Très satisfait"}
                onClick={() => setSatisfaction(3)}
            />
            <i
                className={"fal fa-meh satisfaction-mid satisfaction-choice" + (satisfaction === 2 ? " selected-satisfaction-choice" : "")}
                title={"Moyennement satisfait"}
                onClick={() => setSatisfaction(2)}
            />
            <i
                className={"fal fa-frown satisfaction-min satisfaction-choice" + (satisfaction === 1 ? " selected-satisfaction-choice" : "")}
                title={"Insatisfait"}
                onClick={() => setSatisfaction(1)}
            />
            <i
                className={"fal fa-meh-blank satisfaction-unknown satisfaction-choice" + (satisfaction === 0 ? " selected-satisfaction-choice" : "")}
                title={"Ne se prononce pas"}
                onClick={() => setSatisfaction(0)}
            />
        </div>

        <div className={"send-month-reminder-harass"}>
            <span className="bold">RAPPEL :</span> En cas de de situation de mal-être ou de difficultés professionnelles
            rencontrées, n’hésitez pas à contacter le service HRBP à l’adresse électronique suivante : <a href="mailto:hrbp@synchrone.fr">hrbp@synchrone.fr</a> et/ou le service d’écoute et de soutien psychologie CARE disponible
            24h/24 et 7j/7 au numéro gratuit et dédié suivant <span className={"phone-number"}>0 800 947 352</span>
            <br/><br/>

            <div className="bold">Ils sont à votre écoute !</div>

        </div>
    </>
}

export default Step2SendMonth
