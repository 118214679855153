import { MY_SYNCHRONE_URL as baseUrl } from "config/Url.js";
import File from "entities/File";

class Union {
    id;
    description;
    logo;
    label;
    files;
    role;

    constructor(json) {
        this.id = json.id;
        this.label = json.label;
        if (json.logo || json.description) {
            this.description = json.description;
            this.logo = json.logo;
            let filesArray = [];
            json.files.map((file) => {
                filesArray.push(new File(file));
            });
            this.files = filesArray;
            this.role = json.role;
        }
    }

    get logoUrl() {
        return baseUrl + this.logo;
    }
}

export default Union;
