import React from "react";
import moment from "moment";
import "moment/locale/fr";

const TeamPlanningTableHeader = (props) => {
    const { daysArray } = props;

    return (
        <thead>
            <tr>
                <th className="empty-header">&nbsp;</th>
                {daysArray.map((element, key) => {
                    return (
                        <th className="day-header" key={key}>
                            {moment.parseZone(element).format("dd D")}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

export default TeamPlanningTableHeader;
