import store from "store/ConfigureStore";
import { isEmpty } from "utils/Utils";

class UserRepository {
    findByMatricule = (matricule) => {
        let users = store.getState().user.users;
        if (matricule) {
            if (!isEmpty(users)) {
                let result = null;
                let array = Object.values(users);
                array.forEach((element) => {
                    if (element.matricule === matricule) {
                        result = element;
                    }
                });
                return result;
            }
        }

        return;
    };
}

export default UserRepository;
