import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import Header from "components/TimesheetContainer/Forms/Event/Header";
import "./Edit.scss";
import Lightbox from "components/common/Lightbox";
import * as TimesheetActions from "actions/Timesheet";
import * as UserActions from "actions/User";
import Step1Actions from "components/TimesheetContainer/Forms/Event/StepActions/Step1Actions";
import Step1AActions from "components/TimesheetContainer/Forms/Event/StepActions/Step1AActions";
import Step1BActions from "components/TimesheetContainer/Forms/Event/StepActions/Step1BActions";
import Step1CActions from "components/TimesheetContainer/Forms/Event/StepActions/Step1CActions";
import Step2Actions from "components/TimesheetContainer/Forms/Event/StepActions/Step2Actions";
import Step3Actions from "components/TimesheetContainer/Forms/Event/StepActions/Step3Actions";
import Step4Actions from "components/TimesheetContainer/Forms/Event/StepActions/Step4Actions";
import FormHeader from "components/TimesheetContainer/Forms/Event/FormHeader";
import {
    EntityRepository,
    EVENT_CODE_REPOSITORY,
    OVERTIME_CODE_REPOSITORY,
    HALFDAY_REPOSITORY,
} from "store/EntityRepository";
import {changeOverlayContent} from "events/OverlayEvents";
import ConfirmDelete from "components/TimesheetContainer/Forms/Event/ConfirmDelete";
import OvertimeCode from "entities/OvertimeCode";
import EventCode from "entities/EventCode";
import {isEmpty} from "../../../../utils/Utils";

export const STEP1 = 0;
export const STEP1A = 5;
export const STEP1B = 7;
export const STEP1C = 9;
export const STEP2 = 10;
export const STEP3 = 20;
export const STEP4 = 30;

const standardSteps = [STEP1, STEP2, STEP3, STEP4];
const hoursNotWorkedSteps = [
    STEP1,
    STEP1A,
    STEP1B,
    STEP1C,
    STEP2,
    STEP3,
    STEP4,
];

const Edit = (props) => {
    moment.updateLocale("fr", {
        weekdaysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    });

    const monthEntity = props.monthEntity;
    const currentMonth = props.currentMonth;
    const isEdit = props.action === "edit";
    const eventToEdit = props.targetEvent;
    const [allowedEventCodes, setAllowedEventCodes] = useState([]);
    const [allowedEventCodesApi, setAllowedEventCodesApi] = useState([]);
    const [allowedOvertimeCodes, setAllowedOvertimeCodes] = useState([]);
    const dispatch = useDispatch();
    const [selectedEventDescription, setSelectedEventDescription] = useState();
    const [selectedEventClass, setSelectedEventClass] = useState();
    const [selectedEventCode, setSelectedEventCode] = useState(0);
    const [selectedOvertimeCode, setSelectedOvertimeCode] = useState(0);
    const [selectedStartMoment, setSelectedStartMoment] = useState("am");
    const [selectedEndMoment, setSelectedEndMoment] = useState("pm");
    const [selectedMission, setSelectedMission] = useState();
    const [availableMissions, setAvailableMissions] = useState([]);
    const [constraintCategory, setConstraintCategory] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [overtimeFormValues, setOvertimeFormValues] = useState({});
    const [hasEnoughVacations, setHasEnoughVacations] = useState(true);

    const getApiDate = (day) => {
        if (day !== null && day !== undefined) {
            return moment.parseZone(day.day.dateAt).format("YYYY-MM-DD");
        }

        return null;
    };
    const startLocked = useSelector((state) => state.timesheet.lockedStart);
    const endLocked = useSelector((state) => state.timesheet.lockedEnd);
    const overtimes = useSelector(
        (state) => state.timesheet.entities.overtimes
    );

    // const [remoteWorking, setRemoteWorking] = useState(
    //     eventToEdit ? eventToEdit.remoteWorking : false
    // );

    const eventCodeRepo = EntityRepository().getRepository(
        EVENT_CODE_REPOSITORY
    );
    const halfdayRepo = EntityRepository().getRepository(HALFDAY_REPOSITORY);

    const eventCode = eventToEdit
        ? eventCodeRepo.find(eventToEdit.eventCode)
        : null;

    const [step, setStep] = useState(isEdit ? STEP2 : STEP1);
    const selectedStartDay = useSelector(
        (state) => state.timesheet.selectedStartDay
    );
    const selectedEndDay = useSelector(
        (state) => state.timesheet.selectedEndDay
    );
    const [startAt, setStartAt] = useState(getApiDate(selectedStartDay));
    const [endAt, setEndAt] = useState(getApiDate(selectedEndDay));
    const [affectations, setAffectations] = useState([]);

    const decideHasEnoughVacations = (daysCount, vacationCount) => {
        if (selectedEventCode) {
            let relevantCounter = selectedEventCode.relevantCounter;
            if (relevantCounter) {
                let counterToAccountFor = vacationCount[relevantCounter];
                if (isEdit) {
                    counterToAccountFor += props.targetEvent.daysCount;
                }

                if (relevantCounter === 'rtt') {
                    counterToAccountFor += vacationCount['rttRemainder'];
                    if (moment().format('M') <= 2) {
                        counterToAccountFor += 10;
                    }
                } else if (relevantCounter === 'cp') {
                    counterToAccountFor += vacationCount['remainder'];
                    counterToAccountFor += vacationCount['beforeJune'];

                    counterToAccountFor += 2.08;
                }
                let expectedCount = counterToAccountFor - daysCount;
                if (expectedCount < 0) {
                    return false;
                }
            }
        }
        return true;
    };

    const getAffectations = (codes) => {
        let result = [];
        let array = Object.values(codes);
        array.forEach((element) => {
            if (result.indexOf(element.affectation) === -1) {
                result.push(element.affectation);
            }
        });
        return result;
    };

    useEffect(() => {
        // if (selectedEventClass === "hours-not-worked") {
            let missions = [];
            affectations.forEach((element) => {
                let eventCode = eventCodeRepo.findForAffectationCode(element)
                if (eventCode) {
                    missions.push(eventCode);
                }
            });
            setAvailableMissions(missions);
            if (missions.length === 1) {
                setSelectedMission(missions[0]);
            }
        // }
    }, [affectations]);

    useEffect(() => {
        Promise.resolve(
            dispatch(TimesheetActions.listOvertimeCodes(false, startAt, endAt))
        ).then((response) => {
            const overtimeCodes = response.response.entities.overtimeCodes;
            let tempOvertimeCodes = [];
            if (overtimeCodes) {
                tempOvertimeCodes = Object.values(overtimeCodes);
            }
            let overtimeCodeEntities = [];
            tempOvertimeCodes.forEach((element) => {
                if (
                    element.category === "availability"
                ) {
                    overtimeCodeEntities.push(new OvertimeCode(element));
                }
            });
            setAllowedOvertimeCodes(overtimeCodeEntities);
            setAffectations(getAffectations(overtimeCodeEntities));
        });
        Promise.resolve(
            dispatch(TimesheetActions.fetchEventCodes(false, startAt, endAt))
        ).then((response) => {
            const eventCodes = response.response.entities.eventCodes;
            setAllowedEventCodesApi(eventCodes);
        });
    }, [])

    useEffect(() => {
        if (isEdit) {
            if (eventCode.type === "activity") {
                setSelectedEventDescription("Mes jours travaillés");
            } else {
                setSelectedEventDescription(eventCode.type);
            }
            if (eventCode.type === "overtime") {
                setSelectedType("overtime");
            } else {
                setSelectedType("event");
            }
            setSelectedEventClass(eventCode.type);
            setStartAt(
                moment.parseZone(eventToEdit.startAt).format("YYYY-MM-DD")
            );
            setEndAt(moment.parseZone(eventToEdit.endAt).format("YYYY-MM-DD"));
            setSelectedStartMoment(eventToEdit.startMoment);
            setSelectedEndMoment(eventToEdit.endMoment);
            Promise.resolve(
                dispatch(
                    TimesheetActions.fetchEventCodes(
                        false,
                        moment
                            .parseZone(eventToEdit.startAt)
                            .format("YYYY-MM-DD"),
                        moment.parseZone(eventToEdit.endAt).format("YYYY-MM-DD")
                    )
                )
            ).then((response) => {
                const eventCodes = response.response.entities.eventCodes;
                let eventCodesEntities = [];
                let tempEventCodes = Object.values(eventCodes).filter(
                    (tempEventCode) => tempEventCode.type === eventCode.type
                );
                tempEventCodes.forEach((element) => {
                    eventCodesEntities.push(new EventCode(element));
                });
                eventCodesEntities.sort((a, b) => {
                    return b.priority - a.priority;
                });
                setAllowedEventCodes(eventCodesEntities);
            });
        } else {

            if (
                halfdayRepo.findWithoutHook(selectedStartDay.day.am)
                    ?.workingState === "worked"
            ) {
                setSelectedStartMoment("pm");
            }

            if (
                halfdayRepo.findWithoutHook(selectedEndDay.day.pm)
                    ?.workingState === "worked"
            ) {
                setSelectedEndMoment("am");
            }
        }
    }, []);

    const MissionLabel = () => {
        return (
            <div className="mission-label">
                Ma mission : {selectedMission.description}
            </div>
        );
    };

    const goToStep = (newStep) => {
        if (newStep < step) {
            setStep(newStep);
            console.log(newStep);
            if (newStep === STEP1) {
                setSelectedEventDescription();
                setSelectedEventCode(0);
                setSelectedMission();
            }
        }
    };

    const nextStep = (eventType, skipStep) => {
        if (step === STEP3) {
            let errorMessage =
                "La date de fin doit être supérieure à la date de début";
            if (
                moment.parseZone(endAt).format("DD/MM/YYYY") <
                moment.parseZone(startAt).format("DD/MM/YYYY")
            ) {
                return dispatch(TimesheetActions.notifyError(errorMessage));
            } else if (
                moment.parseZone(endAt) === moment.parseZone(startAt) &&
                selectedStartMoment === "pm" &&
                selectedEndMoment === "am"
            ) {
                return dispatch(TimesheetActions.notifyError(errorMessage));
            }
        }

        if (eventType === "hours-not-worked") {
            let newStep;
            // console.log(isEmpty(availableMissions))
            if (skipStep) {
                if (isEmpty(availableMissions)) {
                    newStep = hoursNotWorkedSteps.indexOf(step) + 4;
                    setConstraintCategory("availability");
                } else {
                    newStep = hoursNotWorkedSteps.indexOf(step) + 2;
                }
            } else {
                newStep = hoursNotWorkedSteps.indexOf(step) + 1;
            }
            setStep(hoursNotWorkedSteps[newStep]);


        } else {
            let newStep = standardSteps.indexOf(step) + 1;
            setStep(standardSteps[newStep]);
        }
    };

    const handleSubmit = () => {
        if (!submitting) {
            setSubmitting(true);
            let errorMessage;
            let object = {};
            object.start_at = startAt;
            object.end_at = endAt;
            object.start_moment = selectedStartMoment;
            object.end_moment = selectedEndMoment;
            if (selectedEventCode) {
                object.event_code = selectedEventCode.id;
            }
            // object.remote_working = remoteWorking;
            let body = JSON.stringify(object);

            if (isEdit) {
                return dispatch(
                    TimesheetActions.patchEvent(props.targetEvent.id, body)
                ).then(
                    () => {
                        changeOverlayContent(null);
                        return Promise.resolve(
                            dispatch(TimesheetActions.clearDaysSelection())
                        ).then(() => {
                            let targetMoment = moment.parseZone(endAt);
                            dispatch(UserActions.fetchCurrentUser());
                            dispatch(
                                TimesheetActions.notifySuccess(
                                    "Votre évènement a été modifié avec succès"
                                )
                            );
                        });
                    },
                    (error) => {
                        error.json().then((json) => {
                            errorMessage = "Une erreur est survenue";
                            if (json.errors) {
                                errorMessage = Object.values(json.errors)[0][0];
                            } else if (json.message) {
                                errorMessage = json.message;
                            }
                            return dispatch(
                                TimesheetActions.notifyError(errorMessage)
                            );
                        });
                    }
                );
            } else {
                if (selectedType === "overtime") {
                    if (overtimeFormValues.type === "constraint") {
                        delete overtimeFormValues.days;
                    } else if (overtimeFormValues.type === "hour") {
                        delete overtimeFormValues.constraint_count;
                    }
                    return dispatch(
                        TimesheetActions.postOvertime(
                            JSON.stringify(overtimeFormValues)
                        )
                    ).then(
                        () => {
                            changeOverlayContent(null);
                            return Promise.resolve(
                                dispatch(TimesheetActions.clearDaysSelection())
                            ).then(() => {
                                let targetMoment = moment.parseZone(endAt);
                                dispatch(UserActions.fetchCurrentUser());
                                dispatch(
                                    TimesheetActions.notifySuccess(
                                        "Votre évènement a été enregistré avec succès"
                                    )
                                );
                            });
                        },
                        (error) => {
                            error.json().then((json) => {
                                errorMessage = "Une erreur est survenue";
                                if (json.errors) {
                                    errorMessage = Object.values(
                                        json.errors
                                    )[0][0];
                                } else if (json.message) {
                                    errorMessage = json.message;
                                }
                                return dispatch(
                                    TimesheetActions.notifyError(errorMessage)
                                );
                            });
                        }
                    );
                } else {
                    return dispatch(TimesheetActions.postEvent(body)).then(
                        () => {
                            changeOverlayContent(null);
                            return Promise.resolve(
                                dispatch(TimesheetActions.clearDaysSelection())
                            ).then(() => {
                                let targetMoment = moment.parseZone(endAt);
                                dispatch(UserActions.fetchCurrentUser());
                                dispatch(
                                    TimesheetActions.notifySuccess(
                                        "Votre évènement a été enregistré avec succès"
                                    )
                                );
                            });
                        }
                        // (error) => {
                        //     console.log(error);
                        //     error.json().then((json) => {
                        //         errorMessage = "Une erreur est survenue";
                        //         if (json.errors) {
                        //             errorMessage = Object.values(
                        //                 json.errors
                        //             )[0][0];
                        //         } else if (json.message) {
                        //             errorMessage = json.message;
                        //         }
                        //         return dispatch(
                        //             TimesheetActions.notifyError(errorMessage)
                        //         );
                        //     });
                        // }
                    );
                }
            }
            setSubmitting(false);
        }
    };

    const handleDelete = () => {
        changeOverlayContent(
            <ConfirmDelete
                eventToDelete={eventToEdit}
                eventCodeToDelete={eventCode}
                autofillable={props.autofillable}
                mainActivity={props.mainActivity}
            />
        );
    };

    return (
        <Lightbox className="lb-w1200">
            <div className="lb-event-edit">
                {/* <div className="lb-event-calendar"></div> */}
                <div className="lb-event-edit-form">
                    <Header step={step} goToStep={goToStep} isEdit={isEdit}/>
                    {isEdit && step === STEP2 ? (
                        <div
                            className="lb-event-edit-delete-btn"
                            onClick={() => handleDelete()}
                        >
                            <i className="fal fa-trash fa-fw"></i>
                            <div className="delete-btn-label">
                                Supprimer l'événement
                            </div>
                        </div>
                    ) : (
                        ""
                    )}

                    <div className="lb-event-form">
                        <FormHeader
                            step={step}
                            selectedEventClass={selectedEventClass}
                            selectedEventDescription={selectedEventDescription}
                            selectedEventCode={selectedEventCode}
                            selectedOvertimeCode={selectedOvertimeCode}
                            selectedMission={selectedMission}
                            selectedType={selectedType}
                            constraintCategory={constraintCategory}
                            isEdit={isEdit}
                            eventCodeToEdit={eventCode}
                            startAt={startAt}
                            endAt={endAt}
                            hasEnoughVacations={hasEnoughVacations}
                            setHasEnoughVacations={setHasEnoughVacations}
                            decideHasEnoughVacations={decideHasEnoughVacations}
                            selectedStartMoment={selectedStartMoment}
                            selectedEndMoment={selectedEndMoment}
                        />

                        {selectedMission && step >= STEP1B ? (
                            <MissionLabel/>
                        ) : (
                            ""
                        )}
                        <Step1Actions
                            step={step}
                            nextStep={nextStep}
                            setSelectedEventDescription={
                                setSelectedEventDescription
                            }
                            setSelectedEventClass={setSelectedEventClass}
                            allowedEventCodesApi={allowedEventCodesApi}
                            setAllowedEventCodes={setAllowedEventCodes}
                            setAllowedOvertimeCodes={setAllowedOvertimeCodes}
                            allowedOvertimeCodes={allowedOvertimeCodes}
                            startAt={startAt}
                            endAt={endAt}
                            setAffectations={setAffectations}
                            setSelectedType={setSelectedType}
                            selectedStartDay={selectedStartDay}
                            selectedEndDay={selectedEndDay}
                            overtimes={overtimes}
                            monthEntity={monthEntity}
                        />
                        {!isEmpty(availableMissions) && <Step1AActions
                            step={step}
                            nextStep={nextStep}
                            affectations={affectations}
                            availableMissions={availableMissions}
                            setSelectedMission={setSelectedMission}
                            allowedOvertimeCodes={allowedOvertimeCodes}
                        />}
                        {!isEmpty(availableMissions) && <Step1BActions
                            step={step}
                            nextStep={nextStep}
                            allowedOvertimeCodes={allowedOvertimeCodes}
                            availableMissions={availableMissions}
                            setSelectedMission={setSelectedMission}
                            setConstraintCategory={setConstraintCategory}
                        />}
                        {!isEmpty(availableMissions) && <Step1CActions
                            step={step}
                            nextStep={nextStep}
                            setConstraintCategory={setConstraintCategory}
                            allowedOvertimeCodes={allowedOvertimeCodes}
                            selectedMission={selectedMission}
                        />}
                        <Step2Actions
                            step={step}
                            nextStep={nextStep}
                            selectedMission={selectedMission}
                            setSelectedEventCode={setSelectedEventCode}
                            setSelectedOvertimeCode={setSelectedOvertimeCode}
                            allowedEventCodes={allowedEventCodes}
                            allowedOvertimeCodes={allowedOvertimeCodes}
                            selectedEventClass={selectedEventClass}
                            constraintCategory={constraintCategory}
                            startAt={startAt}
                            endAt={endAt}
                            ignoreMisssion={isEmpty(availableMissions)}
                        />
                        <Step3Actions
                            isEdit={isEdit}
                            eventToEdit={eventToEdit}
                            step={step}
                            nextStep={nextStep}
                            startAt={startAt}
                            endAt={endAt}
                            selectedStartMoment={selectedStartMoment}
                            selectedEndMoment={selectedEndMoment}
                            setSelectedStartMoment={setSelectedStartMoment}
                            setSelectedEndMoment={setSelectedEndMoment}
                            selectedEventClass={selectedEventClass}
                            selectedEventCode={selectedEventCode}
                            startLocked={startLocked}
                            endLocked={endLocked}
                            setStartAt={setStartAt}
                            setEndAt={setEndAt}
                            constraintCategory={constraintCategory}
                            selectedOvertimeCode={selectedOvertimeCode}
                            setOvertimeFormValues={setOvertimeFormValues}
                            currentMonth={currentMonth}
                            monthEntity={monthEntity}
                            hasEnoughVacations={hasEnoughVacations}
                            // remoteWorking={remoteWorking}
                            // setRemoteWorking={setRemoteWorking}
                            ignoreMisssion={isEmpty(availableMissions)}
                            modality={props.modality}
                        />
                        <Step4Actions
                            isEdit={isEdit}
                            step={step}
                            nextStep={nextStep}
                            startAt={startAt}
                            endAt={endAt}
                            selectedStartMoment={selectedStartMoment}
                            selectedEndMoment={selectedEndMoment}
                            handleSubmit={handleSubmit}
                            overtimeFormValues={overtimeFormValues}
                            constraintCategory={constraintCategory}
                            selectedType={selectedType}
                            monthEntity={monthEntity}
                        />
                    </div>
                </div>
            </div>
        </Lightbox>
    );
};

export default Edit;
