import {
    TOGGLE_MENU,
    SET_LOADING,
    SET_APP_LOADING,
    SET_AUTHENTICATED,
    REQUEST_IOS_TOKEN_SUCCESS,
    RESET_IOS_TOKEN_REQUEST_MESSAGE,
} from "actions/Common";

const initialState = {
    menuOpen: false,
    isLoading: false,
    appLoading: false,
    authenticated: false,
    iosTokenRequestSuccess: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_MENU:
            if (action.state !== undefined) {
                return { ...state, menuOpen: action.state };
            }

            return { ...state, menuOpen: !state.menuOpen };
        case SET_LOADING:
            if (action.isLoading !== undefined) {
                return { ...state, isLoading: action.isLoading };
            }

            return state;
        case SET_APP_LOADING:
            if (action.appLoading !== undefined) {
                return { ...state, appLoading: action.appLoading };
            }

            return state;
        case SET_AUTHENTICATED:
            return { ...state, authenticated: action.authenticated };
        case REQUEST_IOS_TOKEN_SUCCESS:
            return {
                ...state,
                iosTokenRequestSuccess: "Un email a été envoyé",
            };
        case RESET_IOS_TOKEN_REQUEST_MESSAGE:
            return {
                ...state,
                iosTokenRequestSuccess: null,
            };
        default:
            return state;
    }
};

export const resetIosTokenRequestMessage = (state = null, action) => {
    const { type, iosTokenRequestSuccess } = action;

    if (type === RESET_IOS_TOKEN_REQUEST_MESSAGE) {
        return null;
    }

    return state;
};
