import React, { useEffect, useState } from "react";
import "./ShareBlock.scss";

const ShareBlock = (props) => {
    const post = props.post;
    const [topPosition, setTopPosition] = useState(300);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleScroll = () => {
        let scrollTop = document.documentElement.scrollTop;
        if (
            scrollTop > 300 &&
            scrollTop < props.containerRef.current.clientHeight - 300
        ) {
            setTopPosition(document.documentElement.scrollTop + 100);
        } else if (scrollTop <= 400) {
            setTopPosition(300);
        }
    };

    return (
        <div
            className="share-block share-block-desktop"
            style={{ top: `${topPosition}px` }}
        >
            <div className="share-icon">
                <i className="fa fa-share-alt" aria-hidden="true"></i>
            </div>
            <div className="share-media">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={post.linkedinShareUrl}
                    id="share-linkedin"
                >
                    <i className="fab fa-linkedin-in"></i>
                </a>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={post.twitterShareUrl}
                    id="share-twitter"
                >
                    <i className="fab fa-twitter"></i>
                </a>
                {/* <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={post.viadeoShareUrl}
                    id="share-viadeo"
                >
                    <i className="fab fa-viadeo"></i>
                </a> */}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={post.facebookShareUrl}
                    id="share-facebook"
                >
                    <i className="fab fa-facebook-f"></i>
                </a>
                {/* <a href={post.shareUrl} id="share-mail">
                    <i className="fa fa-envelope"></i>
                </a> */}
            </div>
        </div>
    );
};

export default ShareBlock;
