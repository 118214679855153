import React, {Fragment} from "react";
import {useSelector} from "react-redux";

import "./Step2Actions.scss";

import Button from "components/TimesheetContainer/Forms/Event/Button";
import ButtonList from "components/TimesheetContainer/Forms/Event/StepActions/ButtonList";
import {STEP2} from "components/TimesheetContainer/Forms/Event/Edit";

const Step2Actions = (props) => {
    const {
        allowedOvertimeCodes,
        nextStep,
        step,
        constraintCategory,
        allowedEventCodes,
        setSelectedOvertimeCode,
        setSelectedEventCode,
        selectedEventClass,
        selectedMission,
        ignoreMisssion
    } = props;

    const maxEventCodeDisplayed = 3;

    const getMaxEventCodeDisplayed = () => {
        let max = 0
        if (allowedEventCodes) {
            max = maxEventCodeDisplayed
            if (allowedEventCodes.length > maxEventCodeDisplayed) {
                max = max - 1
            }
        }

        return max
    }

    const getPriorityEventCodes = () => {
        const codes = [];
        if (allowedEventCodes) {
            allowedEventCodes.map((item, key) => {
                if (key < getMaxEventCodeDisplayed()) {
                    codes.push(item);
                }
            });
        }
        return codes;
    };
    const priorityEventCodes = getPriorityEventCodes();

    const getOtherEventCodes = () => {
        const codes = [];
        if (allowedEventCodes) {
            allowedEventCodes.map((item, key) => {
                if (key >= getMaxEventCodeDisplayed()) {
                    codes.push(item);
                }
            });
        }
        return codes;
    };
    const otherEventCodes = getOtherEventCodes();

    const postOvertimeCode = (item) => {
        setSelectedOvertimeCode(item);
        nextStep();
    };

    const postEventCode = (item) => {
        setSelectedEventCode(item);
        nextStep();
    };

    const OvertimeCodes = (props) => {
        const codes = allowedOvertimeCodes.filter((element, index) => {
            return (
                (element.category === constraintCategory &&
                selectedMission &&
                element.affectation === selectedMission.code &&
                element.active) || ignoreMisssion
            );
        });

        codes.forEach((element) => {
            element.type = "hours-not-worked";
            element.shortCode = "-";
        });

        const otherCodes = codes.filter((element, index) => {
            return index >= 2;
        });

        return (
            <Fragment>
                {codes
                    ? codes.map((item, key) => {
                        if (key < 2) {
                            return (
                                <Button
                                    key={key}
                                    text={item.description}
                                    type={item.type}
                                    shortname={item.shortCode}
                                    onClick={() => postOvertimeCode(item)}
                                    additionnalStyle={{
                                        lineHeight: "45px",
                                    }}
                                />
                            );
                        }
                    })
                    : ""}
                {otherCodes.length > 0 ? (
                    <ButtonList
                        eventCodes={otherCodes}
                        postEventCode={postOvertimeCode}
                    />
                ) : (
                    //   }
                    ""
                )}
            </Fragment>
        );
    };

    const EventCodes = (props) => {
        return (
            <Fragment>
                {priorityEventCodes
                    ? priorityEventCodes.map((item, key) => (
                        <Button
                            key={key}
                            text={item.description}
                            type={item.type}
                            shortname={item.shortCode}
                            onClick={() => postEventCode(item)}
                        />
                    ))
                    : ""}
                {otherEventCodes.length > 0 ? (
                    <ButtonList
                        eventCodes={otherEventCodes}
                        postEventCode={postEventCode}
                    />
                ) : (
                    //   }
                    ""
                )}
            </Fragment>
        );
    };

    return (
        <Fragment>
            <div
                className={
                    (step !== STEP2 ? "hidden " : "") +
                    "step2-actions event-code-buttons"
                }
            >
                {selectedEventClass === "hours-not-worked" ? (
                    <OvertimeCodes/>
                ) : (
                    <EventCodes/>
                )}
            </div>
        </Fragment>
    );
};

export default Step2Actions;
