import React, { Fragment } from "react";
import { getContactDefaultRoute } from "config/Routes";
import { useSelector } from "react-redux";
import { isAuthenticated } from "utils/Utils";
import { Link } from "react-router-dom";
import Disclaimer from "components/common/Disclaimer";

import "./GDPRContainer.scss";

const GDPRContainer = () => {
    const GDPRHeader = () => {
        return (
            <header className="header gdpr-header">
                <div className="logo-synchrone"></div>
                <div className="title">Mentions RGPD</div>
            </header>
        );
    };

    const Row = (props) => {
        return (
            <tr className="row">
                <td className="means">{props.means}</td>
                <td className="data-type">{props.dataType}</td>
                <td className="legal-basis">{props.legalBasis}</td>
                <td className="duration">{props.duration}</td>
                <td className="recipient">{props.recipient}</td>
            </tr>
        );
    };

    return (
        <Fragment>
            {!isAuthenticated() && <GDPRHeader />}
            <div id="gdpr-container">
                <div className="gdpr-list-title">Mentions légales</div>

                <h1 className="main-title">
                    Note de Protection des Données Personnelles des
                    collaborateurs Synchrone
                </h1>

                <p>
                    Dans le cadre de son besoin de fonctionnement, Synchrone
                    utilise des données à caractère personnel de ses
                    collaborateurs.
                </p>
                <p>
                    La présente Note a pour but d’informer les collaborateurs
                    sur l’utilisation de leurs données personnelles, et de leur
                    permettre d’exercer leurs droits.
                </p>
                <p>
                    Pour rappel, la réglementation européenne définit une donnée
                    à caractère personnel comme toute information susceptible
                    d’identifier une personne physique. La mise en œuvre de
                    traitements de données personnelles est notamment régie par
                    le <span className="bold">Règlement (UE) 2016/679</span> du
                    Parlement européen et du Conseil du 27 avril 2016 relatif à
                    la protection des personnes physiques à l'égard du
                    traitement des données à caractère personnel et à la libre
                    circulation de ces données, ci-après désigné{" "}
                    <span className="bold">« RGPD ».</span>
                </p>
                <p>
                    Le responsable de traitement des données personnelles de ses
                    collaborateurs est Synchrone, représentée par Pierre LACAZE
                    et Laurent LECONTE. Pour répondre à ses obligations,
                    SYNCHRONE a mis en place les mesures suivantes :
                </p>

                <section className="list">
                    <p>
                        <i className="fal fa-check"></i> Un niveau de protection
                        adéquate pour préserver la confidentialité, l’intégrité
                        et la disponibilité des données personnelles des
                        collaborateurs.
                    </p>
                    <p>
                        <i className="fal fa-check"></i> La création d’un Pôle
                        dédié à la protection des données pour permettre aux
                        collaborateurs d’exercer leurs droits d’accès, de
                        rectification, de limitation, d’opposition Les
                        collaborateurs peuvent joindre le Pôle en écrivant à
                        l’adresse{" "}
                        <a
                            className="privacy-mail"
                            href="mailto:privacy@synchrone.fr"
                        >
                            privacy@synchrone.fr
                        </a>
                        . D’autre part, les collaborateurs en cas de besoin ont
                        le droit de porter réclamation auprès de la CNIL.
                    </p>
                    <p>
                        <i className="fal fa-check"></i> La mise en œuvre d’un
                        registre qui recense tous les traitements qui traitent
                        des données personnelles des collaborateurs et qui
                        indique la finalité du traitement, la base juridique du
                        traitement, les durées de conservations, ainsi que les
                        destinataires des données.
                    </p>
                </section>

                <p>
                    L’annexe du document recense les traitements de données
                    personnelles dont Synchrone a besoin pour son
                    fonctionnement. Elle décrit les caractéristiques de ces
                    traitements, notamment les types de données concernées, la
                    base légale, les durées de conservation de vos données et
                    les destinataires de vos données personnelles. Sur ce
                    dernier point, il est à noter que Synchrone ne réalise aucun
                    transfert de données hors de l’Union Européenne.
                </p>

                <h2 className="annexe-title">
                    ANNEXE : Informations détaillées sur les traitements des
                    données des collaborateurs
                </h2>

                <table className="annexe">
                    <tbody>
                        <tr className="header">
                            <td className="header-col1">
                                Traitement et finalités
                            </td>
                            <td className="header-col2">Type de données*</td>
                            <td className="header-col3">Base légale</td>
                            <td className="header-col4">
                                Durée de conservation
                            </td>
                            <td className="header-col5">
                                Destinataires pour tout ou partie des données
                            </td>
                        </tr>

                        <Row
                            means={
                                <Fragment>
                                    <span className="bold">
                                        Gestion du personnel
                                    </span>{" "}
                                    (fonction, ancienneté, absentéisme,
                                    handicap, travailleur étranger...);
                                    Organisation du travail; Réalisation d'états
                                    statistiques ou de listes d'employés pour
                                    répondre aux besoins de gestion
                                    administrative; Extraction de données RH
                                    pour les tiers autorisés ; Actions logement
                                    ; Visite médicale ; Gestion des mutuelles
                                </Fragment>
                            }
                            dataType="(1), (2), (3), (4), (8), (A), (B), (C)"
                            legalBasis="Contrat de travail. Obligation légale et Intérêt légitime pour la gestion de la visite médicale"
                            duration="Durée du contrat de travail, ensuite archivage distinct sécurisé à accès limité pour l’établissement des droits du personnel (retraite…). 3 mois pour l’extrait de casier judiciaire. 5 ans pour la situation de handicap."
                            recipient="Personnes habilitées chargées de la gestion du personnel (Administration des Ressources Humaines, Paie, Gestion de carrière, Affaires Sociales, CDG, CE) ; Supérieurs hiérarchiques pour accéder aux informations nécessaires à l’exercice de leurs fonctions. Tiers externes autorisés : Organismes publics habilités, Cabinet d'audit, Mutuelle, Clients de la mission"
                        />
                        <Row
                            means={
                                <Fragment>
                                    <span className="bold">
                                        Gestion des accès physiques aux
                                        bâtiments
                                    </span>{" "}
                                    (badges et alarmes)
                                </Fragment>
                            }
                            dataType="(1), (5)"
                            legalBasis="Intérêt légitime"
                            duration="Enregistrement des passages conservés pendant 1 an"
                            recipient="Services internes (Administration des Ressources Humaines, DSI, Facility Management, Sécurité). Tiers externes autorisés : fournisseurs d’accès physiques et d’alarme"
                        />
                        <Row
                            means={
                                <Fragment>
                                    <span className="bold">
                                        Transmission des données d’identité
                                    </span>{" "}
                                    (nom, prénom, date de naissance){" "}
                                    <span className="bold">
                                        voire de copie d’une pièce d'identité
                                    </span>{" "}
                                    (sur demande des clients à des fins de{" "}
                                    <span className="bold">
                                        gestion d'accès dans les locaux des
                                        clients (badges) et création des profils
                                        utilisateurs)
                                    </span>
                                </Fragment>
                            }
                            dataType="(1)"
                            legalBasis="Exécution d’un contrat"
                            duration="Le temps de la période de la mission"
                            recipient="Services internes (Business, Direction, Administration des Ressources Humaines, Paie). Tiers externes autorisés : Clients de la mission"
                        />
                        <Row
                            means={
                                <Fragment>
                                    <span className="bold">
                                        Gestion de la mise à disposition des
                                        outils IT et des applications et gestion
                                        des accès et habilitations
                                    </span>
                                </Fragment>
                            }
                            dataType="(1), (5)"
                            legalBasis="Contrat de travail"
                            duration="Contrat de travail"
                            recipient="Personnes en charge du Système d’Information (DSI) et Sécurité"
                        />
                        <Row
                            means={
                                <Fragment>
                                    <span className="bold">
                                        Gestion de la rémunération
                                    </span>
                                </Fragment>
                            }
                            dataType="(1), (2), (4), (5), (7), (8)"
                            legalBasis="Contrat de travail"
                            duration="Contrat de travail + 5 années après la date de départ du collaborateur  Jusqu'à la liquidation des droits de retraite pour les données de retraite"
                            recipient="Personnes en charge de la Gestion de la Paie Tiers externes autorisés : Organismes publics habilités. Managers. Direction des Affaires Sociales"
                        />
                        <Row
                            means={
                                <Fragment>
                                    <span className="bold">
                                        Gestion des notes de frais
                                    </span>
                                </Fragment>
                            }
                            dataType="(1), (2), (4), (6)"
                            legalBasis="Contrat de travail"
                            duration="10 ans (pièces comptables)"
                            recipient="Services internes (Facility Management, Déplacement, Comptabilité, Facturation, Direction commerciale et Direction financière) et le Manager. Tiers externe autorisé : Organismes publics habilités"
                        />
                        <Row
                            means={
                                <Fragment>
                                    <span className="bold">
                                        Gestion des entretiens individuels
                                    </span>
                                </Fragment>
                            }
                            dataType="(1), (3), (4)"
                            legalBasis="Obligation légale"
                            duration="Contrat de travail"
                            recipient="Personnes en charge de la Gestion des Carrières et le Manager. Direction des Affaires Sociales"
                        />
                        <Row
                            means={
                                <Fragment>
                                    <span className="bold">
                                        Gestion des actifs (PC, téléphone,
                                        écran, clés 4G, ...)
                                    </span>
                                </Fragment>
                            }
                            dataType="(1), (6)"
                            legalBasis="Contrat de travail"
                            duration="2 ans"
                            recipient="Services internes (Administration des Ressources Humaines, Achat, Paie, DSI) et le Manager. Tiers externe autorisé : Opérateur. Outils de gestion des actifs (GLPI), Outils de reporting (PowerBI)"
                        />
                        <Row
                            means={
                                <Fragment>
                                    <span className="bold">
                                        Gestion de la flotte automobile
                                    </span>
                                </Fragment>
                            }
                            dataType="(1), (6), (9)"
                            legalBasis="Contrat de travail"
                            duration="Contrat de travail"
                            recipient="Services internes (Achat, Administration des Ressources Humaines, Paie, Facility management) et le Manager. Tiers externes autorisés : Fournisseur de gestion du parc, Fournisseur de location de véhicules, fournisseur de carburant"
                        />
                        <Row
                            means={
                                <Fragment>
                                    <span className="bold">
                                        Gestion des contraventions
                                    </span>
                                </Fragment>
                            }
                            dataType="(1), (6), (A)"
                            legalBasis="Obligation légale"
                            duration="Le temps de l'extinction de la responsabilité"
                            recipient="Services internes (Achat, Administration des Ressources Humaines, Paie) et le Manager. Tiers externe autorisé : Organismes publics habilités"
                        />
                        <Row
                            means={
                                <Fragment>
                                    <span className="bold">
                                        Gestion des déplacements
                                    </span>
                                </Fragment>
                            }
                            dataType="(1), (6)"
                            legalBasis="Contrat de travail"
                            duration="1 an"
                            recipient="Services internes (Déplacement, Facility Management, Comptabilité) et le Manager Tiers externes autorisés : portail de réservation, Hôtels"
                        />
                        <Row
                            means={
                                <Fragment>
                                    <span className="bold">
                                        Suivi des accès internet
                                    </span>
                                </Fragment>
                            }
                            dataType="(5)"
                            legalBasis="Intérêt légitime"
                            duration="1 an"
                            recipient="Personnes en charge du Système d’Information (DSI) et Sécurité. Tiers externes autorisés : fournisseur de services externalisé"
                        />
                        <Row
                            means={
                                <Fragment>
                                    <span className="bold">
                                        Résolution d’incidents via des analyses
                                        de logs
                                    </span>
                                </Fragment>
                            }
                            dataType="(1)"
                            legalBasis="Intérêt légitime"
                            duration="1 an"
                            recipient="Personnes en charge du Système d’Information (DSI) et Sécurité"
                        />
                        <Row
                            means={
                                <Fragment>
                                    <span className="bold">
                                        Gestion des habilitations secret défense
                                    </span>
                                </Fragment>
                            }
                            dataType="(1), (2), (3), (A)"
                            legalBasis="Exécution d’un contrat"
                            duration="Le temps de la période de la mission"
                            recipient="Services internes (Business et Direction). Tiers externes : DGA et DGSI. Personnel en charge de la Gestion des Carrières"
                        />
                        <Row
                            means={
                                <Fragment>
                                    <span className="bold">
                                        Prise de contact avec les collaborateurs
                                        dans le cadre de la gestion de crise
                                        (sécurité)
                                    </span>
                                </Fragment>
                            }
                            dataType="(1)"
                            legalBasis="Intérêt légitime"
                            duration="Contrat de travail"
                            recipient="Services internes (Administration des Ressources Humaines et DSI et Sécurité)"
                        />
                        <Row
                            means={
                                <Fragment>
                                    <span className="bold">
                                        BI – Business Intelligence
                                    </span>{" "}
                                    (Etude de données)
                                </Fragment>
                            }
                            dataType="(1), (2), (3)"
                            legalBasis="Intérêt légitime"
                            duration="Contrat de travail"
                            recipient="Direction et Services internes (Administration des Ressources Humaines, DSI)"
                        />
                    </tbody>
                </table>

                <section className="data-types">
                    <div className="header">
                        * Les données personnelles traitées par ces traitements
                        sont :{" "}
                    </div>

                    <p>
                        - (1) des données d'identification (Etat civil,
                        identité, adresse, email, téléphone...)
                    </p>
                    <p>
                        - (2) des données de vie familiale (situation maritale,
                        nombre d’enfant)
                    </p>
                    <p>
                        - (3) des données de vie professionnelle (CV, diplôme,
                        parcours professionnel, formation)
                    </p>
                    <p>
                        - (4) des données d’informations d'ordre économique
                        (rémunération, données bancaires)
                    </p>
                    <p>- (5) des données de connexion (adresse IP, logs)</p>
                    <p>- (6) des données de tags (cookies)</p>
                    <p>- (7) le numéro de sécurité sociale</p>
                    <p>- (8) données bancaires</p>
                    <p>- (9) le permis de conduire</p>
                    <p>
                        - (A) des données d’infraction ou de condamnations
                        (extrait de casier judiciaire, contraventions)
                    </p>
                    <p>
                        - (B) des données de santé (aptitudes au travail,
                        handicap)
                    </p>
                    <p>- (C) des données sur la nationalité</p>
                </section>
            </div>
            <Disclaimer gdpr={true} />
        </Fragment>
    );
};

export default GDPRContainer;
