import {logout} from "utils/Utils";
import * as Params from "config/Parameters";
import jwt_decode from "jwt-decode";
import {Schemas} from "middleware/Schemas";
import {apiOptions} from "utils/Api";

export const TOGGLE_MENU = "TOGGLE_MENU";
export const toggleMenu = (state) => ({
    type: TOGGLE_MENU,
    state,
});

export const SET_LOADING = "SET_LOADING";
export const setLoading = (isLoading) => ({
    type: SET_LOADING,
    isLoading,
});

export const SET_APP_LOADING = "SET_APP_LOADING";
export const setAppLoading = (appLoading) => ({
    type: SET_APP_LOADING,
    appLoading,
});

export const SET_LIGTHBOX_CONTENT = "SET_LIGTHBOX_CONTENT";
export const setLightboxContent = (lightboxContent) => ({
    type: SET_LIGTHBOX_CONTENT,
    lightboxContent,
});

// export const SET_OVERLAY_CONTENT = "SET_OVERLAY_CONTENT";
// export const setOverlayContent = overlayContent => ({
//     type: SET_OVERLAY_CONTENT,
//     overlayContent
// });

export const RESET_IOS_TOKEN_REQUEST_MESSAGE =
    "RESET_IOS_TOKEN_REQUEST_MESSAGE";
export const resetIosTokenRequestMessage = () => ({
    type: RESET_IOS_TOKEN_REQUEST_MESSAGE,
});

export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const setAuthenticated = (authenticated) => ({
    type: SET_AUTHENTICATED,
    authenticated,
});

export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";

export const refreshToken = () => {
    let refreshOptions = {
        method: "POST",
        headers: {
            // "Content-Type": "application/json",
            Accept: "application/json",
        },
        credentials: "include",
        mode: "cors",
        // body: JSON.stringify({
        //     refresh_token: localStorage.getItem("synchroneRefreshToken")
        // })
    };

    return fetch(Params.API_ROOT + "token/refresh", refreshOptions)
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    logout();
                }
                return Promise.reject(response);
            } else {
                return response.json();
            }
        })
        .catch((err) => {
            if (err.status === 503) {
                window.location = "/maintenance";
            } else {
                window.location = "/error";
            }
        })
        .then((json) => {
            if (json) {
                const data = jwt_decode(json.token);
                localStorage.setItem("synchroneTokenExpiration", data.exp);
            }
            // localStorage.setItem("synchroneToken", json.token);
            // localStorage.setItem("synchroneRefreshToken", json.refresh_token);
        });
};

export const REQUEST_IOS_TOKEN_REQUEST = "REQUEST_IOS_TOKEN_REQUEST";
export const REQUEST_IOS_TOKEN_SUCCESS = "REQUEST_IOS_TOKEN_SUCCESS";
export const REQUEST_IOS_TOKEN_FAILURE = "REQUEST_IOS_TOKEN_FAILURE";
export const requestIosToken = () => {
    return {
        types: [
            REQUEST_IOS_TOKEN_REQUEST,
            REQUEST_IOS_TOKEN_SUCCESS,
            REQUEST_IOS_TOKEN_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(`${Params.API_ROOT}ios-token`, apiOptions("POST"));
        },
        payload: {},
        schema: Schemas.IOS_APP_TOKEN,
    };
};
