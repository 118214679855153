class ExtraActivityHour {
    start;
    end;
    code;
    mission;
    duration;

    constructor(json) {
        this.start = json.start;
        this.end = json.end;
        this.code = json.code;
        this.mission = json.mission;
        this.duration = json.duration;
    }
}

export default ExtraActivityHour;