class LinkItem {
    id;
    label;
    itemType;

    constructor(json) {
        this.itemType = "link";
        this.id = json.id;
        this.label = json.label;
    }
}

export default LinkItem;
