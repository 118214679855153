import React, { useState } from "react";
import SocialItem from "components/DocumentContainer/Social/SocialItem";
import "./SocialFolder.scss";

const SocialFolder = (props) => {
    const { folder, type } = props;
    const [open, setOpen] = useState(false);

    return (
        <div
            className={
                "folder-container" +
                (type === "union" ? " union-folder-container" : "") +
                (open ? "" : " closed")
            }
        >
            <div className="social-folder-row" onClick={() => setOpen(!open)}>
                <div className="folder-label">{folder.label}</div>
                <i
                    className={
                        "far fa-chevron-right expand-icon expand-icon-before" +
                        (open ? " rotated-icon" : "")
                    }
                ></i>
            </div>
            <div className={open ? "" : "hidden"}>
                {folder.items instanceof Array &&
                    folder.items.map((item, key) => {
                        return <SocialItem item={item} key={key} />;
                    })}
                {folder.files instanceof Array &&
                    folder.files.map((item, key) => {
                        return <SocialItem item={item} key={key} />;
                    })}
            </div>
        </div>
    );
};

export default SocialFolder;
