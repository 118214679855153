import React, { Fragment } from "react";
import ChangePersonalEmailContainer from "components/MyAccountContainer/ChangePersonalEmailContainer";
import ChangePasswordContainer from "components/MyAccountContainer/ChangePasswordContainer";

import "./MyAccountContainer.scss";

const MyAccountContainer = () => {
    return (
        <Fragment>
            <div id="page-container">
                <div className="container">
                    <div className="my-account-title">Mon compte</div>
                    <ChangePersonalEmailContainer />
                    <ChangePasswordContainer />
                </div>
            </div>
        </Fragment>
    );
};

export default MyAccountContainer;
