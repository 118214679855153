export const apiOptions = (method, body, isMultiPart) => {
    // let contentType = isMultiPart ? "multipart/form-data" : ;
    const headers = {
        Accept: "application/json",
        // Authorization: `Bearer ${localStorage.getItem("synchroneToken")}`
    };
    if (!isMultiPart) {
        headers["Content-Type"] = "application/json";
    }
    if (localStorage.getItem("switchUser")) {
        headers["x-switch-user"] = localStorage.getItem("switchUser");
    }
    const options = {
        headers: headers,
        credentials: "include",
        mode: "cors",
    };
    options.method = method;
    if (body) {
        options.body = body;
    }
    return options;
};
