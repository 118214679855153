import React, { useState } from "react";
import "./Slider.scss";
import { useEffect } from "react";

const Slider = (props) => {
    const [value, setValue] = useState(
        props.selectedMoment ? props.selectedMoment : props.defaultValue
    );
    const allowedToSwitch = props.allowedToSwitch;

    const getClassName = () => {
        let classes = `${props.type} slider`;

        if (value === "pm") {
            classes += " slider-right";
        }

        if (!allowedToSwitch) {
            classes += " locked";
        }

        return classes;
    };

    const toggleSlider = () => {
        if (value === "am") {
            setValue("pm");
        } else if (value === "pm") {
            setValue("am");
        }
    };

    const [classNames, setClassNames] = useState(getClassName());

    useEffect(() => {
        setClassNames(getClassName());
    }, [props.type, allowedToSwitch, value]);

    useEffect(() => {
        setValue(props.selectedMoment);
    }, [props.selectedMoment]);

    useEffect(() => {
        props.setSelectedMoment(value);
    }, [value]);

    const handleClick = () => {
        if (allowedToSwitch) {
            toggleSlider();
        }
    };

    return (
        <span className="slider-container">
            <span className="left-label">{props.leftLabel}</span>
            <div onClick={handleClick} className={classNames}></div>
            <span className="right-label">{props.rightLabel}</span>
        </span>
    );
};

export default Slider;
