import React from "react";
import moment from "moment";
import "moment/locale/fr";

import "./DaysBlock.scss";

const DaysBlock = (props) => {
    return (
        <div className="days-amount-block">
            {props.absenceGroups &&
                Object.entries(props.absenceGroups).map((element, key) => {
                    let total = 0;
                    element[1].map((event) => {
                        total += event.daysCount;
                    });
                    return (
                        <div key={key} className="days-amount-group">
                            <div className="days-amount-group-total">
                                {total}
                            </div>
                            <div className="days-amount-group-content">
                                {element[1]
                                    .sort((a, b) => {
                                        return (
                                            moment.parseZone(a.startAt) -
                                            moment.parseZone(b.startAt)
                                        );
                                    })
                                    .map((event, key) => {
                                        return (
                                            <div key={key}>
                                                {event.daysCount}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default DaysBlock;
