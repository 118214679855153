import React from "react";
import "./VacationCountHeader.scss";

const VacationCountHeader = props => {
    return (
        <div onClick={props.toggleOpen} className="vacation-count-header">
            <i className="left-vacation fal fa-stopwatch"></i>
            Compteur d'absences
            <i className="right-vacation fal fa-times"></i>
        </div>
    );
};

export default VacationCountHeader;
