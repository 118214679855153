import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { addSynchroneUrlToPictures } from "utils/Utils";

import moment from "moment";
import "moment/locale/fr";
import "./PressView.scss";

const PressView = (props) => {
    const post = props.post;

    return (
        <Fragment>
            <div className="article-type article-type-press-release">
                <i className="fa fa-bullhorn"></i> Communiqué de presse
            </div>
            <div className="press-release-date">
                <i className="far fa-calendar"></i> Le{" "}
                {moment.parseZone(post.dateAt).format("DD/MM/YYYY")} à {post.place}
            </div>
            <h1>{post.title}</h1>
            <div
                dangerouslySetInnerHTML={{
                    __html: addSynchroneUrlToPictures(post.content),
                }}
            ></div>
            <a
                className="press-release-download"
                target="_blank"
                href={"https://www.synchrone.fr" + post.secondaryFile}
            >
                <i className="fa fa-file-pdf-o"></i> Télécharger le PDF
            </a>
        </Fragment>
    );
};

export default PressView;
