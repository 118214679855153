import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SocialSection from "components/DocumentContainer/SocialSection";
import Section from "components/DocumentContainer/Section";
import LinkItem from "entities/Social/LinkItem";
import * as DocumentActions from "actions/Document";

const SocialList = () => {
    const dispatch = useDispatch();

    const socialDocuments = useSelector(
        (state) => state.document.entities.socialDocuments
    );

    const unionInfos = useSelector(
        (state) => state.document.entities.unionsArray
    );

    const socialInfos = useSelector(
        (state) => state.document.entities.socialInfos
    );

    const cseInfos = useSelector((state) => state.document.entities.cse);

    const generateUnionSection = () => {
        let linkItems = [];
        Object.values(unionInfos).map((union) => {
            linkItems.push(new LinkItem(union));
        });
        return {
            label: "Communications syndicales",
            categories: [
                {
                    label: "Syndicats",
                    folders: [
                        {
                            label: "Les différents syndicats",
                            items: linkItems,
                        },
                    ],
                },
            ],
        };
    };

    const [generatedUnionSection, setGeneratedUnionSection] = useState(
        generateUnionSection()
    );

    useEffect(() => {
        dispatch(DocumentActions.fetchSocialDocuments());
    }, []);

    useEffect(() => {
        setGeneratedUnionSection(generateUnionSection());
    }, [unionInfos]);

    return (
        <div>
            <div className="sections-container">
                {Object.values(socialDocuments).length > 0
                    ? socialDocuments?.map((section, key) => {
                          return (
                              <Section
                                  key={key}
                                  section={section}
                                  type="social"
                              />
                          );
                      })
                    : ""}
                {Object.values(cseInfos).length > 0 ? (
                    <SocialSection section={cseInfos[0]} type="cse" />
                ) : (
                    ""
                )}

                <SocialSection
                    section={generatedUnionSection}
                    type="union"
                    // label="Communications syndicales"
                    // categoryLabel="Syndicats"
                />
                {/* {Object.values(unionInfos).length > 0
                    ? unionInfos?.map((unionInfo, key) => {
                          return (
                              <SocialSection key={key} section={unionInfo} />
                          );
                      })
                    : ""} */}
                {Object.values(socialInfos).length > 0
                    ? socialInfos?.map((socialInfo, key) => {
                          return (
                              <SocialSection
                                  type="union"
                                  key={key}
                                  section={socialInfo}
                              />
                          );
                      })
                    : ""}
            </div>
        </div>
    );
};

export default SocialList;
