import store from "store/ConfigureStore";
import { isEmpty } from "utils/Utils";
import Overtime from "entities/Overtime";
import moment from "moment";
import "moment/locale/fr";

import { useSelector } from "react-redux";

class OvertimeRepository {
    find = (id) => {
        return useSelector((state) => {
            if (id) {
                let overtimes = state.timesheet.entities.month.overtimes;
                if (!isEmpty(overtimes)) {
                    let result = null;
                    let array = Object.values(overtimes);
                    array.forEach((element) => {
                        const overtime = element;
                        if (overtime.id === id) {
                            result = overtime;
                        }
                    });
                    return result;
                }
            }

            return null;
        });
    };

    findForCurrentMonth = (type) => {
        return useSelector((state) => {
            let overtimes;
            if (type === "view") {
                overtimes = state.validation.craDisplayMonth.overtimes;
            } else {
                overtimes = state.timesheet.entities.month.overtimes;
            }

            if (!isEmpty(overtimes)) {
                const result = [];
                let array = Object.values(overtimes);
                array.forEach((element) => {
                    const overtime = element;
                    result.push(overtime);
                });

                return result;
            }
            return [];
        });
    };

    findForDate = (date, type) => {
        return useSelector((state) => {
            let overtimes;
            if (type === "view") {
                overtimes = state.validation.craDisplayMonth.overtimes;
            } else {
                overtimes = state.timesheet.entities.month.overtimes;
            }

            if (!isEmpty(overtimes)) {
                const result = [];
                let array = Object.values(overtimes);
                array.forEach((element) => {
                    const overtime = element;
                    if (
                        overtime.startAt <=
                            moment.parseZone(date).format("YYYY-MM-DD") &&
                        overtime.endAt >=
                            moment.parseZone(date).format("YYYY-MM-DD")
                    ) {
                        result.push(overtime);
                    }
                });

                return result;
            }
            return [];
        });
    };

    findForDateWithoutHook = (date, type) => {
        let overtimes;
        if (type === "view") {
            overtimes = store.getState().validation.craDisplayMonth.overtimes;
        } else {
            overtimes = store.getState().timesheet.entities.month.overtimes;
        }

        if (!isEmpty(overtimes)) {
            const result = [];
            let array = Object.values(overtimes);
            array.forEach((element) => {
                const overtime = element;
                if (
                    overtime.startAt <=
                        moment.parseZone(date).format("YYYY-MM-DD") &&
                    overtime.endAt >=
                        moment.parseZone(date).format("YYYY-MM-DD")
                ) {
                    result.push(overtime);
                }
            });

            return result;
        }

        return [];
    };
}

export default OvertimeRepository;
