import React, { useState } from "react";
import { useDispatch  } from "react-redux";
import { Link } from "react-router-dom";
import Disclaimer from "components/common/Disclaimer";
import { Form, Formik, Field, yupToFormErrors } from "formik";
import * as Yup from "yup";
import * as SecurityActions from "actions/Security";
import * as TimesheetActions from "actions/Timesheet";

import "./ResetPasswordContainer.scss";

const ForgotPasswordContainer = () => {
    const dispatch = useDispatch();
    const [usernameValid, setUsernameValid] = useState(false);

    const usernameSchema = Yup.object().shape({
        username: Yup.string().required(
            "Veuillez renseigner un nom d'utilisateur"
        ),
    });

    return (
        <div id="login-container">
            <div id="form-login-container">
                <img src="/logo-my-synchrone-w.svg" alt="My Synchrone" />
                <p className="forgot-password-text">
                    Afin de réinitialiser votre mot de passe, merci de saisir
                    votre identifiant
                </p>
                {/* {renderError()} */}
                <div className="forgot-password-splitter">
                    <Formik
                        // validateOnChange={false}
                        // validateOnBlur={false}
                        initialValues={{ username: "" }}
                        validate={(values, props) => {
                            return Promise.all([
                                usernameSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => setUsernameValid(true))
                                    .catch((err) => {
                                        setUsernameValid(false);
                                    }),
                            ]).then(() => {
                                return usernameSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => {
                                        return true;
                                    })
                                    .catch((err) => {
                                        return yupToFormErrors(err);
                                    });
                            });
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            dispatch(
                                SecurityActions.forgotPassword(
                                    JSON.stringify(values)
                                )
                            ).then((response) => {
                                if (
                                    response.type ===
                                    SecurityActions.POST_FORGOT_SUCCESS
                                ) {
                                    let message =
                                        response.response.entities.message[
                                            response.response.result
                                        ].message;
                                    dispatch(
                                        TimesheetActions.notifySuccess(message)
                                    );
                                    setTimeout(() => {
                                        window.location.href = "/login";
                                    }, 5000);
                                }
                            });
                            setTimeout(() => {
                                setSubmitting(false);
                            }, 1000);
                        }}
                    >
                        {({ errors, isSubmitting }) => (
                            <Form noValidate className="form-forgot-password">
                                <div className="field-container">
                                    <Field
                                        className={
                                            usernameValid ? "border-valid" : ""
                                        }
                                        id="username"
                                        // value={values.username}
                                        type="text"
                                        name="username"
                                        placeholder="Votre identifiant"
                                    />
                                </div>
                                <Link className="forgot-password" to="/login">
                                    Retour à la page de connexion
                                </Link>
                                <button
                                    className={
                                        "next-btn" +
                                        (!usernameValid || isSubmitting
                                            ? " disabled"
                                            : "")
                                    }
                                    type="submit"
                                    disabled={!usernameValid || isSubmitting}
                                >
                                    Envoyer
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            <Disclaimer login={true}/>
        </div>
    );
};

export default ForgotPasswordContainer;
