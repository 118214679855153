import React from "react";

import "./ModalityBadge.scss";

const ModalityBadge = (props) => {
    const { modality } = props;

    let modalityLabel, additionnalClass;

    switch (modality) {
        case "1":
            modalityLabel = "35h";
            additionnalClass = "modality-one-badge";
            break;
        case "2":
            modalityLabel = "38h30";
            break;
        case "3":
            modalityLabel = "Forfait Jour";
            break;
        default:
            modalityLabel = "Inconnu"
            break;
    }

    return (
        <div className={"modality-badge one-liner " + additionnalClass}>
            {modalityLabel}
        </div>
    );
};

export default ModalityBadge;
