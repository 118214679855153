import React from "react";
import Lightbox from "components/common/Lightbox";
import { changeOverlayContent } from "events/OverlayEvents";
import moment from "moment";
import "moment/locale/fr";

import "./ShowComment.scss";

const ShowComment = (props) => {
    const { period } = props;

    const close = () => {
        changeOverlayContent(null);
    };

    return (
        <Lightbox className="lb-centered comment-period-lb">
            <div className="comment-period-lb-header">Commentaire</div>
            <div className="close-lb" onClick={close}>
                <i className="fal fa-times"></i>
            </div>
            <div className="comment-author">
                {period.author.id
                    ? period.author.firstname + " " + period.author.lastname
                    : "Auteur inconnu"}
            </div>
            <div className="comment-closed-at">
                Le {moment.parseZone(period.closedAt).format("DD/MM/YYYY")} à{" "}
                {moment.parseZone(period.closedAt).format("H[h]mm")}
            </div>
            <div className="comment-comment">{period.comment}</div>
        </Lightbox>
    );
};

export default ShowComment;
