import React from "react";
import { Field } from "formik";

const TicketInputLine = (props) => {
    const onChange = props.onChange;
    const onBlur = props.onBlur;

    return (
        <div className="ticket-input-container">
            <Field
                value={props.value}
                onChange={onChange}
                onBlur={onBlur}
                className="ticket-input"
                type="number"
                name={`days[${props.id}].tickets`}
                id={`days[${props.id}].tickets`}
                min={1}
            />
        </div>
    );
};

export default TicketInputLine;
