import React from "react";
import { Link } from "react-router-dom";

const TutorialLink = () => {
    return (
        <Link id="tutorial-link" to={`/tutorial`} target="_blank">
            <i className="fal fa-question-circle"></i>
            <span className="tutorial-link-text">Accéder aux tutos</span>
        </Link>
    );
};

export default TutorialLink;
