import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {logout} from "utils/Utils";

import "./Menu.scss";

import * as UserActions from "actions/User";
import {setAppLoading} from "actions/Common";
import * as CommonActions from "actions/Common";
import useClickOutside from "hooks/useClickOutside";
import usePressEscapeKey from "hooks/usePressEscapeKey";
import {getContactDefaultRoute} from "config/Routes";
import {removeCache, removeExpirableCache} from "utils/LocalStorage";

const Menu = (props) => {
    const dispatch = useDispatch();
    const menuOpen = useSelector((state) => state.common.menuOpen);
    const me = useSelector((state) => state.user.me);

    const ref = useClickOutside(() => {
        if (menuOpen) {
            close();
        }
    });

    const exit = () => {
        localStorage.removeItem("switchUser");
        removeCache("userForAbsenceList");
        removeCache("userForCRAList");
        removeCache("userForAbsenceListFetchedAt");
        removeCache("userForCRAListFetchedAt");
        removeExpirableCache("overtimeCodes");
        removeExpirableCache("eventCodes");
        me.loaded = false;
        dispatch(setAppLoading(true));
        dispatch(UserActions.fetchCurrentUser());
    };

    usePressEscapeKey(() => {
        if (menuOpen) {
            close();
        }
    });

    function toggleActive() {
        dispatch(CommonActions.toggleMenu());
    }

    // function open() {
    //     dispatch(CommonActions.toggleMenu(true));
    // }

    function close() {
        dispatch(CommonActions.toggleMenu(false));
    }

    return (
        <div className="menu" ref={ref}>
            {/* <div className="menu"> */}
            <div onClick={toggleActive} className="menu-button">
                <div className="bar bar-1"></div>
                <div className="bar bar-2"></div>
                <div className="bar bar-3"></div>
            </div>
            <div
                className={
                    (menuOpen ? "main-navigation-open " : "") +
                    "overlay-adjustable main-navigation"
                }
            >
                <div id="top-part-menu">
                    <Link onClick={close} to="/">
                        <i className="fal fa-home-lg-alt fa-fw"></i>Accueil
                    </Link>

                    <Link onClick={close} to="/timesheet">
                        <i className="fal fa-calendar-edit fa-fw"></i>
                        Mon activité
                    </Link>

                    <Link onClick={close} to={getContactDefaultRoute(me)}>
                        <i className="fal fa-id-card fa-fw"></i>
                        Mes contacts
                    </Link>

                    {me.type !== "contractor" && (
                        <Link onClick={close} to="/actus">
                            <i className="fal fa-comments-alt fa-fw"></i>
                            Mes actualités
                        </Link>
                    )}

                    {me.type !== "contractor" && (
                        <a
                            onClick={close}
                            href="https://www.expensya.com/Portal/#/Login?lang=fr"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="fal fa-file-invoice-dollar fa-fw"></i>
                            Mes frais
                        </a>
                    )}

                    {me.type !== "contractor" && (
                        <>
                            <Link onClick={close} to="/documents">
                                <i className="fal fa-folder-open fa-fw"></i>
                                Mes documents
                            </Link>

                            <Link onClick={close} to="/documents/social">
                                <i className="fal fa-flag fa-fw"></i>
                                Mes informations sociales
                            </Link>
                        </>
                    )}

                    {me.canRunAs() && (
                        <Link onClick={close} to="/runas">
                            <i className="fal fa-user-ninja fa-fw"></i>Run As
                        </Link>
                    )}

                    <hr className="menu-separator"/>

                    <Link to="/parameters" onClick={close}>
                        <i className="fal fa-cog fa-fw"></i>
                        Paramètres
                    </Link>

                    {me.type !== "internal" && (
                        <Link to="/account" onClick={close}>
                            <i className="fal fa-user-cog fa-fw"></i>
                            Mon compte
                        </Link>
                    )}

                    {localStorage.getItem("switchUser") ? (
                        <Link to="/" onClick={exit}>
                            <i className="fal fa-sign-out fa-fw "></i>
                            Déconnexion
                        </Link>
                    ) : (
                        <Link to="#" onClick={logout}>
                            <i className="fal fa-sign-out fa-fw"></i>
                            Déconnexion
                        </Link>
                    )}
                </div>

                <div>
                    <Link
                        to="/tutorial"
                        onClick={close}
                        className="synchrone-blue-link"
                    >
                        <i className="fal fa-question-circle fa-fw"></i>
                        Mes tutos
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Menu;
