import React from "react";

import "./Lightbox.scss";

const Lightbox = React.forwardRef((props, ref) => {
    let className = ["lightbox"];

    if (props.className) {
        className.push(props.className);
    }

    return (
        <div className={className.join(" ")} ref={ref}>
            {props.children}
        </div>
    );
});

export default Lightbox;
