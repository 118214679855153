import React, { Fragment, useState } from "react";
import InputText from "components/LoginContainer/Form/InputText";

import "./InputPassword.scss";

const InputPassword = (props) => {
    const [isPasswordHidden, setPasswordHidden] = useState(
        props.passwordHidden ? props.passwordHidden : true
    );

    return (
        <Fragment>
            {isPasswordHidden ? (
                <InputText {...props} type="password" />
            ) : (
                <InputText {...props} />
            )}
            <i
                id="password-hidden-indicator"
                className={
                    "far " + (isPasswordHidden ? "fa-eye" : "fa-eye-slash")
                }
                onClick={() => setPasswordHidden(!isPasswordHidden)}
            ></i>
        </Fragment>
    );
};

export default InputPassword;
