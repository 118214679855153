import React, { Fragment } from "react";
import { addSynchroneUrlToPictures } from "utils/Utils";

import "./InterviewView.scss";

const InterviewView = (props) => {
    const post = props.post;
    return (
        <Fragment>
            <div className="article-type article-type-interview">
                <i className="fa fa-microphone"></i> Interview
            </div>
            <h1 className="article-interview-name">
                {post.title}{" "}
                <span className="article-interview-summary">
                    {post.summary}
                </span>
            </h1>
            <img
                className="interview-img"
                src={post.picture}
                alt={post.title}
            />
            <div
                dangerouslySetInnerHTML={{
                    __html: addSynchroneUrlToPictures(post.content),
                }}
            ></div>
        </Fragment>
    );
};

export default InterviewView;
