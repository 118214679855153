import { combineReducers } from "redux";

import {
    GET_TUTORIALS_SUCCESS,
    GET_PUBLIC_TUTORIALS_SUCCESS,
} from "actions/Tutorial";
import Tutorial from "entities/Tutorial";

export const entities = (state = { tutorials: {} }, action) => {
    switch (action.type) {
        case GET_TUTORIALS_SUCCESS:
        case GET_PUBLIC_TUTORIALS_SUCCESS:
            let tutorials = [];
            if (action.response.entities.tutorials) {
                let tutorialsArray = Object.values(
                    action.response.entities.tutorials
                );
                tutorialsArray.forEach((element) => {
                    tutorials.push(new Tutorial(element));
                });
            }
            return {
                ...state,
                tutorials: tutorials,
            };
        default:
            return state;
    }
};

export default combineReducers({
    entities,
});
