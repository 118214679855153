import React from "react";
import "./ContactMenu.scss";
import { NavLink } from "react-router-dom";
import { CONTACTS } from "config/Routes";
import { useSelector } from "react-redux";

const ContactMenu = (props) => {
    const me = useSelector((state) => state.user.me);
    const items = [];

    if (me.hasRole("ROLE_MY_CONTACTS")) {
        items.push({
            url: "/contacts/mes-interlocuteurs",
            label: "Mes interlocuteurs",
        });
    }

    // if (me.hasRole("ROLE_CONSULTING_TEAM")) {
    //     items.push({
    //         url: "/contacts/ma-consulting-team",
    //         label: "Ma consulting team",
    //     });
    // }

    if (me.hasRole("ROLE_INTERNAL_CONTACTS")) {
        items.push({
            url: CONTACTS,
            label: "Mes contacts",
        });
    }
    return (
        <div id="contact-menu">
            {items.map((item, key) => {
                return (
                    <div className="contact-menu-title" key={key}>
                        {item.label}
                    </div>
                );
            })}

            {me.hasRole("ROLE_CONSULTING_TEAM") && (
                <div className="contact-menu-navigation">
                    <NavLink
                        className="contact-menu-item"
                        to="/contacts/ma-consulting-team"
                        activeClassName="active"
                    >
                        Ma consulting team
                    </NavLink>
                    <NavLink
                        className="contact-menu-item"
                        to={CONTACTS}
                        activeClassName="active"
                    >
                        Corporate & business team
                    </NavLink>
                    {/* <div></div>
                    <div>Corporate & business team</div> */}
                </div>
            )}
        </div>
    );
};

export default ContactMenu;
