import { dispatchCustomEvent } from "events/EventManager";

export const subscribeToOverlayChange = callback => {
    document.addEventListener("changeOverlayContent", callback, false);
};

export const unsubscribeToOverlayChange = callback => {
    document.removeEventListener("changeOverlayContent", callback, false);
};

export const changeOverlayContent = component => {
    dispatchCustomEvent("changeOverlayContent", {
        component: component
    });
};
