import React, {Fragment, useEffect} from "react";
import moment from "moment";
import "moment/locale/fr";

const NewsFeed = (props) => {
    // moment.locale('fr', {
    //     relativeTime: {
    //         m: '1 min',
    //         mm: '%d min',
    //         h: '1 h',
    //         hh: '%d h',
    //         d: '1 j',
    //         dd: '%d j',
    //         M: '1 m',
    //         MM: '%d m',
    //         y: '1 a',
    //         yy: '%d a'
    //     },
    // });

    const translateDates = () => {
        const posts = document.querySelectorAll('.j-date');
        if (posts.length > 0) {
            posts.forEach((post) => {
                const postDate = moment(post.getAttribute('datetime'));
                if (post.classList.contains('j-twitter-date')) {
                    post.innerHTML = postDate.format("D MMM YY");
                } else {
                    // post.innerHTML = postDate.fromNow(true)
                    post.innerHTML = postDate.fromNow()
                }
            })
        } else {
            setTimeout(translateDates, 100)
        }
    }

    const loadJuicerJs = () => {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "juicer-js";
        script.src = "https://assets.juicer.io/embed.js";
        document.body.appendChild(script);
        script.addEventListener("load", translateDates);
    };

    useEffect(() => {
        loadJuicerJs()
    }, [])

    return (
        <Fragment>
            <div className="news-title">Mes actualités</div>
            <link href="https://assets.juicer.io/embed.css" media="all" rel="stylesheet" type="text/css"/>
            <ul className="juicer-feed" data-feed-id="synchronefr" data-overlay="false" data-origin="embed-code"></ul>

        </Fragment>
    );
};

export default NewsFeed;
