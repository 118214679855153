import React from "react";
import moment from "moment";
import "moment/locale/fr";

import "./Disclaimer.scss";
import { Link } from "react-router-dom";
import { isMobileOrTablet } from "../../utils/Utils";

const Disclaimer = (props) => {
    return (
        <div
            id="disclaimer"
            className={
                (props.login
                    ? "login-disclaimer"
                    : props.gdpr
                    ? "gdpr-disclaimer"
                    : props.isContractor ? "contractor-disclaimer" : "")
                + (props.reset ? "reset-disclaimer" : "")
            }
        >
            © Synchrone - {moment().format("YYYY")}
            {!isMobileOrTablet() && !props.login ? (
                <span>
                    {" "}
                    • <Link to="/rgpd">Mentions RGPD</Link>
                </span>
            ) : (
                ""
            )}
        </div>
    );
};

export default Disclaimer;
