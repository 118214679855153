import React, {useEffect} from "react";

import "./Error.scss";
import {Link} from "react-router-dom";

const Error = (props) => {
    const {code, label, reloadTimeout, btnLabel} = props;

    useEffect(() => {
        if (reloadTimeout) {
            setTimeout(() => {
                redirectHome()
            }, reloadTimeout);
        }
    }, []);

    const redirectHome = () => {
        window.location = "/"
    }

    return (
        <div id="page-container" className="not-found-container">
            <div className="container">
                {code && <div className="error-code">{code}.</div>}
                <div className="error-label">{label}</div>

                <div className="back-btn" onClick={redirectHome}>
                    {btnLabel ? btnLabel : "Retourner sur la page d'accueil"}
                </div>
            </div>
        </div>
    );
};

export default Error;
