import React, {useState} from "react";
import * as UserActions from "actions/User";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setAppLoading } from "actions/Common";

import "./RunAsUser.scss";

const RunAsUser = (props) => {
    const me = useSelector((state) => state.user.me);
    const dispatch = useDispatch();

    return (
        <div
            // to="/"
            onClick={(e) => {
                props.handleClick(props.user.username)
            }}

            onContextMenu={(e) => {
                e.preventDefault()
                props.handleRightClick(props.user)
            }}
            className={"run-as-user" + (props.user.isVisible ? "" : " inactive-user") + ` ${props.user.type}-user`}
        >
            {props.user.identity}&nbsp;
            {!props.favorite ? <span><i className={"fal fa-star"}></i></span> : <span><i className={"fa fa-star"} style={{color: "gold"}}></i></span>}
        </div>
    );
};

export default RunAsUser;
