import ExtraActivityHour from "./ExtraActivityHour";

class ExtraActivityDay {
    date;
    hours;

    constructor(json) {
        this.date = json.date;
        this.hours = []

        json.hours.map((hour) => {
            this.hours.push(new ExtraActivityHour(hour))
        })
    }

}

export default ExtraActivityDay;