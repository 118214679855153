import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";

import { monthInLetter } from "utils/Utils";

import "./TeamPlanningHeader.scss";

const TeamPlanningHeader = (props) => {
    const { month, year } = props;

    const changeMonth = (movement) => {
        const targetMonth = moment(`01-${month}-${year}`, "DD-MM-YYYY");

        if (movement === "previous") {
            targetMonth.subtract(1, "months");
        } else if (movement === "next") {
            targetMonth.add(1, "months");
        }

        return `/backend/team-planning/${
            targetMonth.month() + 1
        }/${targetMonth.year()}`;
    };

    return (
        <div className="team-planning-header">
            <Link to={changeMonth("previous")}>
                <i className="left-header far fa-chevron-left"></i>
            </Link>

            <span>
                <span className="month">{monthInLetter(month)} </span>
                <span className="year">{year} </span>
            </span>

            <Link to={changeMonth("next")}>
                <i className="right-header far fa-chevron-right"></i>
            </Link>
        </div>
    );
};

export default TeamPlanningHeader;
