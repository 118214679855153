import React from "react";
import { useSelector } from "react-redux";
import "./Loader.scss";

const Loader = props => {
    const loading = useSelector(state => state.common.isLoading);

    return (
        <div className={loading ? "lds-ripple" : "lds-ripple hidden"}>
            <div></div>
            <div></div>
        </div>
    );
};

export default Loader;
