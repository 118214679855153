import React from "react";
import Lightbox from "components/common/Lightbox";
import useClickOutside from "hooks/useClickOutside";
import usePressEscapeKey from "hooks/usePressEscapeKey";
import "./ContactLightbox.scss";
import { changeOverlayContent } from "events/OverlayEvents";

const ContactGroupLightbox = (props) => {
    const group = props.group;

    const close = () => {
        changeOverlayContent(null);
    };

    usePressEscapeKey(() => {
        close();
    });

    const ref = useClickOutside(() => {
        close();
    });

    const title = () => {
        let title;
        if (group.title) {
            title = group.title;
        }

        return <div className="contact-title">{title}</div>;
    };

    const pictures = () => {
        return (
            <div className="contact-pictures">
                {group.contacts.map((contact) => (
                    contact && <img src={contact.picture} alt={group.displayName && contact.identity} />
                ))}
            </div>
        );
    };

    const identities = () => {
        return (
            <div className="contact-identity">
                {group.contacts.map((contact) => contact.identity).join(", ")}
            </div>
        );
    };

    const line = (value, icon, type) => {
        let label = value;

        if (type) {
            label = <a href={type + ":" + value}>{value}</a>;
        }

        return (
            <div className="contact-line">
                <i className={"fal fa-" + icon}></i> {label}
            </div>
        );
    };

    const lines = () => {
        const lines = [];
        if (group.mobile) {
            lines.push(line(group.mobile, "mobile", "tel"));
        }
        if (group.phone) {
            lines.push(line(group.phone, "phone-office", "tel"));
        }
        if (group.email) {
            lines.push(line(group.email, "envelope-open", "mailto"));
        }

        return (
            <div className="contact-lines">
                {lines.map((item) => {
                    return item;
                })}
            </div>
        );
    };

    return (
        <Lightbox ref={ref} className="contact-lb">
            <div className="close-lb" onClick={close} />
            {title()}
            {pictures()}
            {group.displayName && identities()}
            <div className="contact-help">{group.text}</div>
            {lines()}
        </Lightbox>
    );
};

export default ContactGroupLightbox;
