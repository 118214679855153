import React, {Fragment, useEffect} from "react";

import moment from "moment";
import "moment/locale/fr";

import {
    HOUR_INPUT,
    TICKET_INPUT,
} from "components/TimesheetContainer/Forms/Event/StepActions/Step3Overtime";

import HourInputLine from "components/TimesheetContainer/Forms/Event/StepActions/HourInputLine";
import TicketInputLine from "components/TimesheetContainer/Forms/Event/StepActions/TicketInputLine";
import {FieldArray} from "formik";

const DayLine = (props) => {
    const onChange = props.onChange;
    const onBlur = props.onBlur;
    const values = props.values;
    const config = props.config;
    const missionSelected = props.missionSelected;
    const path = props.path ? props.path : 'days';
    const missions = props.missions ? props.missions : []
    const codes = props.codes ? props.codes : []
    const setFieldValue = props.setFieldValue

    const TotalHoursContainer = () => {
        let array = values.hours;
        let duration = 0;
        let durationString;
        array.forEach((element) => {
            if (element) {
                let startTime = moment.parseZone(element.start, "HH:mm");
                let endTime = moment.parseZone(element.end, "HH:mm");
                let addTime;
                if (endTime.format("HH:mm") === "00:00") {
                    addTime = 24 * 60 * 60 * 1000 + endTime.diff(startTime);
                } else {
                    addTime = endTime.diff(startTime);
                }
                if (!isNaN(addTime)) {
                    duration += addTime;
                }
            }
        });

        let hours = parseInt(moment.duration(duration).asHours());
        let minutes = parseInt(moment.duration(duration).asMinutes()) % 60;

        if (!isNaN(hours) && !isNaN(minutes) && duration > 0) {
            if (minutes < 10) {
                minutes = "0" + minutes;
            }

            durationString = hours + ":" + minutes;
        } else {
            durationString = "0:00";
        }

        return durationString;
    };

    useEffect(() => {
        if (missions.length === 1) {
            setFieldValue(`${path}[${props.id}].mission`, missions[0].id)
        }
    }, [missions])

    return (
        <div className="overtime-day-line">
            {missions.length > 0 ?
                (<select
                    className="extra-activities-mission"
                    name={`${path}[${props.id}].mission`}
                    value={values.mission}
                    onChange={onChange}
                    onBlur={onBlur}
                    // value={values.mission ? values.mission : (missions.length === 1 ? missions[0].id : '')}
                >
                        {missions.length > 1 && <option>-</option>}
                    {missions.map(mission => <option key={mission.id}
                                                     value={mission.id}>{mission.description}</option>)}
                </select>) :
                (path === "complementaries" ?
                        (config.canDisplayEventCodesForComplementaries() ?
                            <div className={"extra-activities-mission"}>&nbsp;</div> : "")
                        : ""
                )
            }
            <Fragment>
                <div className="hour-line-container">
                    <FieldArray
                        name={`${path}[${props.id}].hours`}
                        render={(arrayHelpers) =>
                            values.hours.map((hour, key) => (
                                <div className="hour-line" key={key}>
                                    <HourInputLine
                                        value={values.hours[key]}
                                        key={key}
                                        id={props.id}
                                        hour={key}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        path={path}
                                    />
                                    {codes.length > 0 ?
                                        <select
                                            className="extra-activities-code"
                                            name={`${path}[${props.id}].hours[${key}].code`}
                                            value={values.hours[key].code}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                        >
                                            <option value="">-</option>
                                            {codes.map(code => {
                                                let selectedMissionId;
                                                if (missionSelected) {
                                                    selectedMissionId = missionSelected;
                                                } else {
                                                    selectedMissionId = values.mission;
                                                }
                                                if (selectedMissionId && selectedMissionId !== '-') {
                                                    let selectedMission;
                                                    if (missions.length > 1) {
                                                        selectedMission = missions.find((mission) => mission.id == selectedMissionId)
                                                    } else {
                                                        selectedMission = missions;
                                                    }
                                                    if (selectedMission.length >= 1) {
                                                        selectedMission = selectedMission[0];
                                                    }
                                                    if (selectedMission && code.affectation === selectedMission.code) {
                                                        return <option key={code.id} value={code.id}>
                                                            {code.description}
                                                        </option>
                                                    }
                                                }
                                            })}
                                        </select> : (path === "complementaries" ?
                                                (config.canDisplayOvertimeCodesForComplementaries() ?
                                                    <div className={"extra-activities-code"}>&nbsp;</div> : "")
                                                : (config.canDisplayOvertimeCodesForConstraints() ?
                                                    <div className={"extra-activities-code"}>&nbsp;</div> : "")
                                        )
                                    }
                                    <div className="hour-input-actions">
                                        <i
                                            onClick={() => {
                                                arrayHelpers.insert(
                                                    key + 1,
                                                    {
                                                        start: "",
                                                        end: "",
                                                        code: ""
                                                    }
                                                );
                                            }}
                                            className="far fa-plus-circle"
                                        ></i>
                                        {values.hours.length > 1 ? (
                                            <i
                                                onClick={() => {
                                                    arrayHelpers.remove(
                                                        key
                                                    );
                                                }}
                                                className="far fa-times"
                                            ></i>
                                        ) : (
                                            <i
                                                onClick={() => {
                                                    arrayHelpers.remove(
                                                        key
                                                    )
                                                    arrayHelpers.push({
                                                        start: "",
                                                        end: "",
                                                        code: ""
                                                    });
                                                }}
                                                className="far fa-trash"
                                            ></i>
                                        )}
                                    </div>
                                </div>
                            ))
                        }
                    />
                </div>
                <div
                    className={"total-hours-container" + (path === "constraints" ? " constraint-total-hours-container" : "")}>
                    <div style={{
                        height: (20 + (40 * (values.hours.length - 1))) + "px",
                        lineHeight: (20 + (40 * (values.hours.length - 1))) + "px"
                    }}>
                        <TotalHoursContainer/>
                    </div>
                </div>
            </Fragment>
        </div>
    );
};

export default DayLine;
