import store from "store/ConfigureStore";
import Month from "entities/Month";
import { isEmpty } from "utils/Utils";

class MonthRepository {
    findCurrentMonth = () => {
        let state = store.getState().timesheet.entities.month;

        if (!isEmpty(state)) {
            let month = Object.values(state)[0];
            return month;
        }

        return;
    };
}

export default MonthRepository;
