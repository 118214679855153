import User from "./User";
import EventCode from "./EventCode";
import OvertimeCode from "./OvertimeCode";
import moment from "moment";

class ExtraActivityConfig {
    complementaries;
    constraints;
    missingSide;

    constructor(json) {
        let complementaries = []
        json.complementaries.map(day => {
            let eventCodes = []
            day.event_codes.map(eventCode => {
                eventCodes.push(new EventCode(eventCode))
            })
            let overtimeCodes = []
            day.overtime_codes.map(overtimeCode => {
                overtimeCodes.push(new OvertimeCode(overtimeCode))
            })
            let dayData = {
                "date": day.date,
                "display_event_codes": day.display_event_codes,
                "display_overtime_codes": day.display_overtime_codes,
                "fillable": day.fillable,
                "event_codes": eventCodes,
                "overtime_codes": overtimeCodes,
            }
            complementaries.push(dayData)
        })

        this.complementaries = complementaries;

        let constraints = []

        json.constraints.map(day => {
            let eventCode = null
            if (day.event_code != null) {
                eventCode = new EventCode(day.event_code)
            }
            let overtimeCodes = []
            day.overtime_codes.map(overtimeCode => {
                overtimeCodes.push(new OvertimeCode(overtimeCode))
            })
            let dayData = {
                "date": day.date,
                "display_event_codes": day.display_event_codes,
                "display_overtime_codes": day.display_overtime_codes,
                "fillable": day.fillable,
                "event_code": eventCode,
                "overtime_codes": overtimeCodes,
            }
            constraints.push(dayData)
        })

        this.constraints = constraints;
    }

    canComplementary(date) {
        return this.complementaries.find(day => day.date == date).fillable
    }

    canConstraint(date) {
        return this.constraints.find(day => day.date == date).fillable
    }

    getComplementaryEventCodes(date) {
        return this.complementaries.find(day => day.date == date).event_codes
    }

    getComplementaryOvertimeCodes(date) {
        return this.complementaries.find(day => day.date == date).overtime_codes
    }

    getConstraintOvertimeCodes(date) {
        return this.constraints.find(day => day.date == date).overtime_codes
    }

    getConstraintEventCode(date) {
        return this.constraints.find(day => day.date == date).event_code
    }

    canDisplayEventCodesForComplementaries() {
        let canDisplay = false;
        this.complementaries.map((day) => {
            if (day.display_event_codes) {
                canDisplay = true;
            }
        })

        return canDisplay;
    }

    canDisplayOvertimeCodesForComplementaries() {
        let canDisplay = false;
        this.complementaries.map((day) => {
            if (day.display_overtime_codes) {
                canDisplay = true;
            }
        })

        return canDisplay;
    }

    canDisplayEventCodesForConstraints() {
        let canDisplay = false;
        this.constraints.map((day) => {
            if (day.display_event_codes) {
                canDisplay = true;
            }
        })

        return canDisplay;
    }

    canDisplayOvertimeCodesForConstraints() {
        let canDisplay = false;
        this.constraints.map((day) => {
            if (day.display_overtime_codes) {
                canDisplay = true;
            }
        })

        return canDisplay;
    }

    buildDefaultValues() {
        let values = {
            comment: "",
            complementaries: [],
            constraints: []
        }
        this.complementaries.map(day => {
            if (day.fillable) {
                values.complementaries.push({
                    date: day.date,
                    hours: [{start: "", end: "", code: ""}],
                    mission: ""
                })
            }
        })

        this.constraints.map(day => {
            if (day.fillable) {
                values.constraints.push({
                    date: day.date,
                    hours: [{start: "", end: "", code: ""}],
                    mission: ""
                })
            }
        })

        return values
    }

    buildForm(values) {
        let initialValues = this.buildDefaultValues()
        if (values.id) {
            initialValues.comment = values.comment

            values.complementaries.map((day) => {
                let dayToFind = initialValues.complementaries.find((element) => element.date === day.date)
                if (dayToFind) {
                    dayToFind.hours = day.hours
                }
                let complementary = this.complementaries.find((element) => element.date === day.date)
                if (complementary) {
                    let affectationToFind = complementary.overtime_codes.find((overtimeCode) => {
                        return overtimeCode.id === dayToFind.hours[0].code
                    })
                    if (complementary.event_codes && affectationToFind) {
                        dayToFind.mission = complementary.event_codes.find((eventCode) => eventCode.code === affectationToFind.affectation).id;
                    }
                }

            })

            values.constraints.map((day) => {
                let dayToFind = initialValues.constraints.find((element) => element.date === day.date)
                if (dayToFind) {
                    dayToFind.hours = day.hours
                }
                let constraint = this.constraints.find((element) => element.date === day.date)
                if (constraint) {
                    let affectationToFind = constraint.overtime_codes.find((overtimeCode) => {
                        return overtimeCode.id === dayToFind.hours[0].code
                    })
                    if (constraint.event_codes && affectationToFind) {
                        dayToFind.mission = constraint.event_codes.find((eventCode) => eventCode.code === affectationToFind.affectation).id;
                    }
                }
            })
        }

        return initialValues
    }

    firstDay() {
        const days = this.complementaries
        const [first] = days
        let weekNumber = moment(first.date).isoWeekday(1)
        return weekNumber.parseZone()
    }

    lastDay() {
        const days = this.complementaries
        let temp = [...days]
        return moment(temp.pop().date).isoWeekday(7).parseZone()
    }

    canDisplayComplementaries() {
        let canDisplayComplementaries = false;
        this.complementaries.map((day) => {
            if (day.fillable) {
                canDisplayComplementaries = true;
            }
        })

        return canDisplayComplementaries;
    }

    canDisplayConstraints() {
        let canDisplayConstraints = false;
        this.constraints.map((day) => {
            if (day.fillable) {
                canDisplayConstraints = true;
            }
        })

        return canDisplayConstraints;
    }
}

export default ExtraActivityConfig;
