import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as DocumentActions from "actions/Document";

const LinkItem = (props) => {
    const { item } = props;
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(DocumentActions.resetSelectedUnion());
    };

    return (
        <Link
            className="file-row line-container union-row"
            to={`/documents/syndicats/${item.id}`}
            target="_blank"
            onClick={handleClick}
        >
            {item.label}
        </Link>
    );
};

export default LinkItem;
