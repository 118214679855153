import { useRef, useEffect } from "react";

function useClickOutside(callback) {
    const ref = useRef(null);

    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            callback.call();
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    return ref;
}

export default useClickOutside;
