import { Schemas } from "middleware/Schemas";
import * as Params from "config/Parameters";

import { setLoading } from "actions/Common";
import { apiOptions } from "utils/Api";

export const GET_CURRENT_PERIOD_REQUEST = "GET_CURRENT_PERIOD_REQUEST";
export const GET_CURRENT_PERIOD_SUCCESS = "GET_CURRENT_PERIOD_SUCCESS";
export const GET_CURRENT_PERIOD_FAILURE = "GET_CURRENT_PERIOD_FAILURE";
export const getCurrentPeriod = () => {
    return {
        types: [
            GET_CURRENT_PERIOD_REQUEST,
            GET_CURRENT_PERIOD_SUCCESS,
            GET_CURRENT_PERIOD_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}timesheet/period/current`,
                apiOptions("GET")
            );
        },
        payload: {},
        schema: Schemas.PERIOD_LITE,
    };
};
