import React, { useState } from "react";
import "./SsctItem.scss";

const SsctItem = (props) => {
    const { item } = props;

    return (
        <div className="ssct-item">
            <div className="ssct-title">{item.title}</div>
            <div className="ssct-contact">
                <div className="ssct-persons">
                    {item.persons.map((person, key) => {
                        return <div key={key}>{person}</div>;
                    })}
                </div>
                <div className="separator">
                    <div className="triangle"></div>
                </div>
                <div className="ssct-emails">
                    <i className="fal fa-envelope-open"></i>
                    <div className="ssct-emails-content">
                        {item.emails.map((email, key) => {
                            return (
                                <a href={`mailto:${email}`} key={key}>
                                    {email}
                                </a>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SsctItem;
