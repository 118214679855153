import React from "react";

import { STEP1B } from "components/TimesheetContainer/Forms/Event/Edit";
import Button from "../Button";

const Step1BActions = (props) => {
    const { nextStep, step, availableMissions, setSelectedMission, setConstraintCategory } = props;

    const handleClick = (item) => {
        setSelectedMission(item);
        setConstraintCategory("availability");
        nextStep("hours-not-worked", true);
    };

    return (
        <div
            className={
                (step !== STEP1B ? "hidden " : "") +
                "step1b-actions event-code-buttons"
            }
        >
            {availableMissions.map((element, key) => {
                return (
                    <Button
                        key={key}
                        text={element.description}
                        type={element.type}
                        shortname={element.shortCode}
                        onClick={() => handleClick(element)}
                        // additionnalStyle={{
                        //     lineHeight: "22.5px"
                        //         // element.description.length > 43
                        //         //     ? "22.5px"
                        //         //     : "45px",
                        // }}
                    />
                );
            })}
        </div>
    );
};

export default Step1BActions;
