class HalfDay {
    id;
    event;
    moment;
    workingState;
    dateAt;

    constructor(json) {
        this.id = json.id;
        this.event = json.event;
        this.moment = json.moment;
        this.workingState = json.working_state;
        this.dateAt = json.date_at;
    }

    get worked() {
        return this.workingState === "worked"
    }
}

export default HalfDay;
