import React, { useEffect, useState } from "react";
import { MY_SYNCHRONE_URL as baseUrl } from "config/Url.js";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as ValidationActions from "actions/Validation";
import * as UserActions from "actions/User";
import {monthInLetter, needTokenRefresh} from "utils/Utils";
import { refreshToken } from "actions/Common";

const Remote = () => {
    const [targetYear, setTargetYear] = useState(
        moment.parseZone().format("YYYY")
    );
    const dispatch = useDispatch();
    const periods = useSelector((state) => state.validation.periods);
    const today = moment.parseZone();

    useEffect(() => {
        dispatch(ValidationActions.fetchPeriods());
        dispatch(UserActions.fetchUserList());
    }, []);

    const PeriodRow = (props) => {
        const { period } = props;

        return (
            <div className="period-row">
                <div className="period-row-month">
                    {monthInLetter(period.month)}
                </div>
                <div className="period-row-remote-workers">
                    {period.remoteWorkers}
                </div>
                <div className="period-row-remote-days">
                    {period.remoteDays}
                </div>
                <div className="period-row-remote-actions">
                    {period.remoteDays > 0 && (
                        <span
                            className="download-report-action"
                            title="Télécharger"
                            onClick={() => {
                                if (needTokenRefresh()) {
                                    refreshToken().then(() => {
                                        window.open(baseUrl + period.remoteFileUrl);
                                    });
                                } else {
                                    window.open(baseUrl + period.remoteFileUrl);
                                }
                            }}
                        >
                            <i className="fas fa-download"></i> Télécharger
                        </span>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="period-container">
            <div className="year">
                <span className="previous-year-btn">
                    {periods.filter(
                        (element, key) =>
                            parseInt(element.year) === parseInt(targetYear - 1)
                    ).length > 0 ? (
                        <i
                            className="fas fa-chevron-left"
                            onClick={() => setTargetYear(targetYear - 1)}
                        ></i>
                    ) : (
                        <i className="fas fa-chevron-left unavailable"></i>
                    )}
                </span>
                <span className="current-year">{targetYear}</span>
                <span className="next-year-btn">
                    {periods.filter(
                        (element, key) =>
                            parseInt(element.year) === parseInt(targetYear + 1)
                    ).length > 0 ? (
                        <i
                            className="fas fa-chevron-right"
                            onClick={() => setTargetYear(targetYear + 1)}
                        ></i>
                    ) : (
                        <i className="fas fa-chevron-right unavailable"></i>
                    )}
                </span>
            </div>
            <div className="period-container-table">
                <div className="period-container-table-header">
                    <div className="period-header-month">Période</div>
                    <div className="period-header-remote-workers">
                        Nombre de collaborateurs en télétravail
                    </div>
                    <div className="period-header-remote-days">
                        Nombre de jours télétravaillés
                    </div>
                    <div className="period-header-remote-actions">Actions</div>
                </div>
                {periods
                    .filter(
                        (element, key) =>
                            parseInt(element.year) === parseInt(targetYear)
                    )
                    .sort((a, b) => b.month - a.month)
                    .map((element, key) => {
                        return <PeriodRow key={key} period={element} />;
                    })}
            </div>
        </div>
    );
};

export default Remote;
