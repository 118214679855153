import { Schemas } from "middleware/Schemas";
import * as Params from "config/Parameters";

import { setLoading } from "actions/Common";
import { apiOptions } from "utils/Api";

export const FETCH_UNIONS_REQUEST = "FETCH_UNIONS_REQUEST";
export const FETCH_UNIONS_SUCCESS = "FETCH_UNIONS_SUCCESS";
export const FETCH_UNIONS_FAILURE = "FETCH_UNIONS_FAILURE";
export const fetchUnions = () => {
    return {
        types: [
            FETCH_UNIONS_REQUEST,
            FETCH_UNIONS_SUCCESS,
            FETCH_UNIONS_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(`${Params.API_ROOT}syndic/`, apiOptions("GET"));
        },
        schema: Schemas.UNION_ARRAY,
    };
};

export const FETCH_UNION_REQUEST = "FETCH_UNION_REQUEST";
export const FETCH_UNION_SUCCESS = "FETCH_UNION_SUCCESS";
export const FETCH_UNION_FAILURE = "FETCH_UNION_FAILURE";
export const fetchUnion = (id) => {
    return {
        types: [FETCH_UNION_REQUEST, FETCH_UNION_SUCCESS, FETCH_UNION_FAILURE],
        needLogin: true,
        callAPI: () => {
            return fetch(`${Params.API_ROOT}syndic/${id}`, apiOptions("GET"));
        },
        schema: Schemas.UNION,
    };
};

export const FETCH_DOCUMENTS_REQUEST = "FETCH_DOCUMENTS_REQUEST";
export const FETCH_DOCUMENTS_SUCCESS = "FETCH_DOCUMENTS_SUCCESS";
export const FETCH_DOCUMENTS_FAILURE = "FETCH_DOCUMENTS_FAILURE";
export const fetchDocuments = () => {
    return {
        types: [
            FETCH_DOCUMENTS_REQUEST,
            FETCH_DOCUMENTS_SUCCESS,
            FETCH_DOCUMENTS_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(`${Params.API_ROOT}document/`, apiOptions("GET"));
        },
        schema: Schemas.SECTIONS,
    };
};

export const DOWNLOAD_UNION_DOCUMENT_REQUEST =
    "DOWNLOAD_UNION_DOCUMENT_REQUEST";
export const DOWNLOAD_UNION_DOCUMENT_SUCCESS =
    "DOWNLOAD_UNION_DOCUMENT_SUCCESS";
export const DOWNLOAD_UNION_DOCUMENT_FAILURE =
    "DOWNLOAD_UNION_DOCUMENT_FAILURE";
export const downloadUnionDocument = (id) => {
    return {
        types: [
            DOWNLOAD_UNION_DOCUMENT_REQUEST,
            DOWNLOAD_UNION_DOCUMENT_SUCCESS,
            DOWNLOAD_UNION_DOCUMENT_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(`${Params.API_ROOT}syndic/${id}`, apiOptions("GET"));
        },
        schema: Schemas.FILE,
    };
};

export const UPLOAD_DOCUMENT_TO_CATEGORY_REQUEST =
    "UPLOAD_DOCUMENT_TO_CATEGORY_REQUEST";
export const UPLOAD_DOCUMENT_TO_CATEGORY_SUCCESS =
    "UPLOAD_DOCUMENT_TO_CATEGORY_SUCCESS";
export const UPLOAD_DOCUMENT_TO_CATEGORY_FAILURE =
    "UPLOAD_DOCUMENT_TO_CATEGORY_FAILURE";
export const uploadDocumentToCategory = (categoryId, formData) => {
    return {
        types: [
            UPLOAD_DOCUMENT_TO_CATEGORY_REQUEST,
            UPLOAD_DOCUMENT_TO_CATEGORY_SUCCESS,
            UPLOAD_DOCUMENT_TO_CATEGORY_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}document/category/${categoryId}`,
                apiOptions("POST", formData, true)
            );
        },
        schema: Schemas.FILE,
    };
};

export const UPLOAD_DOCUMENT_TO_FOLDER_REQUEST =
    "UPLOAD_DOCUMENT_TO_FOLDER_REQUEST";
export const UPLOAD_DOCUMENT_TO_FOLDER_SUCCESS =
    "UPLOAD_DOCUMENT_TO_FOLDER_SUCCESS";
export const UPLOAD_DOCUMENT_TO_FOLDER_FAILURE =
    "UPLOAD_DOCUMENT_TO_FOLDER_FAILURE";
export const uploadDocumentToFolder = (folderId, formData) => {
    return {
        types: [
            UPLOAD_DOCUMENT_TO_FOLDER_REQUEST,
            UPLOAD_DOCUMENT_TO_FOLDER_SUCCESS,
            UPLOAD_DOCUMENT_TO_FOLDER_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}document/folder/${folderId}`,
                apiOptions("POST", formData, true)
            );
        },
        schema: Schemas.FILE,
    };
};

export const UPLOAD_UNION_DOCUMENT_REQUEST = "UPLOAD_UNION_DOCUMENT_REQUEST";
export const UPLOAD_UNION_DOCUMENT_SUCCESS = "UPLOAD_UNION_DOCUMENT_SUCCESS";
export const UPLOAD_UNION_DOCUMENT_FAILURE = "UPLOAD_UNION_DOCUMENT_FAILURE";
export const uploadUnionDocument = (unionId, formData) => {
    return {
        types: [
            UPLOAD_UNION_DOCUMENT_REQUEST,
            UPLOAD_UNION_DOCUMENT_SUCCESS,
            UPLOAD_UNION_DOCUMENT_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}syndic/file/${unionId}`,
                apiOptions("POST", formData, true)
            );
        },
        schema: Schemas.FILE,
    };
};

export const UPDATE_FILE_LABEL_REQUEST = "UPDATE_FILE_LABEL_REQUEST";
export const UPDATE_FILE_LABEL_SUCCESS = "UPDATE_FILE_LABEL_SUCCESS";
export const UPDATE_FILE_LABEL_FAILURE = "UPDATE_FILE_LABEL_FAILURE";
export const updateFileLabel = (fileId, json, type) => {
    return {
        types: [
            UPDATE_FILE_LABEL_REQUEST,
            UPDATE_FILE_LABEL_SUCCESS,
            UPDATE_FILE_LABEL_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            if (type === "union") {
                return fetch(
                    `${Params.API_ROOT}syndic/file/${fileId}`,
                    apiOptions("PATCH", json)
                );
            } else {
                return fetch(
                    `${Params.API_ROOT}document/${fileId}`,
                    apiOptions("PATCH", json)
                );
            }
        },
        schema: Schemas.FILE,
    };
};

export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE";
export const createCategory = (json) => {
    return {
        types: [
            CREATE_CATEGORY_REQUEST,
            CREATE_CATEGORY_SUCCESS,
            CREATE_CATEGORY_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}document/category/`,
                apiOptions("POST", json)
            );
        },
        schema: Schemas.FILE,
    };
};

export const CREATE_FOLDER_REQUEST = "CREATE_FOLDER_REQUEST";
export const CREATE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS";
export const CREATE_FOLDER_FAILURE = "CREATE_FOLDER_FAILURE";
export const createFolder = (json) => {
    return {
        types: [
            CREATE_FOLDER_REQUEST,
            CREATE_FOLDER_SUCCESS,
            CREATE_FOLDER_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}document/folder/`,
                apiOptions("POST", json)
            );
        },
        schema: Schemas.FILE,
    };
};

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";
export const deleteCategory = (categoryId) => {
    return {
        types: [
            DELETE_CATEGORY_REQUEST,
            DELETE_CATEGORY_SUCCESS,
            DELETE_CATEGORY_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}document/category/${categoryId}`,
                apiOptions("DELETE")
            );
        },
        schema: Schemas.FILE,
    };
};

export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";
export const deleteFile = (fileId, type) => {
    return {
        types: [DELETE_FILE_REQUEST, DELETE_FILE_SUCCESS, DELETE_FILE_FAILURE],
        needLogin: true,
        callAPI: () => {
            if (type === "union") {
                return fetch(
                    `${Params.API_ROOT}syndic/file/${fileId}`,
                    apiOptions("DELETE")
                );
            } else {
                return fetch(
                    `${Params.API_ROOT}document/${fileId}`,
                    apiOptions("DELETE")
                );
            }
        },
        schema: Schemas.FILE,
    };
};

export const DELETE_FOLDER_REQUEST = "DELETE_FOLDER_REQUEST";
export const DELETE_FOLDER_SUCCESS = "DELETE_FOLDER_SUCCESS";
export const DELETE_FOLDER_FAILURE = "DELETE_FOLDER_FAILURE";
export const deleteFolder = (folderId) => {
    return {
        types: [
            DELETE_FOLDER_REQUEST,
            DELETE_FOLDER_SUCCESS,
            DELETE_FOLDER_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}document/folder/${folderId}`,
                apiOptions("DELETE")
            );
        },
        schema: Schemas.FILE,
    };
};

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";
export const updateCategory = (categoryId, json) => {
    return {
        types: [
            UPDATE_CATEGORY_REQUEST,
            UPDATE_CATEGORY_SUCCESS,
            UPDATE_CATEGORY_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}document/category/${categoryId}`,
                apiOptions("PATCH", json)
            );
        },
        schema: Schemas.FILE,
    };
};

export const UPDATE_FOLDER_REQUEST = "UPDATE_FOLDER_REQUEST";
export const UPDATE_FOLDER_SUCCESS = "UPDATE_FOLDER_SUCCESS";
export const UPDATE_FOLDER_FAILURE = "UPDATE_FOLDER_FAILURE";
export const updateFolder = (folderId, json) => {
    return {
        types: [
            UPDATE_FOLDER_REQUEST,
            UPDATE_FOLDER_SUCCESS,
            UPDATE_FOLDER_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}document/folder/${folderId}`,
                apiOptions("PATCH", json)
            );
        },
        schema: Schemas.FILE,
    };
};

export const UPDATE_UNION_REQUEST = "UPDATE_UNION_REQUEST";
export const UPDATE_UNION_SUCCESS = "UPDATE_UNION_SUCCESS";
export const UPDATE_UNION_FAILURE = "UPDATE_UNION_FAILURE";
export const updateUnion = (unionId, json) => {
    return {
        types: [
            UPDATE_UNION_REQUEST,
            UPDATE_UNION_SUCCESS,
            UPDATE_UNION_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}syndic/${unionId}`,
                apiOptions("PATCH", json)
            );
        },
        schema: Schemas.UNION_ARRAY,
    };
};

export const MOVE_ELEMENT_REQUEST = "MOVE_ELEMENT_REQUEST";
export const MOVE_ELEMENT_SUCCESS = "MOVE_ELEMENT_SUCCESS";
export const MOVE_ELEMENT_FAILURE = "MOVE_ELEMENT_FAILURE";
export const moveElement = (json) => {
    return {
        types: [
            MOVE_ELEMENT_REQUEST,
            MOVE_ELEMENT_SUCCESS,
            MOVE_ELEMENT_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}document/sort`,
                apiOptions("PATCH", json)
            );
        },
        schema: Schemas.FILE,
    };
};

export const FETCH_SOCIAL_DOCUMENTS_REQUEST = "FETCH_SOCIAL_DOCUMENTS_REQUEST";
export const FETCH_SOCIAL_DOCUMENTS_SUCCESS = "FETCH_SOCIAL_DOCUMENTS_SUCCESS";
export const FETCH_SOCIAL_DOCUMENTS_FAILURE = "FETCH_SOCIAL_DOCUMENTS_FAILURE";
export const fetchSocialDocuments = () => {
    return {
        types: [
            FETCH_SOCIAL_DOCUMENTS_REQUEST,
            FETCH_SOCIAL_DOCUMENTS_SUCCESS,
            FETCH_SOCIAL_DOCUMENTS_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(`${Params.API_ROOT}social/`, apiOptions("GET"));
        },
        schema: Schemas.SOCIAL,
    };
};

export const RESET_SELECTED_UNION = "RESET_SELECTED_UNION";
export const resetSelectedUnion = () => ({
    type: RESET_SELECTED_UNION,
});
