class OvertimeCode {
    id;
    code;
    description;
    affectation;
    startAt;
    endAt;
    category;
    subCategory;
    constraint;
    hour;
    ticket;
    user;
    active;

    constructor(json) {
        this.id = json.id;
        this.code = json.code;
        this.description = json.description;
        this.affectation = json.affectation;
        this.startAt = json.start_at;
        this.endAt = json.end_at;
        this.category = json.category;
        this.subCategory = json.sub_category;
        this.constraint = json.constraint;
        this.hour = json.hour;
        this.ticket = json.ticket;
        this.user = json.user;
        this.active = json.active;
    }
}

export default OvertimeCode;
