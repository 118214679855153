class DoctorItem {
    name;
    type;
    doctor;
    phones;
    emails;
    address;
    itemType;

    constructor(json, itemType) {
        this.itemType = itemType;
        this.name = json.name;
        this.type = json.type;
        this.doctor = json.doctor;
        let phonesArray = [];
        let emailsArray = [];
        json.phones.map((phone) => {
            phonesArray.push(phone);
        });
        json.emails.map((email) => {
            emailsArray.push(email);
        });
        this.phones = phonesArray;
        this.emails = emailsArray;
        this.address = json.address;
    }
}

export default DoctorItem;
