import React from "react";

import { changeOverlayContent } from "events/OverlayEvents";
import DepositCRAContainer from "./DepositCRAContainer";

import "./DepositSpecificCRAButton.scss";

const DepositSpecificCRAButton = (props) => {
    const { monthEntity } = props;

    const handleClick = () => {
        changeOverlayContent(<DepositCRAContainer monthEntity={monthEntity} />);
    };

    return (
        <div onClick={handleClick} className={"deposit-specific-cra-btn"}>
            Déposer le CRA spécifique
        </div>
    );
};

export default DepositSpecificCRAButton;
