import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const ZoomView = (props) => {
    const post = props.post;
    return (
        <Fragment>
            {post.url ? (
                <Link target="_blank">
                    <img
                        className="marketing-img"
                        src={post.secondaryPicture}
                        alt={post.title}
                    />
                </Link>
            ) : (
                <img
                    className="marketing-img"
                    src={post.secondaryPicture}
                    alt={post.title}
                />
            )}
        </Fragment>
    );
};

export default ZoomView;
