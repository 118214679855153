import { MY_SYNCHRONE_URL as baseUrl } from "config/Url.js";

class OrganizationChart {
    url;
    label;

    constructor(json) {
        this.url = json.url;
        this.label = json.label;
    }

    get downloadUrl() {
        return baseUrl + this.url;
    }
}

export default OrganizationChart;
