import {useSelector} from "react-redux";
import OvertimeCode from "../entities/OvertimeCode";

export const VALIDATION = "validation"
export const TIMESHEET = "timesheet"

function useOvertime(context) {
    const eventCodes = useSelector((state) => context === VALIDATION ? state.validation.craEventCodes : state.timesheet.entities.eventCodes)
    const overtimeCodes = useSelector((state) => context === VALIDATION ? state.validation.craOvertimeCodes : state.timesheet.entities.overtimeCodes)

    const getOvertimeCode = (id) => {
        return overtimeCodes.find(overtimeCode => overtimeCode.id === id)
    }

    const getMission = (id) => {
        const overtimeCode = getOvertimeCode(id)
        if (overtimeCode) {
            return eventCodes.find(eventCode => overtimeCode.affectation === eventCode.code)
        }
        return null
    }

    return {
        getOvertimeCode: getOvertimeCode,
        getMission: getMission
    }
}

export default useOvertime;
