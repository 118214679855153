import React from "react";
import { useState } from "react";

const InputText = props => {
    const [values, setValues] = useState({
        value: ""
    });

    function handleChange(event) {
        setValues({ value: event.target.value });
        props.onChange(event);
    }

    return (
        <div className="field-container">
            <input
                type={props.type || "text"}
                value={values.value}
                name={props.name}
                placeholder={props.placeholder}
                onChange={handleChange}
                className={props.additionalClassnames}
            />
        </div>
    );
};

export default InputText;
