import ContactItem from "entities/Social/ContactItem";

class HarassmentItem {
    contacts;
    itemType;

    constructor(json, itemType) {
        let contactsArray = [];
        this.itemType = itemType;
        json.contacts.map((contact) => {
            contactsArray.push(new ContactItem(contact, itemType));
        });
        this.contacts = contactsArray;
    }
}

export default HarassmentItem;
