import React, { useEffect, useState } from "react";
import "./Header.scss";

import Menu from "components/common/Header/Menu/Menu";
import { Link } from "react-router-dom";
import { logout, isDocumentsManager } from "utils/Utils";
import { useSelector, useDispatch } from "react-redux";

const Header = (props) => {
    const me = useSelector((state) => state.user.me);
    const [canManageDocuments, setCanManageDocuments] = useState(false);

    useEffect(() => {
        setCanManageDocuments(isDocumentsManager(me));
    }, [me]);

    return (
        <header className={"header overlay-adjustable" + (me.type !== "contractor" && " header-box-shadow")}>
            <Menu />

            <Link
                to="/"
                className={me.type === "contractor" ? "logo-contractor" : "logo-synchrone"}
                title="Retour à l'accueil"
            ></Link>
            {me.loaded ? (
                <div className="user-block">
                    <div className="user-identity">Hello, {me.identity}</div>
                </div>
            ) : (
                ""
            )}
            {(me.hasRole("ROLE_VALIDATION") || canManageDocuments) && (
                <Link
                    to="/backend/"
                    title="Administration"
                    className={
                        (props.backend ? "selected " : "") +
                        "administration-link"
                    }
                    target={
                        props.backend || process.env.NODE_ENV === "development"
                            ? ""
                            : "_blank"
                    }
                >
                    <i className="fal fa-user-check"></i> Administration
                </Link>
            )}
        </header>
    );
};

export default Header;
