import React from "react";
import moment from "moment";
import "moment/locale/fr";

import "./AbsenceBlock.scss";

const AbsenceBlock = (props) => {
    const AbsenceGroup = (props) => {
        const { title, events, user } = props;

        const AbsenceLine = (props) => {
            const { event, user } = props;

            const eventDescription = () => {
                let description = "";
                if (event.startAt === event.endAt) {
                    description += `Le ${moment
                        .parseZone(event.startAt)
                        .format("DD/MM/YYYY")}`;
                    if (event.daysCount === 0.5) {
                        if (event.startMoment === "am") {
                            description += " matin";
                        } else if (event.startMoment === "pm") {
                            description += " après-midi";
                        }
                    }
                } else {
                    description += `Du ${moment
                        .parseZone(event.startAt)
                        .format("DD/MM/YYYY")}`;
                    if (event.startMoment === "pm") {
                        description += " midi";
                    }
                    description += ` au ${moment
                        .parseZone(event.endAt)
                        .format("DD/MM/YYYY")}`;
                    if (event.endMoment === "am") {
                        description += " midi";
                    }
                }
                return description;
            };

            const hasEnoughVacations = () => {
                let relevantCounter = event.eventCode.relevant_counter;
                let hasEnoughVacations = true;

                switch (relevantCounter) {
                    case "cp":
                        if (event.state === "submitted") {
                            if (user.cpManager < event.daysCount) {
                                hasEnoughVacations = false;
                            }
                        } else if (event.state === "validated1") {
                            if (user.cpPayManager < event.daysCount) {
                                hasEnoughVacations = false;
                            }
                        }
                        break;

                    case "rtt":
                        if (event.state === "submitted") {
                            if (user.rttManager < event.daysCount) {
                                hasEnoughVacations = false;
                            }
                        } else if (event.state === "validated1") {
                            if (user.rttPayManager < event.daysCount) {
                                hasEnoughVacations = false;
                            }
                        }
                        break;

                    case "remainder":
                        if (event.state === "submitted") {
                            if (user.remainderManager < event.daysCount) {
                                hasEnoughVacations = false;
                            }
                        } else if (event.state === "validated1") {
                            if (user.remainderPayManager < event.daysCount) {
                                hasEnoughVacations = false;
                            }
                        }
                        break;

                    default:
                        break;
                }

                return hasEnoughVacations;
            };

            return (
                <div className="absence-validation-event-container">
                    {event.backwarded && (
                        <i
                            className="backwarded-icon far fa-repeat"
                            title="A subi un retour en arrière"
                        ></i>
                    )}
                    {eventDescription()}
                    {!hasEnoughVacations() && (
                        <i
                            className="fal fa-exclamation-square insufficient-vacations-icon"
                            title="Solde Insuffisant"
                        ></i>
                    )}
                </div>
            );
        };

        return (
            <div className="absence-block-group">
                <div className="absence-group-title">{title}</div>
                <div className="absence-block-content">
                    {events
                        .sort((a, b) => {
                            return (
                                moment.parseZone(a.startAt) -
                                moment.parseZone(b.startAt)
                            );
                        })
                        .map((event, key) => {
                            return (
                                <AbsenceLine
                                    key={key}
                                    event={event}
                                    user={user}
                                />
                            );
                        })}
                </div>
            </div>
        );
    };

    return (
        <div className="absence-block">
            {props.absenceGroups &&
                Object.entries(props.absenceGroups).map((element, key) => {
                    return (
                        <AbsenceGroup
                            key={key}
                            title={element[0]}
                            events={element[1]}
                            user={props.user}
                        />
                    );
                })}
        </div>
    );
};

export default AbsenceBlock;
