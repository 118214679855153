import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import FilterContainer from "../FilterContainer";
import * as ValidationActions from "actions/Validation";
import * as UserActions from "actions/User";
import {setLoading} from "actions/Common";

import "./ExtraActivitiesRequestsContainer.scss"
import ExtraActivityRow from "./ExtraActivityRow";
import {load} from "../../../utils/LoadDynamicScript";

const PENDING_EXTRA_ACTIVITIES = "pending";
const ACCEPTED_EXTRA_ACTIVITIES = "accepted";
const REFUSED_EXTRA_ACTIVITIES = "refused";

const ExtraActivitiesRequestsContainer = (props) => {
    const {me} = props;
    const dispatch = useDispatch()
    const [currentPeriodIndex, setCurrentPeriodIndex] = useState(0);
    const [page, setPage] = useState(1);
    const [needPeriodUpdate, setNeedPeriodUpdate] = useState(false);
    const [periods, setPeriods] = useState([]);
    const today = moment.parseZone().subtract(1, "months");
    const [rows, setRows] = useState([]);
    const [minDate, setMinDate] = useState(
        me.currentPeriod
            ? moment(
                me.currentPeriod.year +
                "-" +
                me.currentPeriod.month.toString().padStart(2, "0") +
                "01",
                "YYYY-MM-DD"
            )
                .subtract("3", "M")
                .format("YYYY-MM")
            : ""
    );
    const [maxDate, setMaxDate] = useState(
        me.currentPeriod
            ? moment(minDate + "-01", "YYYY-MM-DD")
                .add("15", "M")
                .format("YYYY-MM")
            : ""
    );
    const extraActivities = useSelector((state) => {
        return state.validation.extraActivityMonths;
    });

    const initFilters = () => {
        let filters = {
            period: "",
            matricules: null,
            states: null,
            types: null,
            manager: null,
            minDate: minDate,
            maxDate: maxDate,
        };

        filters.manager = me.matricule;
        filters.types = ["all"];
        filters.states = [PENDING_EXTRA_ACTIVITIES];

        return filters;
    };
    const [filters, setFilters] = useState(initFilters());
    const resetFilters = () => {
        setFilters(initFilters());
    };

    useEffect(() => {
        let tempRows = []
        if (extraActivities.length > 0) {
            extraActivities.map((element) => {
                if (tempRows.some(obj => obj.id === element.user.id)) {
                    let index = tempRows.findIndex(obj => obj.id === element.user.id)
                    tempRows[index].months.push(element)
                } else {
                    tempRows.push({"id": element.user.id, "months": [element]})
                }
            })
        }
        setRows(tempRows)
    }, [extraActivities])

    useEffect(() => {
        let tempPeriods = [];
        for (let index = 0; index < 12; index++) {
            tempPeriods.push({
                value: today.format("YYYY-MM"),
                label: today.format("YYYY-MM"),
            });
            today.add(1, "months");
        }
        setPeriods(tempPeriods);

        if (!localStorage.getItem("userForExtraActivityList")) {
            let periodRange = [];
            periodRange["startDate"] = filters["minDate"];
            periodRange["endDate"] = filters["maxDate"];

            dispatch(UserActions.fetchAll(periodRange)).then((json) => {
                localStorage.setItem(
                    "userForExtraActivityList",
                    JSON.stringify(json.response.entities.user)
                );
                // setNeedUserListUpdate(true);
            });
        }
        dispatch(UserActions.fetchManagerList());
    }, [])

    const loadList = () => {
        dispatch(setLoading(true));
        dispatch(ValidationActions.fetchExtraActivities(filters)).then(() => {
            dispatch(setLoading(false))
        })
    };

    useEffect(() => {
        if (me.loaded) {
            loadList()
        }
    }, [filters])

    const managers = useSelector((state) => state.user.listManagers);

    return <div className={"extra-activities-requests-container"}>
        <FilterContainer
            filters={filters}
            setFilters={setFilters}
            managers={managers}
            resetFilters={resetFilters}
            type="extra-activity"
            periods={periods}
            setPage={setPage}
            currentPeriodIndex={currentPeriodIndex}
            setCurrentPeriodIndex={setCurrentPeriodIndex}
        />
        <div className="extra-activities-requests-list">
            <div className="extra-activities-requests-list-title-container">
                <span className="extra-activities-requests-list-title">
                    Valider les demandes de saisie d'activités complémentaires
                </span>
            </div>

            {rows.length > 0 && (
                <div className="extra-activities-requests-list-header">
                    <span className="header-identity">Collaborateur</span>
                    <span className="header-extra-activity-requested-at">Date de la demande</span>
                    <span className="header-extra-activity-request-for">Mois concerné par la demande</span>
                    <span className="header-extra-activity-actions">Actions</span>
                </div>
            )}
            {rows.length > 0 ? (
                rows
                    // .sort((a, b) => {
                    //     return a.user.lastname.localeCompare(
                    //         b.user.lastname
                    //     );
                    // })
                    .map((row, key) => {
                        return (
                            <div key={key}>
                                <ExtraActivityRow row={row} me={me} loadList={loadList}/>
                            </div>
                        );
                    })
            ) : (
                <div className="no-activity">
                    Aucune demande de saisie d'activité complémentaire à valider
                </div>
            )}
        </div>
    </div>
}

export default ExtraActivitiesRequestsContainer;
