import React from "react";
import moment from "moment";
import "moment/locale/fr";

import "./SentAt.scss";

const SentAt = (props) => {
    return (
        <div className="sent-at-label">
            C.R.A. envoyé{" "}
            {props.submittedAt
                ? "le " + moment.parseZone(props.submittedAt).format("DD/MM/YYYY à HH:mm")
                : ""}
        </div>
    );
};

export default SentAt;
