import React, {Fragment, useState} from "react";
import "./HarassmentItem.scss";

const HarassmentItem = (props) => {
    const {item} = props;

    return (
        <div className="harassment-item">
            {Object.values(item.contacts).map((contact, key) => {
                return (
                    <Fragment key={key}>
                        <div className="harassment-title">
                            {contact.name} {contact.source ? <em>({contact.source})</em> : ''}
                        </div>
                        <div className="harassment-contact">
                            {contact.phone &&
                                <div className="harassment-phones">
                                    <i className="fal fa-phone"></i>
                                    <div className="harassment-phones-content">
                                        {contact.phone}
                                    </div>
                                </div>
                            }
                            {contact.email &&
                                <div className="harassment-emails">
                                    <i className="fal fa-envelope-open"></i>
                                    <div className="harassment-emails-content">
                                        <a
                                            className="harassment-email"
                                            href={`mailto:${contact.email}`}
                                            key={key}
                                        >
                                            {contact.email}
                                        </a>
                                    </div>
                                </div>
                            }
                        </div>
                    </Fragment>
                );
            })}
        </div>
    );
};

export default HarassmentItem;
