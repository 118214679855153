import {combineReducers} from "redux";
import {FETCH_REMOTE_DAYS_CONFIG_SUCCESS} from "actions/Settings";

export const entities = (state = {remoteDaysConfig: {}}, action) => {
    switch (action.type) {
        case FETCH_REMOTE_DAYS_CONFIG_SUCCESS:
            return Object.assign({}, state, action.response.entities.remoteDaysConfig);
        default:
            return state;
    }
};

const translateDays = (days) => {
    return {
        remoteDaysConfig: {
            lundi: days['monday'],
            mardi: days['tuesday'],
            mercredi: days['wednesday'],
            jeudi: days['thursday'],
            vendredi: days['friday'],
            samedi: days['saturday'],
            dimanche: days['sunday'],
        }
    }
}

export default combineReducers({
    entities,
});
