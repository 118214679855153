import React, {useEffect, useState, Fragment} from "react";
import "./HomepageContainer.scss";
import {getContactDefaultRoute} from "config/Routes";
import {useSelector, useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import "moment/locale/fr";
import moment from "moment";
import Disclaimer from "components/common/Disclaimer";
import {changeOverlayContent} from "events/OverlayEvents";

import * as NewsActions from "actions/News";
import * as UserActions from "actions/User";
import {setAppLoading} from "actions/Common";
import HomepagePost from "components/HomepageContainer/HomepagePost";
import HomepageNews from "./HomepageNews";
import Greenly2024PopUp from "./Greenly2024PopUp/Greenly2024PopUp";

const HomeApp = (props) => {
    const attributes = {};
    if (props.external) {
        attributes.target = "blank";
    }

    const styles = {};
    if (props.isContractor) {
        if (props.color) {
            styles.backgroundColor = props.color;
            styles.color = "#FFFFFF";
            styles.width = "300px";
        }
    } else {
        if (props.color) {
            styles.color = props.color;
        }
    }

    const className = props.className
        ? props.className + " home-app"
        : "home-app";

    return props.external ? (
        <a href={props.url} style={props.isContractor ? styles : {}}
           className={className + (props.isContractor ? " home-app-contractor" : "")} target="_blank">
            <i style={props.isContractor ? {} : styles} className={props.picto}></i>
            <div className="home-app-text">{props.text}</div>
            <div className="home-app-subtext">{props.subtext}</div>
        </a>
    ) : (
        <Link to={props.url} style={props.isContractor ? styles : {}}
              className={className + (props.isContractor ? " home-app-contractor" : "")}>
            <i style={props.isContractor ? {} : styles} className={props.picto}></i>
            <div className="home-app-text">{props.text}</div>
            <div className="home-app-subtext">{props.subtext}</div>
        </Link>
    );
};

const HomeAppContainer = (props) => {
    return <div className="home-app-container">{props.children}</div>;
};

const HomepageContainer = () => {
    const dispatch = useDispatch();
    const today = new Date();

    // const [popupVisible, setPopupVisible] = useState(!localStorage.getItem("greenly2024Hidden"));

    const me = useSelector((state) => state.user.me);
    const internalNews = useSelector((state) => state.news.entities.internalNews);

    const loadSpreadTracker = () => {
        window.domLoadEventFired = !0;
        let a = document.createElement("script");
        a.type = "text/javascript";
        a.async = !0;
        a.charset = "UTF-8";
        a.id = "spread-tracker";
        a.src = "//static-sb.com/js/sb-tracker.js";
        document.body.appendChild(a);
    };

    useEffect(() => {
        if (!me.loaded) {
            dispatch(setAppLoading(true));
            dispatch(UserActions.fetchCurrentUser());
        }
        dispatch(NewsActions.fetchHighlight());
        dispatch(NewsActions.fetchInternalNews());
    }, []);

    useEffect(() => {
        // Méthode de chargement du tracker
        if (typeof me !== "undefined") {
            if (me.type === "consultant" || me.type === "internal") {
                loadSpreadTracker();
            }
        }

        // if (me.loaded && popupVisible) {
        //     changeOverlayContent(
        //         <Greenly2024PopUp setPopupVisible={setPopupVisible} />
        //     );
        // }
    }, [me]);



    return (
        me.loaded && (
            <div id="page-container"
                 className={"home-page" + (me.type === "contractor" ? " home-page-contractor" : "")}>
                <div className="homepage-container">

                    <div className="container">
                        <div className="home-apps">
                            {me.type !== "contractor" && <div className="home-upper-container">
                                <HomepageNews internalNews={internalNews}/>
                                <HomepagePost/>
                            </div>}

                            <div
                                className={"home-lower-container" + (me.type === "contractor" ? " lower-container-no-width" : "")}>
                                <HomeApp
                                    color="#007188"
                                    url="/timesheet"
                                    text="Mes activités"
                                    subtext="CRA avant le 25 du mois"
                                    picto="fal fa-calendar-edit"
                                    isContractor={me.type === "contractor"}
                                />
                                <HomeApp
                                    color="#a51160"
                                    url={getContactDefaultRoute(me)}
                                    text="Mes Contacts"
                                    picto="fal fa-user-friends"
                                    isContractor={me.type === "contractor"}
                                />
                                {/* {me.type !== "contractor" && (
                                    <HomeApp
                                        color="#00c1de"
                                        url="https://hcmcloudfr.talentiasw.com/hrsynch/landing-page/1105232"
                                        text="Mon Portail RH"
                                        picto="fal fa-comments-alt"
                                    />
                                )} */}
                                {me.type !== "contractor" && (
                                    <HomeApp
                                        color="#8bc53f"
                                        url="https://www.expensya.com/Portal/#/Login?lang=fr"
                                        external="true"
                                        text="Mes Frais"
                                        subtext="Expensya"
                                        picto="fal fa-file-invoice-dollar"
                                        isContractor={me.type === "contractor"}
                                    />
                                )}
                                {me.type !== "contractor" && (
                                    <HomeApp
                                        color="#12afa9"
                                        url="/documents"
                                        text="Mes documents"
                                        picto="fal fa-folder-open"
                                        isContractor={me.type === "contractor"}
                                    />
                                )}
                                {me.type !== "contractor" && (
                                    <HomeApp
                                        color="#12afa9"
                                        url="/documents/social"
                                        text="Mes informations sociales"
                                        picto="fal fa-flag"
                                        isContractor={me.type === "contractor"}
                                    />
                                )}
                                {me.type !== "contractor" && (
                                    <HomeApp
                                        color="#007188"
                                        url="/actus"
                                        text="Mes Actualités"
                                        picto="fal fa-newspaper"
                                        isContractor={me.type === "contractor"}
                                    />
                                )}
                                <HomeApp
                                    color="#17313a"
                                    url="/tutorial"
                                    text="Mes Tutos"
                                    picto="fal fa-file"
                                    isContractor={me.type === "contractor"}
                                />
                            </div>
                        </div>
                        <Disclaimer isContractor={me.type === "contractor"}/>
                    </div>
                </div>
            </div>
        )
    );
};

export default HomepageContainer;
