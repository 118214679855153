import { MY_SYNCHRONE_URL as baseUrl } from "config/Url.js";
import Category from "entities/Category";

class Section {
    id;
    icon;
    label;
    priority;
    categories;
    role;

    constructor(json) {
        this.id = json.id;
        this.icon = json.icon;
        this.label = json.label;
        this.priority = json.priority;
        let categoriesArray = [];
        json.categories.map((category, key) => {
            categoriesArray.push(new Category(category));
        });
        this.categories = categoriesArray;
        this.role = json.role;
    }

    get iconUrl() {
        return baseUrl + this.icon;
    }
}

export default Section;
