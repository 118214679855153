import User from "./User";

class ExtraActivityMonth {
    id;
    month;
    year;
    editable;
    submitted;
    submittable;
    autofillable;
    absenceValidated;
    mainActivity;
    noPrestation;
    specialTime;
    submittedAt;
    updatedAt;
    locked;
    user;
    canExtraActivities;
    canRequestExtraActivities;
    extraActivitiesStatus;
    requestActivityAt;

    constructor(json) {
        this.id = json.id;
        this.month = json.month;
        this.year = json.year;
        this.editable = json.editable;
        this.submitted = json.submitted;
        this.submittable = json.submittable;
        this.autofillable = json.autofillable;
        this.absenceValidated = json.absence_validated;
        this.mainActivity = json.main_activity;
        this.noPrestation = json.no_prestation;
        this.specialTime = json.special_time;
        this.submittedAt = json.submitted_at;
        this.updatedAt = json.updated_at;
        this.locked = json.locked;
        this.user = new User(json.user);
        this.canExtraActivities = json.can_extra_activities;
        this.canRequestExtraActivities = json.can_request_extra_activities;
        this.extraActivitiesStatus = json.extra_activities_status;
        this.requestActivityAt = json.request_activity_at;
    }

    get loaded() {
        return Boolean(this.id);
    }
}

export default ExtraActivityMonth;
