import React, { useEffect, useState } from "react";
import "./MiniLoader.scss";

const MiniLoader = (props) => {
    const { color, borderWidth, containerWidth, containerHeight } = props;

    const containerStyle = {
        width: containerWidth,
        height: containerHeight,
    };
    const style = {
        borderColor: `${color} transparent transparent transparent`,
        borderWidth: borderWidth,
        width: `calc(0.7 * ${containerWidth})`,
        height: `calc(0.7 * ${containerHeight})`,
    };

    return (
        <div className="component-loader" style={containerStyle}>
            <div style={style}></div>
            <div style={style}></div>
            <div style={style}></div>
            <div style={style}></div>
        </div>
    );
};

export default MiniLoader;
