import React from "react";

import { STEP1C } from "components/TimesheetContainer/Forms/Event/Edit";

const Step1CActions = (props) => {
    const { allowedOvertimeCodes, step, selectedMission } = props;

    let availabilityCount = allowedOvertimeCodes.filter(
        (element) =>
            element.category === "availability" &&
            selectedMission &&
            element.affectation === selectedMission.code &&
            element.active
    ).length;
    // let interventionCount = allowedOvertimeCodes.filter(
    //     (element) =>
    //         element.category === "intervention" &&
    //         selectedMission &&
    //         element.affectation === selectedMission.code &&
    //         element.active
    // ).length;

    const handleClick = (category) => {
        props.setConstraintCategory(category);
        props.nextStep("hours-not-worked");
    };

    return (
        <div
            className={
                (step !== STEP1C ? "hidden " : "") +
                "step1c-actions event-type-buttons"
            }
        >
            {availabilityCount > 0 && (
                <div
                    onClick={(e) => handleClick("availability")}
                    className="category-button"
                >
                    J'étais en disponibilité
                </div>
            )}

            {/*{interventionCount > 0 && (*/}
            {/*    <div*/}
            {/*        onClick={(e) => handleClick("intervention")}*/}
            {/*        className="category-button"*/}
            {/*    >*/}
            {/*        Je suis intervenu.e*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
};

export default Step1CActions;
