import React from "react";
import { Field } from "formik";

const Comment = (props) => {
    const onChange = props.onChange;
    const onBlur = props.onBlur;

    return (
        <div className="step3-comment">
            <div className="step3-comment-label">
                <i className="far fa-comment-alt"></i> Commentaire
            </div>
            <Field
                as="textarea"
                name="comment"
                className="step3-comment-input"
                value={props.value}
                onChange={onChange}
                onBlur={onBlur}
            />
        </div>
    );
};

export default Comment;
