import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticated } from "utils/Utils";
import * as TutorialActions from "actions/Tutorial";

import "./TutorialContainer.scss";

const TutorialContainer = () => {
    const dispatch = useDispatch();
    const tutorials = useSelector((state) => state.tutorial.entities.tutorials);

    const TutorialHeader = () => {
        return (
            <header className="header gdpr-header">
                <div className="logo-synchrone"></div>
                <div className="title">Tutos</div>
            </header>
        );
    };

    useEffect(() => {
        if (isAuthenticated()) {
            dispatch(TutorialActions.fetchTutorials());
        } else {
            dispatch(TutorialActions.fetchPublicTutorials());
        }
    }, []);

    return (
        <Fragment>
            {!isAuthenticated() && <TutorialHeader />}
            <div id="page-container">
                <div className="container">
                    <div className="tutorial-title">
                        Quel tutoriel cherchez-vous ?
                    </div>
                    {Object.values(tutorials)
                        .sort((a, b) => {
                            return a.category.localeCompare(b.category);
                        })
                        .map((tutorial, key) => {
                            return (
                                <a
                                    href={tutorial.youtubeUrl}
                                    className="tutorial-thumbnail"
                                    target="_blank"
                                    title={tutorial.description}
                                    key={key}
                                >
                                    <img
                                        alt={tutorial.description}
                                        width="280"
                                        height="158"
                                        src={tutorial.thumbnail}
                                        key={key}
                                    />
                                </a>
                            );
                        })}
                </div>
            </div>
        </Fragment>
    );
};

export default TutorialContainer;
