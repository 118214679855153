import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { isEmpty } from "utils/Utils";
import ParametersCategoryContainer from "./ParametersCategoryContainer";
import * as SettingsActions from "actions/Settings";
import "./ParametersContainer.scss"

const ParametersContainer = (props) => {
    const remoteDays = useSelector((state) => state.settings.entities.remoteDaysConfig);
    const dispatch = useDispatch();
    const {personType} = props

    const patchRemoteDaysConfig = () => {

    }

    const buildCraCategory = () => {
        const subCategories = []

        if (remoteDays) {
            subCategories.push({
                title: personType === "contractor" ? "Travail à distance récurrent" : "Télétravail récurrent",
                dataType: "checkboxList",
                data: remoteDays,
                patchFunction: patchRemoteDaysConfig
            })
        }

        return {
            categoryTitle: "CRA",
            subCategories: subCategories
        }
    }


    const [craCategory, setCraCategory] = useState({})

    useEffect(() => {
        dispatch(SettingsActions.fetchRemoteDaysConfig());
    }, []);

    useEffect(() => {
        if (!isEmpty(remoteDays)) {
            setCraCategory(buildCraCategory())
        }
    }, [remoteDays]);


    return <div id="page-container">
        <div className="container">
            <div className="parameters-title">Paramètres</div>
            {!isEmpty(craCategory) && <ParametersCategoryContainer category={craCategory}/>}
        </div>
    </div>
}

export default ParametersContainer