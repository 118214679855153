import { MY_SYNCHRONE_URL as baseUrl } from "config/Url.js";
import File from "entities/File";

class Folder {
    id;
    label;
    priority;
    files;

    constructor(json) {
        this.id = json.id;
        this.label = json.label;
        this.priority = json.priority;
        let filesArray = [];
        json.files.map((file) => {
            filesArray.push(new File(file));
        });
        this.files = filesArray;
        this.position = json.priority;
    }
}

export default Folder;
