import { combineReducers } from "redux";
import { GET_CURRENT_PERIOD_SUCCESS } from "actions/Period";

export const entities = (state = {}, action) => {
    switch (action.type) {
        case GET_CURRENT_PERIOD_SUCCESS:
            return Object.assign({}, state, action.response.entities);
        default:
            return state;
    }
};

export default combineReducers({
    entities,
});
