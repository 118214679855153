import React from "react";

const Slider = (props) => {
    const { value, onClick, label } = props;

    return (
        <div className="slider-container">
            <div
                className={"slider" + (value ? " slider-right" : "")}
                onClick={onClick}
            ></div>
            <span className="slider-label" onClick={onClick}>
                {label}
            </span>
        </div>
    );
};

export default Slider;
