import React from "react";
import { Field } from "formik";

const HourInputLine = (props) => {
    const onChange = props.onChange;
    const onBlur = props.onBlur;
    const path = props.path ? props.path : 'days';

    return (
        <div className="hour-input">
            <Field
                value={props.value.start}
                onChange={onChange}
                onBlur={onBlur}
                type="time"
                className="hour-input"
                name={`${path}[${props.id}].hours[${props.hour}].start`}
                id={`${path}[${props.id}].hours[${props.hour}].start`}
                key={`${props.id}-${props.hour}-start`}
            />
            <div className="hour-separator">
                {/*<i className="far fa-chevron-right"></i>*/}
            </div>
            <Field
                value={props.value.end}
                onChange={onChange}
                onBlur={onBlur}
                type="time"
                className="hour-input"
                name={`${path}[${props.id}].hours[${props.hour}].end`}
                id={`${path}[${props.id}].hours[${props.hour}].end`}
                key={`${props.id}-${props.hour}-end`}
            />
        </div>
    );
};

export default HourInputLine;
