import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import { setLoading } from "actions/Common";
import * as UserActions from "actions/User";
import * as ValidationActions from "actions/Validation";
import * as Params from "config/Parameters";
import FilterContainer from "components/Backend/FilterContainer";

import "./CRAContainer.scss";
import PersonRow from "components/Backend/CRAContainer/PersonRow";

import {
    CRA_NOT_SENT,
    CRA_SUBMITTED,
    CRA_VALIDATED_BY_MANAGER,
} from "config/CRAStateColors";

const ALL = "all";
const SUBMITTED = "submitted";
// const CANCELED = "canceled";
// const REFUSED = "refused";
// const DELETED = "deleted";
const VALIDATED_LEVEL_1 = "validated1";
// const VALIDATED_LEVEL_2 = "validated2";

const CRAContainer = (props) => {
    const today = moment.parseZone();
    const [page, setPage] = useState(1);
    const [needUserListUpdate, setNeedUserListUpdate] = useState(false);
    const [needPeriodUpdate, setNeedPeriodUpdate] = useState(false);
    const [currentPeriodIndex, setCurrentPeriodIndex] = useState(0);
    const [periods, setPeriods] = useState([]);
    const dispatch = useDispatch();
    // const usersToDisplay = useSelector((state) => state.validation.users);
    const months = useSelector((state) => state.validation.months);
    const me = useSelector((state) => state.user.me);
    const totalUsersCount = useSelector(
        (state) => state.validation.totalUsersCount
    );
    const maxPage = Math.ceil(totalUsersCount / 10);

    const initFilters = () => {
        let year = me.currentPeriod.year.toString();
        let month = me.currentPeriod.month.toString();
        let periodString = year + "-" + month.padStart(2, "0");
        let defaultPeriod = {
            value: periodString,
            label: periodString,
        };

        let filters = {
            period: defaultPeriod.value,
            matricules: null,
            states: null,
            company: null,
            types: null,
            manager: null,
            particularities: [],
        };

        if (
            me.hasRole("ROLE_FEE_MANAGER") ||
            me.hasRole("ROLE_PAY_MANAGER") ||
            me.hasRole("ROLE_GTA") ||
            me.hasRole("ROLE_MANAGER") ||
            me.hasRole("ROLE_BILLING_SERVICE")
        ) {
            filters.types = [ALL];
        }

        if (
            // me.hasRole("ROLE_PAY_MANAGER") ||
            (me.hasRole("ROLE_CONTRACTOR_MANAGER") && !me.hasRole("ROLE_BILLING_SUPERVISOR")) ||
            (me.hasRole("ROLE_GTA") && !me.hasRole("ROLE_BILLING_SUPERVISOR")) ||
            me.hasRole("ROLE_FEE_MANAGER")
        ) {
            filters.manager = null;
        } else {
            if (me.hasRole("ROLE_MANAGER")) {
                filters.manager = me.matricule;
            }
        }

        if (me.hasRole("ROLE_MANAGER")) {
            filters.states = [SUBMITTED];
        } else if (
            me.hasRole("ROLE_CONTRACTOR_MANAGER") ||
            me.hasRole("ROLE_GTA")
        ) {
            filters.states = [VALIDATED_LEVEL_1];
        } else if (
            me.hasRole("ROLE_FEE_MANAGER") ||
            me.hasRole("ROLE_PAY_MANAGER") ||
            me.hasRole("ROLE_BILLING_SERVICE") ||
            me.hasRole("ROLE_HRBP")
        ) {
            filters.states = [ALL];
        }

        return filters;
    };
    const [filters, setFilters] = useState(initFilters());
    const resetFilters = () => {
        setFilters(initFilters());
    };

    useEffect(() => {
        let tempPeriods = [],
            tempPeriodsForIndex = [];
        for (let index = 0; index < 12; index++) {
            tempPeriods.push({
                value: today.format("YYYY-MM"),
                label: today.format("YYYY-MM"),
            });
            tempPeriodsForIndex.push(today.format("YYYY-MM"));
            today.subtract(1, "months");
        }

        setPeriods(tempPeriods);
        setCurrentPeriodIndex(tempPeriodsForIndex.indexOf(filters.period));

        if (!localStorage.getItem("userForCRAList")) {
            let periodRange = [];
            periodRange["startDate"] =
                tempPeriods[tempPeriods.length - 1]["label"];
            periodRange["endDate"] = tempPeriods[0]["label"];

            if (
                me.hasRole("ROLE_ACTIVITY_ACCEPT_FIRST_LEVEL") &&
                !me.hasRole("ROLE_ACTIVITY_SHOW_ALL") &&
                !me.hasRole("ROLE_HRBP")
            ) {
                dispatch(UserActions.fetchAll(periodRange, me.matricule)).then(
                    (json) => {
                        localStorage.setItem(
                            "userForCRAList",
                            json.response.entities.user ? JSON.stringify(json.response.entities.user) : JSON.stringify([])
                        );
                        setNeedUserListUpdate(true);
                    }
                );
            } else {
                dispatch(UserActions.fetchAll(periodRange)).then((json) => {
                    localStorage.setItem(
                        "userForCRAList",
                        json.response.entities.user ? JSON.stringify(json.response.entities.user) : JSON.stringify([])
                    );
                    setNeedUserListUpdate(true);
                });
            }
        }
        dispatch(UserActions.fetchManagerList());
    }, []);

    const loadList = () => {
        dispatch(setLoading(true));
        dispatch(
            ValidationActions.fetchCRAList(
                filters,
                Params.MAX_RESULTS_FOR_VALIDATION,
                page
            )
        );
    };

    useEffect(() => {
        if (me.loaded) {
            loadList();
        }
    }, [filters, page]);

    // const usersAvailable = useSelector((state) => state.user.users);
    const managers = useSelector((state) => state.user.listManagers);

    const CaptionEntry = (props) => {
        const { color, title } = props;

        return (
            <div className="caption">
                <div
                    className="caption-color"
                    style={{
                        backgroundColor: color,
                    }}
                ></div>
                <div className="caption-title">{title}</div>
            </div>
        );
    };

    return (
        <div className="cra-container">
            <FilterContainer
                filters={filters}
                setFilters={setFilters}
                // users={usersAvailable}
                managers={managers}
                resetFilters={resetFilters}
                type="activity"
                periods={periods}
                currentPeriodIndex={currentPeriodIndex}
                setCurrentPeriodIndex={setCurrentPeriodIndex}
                needPeriodUpdate={needPeriodUpdate}
                setNeedPeriodUpdate={setNeedPeriodUpdate}
                needUserListUpdate={needUserListUpdate}
                setNeedUserListUpdate={setNeedUserListUpdate}
                setPage={setPage}
            />
            <div className="cra-list">
                <div className="cra-list-title-container">
                    <span className="cra-list-title">
                        Valider les activités
                    </span>
                    <div className="caption-container">
                        <CaptionEntry
                            color={CRA_NOT_SENT}
                            title="CRA non envoyé"
                        />
                        <CaptionEntry
                            color={CRA_SUBMITTED}
                            title="CRA non validé par le Manager"
                        />
                        <CaptionEntry
                            color={CRA_VALIDATED_BY_MANAGER}
                            title="CRA validé par le Manager"
                        />
                    </div>
                </div>

                <div className="current-period-bar">
                    {currentPeriodIndex < 12 && (
                        <div
                            onClick={() => {
                                setCurrentPeriodIndex(currentPeriodIndex + 1);
                                setNeedPeriodUpdate(true);
                            }}
                            className="previous-month far fa-chevron-left"
                        ></div>
                    )}
                    <div className="current-period-label">
                        <div className="month-label">
                            {moment
                                .parseZone(periods[currentPeriodIndex]?.value)
                                .format("MMMM")}
                        </div>
                        <div className="year-label">
                            {moment
                                .parseZone(periods[currentPeriodIndex]?.value)
                                .format("YYYY")}
                        </div>
                    </div>
                    {currentPeriodIndex > 0 && (
                        <div
                            onClick={() => {
                                setCurrentPeriodIndex(currentPeriodIndex - 1);
                                setNeedPeriodUpdate(true);
                            }}
                            className="next-month far fa-chevron-right"
                        ></div>
                    )}
                </div>
                {months.length > 0 && (
                    <div className="cra-list-header">
                        <span className="header-identity">Collaborateur</span>
                        <span className="header-activity-type">Activité</span>
                        <span className="header-days-amount">Jours</span>
                        <span className="header-overtime">Activités complémentaires</span>
                        <span className="header-actions">Actions</span>
                    </div>
                )}
                {months.length > 0 ? (
                    months
                        .sort((a, b) => {
                            return a.user.lastname.localeCompare(
                                b.user.lastname
                            );
                        })
                        .map((month, key) => {
                            // let relevantMonths = months.filter((item) => {
                            //     return item.user === element.id;
                            // });
                            return (
                                <div key={key}>
                                    <PersonRow
                                        user={month.user}
                                        month={month}
                                        me={me}
                                        period={
                                            periods[currentPeriodIndex]
                                                ? moment.parseZone(
                                                      periods[
                                                          currentPeriodIndex
                                                      ].value
                                                  )
                                                : []
                                        }
                                        loadList={loadList}
                                    />
                                </div>
                            );
                        })
                ) : (
                    <div className="no-activity">
                        Aucun CRA ne correspond à vos filtres
                    </div>
                )}
            </div>
            {totalUsersCount > 0 && (
                <div className="page-controls">
                    {page > 1 && (
                        <span
                            onClick={() => setPage(page - 1)}
                            className="previous-page fal fa-chevron-left"
                        ></span>
                    )}
                    <div className="page-number">
                        {page} / {maxPage}
                    </div>
                    {page < maxPage && (
                        <span
                            onClick={() => setPage(page + 1)}
                            className="next-page fal fa-chevron-right"
                        ></span>
                    )}
                </div>
            )}
        </div>
    );
};

export default CRAContainer;

// 'overtime',
// 'special_time',
// 'partial_time',
// 'remote_working',
