import React, {Fragment, useEffect, useState} from "react";
import {Redirect, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Calendar from "components/TimesheetContainer/Calendar/Calendar";
import EventContainer from "components/TimesheetContainer/Calendar/EventContainer";
import VacationCount from "components/TimesheetContainer/VacationCount/VacationCount";
import useClickOutside from "hooks/useClickOutside";

import moment from "moment";
import "moment/locale/fr";

import * as TimesheetActions from "actions/Timesheet";
import CalendarNotAvailable from "./Calendar/CalendarNotAvailable";
import Month from "../../entities/Month";

const TimesheetContainer = () => {
    const me = useSelector((state) => state.user.me);
    const vacationCount = useSelector((state) => state.user.vacationCount);
    const monthEntity = useSelector((state) => state.timesheet.entities.month);
    const dispatch = useDispatch();
    const extraActivities = useSelector((state) => state.timesheet.entities.month.extraActivities)
    const [isRemoteEditing, setRemoteEditing] = useState(false);
    const {
        month = moment.parseZone().month() + 1,
        year = moment.parseZone().year(),
    } = useParams();

    const isVacationWarningVisible = () => {
        const lastClickedAt = localStorage.getItem("lastClickedAt");
        if (lastClickedAt) {
            let clickedAt = moment(lastClickedAt, "YYYYMMDD");
            if (
                clickedAt.format("DD-MM-YYYY") >= moment().format("DD-MM-YYYY")
            ) {
                return false;
            }
        }

        return true;
    };

    const hideVacationWarning = () => {
        const lastClickedAt = localStorage.getItem("lastClickedAt");
        if (lastClickedAt) {
            let clickedAt = moment(lastClickedAt, "YYYYMMDD");
            if (
                clickedAt.format("DD-MM-YYYY") < moment().format("DD-MM-YYYY")
            ) {
                localStorage.setItem(
                    "lastClickedAt",
                    moment().format("YYYYMMDD")
                );
            }
        } else {
            localStorage.setItem("lastClickedAt", moment().format("YYYYMMDD"));
        }
        setVacationWarningVisible(false);
    };

    const [vacationWarningVisible, setVacationWarningVisible] = useState(
        isVacationWarningVisible()
    );

    let fetchable = false;

    const lowerLimit =
        moment(me.seniorityAt) > moment("01-01-2019", "DD-MM-YYYY")
            ? moment(me.seniorityAt)
            : moment("01-01-2019", "DD-MM-YYYY");

    const upperLimit = moment().add("1", "years").startOf("month");
    const cpLimit = moment().date(31).month(4);
    const rttLimit = moment().date(31).month(2);

    const targetMonth = moment(
        `01-${String(month).padStart(2, "0")}-${year}`,
        "DD-MM-YYYY"
    );

    const ref = useClickOutside(() => {
        hideVacationWarning();
    });

    const canDisplayCra = !me.hasRole("ROLE_NOT_CRA");

    const lowerLimitMonth = lowerLimit.startOf("month");

    if (
        (parseInt(year) &&
            parseInt(month) &&
            targetMonth >= lowerLimitMonth &&
            targetMonth <= upperLimit) ||
        (typeof year === "undefined" && typeof month === "undefined")
    ) {
        fetchable = true;
    }

    useEffect(() => {
        if (me.loaded && canDisplayCra) {
            if (fetchable) {
                // dispatch(PeriodActions.getCurrentPeriod());
                dispatch(TimesheetActions.fetchEntireMonth(month, year));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me, month, year]);

    return me.loaded && me.seniorityAt ? (
        fetchable ? (
            <div id="page-container">
                <div className="container">
                    {canDisplayCra ? (
                        <Fragment>
                            {!isRemoteEditing && <EventContainer
                                firstDayOfMonth={targetMonth}
                                currentMonth={monthEntity.month}
                                currentYear={monthEntity.year}
                                days={monthEntity.days}
                                mainActivity={monthEntity.mainActivity}
                                autofillable={monthEntity.autofillable}
                                isMonthSubmitted={monthEntity.submitted}
                                isMonthEditable={monthEntity.editable}
                                isSpecialTime={monthEntity.specialTime}
                                me={me}
                                extraActivities={extraActivities}
                                personType={me.type}
                            />}

                            <Calendar
                                monthEntity={monthEntity}
                                days={monthEntity.days}
                                remoteDays={monthEntity.remoteDays}
                                me={me}
                                isRemoteEditing={isRemoteEditing}
                                setRemoteEditing={setRemoteEditing}
                            />
                            {me.type !== "contractor" && (
                                <>
                                    {((me.beforeJune > 0 &&
                                            moment() < cpLimit) ||
                                        (me.rttRemainder > 0 &&
                                            moment() < rttLimit)) && (
                                        <div
                                            className={
                                                "warning-vacation-count" +
                                                (vacationWarningVisible
                                                    ? ""
                                                    : " warning-vacation-count-closed")
                                            }
                                            ref={ref}
                                            onClick={() =>
                                                setVacationWarningVisible(false)
                                            }
                                        >
                                            <p>
                                                Il vous reste des CP/RTT qui
                                                arrivent à expiration
                                                prochainement, vous risquez de
                                                les perdre
                                            </p>
                                            <img
                                                className="warning-vacation-arrow"
                                                src="/img/curved-arrow.svg"
                                            />
                                        </div>
                                    )}

                                    <VacationCount
                                        modality={me.modality}
                                        cp={vacationCount.cp}
                                        rtt={vacationCount.rtt}
                                        remainder={vacationCount.remainder}
                                        rttRemainder={me.rttRemainder}
                                        rest={me.rest}
                                        beforeJune={vacationCount.beforeJune}
                                        sickChildren={
                                            vacationCount.sickChildrenDays
                                        }
                                        displayRemainderCounter={
                                            me.displayRemainderCounter
                                        }
                                    />
                                </>
                            )}
                        </Fragment>
                    ) : (
                        <CalendarNotAvailable/>
                    )}
                </div>
            </div>
        ) : (
            <Redirect to="/not-found"/>
        )
    ) : (
        ""
    );
};

export default TimesheetContainer;
