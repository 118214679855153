class EventAction {
    id;
    event;
    action;
    author;
    comment;
    createdAt;
    oldData;
    newData;

    constructor(json) {
        this.id = json.id;
        this.event = json.event;
        this.action = json.action;
        this.author = json.author;
        this.comment = json.comment;
        this.createdAt = json.created_at;
        this.oldData = json.old_data;
        this.newData = json.new_data;
    }
}

export default EventAction;
