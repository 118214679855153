import React, { useState } from "react";
import "./InsuranceItem.scss";

const InsuranceItem = (props) => {
    const { item } = props;

    return (
        <div className="insurance-item">
            <div className="label-item">{item.label}</div>
            <div className="insurance-contact">
                <div className="phone-item">
                    <i className="fal fa-phone"></i>
                    {item.phone}
                </div>
                <div className="email-content">
                    <i className="fal fa-envelope-open"></i>
                    <a className="email-item" href={`mailto:${item.email}`}>
                        {item.email}
                    </a>
                </div>
                <div className="url-item">
                    <div className="url-picto">
                        <i className="fal fa-globe"></i>
                    </div>
                    <div className="url-content">
                        <div className="url-content-label">
                            mon espace assuré :{" "}
                        </div>
                        <a href={item.url} target="_blank">
                            {item.url}
                        </a>
                    </div>
                </div>
            </div>
            <div className="insurance-address">
                <i className="fal fa-map-marker"></i>
                {item.address}
            </div>
        </div>
    );
};

export default InsuranceItem;
