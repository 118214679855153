import React, { useState } from "react";
import Lightbox from "components/common/Lightbox";
import { changeOverlayContent } from "events/OverlayEvents";

const ConfirmLightbox = (props) => {
    const { title, handleSubmit, message } = props;
    const [submitting, setSubmitting] = useState(false);

    return (
        <Lightbox className="lb-w1200">
            <div className="lb-confirm-send">
                <div
                    className="lb-confirm-send-header"
                    style={{ textTransform: "uppercase" }}
                >
                    {title}
                </div>

                <div className="confirm-message">{message}</div>

                <div className="lb-confirm-send-actions">
                    <div
                        onClick={() => changeOverlayContent(null)}
                        className="lb-confirm-send-cancel"
                    >
                        Non
                    </div>
                    <div
                        onClick={handleSubmit}
                        className={
                            "lb-confirm-send-confirm" +
                            (submitting ? " disabled-btn" : "")
                        }
                    >
                        Oui
                    </div>
                </div>
            </div>
        </Lightbox>
    );
};

export default ConfirmLightbox;
