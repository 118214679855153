import React from "react";

const RadioButton = ({
                         field: {name, value, onChange, onBlur},
                         inputValue,
                         label,
                         id,
                         className,
                         handleClick,
                         ...props
                     }) => {
    return (
        <div className="input-type-radio">
            <label onClick={(e) => handleClick(e, inputValue)} htmlFor={id}>
                {label}
            </label>
            <input
                name={name}
                type="radio"
                value={inputValue}
                checked={inputValue === value}
                onChange={onChange}
                onBlur={onBlur}
                id={id}
                className="radio-button"
                {...props}
            />
            <div
                onClick={(e) => handleClick(e, inputValue)}
                className="fake-radio"
            ></div>
        </div>
    );
};

export default RadioButton;