import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import UnionDocuments from "components/DocumentContainer/UnionDocuments";
import UnionHome from "components/DocumentContainer/UnionHome";
import DocumentsList from "components/DocumentContainer/DocumentsList";
import SocialList from "components/DocumentContainer/SocialList";
import * as DocumentActions from "actions/Document";
import "./DocumentContainer.scss";

const DocumentContainer = () => {
    const { id = undefined, type = "documents" } = useParams();
    const dispatch = useDispatch();
    const union = useSelector((state) => {
        return state.document.entities.union;
    });

    return (
        <div id="union-container">
            <div className="links-container">
                <Link
                    className="title"
                    to={
                        type === "documents"
                            ? "/documents"
                            : "/documents/social"
                    }
                >
                    {type === "documents"
                        ? "Mes documents"
                        : "Mes informations sociales"}
                </Link>
                {type == "syndicats" && (
                    <>
                        <span className="links-separator">{">"}</span>{" "}
                        <div
                            className={
                                "link-to-union" +
                                (type === "syndicats" && id === undefined
                                    ? " active"
                                    : "")
                            }
                        >
                            Communications syndicales
                        </div>
                    </>
                )}

                {type === "syndicats" && id !== undefined && (
                    <>
                        <span className="links-separator">{">"}</span>{" "}
                        <div
                            className={
                                "link-to-union" +
                                (type === "syndicats" && id !== undefined
                                    ? " active"
                                    : "")
                            }
                        >
                            {union.label}
                        </div>
                    </>
                )}
            </div>

            {/* {type === "syndicats" && id === undefined && <UnionHome />} */}
            {type === "syndicats" && id !== undefined && (
                <UnionDocuments id={id} />
            )}
            {type === "documents" && <DocumentsList />}
            {type === "social" && <SocialList />}
        </div>
    );
};

export default DocumentContainer;
