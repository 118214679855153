import store from "store/ConfigureStore";
import { isEmpty } from "utils/Utils";

class ManagerRepository {
    findByMatricule = (matricule) => {
        let managers = store.getState().user.listManagers;
        if (matricule) {
            if (!isEmpty(managers)) {
                let result = null;
                let array = Object.values(managers);
                array.forEach((element) => {
                    if (element.matricule === matricule) {
                        result = element;
                    }
                });
                return result;
            }
        }

        return;
    };
}

export default ManagerRepository;
