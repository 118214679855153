import React from "react";

import { monthInLetter } from "utils/Utils";

import "./CalendarHeaderDate.scss";

const CalendarHeaderDate = (props) => {
    const { monthEntity, type, user } = props;
    const month = monthEntity.month;
    const year = monthEntity.year;

    return (
        <span>
            <span className="month">{monthInLetter(month)} </span>
            <span className="year">{year} </span>
            {type === "view" && user && (
                <span className="cra-user-identity">({user[0].identity})</span>
            )}
        </span>
    );
};

export default CalendarHeaderDate;
