import React, { useState } from "react";

import { STEP1A } from "components/TimesheetContainer/Forms/Event/Edit";

const Step1AActions = (props) => {
    const {
        allowedOvertimeCodes,
        setSelectedMission,
        nextStep,
        step,
        availableMissions,
    } = props;

    const groupCodes = () => {
        let codes = [];
        for (let index = 0; index < allowedOvertimeCodes.length; index++) {
            const element = allowedOvertimeCodes[index];
            if (codes.indexOf(element.affectation) < 0) {
                codes.push(element.affectation);
            }
        }

        return codes;
    };
    const [groupedCodes, setGroupedCodes] = useState(groupCodes());

    const handleClick = (item) => {
        setSelectedMission(item);
        if (groupedCodes.length > 1) {
            nextStep("hours-not-worked");
        } else {
            nextStep("hours-not-worked", true);
        }
    };

    return (
        <div
            className={
                (step !== STEP1A ? "hidden " : "") +
                "step1a-actions event-code-buttons"
            }
        >
            {availableMissions &&
                availableMissions.map((item, key) => {
                    return (
                        <div
                            onClick={(e) => handleClick(item)}
                            className="category-button"
                            key={key}
                        >
                            <div
                                // style={{
                                //     lineHeight: "22.5px"
                                //         // item.description.length > 43
                                //         //     ? "22.5px"
                                //         //     : "45px",
                                // }}
                                className="content"
                            >
                                {item.description}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default Step1AActions;
