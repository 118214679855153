import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import {changeOverlayContent} from "events/OverlayEvents";
import * as TimesheetActions from "actions/Timesheet";
import "./Day.scss";

import {
    EntityRepository,
    HALFDAY_REPOSITORY,
    OVERTIME_REPOSITORY,
    OVERTIME_CODE_REPOSITORY,
    EVENT_CODE_REPOSITORY,
    EVENT_REPOSITORY
} from "store/EntityRepository";
import ExtraActivitiesPopUp from "./ExtraActivitiesPopUp";
import month from "../../../entities/Month";

const Day = (props) => {
    const dispatch = useDispatch();
    const {
        day,
        selected,
        canOvertime,
        monthEntity,
        type,
        me,
        user,
        hasExtraActivityBtn,
        modality,
        extraActivityBtn,
        isRemoteEditing,
        remoteDays,
        setRemoteDaysForSend,
        remoteDaysForSend
    } = props;
    const halfDayRepo = EntityRepository().getRepository(HALFDAY_REPOSITORY);
    const eventRepo = EntityRepository().getRepository(EVENT_REPOSITORY);
    const overtimeRepo = EntityRepository().getRepository(OVERTIME_REPOSITORY);
    const overtimeCodeRepo = EntityRepository().getRepository(
        OVERTIME_CODE_REPOSITORY
    );
    const eventCodeRepo = EntityRepository().getRepository(
        EVENT_CODE_REPOSITORY
    );

    const am = halfDayRepo.find(day.am);
    const amEventFetched = am ? eventRepo.find(am.event) : null

    const pm = halfDayRepo.find(day.pm);
    const pmEventFetched = pm ? eventRepo.find(pm.event) : null

    const [hovered, setHovered] = useState(false);
    const [hoursForDay, setHoursForDay] = useState("");
    const [targetAmEventCode, setTargetAmEventCode] = useState(null);
    const [targetPmEventCode, setTargetPmEventCode] = useState(null);

    const overtimesForDay = overtimeRepo.findForDate(day.dateAt, type);
    const selectedStartDay = useSelector(
        (state) => state.timesheet.selectedStartDay
    );
    const boundingEvents = useSelector(
        (state) => state.timesheet.entities.boundingEvents
    );
    const nextEvent = boundingEvents.next;
    const currentPeriod = useSelector(
        (state) => state.period.entities.periodLite?.currentPeriod
    );
    const isLoading = useSelector((state) => state.common.isLoading);
    const extraActivities = useSelector((state) => state.timesheet.entities.month.extraActivities)
    const today = currentPeriod
        ? currentPeriod.year + "-" + currentPeriod.month
        : moment.parseZone(day.dateAt).format("YYYY-MM");

    useEffect(() => {
        if (amEventFetched) {
            let targetAmEventCode = eventCodeRepo.findWithoutHook(amEventFetched.eventCode);
            if (targetAmEventCode !== undefined) {
                setTargetAmEventCode(targetAmEventCode)
            }
        }
    }, [amEventFetched, isLoading])

    useEffect(() => {
        if (pmEventFetched) {
            let targetPmEventCode = eventCodeRepo.findWithoutHook(pmEventFetched.eventCode);
            if (targetPmEventCode !== undefined) {
                setTargetPmEventCode(targetPmEventCode)
            }
        }
    }, [pmEventFetched, isLoading])

    useEffect(() => {
        let workedHalfDay = 0;
        if (targetAmEventCode?.type === 'activity' || (!targetAmEventCode && monthEntity.autofillable && day.worked)) {
            workedHalfDay++;
        }
        if (targetPmEventCode?.type === 'activity' || (!targetPmEventCode && monthEntity.autofillable && day.worked)) {
            workedHalfDay++;
        }
        switch (me.modality) {
            case "1":
                if (workedHalfDay === 0) {
                    setHoursForDay('')
                } else if (workedHalfDay === 1) {
                    setHoursForDay('3h30')
                } else {
                    setHoursForDay('7h')
                }
                break;
            case "2":
                if (workedHalfDay === 0) {
                    setHoursForDay('')
                } else if (workedHalfDay === 1) {
                    setHoursForDay('3h51')
                } else {
                    setHoursForDay('7h42')
                }
                break;
            default:
                setHoursForDay('')
                break;
        }
    }, [targetAmEventCode, targetPmEventCode])

    const displayOvertimePopUp = (e, day, monthEntity) => {
        e.preventDefault()
        e.stopPropagation()
        if (selectedStartDay === null) {
            changeOverlayContent(<ExtraActivitiesPopUp day={day} monthEntity={monthEntity} modality={modality}
                                                       type={me.type}/>);
        }
    };

    const isDayRemote = (date) => {
        let targetDay = moment(date).parseZone().format("YYYY-MM-DD")
        let remoteDay = remoteDays.filter((item) => item.day === targetDay)
        if (remoteDay.length > 0) {
            if (remoteDay[0].am && remoteDay[0].pm) {
                return 'full'
            } else if (remoteDay[0].am) {
                return 'am'
            } else if (remoteDay[0].pm) {
                return 'pm'
            }
        }

        return ''
    }
    const [remote, setRemote] = useState(isDayRemote(day.dateAt))

    const isDayAvailable = () => {
        if (
            day.workingState !== "worked" ||
            (me &&
                ((me.startAt &&
                        !me.lastEndAt &&
                        moment.parseZone(day.dateAt).format("YYYY-MM-DD") <
                        moment.parseZone(me.startAt).format("YYYY-MM-DD")) ||
                    (me.endAt &&
                        moment.parseZone(day.dateAt).format("YYYY-MM-DD") >
                        moment.parseZone(me.endAt).format("YYYY-MM-DD")) ||
                    (me.lastEndAt &&
                        me.startAt &&
                        moment.parseZone(day.dateAt).format("YYYY-MM-DD") >
                        moment
                            .parseZone(me.lastEndAt)
                            .format("YYYY-MM-DD") &&
                        moment.parseZone(day.dateAt).format("YYYY-MM-DD") <
                        moment
                            .parseZone(me.startAt)
                            .format("YYYY-MM-DD")))) ||
            (user &&
                ((user[0].startAt &&
                        moment.parseZone(day.dateAt).format("YYYY-MM-DD") <
                        moment
                            .parseZone(user[0].startAt)
                            .format("YYYY-MM-DD")) ||
                    (user[0].endAt &&
                        moment.parseZone(day.dateAt).format("YYYY-MM-DD") >
                        moment
                            .parseZone(user[0].endAt)
                            .format("YYYY-MM-DD"))))
        ) {
            return false;
        }

        return true
    }

    const getClassNames = () => {
        let classNames = "day ";

        if (selected || hovered || props.isInHoveredRange) {
            classNames += " selected";
        }

        if (day.workingState === "notCurrentMonth") {
            classNames += " not-current-month";
        }

        if (!isDayAvailable()) {
            classNames += " unavailable";
        } else if (isRemoteEditing) {
            classNames += " day-remote-editing"
        }

        if (!selectable() || monthEntity.locked) {
            classNames += " not-selectable";
        }

        return classNames;
    };

    const selectable = () => {
        if (selectedStartDay) {
            if (
                moment.parseZone(day.dateAt).format("YYYY-MM-DD") >=
                moment
                    .parseZone(selectedStartDay.day.dateAt)
                    .format("YYYY-MM-DD") &&
                moment.parseZone(day.dateAt).month() ===
                moment.parseZone(selectedStartDay.day.dateAt).month()
            ) {
                if (
                    !nextEvent ||
                    (canOvertime &&
                        formattedMonth(monthEntity.month, monthEntity.year) <=
                        today)
                ) {
                    return true;
                } else {
                    if (
                        moment.parseZone(day.dateAt).format("YYYY-MM-DD") <
                        moment.parseZone(nextEvent.startAt).format("YYYY-MM-DD")
                    ) {
                        return true;
                    } else if (
                        moment.parseZone(day.dateAt).format("YYYY-MM-DD") ===
                        moment.parseZone(nextEvent.startAt).format("YYYY-MM-DD")
                    ) {
                        if (nextEvent.startMoment === "pm") {
                            return true;
                        }
                    }
                }
            }
            return false;
        }

        return true;
    };

    const formattedMonth = (month, year) => {
        let monthString = "";
        if (month <= 10) {
            monthString = `0${month}`;
        } else {
            monthString = month;
        }

        return monthString + "-" + year;
    };

    const selectDay = (e) => {
        if (type !== "view" && day.workingState !== "notCurrentMonth") {
            if (monthEntity.editable) {
                let absenceOnly = false;
                if (
                    formattedMonth(monthEntity.month, monthEntity.year) > today
                ) {
                    absenceOnly = true;
                }

                if (!absenceOnly || (absenceOnly && me.type !== "contractor")) {
                    if (
                        (day.workingState === "worked" &&
                            (!me.endAt ||
                                (me.endAt &&
                                    moment
                                        .parseZone(day.dateAt)
                                        .format("YYYY-MM-DD") <=
                                    moment
                                        .parseZone(me.endAt)
                                        .format("YYYY-MM-DD"))) &&
                            moment.parseZone(day.dateAt).format("YYYY-MM-DD") >=
                            moment
                                .parseZone(me.startAt)
                                .format("YYYY-MM-DD") &&
                            (am === null || pm === null)) ||
                        (canOvertime && !absenceOnly) ||
                        monthEntity.specialTime
                    ) {
                        if (selectedStartDay === null) {
                            props.daySelect(day, "start");
                            dispatch(
                                TimesheetActions.checkEventBoundaries(
                                    moment
                                        .parseZone(day.dateAt)
                                        .format("YYYY-MM-DD")
                                )
                            );
                        } else {
                            if (
                                selectedStartDay.day.dateAt <= day.dateAt &&
                                selectable()
                            ) {
                                props.daySelect(day, "end");
                            }
                        }
                    }
                }
            }
        }
    };

    const hoverDay = (isHovered) => {
        if (type !== "view") {
            if (selectedStartDay) {
                if (
                    !props.isMonthSubmitted &&
                    (day.workingState === "worked" ||
                        (canOvertime &&
                            formattedMonth(
                                monthEntity.month,
                                monthEntity.year
                            ) <= today)) &&
                    selectedStartDay.day.dateAt <= day.dateAt
                ) {
                    if (isHovered) {
                        if (selectable(day)) {
                            props.dayHover(props.day);
                        }
                    } else {
                        dispatch(TimesheetActions.clearDaysHoveredSelection());
                    }
                }
            }
        }
    };

    const availabilitiesCount = () => {
        let availabilities = overtimesForDay.filter((overtime) => {
            let overtimeCode = overtimeCodeRepo.findWithoutHook(
                overtime.code,
                type
            );
            if (overtimeCode) {
                if (overtimeCode.category === "availability") {
                    return overtime;
                }
            }
        });

        let count = 0;
        availabilities.map((availability, index) => {
            return availability.days.map((day, index) => {
                return day.hours.map((hour, index) => {
                    if (availability.type === "hour") {
                        count++;
                    }
                });
            });
        });

        if (count === 0 && availabilities.length > 0) {
            return availabilities.length;
        }

        return count;
    };

    const interventionsCount = () => {
        let interventions = overtimesForDay.filter((overtime) => {
            let overtimeCode = overtimeCodeRepo.findWithoutHook(
                overtime.code,
                type
            );
            if (overtimeCode) {
                if (overtimeCode.category === "intervention") {
                    return overtime;
                }
            }
        });

        let count = 0;
        interventions.map((intervention, index) => {
            return intervention.days.map((day, index) => {
                return day.hours.map((hour, index) => {
                    if (hour.date === moment.parseZone(day.dateAt).format("YYYY-MM-DD")) {
                        count++;
                    }
                });
            });
        });

        let realExtraActivities;
        if (type === "view") {
            realExtraActivities = monthEntity.extraActivities
        } else {
            realExtraActivities = extraActivities
        }

        realExtraActivities.map((extraActivity) => {
            return extraActivity.complementaries.map((complementary) => {
                if (complementary.date === moment.parseZone(day.dateAt).format("YYYY-MM-DD")) {
                    return complementary.hours.map((hour) => {
                        count++;
                    })
                }
            })
        })

        realExtraActivities.map((extraActivity) => {
            return extraActivity.constraints.map((constraint) => {
                if (constraint.date === moment.parseZone(day.dateAt).format("YYYY-MM-DD")) {
                    return constraint.hours.map((hour) => {
                        count++;
                    })
                }
            })
        })

        return count;
    };

    const amountInExceed = () => {
        let count = 0;
        let interventions = interventionsCount();
        let availabilities = availabilitiesCount();
        if (interventions > 1) {
            count += interventions - 1;
        }

        if (availabilities > 1) {
            count += availabilities - 1;
        }

        return count;
    };

    const handleExpand = (e) => {
        e.stopPropagation();
        dispatch(TimesheetActions.expandDay(day, e.pageX, e.pageY));
    };

    const updateRemote = (dayMoment) => {
        if (dayMoment === 'am') {
            if (targetAmEventCode) {
                if (targetAmEventCode.type !== "activity") {
                    return
                }
            }
        } else if (dayMoment === 'pm') {
            if (targetPmEventCode) {
                if (targetPmEventCode.type !== "activity") {
                    return
                }
            }
        }

        let tempRemote = ''
        if (dayMoment === 'am') {
            switch (remote) {
                case 'am':
                    tempRemote = ''
                    break;
                case 'pm':
                    tempRemote = 'full'
                    break;
                case 'full':
                    tempRemote = 'pm'
                    break;
                default:
                    tempRemote = 'am'
                    break;
            }
        } else if (dayMoment === 'pm') {
            switch (remote) {
                case 'am':
                    tempRemote = 'full'
                    break;
                case 'pm':
                    tempRemote = ''
                    break;
                case 'full':
                    tempRemote = 'am'
                    break;
                default:
                    tempRemote = 'pm'
                    break;
            }
        }

        setRemote(tempRemote)
        let currentDay = moment(day.dateAt).parseZone().format("YYYY-MM-DD")
        let tempRemoteDays = remoteDaysForSend.filter((remoteDay) => {
            return remoteDay.day !== currentDay
        })
        tempRemoteDays.push({
            day: currentDay,
            am: (tempRemote === 'am' || tempRemote === 'full'),
            pm: (tempRemote === 'pm' || tempRemote === 'full')
        })
        setRemoteDaysForSend(tempRemoteDays)
    }


    return (
        <div
            onClick={(e) => selectDay(e)}
            className={getClassNames()}
            onMouseEnter={() => hoverDay(true)}
            onMouseLeave={() => hoverDay(false)}
        >
            <div className="day-header">
                {day.DayNumber}
                {!isRemoteEditing && remote && (<div className={"remote-working-indicator"}>
                    {remote === "pm" && <div className="remote-working-hider am-hider">&nbsp;</div>}
                    <i className={"fas fa-home"}></i>
                    {remote === "am" && <div className="remote-working-hider pm-hider">&nbsp;</div>}
                </div>)}
                {interventionsCount() === 0 && !isRemoteEditing && <div className="hours-for-day">{hoursForDay}</div>}
            </div>

            {hasExtraActivityBtn && type !== "view" && <div
                onClick={(e) => displayOvertimePopUp(e, day, monthEntity)}
                className="add-extra-activity"
            >
                <i className={"fal fa-" + extraActivityBtn} title="Saisir mes activités complémentaires"></i>
            </div>}
            {(interventionsCount() > 1 || availabilitiesCount() > 1) && (
                <div
                    onClick={(e) => handleExpand(e)}
                    className={"see-more-overtime" + (interventionsCount() === 0 ? " higher-see-more-overtime" : "")}
                    title="Détails"
                >
                    <i className="fal fa-chevron-double-down more-overtime-icon"></i>
                </div>
            )}
            {isRemoteEditing && isDayAvailable() && (
                <>
                    {/*<div className="remote-day-divider"></div>*/}
                    <div className="remote-day-dividers">
                        <div
                            className={"remote-day-half-divider" + ((remote === 'am' || remote === 'full') ? ' selected' : '') + ((targetAmEventCode && targetAmEventCode.type !== 'activity') ? ' not-selectable' : '')}
                            onClick={() => updateRemote('am')}></div>
                        <div
                            className={"remote-day-half-divider" + ((remote === 'pm' || remote === 'full') ? ' selected' : '') + ((targetPmEventCode && targetPmEventCode.type !== 'activity') ? ' not-selectable' : '')}
                            onClick={() => updateRemote('pm')}></div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Day;
