import { combineReducers } from "redux";

import {
    FETCH_UNIONS_SUCCESS,
    FETCH_SOCIAL_DOCUMENTS_SUCCESS,
    FETCH_DOCUMENTS_SUCCESS,
    FETCH_UNION_SUCCESS,
    RESET_SELECTED_UNION,
} from "actions/Document";
import Union from "entities/Union";
import Section from "entities/Section";
import SocialSection from "entities/SocialSection";
import WelcomeBook from "entities/WelcomeBook";
import OrganizationChart from "entities/OrganizationChart";
import Cse from "entities/Cse";

export const entities = (
    state = {
        unionList: {},
        welcomeBook: {},
        sections: {},
        socialDocuments: {},
        socialInfos: {},
        unionsArray: {},
        union: {},
        cse: {},
    },
    action
) => {
    switch (action.type) {
        case FETCH_DOCUMENTS_SUCCESS:
            let sections = [];
            let welcomeBook = {};
            let organizationChart = {};
            if (action.response.entities.sections.synchrone) {
                let sectionsArray = Object.values(
                    action.response.entities.sections.synchrone.documents
                );
                sectionsArray.forEach((element) => {
                    sections.push(new Section(element));
                });
                welcomeBook = new WelcomeBook(
                    action.response.entities.sections.synchrone.welcome_book
                );
                organizationChart = new OrganizationChart(
                    action.response.entities.sections.synchrone.organization_chart
                );
            }
            return {
                ...state,
                welcomeBook: welcomeBook,
                sections: sections,
                organizationChart: organizationChart,
            };
        case FETCH_UNION_SUCCESS:
            let union;
            let unionArray = Object.values(action.response.entities.union);
            unionArray.forEach((element) => {
                union = new Union(element);
            });

            return {
                ...state,
                union: union,
            };
        case RESET_SELECTED_UNION:
            return {
                ...state,
                union: {},
            };
        case FETCH_UNIONS_SUCCESS:
            let unions = [];
            if (action.response.entities.union) {
                let unionsArray = Object.values(action.response.entities.union);
                unionsArray.forEach((element) => {
                    unions.push(new Union(element));
                });
            }
            return {
                ...state,
                unionList: unions,
            };
        case FETCH_SOCIAL_DOCUMENTS_SUCCESS:
            let socialDocuments = [];
            let unionsArray = [];
            let socialInfos = [];
            let cseInfos = [];
            if (action.response.entities.social) {
                let documentsArray = Object.values(
                    action.response.entities.social.sections.documents
                );
                let cseArray = Object.values(
                    action.response.entities.social.sections.cse
                );
                let infosArray = Object.values(
                    action.response.entities.social.sections.social
                );
                let tempUnions = Object.values(
                    action.response.entities.social.sections.syndic
                );
                documentsArray.forEach((element) => {
                    socialDocuments.push(new Section(element));
                });
                cseArray.forEach((element) => {
                    cseInfos.push(new SocialSection(element));
                });
                infosArray.forEach((element) => {
                    socialInfos.push(new SocialSection(element));
                });
                tempUnions.forEach((element) => {
                    unionsArray.push(new Union(element));
                });
            }
            return {
                ...state,
                socialDocuments: socialDocuments,
                socialInfos: socialInfos,
                unionsArray: unionsArray,
                cse: cseInfos,
            };
        default:
            return state;
    }
};

export default combineReducers({
    entities,
});
