import store from "store/ConfigureStore";
import Event from "entities/Event";
import { isEmpty } from "utils/Utils";
import moment from "moment";
import "moment/locale/fr";

import { useSelector } from "react-redux";

class OvertimeCodeRepository {
    findAffectations = () => {
        return useSelector((state) => {
            let overtimeCodes = state.timesheet.entities.overtimeCodes;
            if (!isEmpty(overtimeCodes)) {
                let result = [];
                let array = Object.values(overtimeCodes);
                array.forEach((element) => {
                    if (result.indexOf(element.affectation) === -1) {
                        result.push(element.affectation);
                    }
                });
                return result;
            }

            return;
        });
    };

    find = (id, type) => {
        return useSelector((state) => {
            if (id) {
                let overtimeCodes;
                if (type === "view") {
                    overtimeCodes = state.validation.craOvertimeCodes;
                } else {
                    overtimeCodes = state.timesheet.entities.overtimeCodes;
                }
                if (!isEmpty(overtimeCodes)) {
                    let result = null;
                    let array = Object.values(overtimeCodes);
                    array.forEach((element) => {
                        if (element.id === id) {
                            result = element;
                        }
                    });
                    return result;
                }
            }

            return;
        });
    };

    findWithoutHook = (id, type) => {
        let overtimeCodes;
        if (type === "view") {
            overtimeCodes = store.getState().validation.craOvertimeCodes;
        } else {
            overtimeCodes = store.getState().timesheet.entities.overtimeCodes;
        }

        if (!isEmpty(overtimeCodes)) {
            if (id) {
                let result = null;
                let array = Object.values(overtimeCodes);
                array.forEach((element) => {
                    if (element.id === id) {
                        result = element;
                    }
                });
                return result;
            }
        }

        return;
    };
}

export default OvertimeCodeRepository;
