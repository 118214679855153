import { Schemas } from "middleware/Schemas";
import * as Params from "config/Parameters";

import { apiOptions } from "utils/Api";

export const GET_POSTS_REQUEST = "GET_POSTS_REQUEST";
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export const GET_POSTS_FAILURE = "GET_POSTS_FAILURE";
export const fetchPosts = () => {
    return {
        types: [GET_POSTS_REQUEST, GET_POSTS_SUCCESS, GET_POSTS_FAILURE],
        needLogin: true,
        callAPI: () => {
            return fetch(`${Params.API_ROOT}news`, apiOptions("GET"));
        },
        payload: {},
        schema: Schemas.POST_ARRAY,
    };
};

export const GET_POST_REQUEST = "GET_POST_REQUEST";
export const GET_POST_SUCCESS = "GET_POST_SUCCESS";
export const GET_POST_FAILURE = "GET_POST_FAILURE";
export const fetchPost = (id) => {
    return {
        types: [GET_POST_REQUEST, GET_POST_SUCCESS, GET_POST_FAILURE],
        needLogin: true,
        callAPI: () => {
            return fetch(`${Params.API_ROOT}news/${id}`, apiOptions("GET"));
        },
        payload: {},
        schema: Schemas.POST,
    };
};

export const GET_HIGHLIGHT_REQUEST = "GET_HIGHLIGHT_REQUEST";
export const GET_HIGHLIGHT_SUCCESS = "GET_HIGHLIGHT_SUCCESS";
export const GET_HIGHLIGHT_FAILURE = "GET_HIGHLIGHT_FAILURE";
export const fetchHighlight = () => {
    const buildUrl = () => {
        let url = `${Params.API_ROOT}news/highlight?expose[]=`;
        let args = [
            "id",
            "title",
            "summary",
            "content",
            "type",
            "url",
            "published_at",
            "homepage_content",
            "homepage_image_url",
            "file_url",
            "place",
        ];
        url += args.join("&expose[]=");

        return url;
    };

    return {
        types: [
            GET_HIGHLIGHT_REQUEST,
            GET_HIGHLIGHT_SUCCESS,
            GET_HIGHLIGHT_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(buildUrl(), apiOptions("GET"));
        },
        payload: {},
        schema: Schemas.HIGHLIGHT,
    };
};

export const GET_INTERNAL_NEWS_REQUEST = "GET_INTERNAL_NEWS_REQUEST";
export const GET_INTERNAL_NEWS_SUCCESS = "GET_INTERNAL_NEWS_SUCCESS";
export const GET_INTERNAL_NEWS_FAILURE = "GET_INTERNAL_NEWS_FAILURE";
export const fetchInternalNews = () => {
    return {
        types: [GET_INTERNAL_NEWS_REQUEST, GET_INTERNAL_NEWS_SUCCESS, GET_INTERNAL_NEWS_FAILURE],
        needLogin: true,
        callAPI: () => {
            return fetch(`${Params.API_ROOT}internal-news`, apiOptions("GET"));
        },
        payload: {},
        schema: Schemas.INTERNAL_NEWS,
    };
};