import React, { useState } from "react";
import "./DoctorItem.scss";

const DoctorItem = (props) => {
    const { item } = props;

    return (
        <div className="doctor-item">
            <div className="doctor-place">{item.name}</div>
            <div className="doctor-type">{item.type}</div>
            <div>
                <span className="doctor-title">Médécin : </span>
                {item.doctor}
            </div>
            <div className="doctor-contact">
                <div className="doctor-phones">
                    <i className="fal fa-phone"></i>
                    <div className="doctor-phones-content">
                        {item.phones.map((phone, key) => {
                            return <div key={key}>{phone}</div>;
                        })}
                    </div>
                </div>
                <div className="doctor-emails">
                    <i className="fal fa-envelope-open"></i>
                    <div className="doctor-emails-content">
                        {item.emails.map((email, key) => {
                            return (
                                <a
                                    className="doctor-email"
                                    href={`mailto:${email}`}
                                    key={key}
                                >
                                    {email}
                                </a>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="doctor-address">
                <i className="fal fa-map-marker"></i>
                {item.address}
            </div>
        </div>
    );
};

export default DoctorItem;
