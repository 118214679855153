import store from "store/ConfigureStore";
import Post from "entities/Post";
import { isEmpty } from "utils/Utils";
import { useSelector } from "react-redux";

class PostRepository {
    find = (id) => {
        let state = store.getState().news.entities.posts;

        if (!isEmpty(state[id])) {
            return new Post(state[id]);
        }

        return;
    };

    findLast = () => {
        return useSelector((state) => {
            let news = state.news.entities.posts;
            if (!isEmpty(news)) {
                let array = Object.values(news);
                // Twitter
                // return array[array.length - 22];

                // Linkedin
                // return array[array.length - 3];

                // FB
                // return array[array.length - 72];

                // Insta
                // return array[array.length - 8];

                // Event
                // return array[array.length - 71];

                // Rex
                // return array[array.length - 2];

                // Zoom
                // for (let index = 0; index < array.length; index++) {
                //     const element = array[index];
                //     if (element.id === 54) {
                //         return element;
                //     }
                // }

                // Interview
                // for (let index = 0; index < array.length; index++) {
                //     const element = array[index];
                //     if (element.id === 200) {
                //         return element;
                //     }
                // }

                // Press
                // for (let index = 0; index < array.length; index++) {
                //     const element = array[index];
                //     if (element.id === 133) {
                //         return element;
                //     }
                // }
            }
            return null;
        });
    };
}

export default PostRepository;
