import React, { useEffect } from "react";
import moment from "moment";
import "moment/locale/fr";
import { useSelector, useDispatch } from "react-redux";
import { changeOverlayContent } from "events/OverlayEvents";
import * as ValidationActions from "actions/Validation";
import Lightbox from "components/common/Lightbox";

import "./CommentBox.scss";

const CommentBox = (props) => {
    const { month } = props;
    const dispatch = useDispatch();
    const monthActions = useSelector((state) => state.validation.monthActions);
    const users = useSelector((state) => state.user.listUsers);

    useEffect(() => {
        dispatch(ValidationActions.fetchMonthAction(month.id));
    }, []);

    const close = () => {
        changeOverlayContent(null);
    };

    const CommentRow = (props) => {
        const { monthAction } = props;

        return (
            <div className="comment-row">
                <div className="comment-author">
                    {monthAction.author ? (
                        monthAction.author.firstname +
                        " " +
                        monthAction.author.lastname
                    ) : (
                        <span className="italic-label">Système</span>
                    )}
                </div>
                <div className="comment-created-at">
                    {moment
                        .parseZone(monthAction.createdAt)
                        .format("DD/MM/YYYY [à] HH[h]mm")}
                </div>
                <div className="comment-comment">{monthAction.comment}</div>
            </div>
        );
    };

    return (
        <Lightbox className="lb-centered comment-absence-lb">
            <div className="comment-absence-title">Commentaires</div>
            <div className="close-lb" onClick={close}>
                <i className="fal fa-times"></i>
            </div>
            <div className="comment-container">
                {monthActions
                    .sort((a, b) => {
                        return (
                            moment.parseZone(b.createdAt) -
                            moment.parseZone(a.createdAt)
                        );
                    })
                    .map((element, key) => {
                        if (element.comment) {
                            return (
                                <CommentRow monthAction={element} key={key} />
                            );
                        }
                        return false;
                    })}
            </div>
        </Lightbox>
    );
};

export default CommentBox;
