import React from "react";

const HomepageNews = (props) => {
    const {internalNews} = props

    return (
        <div className="home-internal-news">
            <img
                className="home-internal-picture"
                src={internalNews?.fullImageUrl}
            />
            <div className="home-internal-content">
                <div className="home-internal-title">
                    {internalNews?.title}
                </div>
                <div className="home-internal-text" dangerouslySetInnerHTML={{
                    __html: internalNews?.htmlContent,
                }}>
                </div>

            </div>
        </div>)
}

export default HomepageNews;