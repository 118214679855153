import {
    USER_FILTER_SUCCESS,
    ABSENCES_FOR_VALIDATION_SUCCESS,
    EVENT_CODES_FOR_VALIDATION_SUCCESS,
    FETCH_PERIOD_SUCCESS,
    FETCH_EVENT_ACTION_REQUEST,
    FETCH_EVENT_ACTION_SUCCESS,
    FETCH_MONTH_ACTION_SUCCESS,
    FETCH_MONTH_ACTION_REQUEST,
    MONTHS_FOR_VALIDATION_SUCCESS,
    FETCH_OVERTIMES_FOR_VALIDATION_SUCCESS,
    RESET_OVERTIMES_FOR_VALIDATION,
    FETCH_EVENT_FOR_VALIDATION_SUCCESS,
    FETCH_ABSENCE_HISTORY_FOR_VALIDATION_SUCCESS,
    EVENTCODES_FOR_CRA_DISPLAY_SUCCESS,
    FETCH_EVENTS_FOR_CRA_DISPLAY_SUCCESS,
    LIST_OVERTIME_CODES_FOR_CRA_DISPLAY_SUCCESS,
    MONTH_FOR_CRA_DISPLAY_SUCCESS,
    RESET_CRA_DISPLAY,
    FETCH_EXTRA_ACTIVITIES_SUCCESS,
    FETCH_EXTRA_ACTIVITIES_WEEKS_FOR_VALIDATION_SUCCESS
} from "actions/Validation";
import User from "entities/User";
import EventCode from "entities/EventCode";
import Event from "entities/Event";
import CRA from "entities/CRA";
import Period from "entities/Period";
import EventAction from "entities/EventAction";
import MonthAction from "entities/MonthAction";
import Overtime from "entities/Overtime";
import ValidationEvent from "entities/ValidationEvent";
import OvertimeCode from "entities/OvertimeCode";
import Month from "entities/Month";
import ExtraActivityMonth from "../entities/ExtraActivityMonth";
import ExtraActivityWeek from "../entities/ExtraActivityWeek";

export const validation = (
    state = {
        users: [],
        absences: [],
        months: [],
        eventCodes: [],
        events: [],
        periods: [],
        eventActions: [],
        monthActions: [],
        overtimes: [],
        totalUsersCount: 0,
        absenceHistory: [],
        craEventCodes: [],
        craOvertimeCodes: [],
        craEvents: [],
        craDisplayMonth: null,
        extraActivityMonths: [],
        extraActivitiesWeeksForValidation: [],
        extraActivitiesConfigForValidation: {}
    },
    action
) => {
    switch (action.type) {
        case USER_FILTER_SUCCESS:
            let users = [];
            if (action.response.entities.user) {
                let userArray = Object.values(action.response.entities.user);
                userArray.forEach((element) => {
                    users.push(new User(element));
                });
            }
            return {
                ...state,
                users: users,
                totalUsersCount: action.totalCount,
            };

        case ABSENCES_FOR_VALIDATION_SUCCESS:
            let absences = [];
            if (action.response.entities.absencesForValidation) {
                let absencesArray = Object.values(
                    action.response.entities.absencesForValidation
                );
                absencesArray.forEach((element) => {
                    absences.push(new Event(element));
                });
            }
            return {
                ...state,
                absences: absences,
            };

        case MONTHS_FOR_VALIDATION_SUCCESS:
            let months = [];
            if (action.response.entities.monthsForValidation) {
                let monthsArray = Object.values(
                    action.response.entities.monthsForValidation
                );
                monthsArray.forEach((element) => {
                    months.push(new CRA(element));
                });
            }
            return {
                ...state,
                months: months,
                totalUsersCount: action.totalCount,
            };

        case EVENT_CODES_FOR_VALIDATION_SUCCESS:
            let eventCodes = [];
            if (action.response.entities.eventCodesForValidation) {
                let eventCodesArray = Object.values(
                    action.response.entities.eventCodesForValidation
                );
                eventCodesArray.forEach((element) => {
                    eventCodes.push(new EventCode(element));
                });
            }
            return {
                ...state,
                eventCodes: eventCodes,
            };

        case FETCH_OVERTIMES_FOR_VALIDATION_SUCCESS:
            let overtimes = [];
            if (action.response.entities.overtimesForValidation) {
                let overtimesArray = Object.values(
                    action.response.entities.overtimesForValidation
                );
                overtimesArray.forEach((element) => {
                    overtimes.push(new Overtime(element));
                });
            }
            return {
                ...state,
                overtimes: overtimes,
            };

        case RESET_OVERTIMES_FOR_VALIDATION:
            let empty = [];
            return {
                ...state,
                overtimes: empty,
            };

        case FETCH_EVENT_FOR_VALIDATION_SUCCESS:
            let events = [];
            if (action.response.entities.eventsForValidation) {
                let eventsArray = Object.values(
                    action.response.entities.eventsForValidation
                );
                eventsArray.forEach((element) => {
                    events.push(new ValidationEvent(element));
                });
            }
            return {
                ...state,
                events: events,
            };

        case FETCH_PERIOD_SUCCESS:
            let periods = [];
            if (action.response.entities.periods) {
                let periodsArray = Object.values(
                    action.response.entities.periods
                );
                periodsArray.forEach((element) => {
                    periods.push(new Period(element));
                });
            }
            return {
                ...state,
                periods: periods,
            };

        case FETCH_EVENT_ACTION_REQUEST:
            return {
                ...state,
                eventActions: [],
            };

        case FETCH_EVENT_ACTION_SUCCESS:
            let eventActions = [];
            if (action.response.entities.eventActions) {
                let eventActionsArray = Object.values(
                    action.response.entities.eventActions
                );
                eventActionsArray.forEach((element) => {
                    eventActions.push(new EventAction(element));
                });
            }
            return {
                ...state,
                eventActions: eventActions,
            };

        case FETCH_MONTH_ACTION_REQUEST:
            return {...state, monthActions: []};

        case FETCH_MONTH_ACTION_SUCCESS:
            let monthActions = [];
            if (action.response.entities.monthActions) {
                let monthActionsArray = Object.values(
                    action.response.entities.monthActions
                );
                monthActionsArray.forEach((element) => {
                    monthActions.push(new MonthAction(element));
                });
            }
            return {
                ...state,
                monthActions: monthActions,
            };

        case FETCH_ABSENCE_HISTORY_FOR_VALIDATION_SUCCESS:
            let absenceHistory = [];
            if (action.response.entities.absenceHistory) {
                for (
                    let index = 0;
                    index < action.response.result.length;
                    index++
                ) {
                    const id = action.response.result[index];
                    absenceHistory.push(
                        new Event(action.response.entities.absenceHistory[id])
                    );
                }
            }
            return {...state, absenceHistory: absenceHistory};

        case FETCH_EVENTS_FOR_CRA_DISPLAY_SUCCESS:
            let craEvents = [];
            if (action.response.entities.events) {
                let eventsArray = Object.values(
                    action.response.entities.events
                );
                eventsArray.forEach((element) => {
                    craEvents.push(new Event(element));
                });
            }
            return {
                ...state,
                craEvents: craEvents,
            };

        case EVENTCODES_FOR_CRA_DISPLAY_SUCCESS:
            let craEventCodes = [];
            let eventCodesArray = Object.values(
                action.response.entities.eventCodes
            );
            eventCodesArray.forEach((element) => {
                craEventCodes.push(new EventCode(element));
            });
            return {
                ...state,
                craEventCodes: craEventCodes,
            };

        case LIST_OVERTIME_CODES_FOR_CRA_DISPLAY_SUCCESS:
            let craOvertimeCodes = [];
            let overtimeCodesArray = [];
            if (action.response.entities.overtimeCodes) {
                overtimeCodesArray = Object.values(
                    action.response.entities.overtimeCodes
                );
            }
            overtimeCodesArray.forEach((element) => {
                craOvertimeCodes.push(new OvertimeCode(element));
            });

            return {
                ...state,
                craOvertimeCodes: craOvertimeCodes,
            };

        case MONTH_FOR_CRA_DISPLAY_SUCCESS:
            let craDisplayMonth = new Month(
                action.response.entities.month[action.response.result]
            );
            return {
                ...state,
                craDisplayMonth: craDisplayMonth,
            };

        case RESET_CRA_DISPLAY:
            return Object.assign({}, state, {
                craEvents: [],
                craEventCodes: [],
                craOvertimeCodes: [],
                craDisplayMonth: null,
            });

        case FETCH_EXTRA_ACTIVITIES_SUCCESS:
            let extraActivityMonths = [];
            if (action.response.entities.extraActivityMonths) {
                let extraActivityMonthsArray = Object.values(
                    action.response.entities.extraActivityMonths
                );
                extraActivityMonthsArray.forEach((element) => {
                    extraActivityMonths.push(new ExtraActivityMonth(element));
                });
            }

            return {
                ...state,
                extraActivityMonths: extraActivityMonths
            };

        case FETCH_EXTRA_ACTIVITIES_WEEKS_FOR_VALIDATION_SUCCESS:
            let extraActivitiesWeeksForValidation = [];
            if (action.response.entities.extraActivitiesWeeksForValidation) {
                let extraActivitiesWeeksForValidationArray = Object.values(
                    action.response.entities.extraActivitiesWeeksForValidation
                );
                extraActivitiesWeeksForValidationArray.forEach((element) => {
                    extraActivitiesWeeksForValidation.push(new ExtraActivityWeek(element));
                })
            }

            return {
                ...state,
                extraActivitiesWeeksForValidation: extraActivitiesWeeksForValidation
            }

        default:
            return state;
    }
};

export default validation;
