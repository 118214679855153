import React from "react";

import "./ActivityBlock.scss";

const ActivityBlock = (props) => {
    const { month, user } = props;

    return (
        <div className="activity-block">
            {month.noPrestation ? (
                <div className="activity-line no-prestation">
                    Aucune prestation ce mois-ci
                </div>
            ) : (
                month.events &&
                Object.values(month.events).map((event, key) => {
                    if (event.type === "absence") {
                        if (event.days > 0 && user.type !== "contractor") {
                            return (
                                <div
                                    key={key}
                                    className="activity-line not-validated"
                                >
                                    <div className="activity-description">
                                        {event.description}
                                    </div>
                                </div>
                            );
                        }
                    } else {
                        return (
                            <div key={key} className="activity-line">
                                {event.description}
                            </div>
                        );
                    }
                })
            )}
        </div>
    );
};

export default ActivityBlock;
