import React, { useEffect, useState } from "react";

import moment from "moment";
import "moment/locale/fr";
import "./PeriodContainer.scss";
import { useDispatch, useSelector } from "react-redux";
import { changeOverlayContent } from "events/OverlayEvents";
import * as ValidationActions from "actions/Validation";
import * as UserActions from "actions/User";
import { monthInLetter } from "utils/Utils";
import LockForm from "components/Backend/PeriodContainer/LockForm";
import ForceSubmit from "components/Backend/PeriodContainer/ForceSubmit";
import ShowComment from "components/Backend/PeriodContainer/ShowComment";

const PeriodContainer = () => {
    const [targetYear, setTargetYear] = useState(
        moment.parseZone().format("YYYY")
    );
    const dispatch = useDispatch();
    const periods = useSelector((state) => state.validation.periods);
    const today = moment.parseZone();

    useEffect(() => {
        dispatch(ValidationActions.fetchPeriods());
        dispatch(UserActions.fetchUserList());
    }, []);

    const PeriodRow = (props) => {
        const { period } = props;

        const isAfterCurrentMonth = () => {
            let month = period.month;
            let periodString =
                "01/" + month.toString().padStart(2, "0") + "/" + period.year;

            if (today < moment(periodString, "DD/MM/YYYY")) {
                return true;
            }

            return false;
        };

        return (
            <div className="period-row">
                <div className="period-row-month">
                    {monthInLetter(period.month)}
                    {period.closed ? (
                        <span className="lock-icon locked-icon-container">
                            <i className="fas fa-lock"></i>
                            <div className="lock-date">
                                {period.closedAt &&
                                    moment
                                        .parseZone(period.closedAt)
                                        .format("DD/MM/YYYY")}
                            </div>
                        </span>
                    ) : (
                        <span className="lock-icon">
                            <i className="fas fa-unlock"></i>
                        </span>
                    )}
                </div>
                <div className="period-row-validated2">{period.validated2}</div>
                <div className="period-row-validated1">{period.validated1}</div>
                <div className="period-row-submitted">{period.submitted}</div>
                <div className="period-row-non-submitted">
                    {period.nonSubmitted}
                </div>
                <div className="period-row-total">{period.total}</div>
                <div className="period-row-actions">
                    {!period.closed ? (
                        !isAfterCurrentMonth() &&
                        period.validated1 === 0 &&
                        period.submitted === 0 ? (
                            <div
                                className="lock-action"
                                title="Clôturer la période"
                                onClick={() =>
                                    changeOverlayContent(
                                        <LockForm
                                            reload={() =>
                                                dispatch(
                                                    ValidationActions.fetchPeriods()
                                                )
                                            }
                                            period={period}
                                        />
                                    )
                                }
                            >
                                <i className="fas fa-lock"></i>
                            </div>
                        ) : (
                            <div className="lock-action unavailable">
                                <i className="fas fa-lock"></i>
                            </div>
                        )
                    ) : (
                        ""
                    )}

                    {!period.closed && !isAfterCurrentMonth() ? (
                        <div
                            className="force-submit-action"
                            title="Envoyer les CRAs complets"
                            onClick={() => {
                                changeOverlayContent(
                                    <ForceSubmit
                                        reload={() =>
                                            dispatch(
                                                ValidationActions.fetchPeriods()
                                            )
                                        }
                                        period={period}
                                    />
                                );
                            }}
                        >
                            <i className="fas fa-paper-plane"></i>
                        </div>
                    ) : (
                        ""
                    )}

                    {period.closed && period.comment ? (
                        <div
                            className="open-comment"
                            title="Afficher le commentaire"
                            onClick={() =>
                                changeOverlayContent(
                                    <ShowComment period={period} />
                                )
                            }
                        >
                            <i className="fas fa-comment-alt"></i>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="period-container">
            <div className="year">
                <span className="previous-year-btn">
                    {periods.filter(
                        (element, key) =>
                            parseInt(element.year) === parseInt(targetYear - 1)
                    ).length > 0 ? (
                        <i
                            className="fas fa-chevron-left"
                            onClick={() => setTargetYear(targetYear - 1)}
                        ></i>
                    ) : (
                        <i className="fas fa-chevron-left unavailable"></i>
                    )}
                </span>
                <span className="current-year">{targetYear}</span>
                <span className="next-year-btn">
                    {periods.filter(
                        (element, key) =>
                            parseInt(element.year) === parseInt(targetYear + 1)
                    ).length > 0 ? (
                        <i
                            className="fas fa-chevron-right"
                            onClick={() => setTargetYear(targetYear + 1)}
                        ></i>
                    ) : (
                        <i className="fas fa-chevron-right unavailable"></i>
                    )}
                </span>
            </div>
            <div className="period-container-table">
                <div className="period-container-table-header">
                    <div className="period-header-month">Période</div>
                    <div className="period-header-validated2">
                        CRAs validés niveau 2
                    </div>
                    <div className="period-header-validated1">
                        CRAs en attente de validation niveau 2
                    </div>
                    <div className="period-header-submitted">
                        CRAs en attente de validation niveau 1
                    </div>
                    <div className="period-header-non-submitted">
                        CRAs non envoyés par les collaborateurs
                    </div>
                    <div className="period-header-total">Total CRAs</div>
                    <div className="period-header-actions">Actions</div>
                </div>
                {periods
                    .filter(
                        (element, key) =>
                            parseInt(element.year) === parseInt(targetYear)
                    )
                    .sort((a, b) => b.month - a.month)
                    .map((element, key) => {
                        return <PeriodRow key={key} period={element} />;
                    })}
            </div>
        </div>
    );
};

export default PeriodContainer;
