import { Schemas } from "middleware/Schemas";
import * as Params from "config/Parameters";

import { apiOptions } from "utils/Api";

export const CHANGE_PERSONAL_EMAIL_REQUEST = "CHANGE_PERSONAL_EMAIL_REQUEST";
export const CHANGE_PERSONAL_EMAIL_SUCCESS = "CHANGE_PERSONAL_EMAIL_SUCCESS";
export const CHANGE_PERSONAL_EMAIL_FAILURE = "CHANGE_PERSONAL_EMAIL_FAILURE";
export const updatePersonalEmail = (json) => {
    return {
        types: [
            CHANGE_PERSONAL_EMAIL_REQUEST,
            CHANGE_PERSONAL_EMAIL_SUCCESS,
            CHANGE_PERSONAL_EMAIL_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}account/change-personal-email`,
                apiOptions("PATCH", json)
            );
        },
        payload: { json },
        schema: Schemas.PERSONAL_EMAIL,
    };
};
