export const CONTACT_SCOPE = "/contacts";
export const CONSULTANT_CONTACT = CONTACT_SCOPE + "/mes-interlocuteurs";
export const CONSULTING_TEAM = CONTACT_SCOPE + "/ma-consulting-team";
export const CONTACTS = CONTACT_SCOPE + "/mes-contacts";

const getContactRoutesList = me => {
    let routes = [];
    if (me) {
        if (me.hasRole("ROLE_MY_CONTACTS")) {
            routes.push(CONSULTANT_CONTACT);
        }

        if (me.hasRole("ROLE_CONSULTING_TEAM")) {
            routes.push(CONSULTING_TEAM);
        }

        if (me.hasRole("ROLE_INTERNAL_CONTACTS")) {
            routes.push(CONTACTS);
        }
    }

    return routes;
};

export const getContactRoutes = me => {
    return "(" + getContactRoutesList(me).join("|") + ")";
};

export const getContactDefaultRoute = me => {
    return getContactRoutesList(me).shift() || CONSULTANT_CONTACT;
};
