import React, {useEffect} from "react";

import moment from "moment";
import "moment/locale/fr";

import {
    CONSTRAINT_INPUT,
    HOUR_INPUT,
    TICKET_INPUT,
} from "components/TimesheetContainer/Forms/Event/StepActions/Step3Overtime";
import { Field, FieldArray } from "formik";

import DayLine from "components/TimesheetContainer/Forms/Event/StepActions/DayLine";
import TotalsContainer from "components/TimesheetContainer/Forms/Event/StepActions/TotalsContainer";
import { daysForOvertimeList } from "utils/Utils";

const OvertimeDaysContainer = (props) => {
    const {
        selectedDays,
        values,
        overtimeType,
        onChange,
        onBlur,
        ignoreMission,
        isSubmitting
    } = props

    const Header = () => {
        const HourHeader = () => {
            return (
                <div className="overtime-days-container-header">
                    <div className="col1">Début :</div>
                    <div className="col2">Fin :</div>
                    <div className="col3">Nombre d'heures :</div>
                </div>
            );
        };

        const TicketHeader = () => {
            return (
                <div className="overtime-days-container-header">
                    <div className="col1">Nombre de tickets :</div>
                </div>
            );
        };

        let component;
        switch (overtimeType) {
            case HOUR_INPUT:
                component = <HourHeader />;
                break;
            case TICKET_INPUT:
                component = <TicketHeader />;
                break;
            default:
                component = "";
                break;
        }

        return component;
    };

    return (
        <div className="overtime-days-container">
            <div className="overtime-days-container-left">
                <Header />
                <FieldArray
                    name="days"
                    render={(arrayHelpers) =>
                        daysForOvertimeList(
                            values,
                            overtimeType
                        ).map((day, key) => (
                            <DayLine
                                day={day}
                                id={key}
                                key={key}
                                values={day}
                                overtimeType={overtimeType}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        ))
                    }
                />
            </div>
            <TotalsContainer
                overtimeType={overtimeType}
                onChange={onChange}
                onBlur={onBlur}
                values={values.days}
                constraintCategory={props.constraintCategory}
                ignoreMission={ignoreMission}
                isSubmitting={isSubmitting}
            />
        </div>
    );
};

export default OvertimeDaysContainer;
