import React from "react";

import "./PersonTypeBadge.scss";

const PersonTypeBadge = (props) => {
    const { personType } = props;

    return (
        <div className={"person-type-badge one-liner"}>
            {personType}
        </div>
    );
};

export default PersonTypeBadge;
