import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import Day from "./Day";
import moment from "moment";
import "moment/locale/fr";

import * as TimesheetActions from "actions/Timesheet";

import Month from "entities/Month";
import {default as DayEntity} from "entities/Day";
import ExpandedDay from "./ExpandedDay";

const DaysContainer = (props) => {
    const dispatch = useDispatch();
    const {days, remoteDays, monthEntity, type, me, user, weeksCount, modality, isRemoteEditing, setRemoteDaysForSend, remoteDaysForSend} = props;

    const selectedDays = useSelector((state) => state.timesheet.selectedDays);
    const hoveredDays = useSelector((state) => state.timesheet.hoveredDays);
    const selectedStartDay = useSelector(
        (state) => state.timesheet.selectedStartDay
    );
    const overtimeCodes = useSelector(
        (state) => state.timesheet.entities.overtimeCodes
    );
    const [canOvertime, setCanOvertime] = useState(false);

    useEffect(() => {
        const startAt = moment().parseZone(monthEntity.year + "-" + monthEntity.month + "-01").format("YYYY-MM-DD");
        const endAt = moment().parseZone(monthEntity.year + "-" + monthEntity.month + "-01").endOf("month").format("YYYY-MM-DD");
        Promise.resolve(
            dispatch(TimesheetActions.listOvertimeCodes(false, startAt, endAt))
        );
    }, []);

    useEffect(() => {
        setCanOvertime(checkCanOvertime());
    }, [overtimeCodes]);

    const today = moment.parseZone().format("MM-YYYY");
    const formattedMonth = (month, year) => {
        let monthString = "";
        if (month <= 10) {
            monthString = `0${month}`;
        } else {
            monthString = month;
        }

        return monthString + "-" + year;
    };

    const daySelect = (day, side) => {
        if (!isRemoteEditing) {
            let selectable = true;
            let iterableDays = Object.values(days);
            let daysToAdd = [];
            daysToAdd.push(day.id);
            if (side === "end") {
                iterableDays.forEach((element) => {
                    if (element.worked || canOvertime) {
                        if (
                            moment.parseZone(element.dateAt) >
                            moment.parseZone(selectedStartDay.day.dateAt) &&
                            moment.parseZone(element.dateAt) <
                            moment.parseZone(day.dateAt)
                        ) {
                            if (!canOvertime && (element.am || element.pm)) {
                                selectable = false;
                            } else {
                                daysToAdd.push(element.id);
                            }
                        }
                    }
                });
            }
            if (selectable) {
                if (side === "start") {
                    dispatch(TimesheetActions.selectStartDay(day));
                    if (day.am) {
                        dispatch(TimesheetActions.lockStart());
                    }
                } else if (side === "end") {
                    dispatch(TimesheetActions.selectEndDay(day));
                    if (day.pm) {
                        dispatch(TimesheetActions.lockEnd());
                    }
                }
                dispatch(TimesheetActions.selectDays(daysToAdd));
            }
        }
    };

    const dayHover = (dayHovered) => {
        if (!isRemoteEditing) {
            if (selectedStartDay) {
                let iterableDays = Object.values(days);
                let daysToAdd = [];
                iterableDays.forEach((element) => {
                    if (element.worked || canOvertime) {
                        if (
                            moment.parseZone(element.dateAt) >
                            moment.parseZone(selectedStartDay.day.dateAt) &&
                            moment.parseZone(element.dateAt) <=
                            moment.parseZone(dayHovered.dateAt)
                        ) {
                            if (
                                !element.am ||
                                (canOvertime &&
                                    formattedMonth(
                                        monthEntity.month,
                                        monthEntity.year
                                    ) <= today)
                            ) {
                                daysToAdd.push(element.id);
                            }
                        }
                    }
                });
                dispatch(TimesheetActions.hoverDays(daysToAdd));
            } else {
                dispatch(TimesheetActions.clearDaysHoveredSelection());
            }
        }
    };

    const getDays = () => {
        let daysArray = [];

        if (monthEntity instanceof Month) {
            let lastDayOfMonth = moment
                .parseZone()
                .month(monthEntity.month - 1)
                .year(monthEntity.year)
                .endOf("month");
            let firstDayOfMonth = moment
                .parseZone()
                .month(monthEntity.month - 1)
                .year(monthEntity.year)
                .startOf("month");

            if (firstDayOfMonth.isoWeekday() !== 1) {
                let startingDay = moment
                    .parseZone()
                    .month(monthEntity.month - 1)
                    .year(monthEntity.year)
                    .startOf("month")
                    .subtract(firstDayOfMonth.isoWeekday() - 1, "days");
                for (let i = 0; i < firstDayOfMonth.isoWeekday() - 1; i++) {
                    let data = {
                        id: null,
                        date_at: moment.parseZone(startingDay),
                        worked: false,
                        working_state: "notCurrentMonth",
                        am: null,
                        pm: null,
                    };
                    let newDay = new DayEntity(data);
                    daysArray.push(newDay);
                    startingDay.add(1, "days");
                }
            }

            Object.values(days).forEach((element, index) => {
                if (!(element instanceof DayEntity)) {
                    daysArray.push(new DayEntity(element));
                } else {
                    daysArray.push(element);
                }
            });

            if (lastDayOfMonth.isoWeekday() !== 7) {
                let nextMonthStartingDay = moment
                    .parseZone()
                    .month(monthEntity.month - 1)
                    .year(monthEntity.year)
                    .endOf("month");
                while (nextMonthStartingDay.isoWeekday() !== 7) {
                    nextMonthStartingDay.add(1, "days");
                    let data = {
                        id: null,
                        date_at: moment.parseZone(nextMonthStartingDay),
                        worked: false,
                        working_state: "notCurrentMonth",
                        am: null,
                        pm: null,
                    };
                    let newDay = new DayEntity(data);
                    daysArray.push(newDay);
                }
            }
        }

        return daysArray;
    };

    const isSelected = (id) => {
        return !!selectedDays.includes(id);


    };

    const isInHoveredRange = (id) => {
        return !!hoveredDays.includes(id);


    };

    const checkCanOvertime = () => {
        if (overtimeCodes.length === 1) {
            return !(overtimeCodes[0].code === 'ASTAND' && !monthEntity.canExtraActivities);
        } else {
            return overtimeCodes.length > 1;
        }
    }



    return (
        <div className="calendar">
            {getDays().map((item, key) => {
                return <Day
                    currentMonth={monthEntity.month}
                    currentYear={monthEntity.year}
                    key={key}
                    day={item}
                    daySelect={daySelect}
                    selected={isSelected(item.id)}
                    isInHoveredRange={isInHoveredRange(item.id)}
                    mainActivity={props.mainActivity}
                    isMonthSubmitted={props.monthEntity.submitted}
                    dayHover={dayHover}
                    canOvertime={checkCanOvertime()}
                    monthEntity={monthEntity}
                    type={type}
                    me={me}
                    user={user}
                    hasExtraActivityBtn={item.IsSunday && monthEntity.extraActivityWeeks[item.WeekNumber].can_extra_activities && !monthEntity.submitted}
                    extraActivityBtn={monthEntity.extraActivityExists(item.WeekNumber) ? 'pen' : 'plus-circle'}
                    modality={modality}
                    isRemoteEditing={isRemoteEditing}
                    remoteDays={remoteDays}
                    // remote={isDayRemote(item.dateAt)}
                    remoteDaysForSend={remoteDaysForSend}
                    setRemoteDaysForSend={setRemoteDaysForSend}
                />
            })}
            <ExpandedDay
                type={type}
                currentMonth={monthEntity.month}
                currentYear={monthEntity.year}
                isMonthEditable={monthEntity.editable}
                extraActivitiesForView={monthEntity.extraActivities}
            />
        </div>
    );
};

export default DaysContainer;
