import React from "react";
import { useSelector } from "react-redux";
import { isoFormat } from "utils/Utils";
import ManageeGroup from "components/Backend/TeamPlanningContainer/TeamPlanningTable/ManageeGroup";
import ManageeLine from "components/Backend/TeamPlanningContainer/TeamPlanningTable/ManageeLine";
import "./TeamPlanningTableBody.scss";

const TeamPlanningTableBody = (props) => {
    const meHierarchy = useSelector((state) => state.user.meHierarchy);
    const {
        me,
        managees,
        month,
        year,
        absences,
        holidays,
        openManager,
        closeManager,
        daysArray,
    } = props;

    const formattedMonth = (month, year) => {
        let monthString = "";
        if (month < 10) {
            monthString = `0${month}`;
        } else {
            monthString = month;
        }

        return year + "-" + monthString;
    };

    return (
        <tbody>
            {managees
                ?.filter((element) => {
                    return element.matricule === me.matricule;
                })
                .filter((element) =>
                    formattedMonth(month, year)
                        ? (isoFormat(element.startAt) <=
                              isoFormat(new Date(year, month + 1, 0)) &&
                              isoFormat(element.endAt) >=
                                  formattedMonth(month, year) + "-01") ||
                          (isoFormat(element.startAt) <=
                              isoFormat(new Date(year, month + 1, 0)) &&
                              !element.endAt)
                        : true
                )
                .map((element, key) => {
                    let relevantAbsences = absences.filter((item) => {
                        return item.user === element.id;
                    });
                    return (
                        <ManageeLine
                            key={key}
                            user={element}
                            absences={relevantAbsences}
                            hasManagees={false}
                            level={0}
                            me={me}
                            holidays={holidays}
                            openManager={openManager}
                            closeManager={closeManager}
                            daysArray={daysArray}
                            manager={element}
                        />
                    );
                })}

            <div className="planning-separator"></div>

            {meHierarchy[me.matricule] !== undefined ? (
                meHierarchy[me.matricule].map((managee, key) => {
                    return (
                        <ManageeGroup
                            key={key}
                            managee={managee}
                            manager={me.matricule}
                            level={0}
                            // meHierarchy={meHierarchy}
                            managees={managees}
                            month={month}
                            year={year}
                            absences={absences}
                            holidays={holidays}
                            // openManagers={openManagers}
                            openManager={openManager}
                            closeManager={closeManager}
                            daysArray={daysArray}
                            me={me}
                        />
                    );
                })
            ) : (
                <tr></tr>
            )}
        </tbody>
    );
};

export default TeamPlanningTableBody;
