class SsctItem {
    title;
    persons;
    emails;
    itemType;

    constructor(json, itemType) {
        this.itemType = itemType;
        this.title = json.title;
        let personsArray = [];
        let emailsArray = [];
        json.persons.map((person) => {
            personsArray.push(person);
        });
        json.emails.map((email) => {
            emailsArray.push(email);
        });
        this.persons = personsArray;
        this.emails = emailsArray;
    }
}

export default SsctItem;
