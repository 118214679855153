import React, { useState, Fragment } from "react";
import TeamPlanningTableHeader from "components/Backend/TeamPlanningContainer/TeamPlanningTable/TeamPlanningTableHeader";
import TeamPlanningTableBody from "components/Backend/TeamPlanningContainer/TeamPlanningTable/TeamPlanningTableBody";

const TeamPlanningTable = (props) => {
    const {
        daysArray,
        me,
        managees,
        month,
        year,
        absences,
        holidays,
        openManager,
        closeManager,
    } = props;

    return (
        <table>
            <TeamPlanningTableHeader daysArray={daysArray} />
            <TeamPlanningTableBody
                me={me}
                managees={managees}
                month={month}
                year={year}
                absences={absences}
                holidays={holidays}
                openManager={openManager}
                closeManager={closeManager}
                daysArray={daysArray}
            />
        </table>
    );
};

export default TeamPlanningTable;
