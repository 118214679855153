import React, { useState } from "react";
import { acceptBrowserNavOnMobile } from "utils/Utils";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as TimesheetActions from "actions/Timesheet";
import { requestIosToken, resetIosTokenRequestMessage } from "actions/Common";

import "./MobileOverlay.scss";

const MobileOverlay = (props) => {
    const { setMobileOverlayVisible } = props;
    const dispatch = useDispatch();
    const [screenWidth, setScreenWidth] = useState(window.screen.width);
    const [screenHeight, setScreenHeight] = useState(window.screen.height);
    const [isPortrait, setPortrait] = useState(
        window.matchMedia("only screen and (orientation: portrait)").matches
    );
    const iosTokenRequestError = useSelector(
        (state) => state.timesheet.errorMessage
    );
    const iosTokenRequestSuccess = useSelector(
        (state) => state.common.iosTokenRequestSuccess
    );

    useEffect(() => {
        if (iosTokenRequestError) {
            setTimeout(() => {
                dispatch(TimesheetActions.resetErrorMessage());
            }, 5000);
        }
    }, [iosTokenRequestError]);

    useEffect(() => {
        if (iosTokenRequestSuccess) {
            setTimeout(() => {
                dispatch(resetIosTokenRequestMessage());
            }, 5000);
        }
    }, [iosTokenRequestSuccess]);

    useEffect(() => {
        const orientationChangeListener = window.addEventListener(
            "orientationchange",
            function (event) {
                setScreenWidth(window.screen.width);
                setScreenHeight(window.screen.height);
                setPortrait(
                    window.matchMedia("only screen and (orientation: portrait)")
                        .matches
                );
            }
        );

        return window.removeEventListener(
            "orientationchange",
            orientationChangeListener
        );
    }, []);

    const handleClick = () => {
        setMobileOverlayVisible(false);
        acceptBrowserNavOnMobile();
    };

    const fetchIosToken = () => {
        return dispatch(requestIosToken());
    };

    return (
        <div className="mobile-overlay-container">
            <div className="mobile-overlay-content">
                <div className="synchrone-overlay-logo"></div>
                <div
                    className={
                        (iosTokenRequestSuccess
                            ? "mobile-overlay-notifier-success"
                            : "") +
                        (iosTokenRequestError
                            ? "mobile-overlay-notifier-error"
                            : "")
                    }
                >
                    {iosTokenRequestSuccess || iosTokenRequestError}
                </div>
                {/* <div>{iosTokenRequestSuccess}</div>
                <div>{iosTokenRequestError}</div> */}
                <div
                    className="mobile-warning"
                    style={{
                        width: screenWidth / 1.2,
                    }}
                >
                    Nous avons détecté que vous utilisez un appareil mobile,
                    nous vous conseillons d'utiliser nos applications mobiles
                    pour une expérience optimale
                </div>

                <div>
                    <a href="https://play.google.com/store/apps/details?id=fr.synchrone.mysynchrone&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        <img
                            style={{ verticalAlign: "middle" }}
                            width={
                                isPortrait ? screenWidth / 2.2 : screenWidth / 5
                            }
                            alt="Disponible sur Google Play"
                            src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png"
                        />
                    </a>
                    <div
                        style={{ display: "inline-block" }}
                        onClick={() => fetchIosToken()}
                    >
                        <img
                            style={{
                                verticalAlign: "middle",
                                padding: isPortrait
                                    ? (screenWidth / 2.2) * 0.0875
                                    : (screenWidth / 5) * 0.0875,
                            }}
                            width={
                                isPortrait ? screenWidth / 2.2 : screenWidth / 5
                            }
                            alt="Disponible sur l'App Store"
                            src="ios-download.svg"
                        />
                    </div>
                    *
                </div>

                <div
                    className="navigate-anyway-btn"
                    onClick={() => handleClick()}
                >
                    Continuer quand même
                </div>
            </div>
            <div className="ios-information">
                * (Un email vous sera envoyé avec un lien de téléchargement pour
                l'application)
            </div>
        </div>
    );
};

export default MobileOverlay;
