import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import "moment/locale/fr";
import * as ValidationActions from "actions/Validation";
import {changeOverlayContent} from "events/OverlayEvents";

import Lightbox from "components/common/Lightbox";
import PersonTypeBadge from "components/Backend/common/PersonTypeBadge";

import "./CRAHistoryActions.scss";

const CRAHistoryActions = (props) => {
    const {month, user, manager} = props;
    const dispatch = useDispatch();
    const monthActions = useSelector((state) => state.validation.monthActions);

    useEffect(() => {
        dispatch(ValidationActions.fetchMonthAction(month.id));
    }, []);

    const close = () => {
        changeOverlayContent(null);
    };

    const CRAHistoryRow = (props) => {
        const {monthAction} = props;

        return (
            <div className="cra-history-actions-row">
                <div className="actions-row-description">
                    {monthAction.label}
                </div>
                <div className="actions-row-date">
                    {moment
                        .parseZone(monthAction.createdAt)
                        .format("DD/MM/YYYY")}
                </div>
                <div className="actions-row-author">
                    {monthAction.author ? (
                        monthAction.author.firstname +
                        " " +
                        monthAction.author.lastname
                    ) : (
                        <span className="italic-label">Système</span>
                    )}
                </div>
            </div>
        );
    };

    return (
        <Lightbox className="lb-centered cra-history-actions-lb">
            <div className="cra-history-actions-header">
                <div className="cra-history-actions-title">
                    Historique des actions sur le CRA
                </div>
                <div className="cra-history-actions-identity-block">
                    <div className="cra-history-actions-identity">
                        {user.identity}
                    </div>
                    <PersonTypeBadge personType={user.personType}/>
                    <div className="cra-history-actions-matricule">
                        {user.matricule}
                    </div>
                    <div className="cra-history-actions-manager">
                        Responsable : {manager && manager.identity}
                    </div>
                </div>
                <div className="close-lb" onClick={close}>
                    <i className="fal fa-times"></i>
                </div>
            </div>
            <div className="cra-history-actions-table-header">
                <div className="table-header-description">Action</div>
                <div className="table-header-date">Date</div>
                <div className="table-header-author">Auteur</div>
            </div>
            <div className="cra-history-actions-content">
                {monthActions.length > 0 ? monthActions.map((element, key) => {
                    return <CRAHistoryRow key={key} monthAction={element}/>;
                }) : <div className={"cra-history-actions-row"}>Aucun historique pour ce CRA</div>}

            </div>
        </Lightbox>
    );
};

export default CRAHistoryActions;
