import { useSelector } from "react-redux";

class HighlightRepository {
    findLast = () => {
        return useSelector((state) => {
            let highlight = state.news.entities.highlight;
            if (highlight) {
                return highlight;
            }
            return null;
        });
    };
}

export default HighlightRepository;
