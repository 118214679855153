export const load = (url, scriptId) => {
    const existingScript = document.getElementById(scriptId);

    if (!existingScript) {
        const script = document.createElement("script");
        script.src = url;
        script.id = scriptId;
        document.body.appendChild(script);
    }
};

export const unload = (scriptId) => {
    const existingScript = document.getElementById(scriptId);

    if (existingScript) {
        document.body.removeChild(existingScript);
    }
};
