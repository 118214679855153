class Tutorial {
    id;
    description;
    youtubeUrl;
    category;
    thumbnail;
    tutorialType;

    constructor(json) {
        this.id = json.id;
        this.youtubeUrl = json.youtube_url;
        this.description = json.description;
        this.category = json.category;
        this.thumbnail = json.thumbnail;
        this.tutorialType = json.tutorial_type;
    }
}

export default Tutorial;
