import store from "store/ConfigureStore";
import Contact from "entities/Contact";
import { isEmpty, sanitizeForSearch } from "utils/Utils";
import ContactGroup from "entities/ContactGroup";

class ContactRepository {
    findAll = (filter) => {
        let state = store.getState().contact.entities.contacts;

        if (!isEmpty(state)) {
            const contacts = [];
            let array = Object.values(state);
            array.forEach((element) => {
                if (!filter) {
                    contacts.push(new Contact(element));
                } else {
                    let lowercasedFilter = sanitizeForSearch(filter);
                    if (
                        sanitizeForSearch(element.firstname).includes(
                            lowercasedFilter
                        ) ||
                        sanitizeForSearch(element.lastname).includes(
                            lowercasedFilter
                        ) ||
                        sanitizeForSearch(element.place).includes(
                            lowercasedFilter
                        ) ||
                        sanitizeForSearch(
                            element.firstname + " " + element.lastname
                        ).includes(lowercasedFilter)
                    ) {
                        contacts.push(new Contact(element));
                    }
                }
            });

            return contacts.sort((a, b) => {
                let A = a.firstname + a.lastname;
                let B = b.firstname + b.lastname;
                return A.localeCompare(B);
            });
        }

        return [];
    };

    findConsultingTeam = (filter) => {
        let state = store.getState().contact.entities.consultingTeam;

        if (!isEmpty(state)) {
            const consultingTeam = [];
            let array = Object.values(state);
            array.forEach((element) => {
                if (!filter) {
                    consultingTeam.push(new Contact(element));
                } else {
                    let lowercasedFilter = sanitizeForSearch(filter);
                    if (
                        sanitizeForSearch(element.firstname).includes(
                            lowercasedFilter
                        ) ||
                        sanitizeForSearch(element.lastname).includes(
                            lowercasedFilter
                        ) ||
                        sanitizeForSearch(
                            element.firstname + " " + element.lastname
                        ).includes(lowercasedFilter)
                    ) {
                        consultingTeam.push(new Contact(element));
                    }
                }
            });

            return consultingTeam.sort((a, b) => {
                let A = a.firstname + a.lastname;
                let B = b.firstname + b.lastname;
                return A.localeCompare(B);
            });
        }

        return [];
    };

    findMyContacts = () => {
        let state = store.getState().contact.entities.mycontacts;

        if (!isEmpty(state)) {
            const mycontacts = [];
            let array = Object.values(state);
            array.forEach((element) => {
                let group = new ContactGroup(element);
                let contacts = [];
                group.contacts.forEach((contactId) => {
                    contacts.push(this.find(contactId));
                });
                group.contacts = contacts;
                mycontacts.push(group);
            });

            return mycontacts;
        }

        return [];
    };

    find = (id) => {
        let state = store.getState().contact.entities.contacts;

        if (!isEmpty(state[id])) {
            return new Contact(state[id]);
        }

        return;
    };
}

export default ContactRepository;
