import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import "./FormHeader.scss";

import moment from "moment";
import "moment/locale/fr";

import {
    STEP1,
    STEP1A,
    STEP1B,
    STEP1C,
    STEP2,
    STEP3,
    STEP4,
} from "components/TimesheetContainer/Forms/Event/Edit";
import { useState } from "react";

const FormHeader = (props) => {
    const vacationCount = useSelector((state) => state.user.vacationCount);
    const days = useSelector((state) => state.timesheet.entities.month.days);
    const [daysCount, setDaysCount] = useState(0);

    const {
        eventCodeToEdit,
        selectedEventCode,
        selectedEventClass,
        selectedEventDescription,
        selectedStartMoment,
        selectedEndMoment,
        selectedType,
        selectedOvertimeCode,
        step,
        isEdit,
        hasEnoughVacations,
        setHasEnoughVacations,
        decideHasEnoughVacations,
        startAt,
        endAt,
    } = props;
    const classes = `lb-event-form-type ${selectedEventClass}-type`;

    useEffect(() => {
        let count = 0;
        let iterableDays = Object.values(days);
        let daysToAdd = [];
        iterableDays.forEach((element) => {
            if (element.worked) {
                if (
                    moment.parseZone(element.dateAt).format("DD/MM/YY") >=
                        moment.parseZone(props.startAt).format("DD/MM/YY") &&
                    moment.parseZone(element.dateAt).format("DD/MM/YY") <=
                        moment.parseZone(props.endAt).format("DD/MM/YY")
                ) {
                    daysToAdd.push(element.id);
                }
            }
        });
        count = daysToAdd.length;

        if (props.selectedStartMoment === "pm") {
            count -= 0.5;
        }
        if (props.selectedEndMoment === "am") {
            count -= 0.5;
        }

        setDaysCount(count);
    }, [step, selectedStartMoment, selectedEndMoment, startAt, endAt]);

    useEffect(() => {
        setHasEnoughVacations(
            decideHasEnoughVacations(daysCount, vacationCount)
        );
    }, [daysCount, selectedEventCode]);

    const Title = () => {
        // if (
        //     props.step === STEP1 ||
        //     props.step === STEP1A ||
        //     props.step === STEP1B ||
        //     props.step === STEP2
        // ) {
        //     return "Je choisis mon évènement :";
        // } else if (props.step === STEP3) {
        if (
            step === STEP1 ||
            step === STEP1A ||
            step === STEP1B ||
            step === STEP1C ||
            step === STEP2
        ) {
            if (isEdit) {
                return "Je modifie mon événement :";
            }

            return "Je choisis mon événement :";
        } else if (step === STEP3) {
            return "Je vérifie les dates saisies :";
        } else if (step === STEP4) {
            return "Je valide mes informations :";
        }

        return "";
    };

    const Description = () => {
        let description;
        let constraintCategory;
        let overtimeCodeDescription;
        const Delimiter = () => {
            return <span style={{ color: "#17313a" }}> > </span>;
        };

        if (selectedType === "event") {
            if (step >= STEP3) {
                description = selectedEventCode.description;
            } else {
                if (isEdit) {
                    description = eventCodeToEdit.description;
                } else {
                    description = selectedEventDescription;
                }
            }
        } else if (selectedType === "overtime") {
            description = selectedEventDescription;
            if (props.step >= STEP2) {
                if (props.constraintCategory === "availability") {
                    constraintCategory = "Disponibilité";
                } else if (props.constraintCategory === "intervention") {
                    constraintCategory = "Intervention";
                }
            }
            if (step >= STEP3) {
                overtimeCodeDescription = selectedOvertimeCode.description;
            }
        }

        return (
            <span className={classes}>
                {description}
                {constraintCategory && (
                    <Fragment>
                        <Delimiter />
                        {constraintCategory}
                    </Fragment>
                )}
                {overtimeCodeDescription && (
                    <Fragment>
                        <Delimiter />
                        {overtimeCodeDescription}
                    </Fragment>
                )}
            </span>
        );
    };

    const InsufficientVacations = () => {
        return (
            <div className="insufficient-vacations">
                <i className="fal fa-exclamation-square insufficient-vacations-icon"></i>
                Solde insuffisant
            </div>
        );
    };

    return (
        <div className="lb-event-form-header">
            <Title />
            <Description />
            {!hasEnoughVacations && (step === STEP3 || step === STEP4) ? (
                <InsufficientVacations />
            ) : (
                ""
            )}
        </div>
    );
};

export default FormHeader;
