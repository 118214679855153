import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as UserActions from "actions/User";
import moment from "moment";
import "moment/locale/fr";
import { changeOverlayContent } from "events/OverlayEvents";
import "./PasswordAboutToExpirePopUp.scss";
import { Link } from "react-router-dom";

const PasswordAboutToExpirePopUp = (props) => {
    const { me } = props;
    const history = useHistory();
    let today = moment();
    let timeLeft = Math.ceil(
        (moment(me.passwordExpirationDate) - today) / (1000 * 60 * 60 * 24)
    );

    const close = () => {
        changeOverlayContent(null);
        history.push(window.location.pathname);
    };

    return (
        <div id="password-about-to-expire-pop-up-container">
            <div id="password-about-to-expire-pop-up">
                <div id="password-about-to-expire-pop-up-header">
                    Expiration de votre mot de passe
                </div>
                <div id="password-about-to-expire-pop-up-body">
                    <div>
                        {process.env.NODE_ENV !== "development" && (
                            timeLeft > 0 ? (
                                <div>
                                    Votre mot de passe arrive à expiration dans{" "}
                                    <span className="days-left">{timeLeft}</span>{" "}
                                    jours
                                </div>
                            ) : (
                                <div>
                                    Votre mot de passe est expiré
                                    <br />
                                    <br />
                                </div>
                            )
                        )}
                    </div>{" "}
                    <div className="change-password-warning">
                        Pour continuer à utiliser l'application MySynchrone,
                        merci de renouveler votre mot de passe
                    </div>
                    <Link
                        id="password-about-to-expire-pop-up-button"
                        className="next-btn"
                        to="/account"
                        onClick={close}
                    >
                        Modifier mon mot de passe
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PasswordAboutToExpirePopUp;
