import React, { useState } from "react";
import FilesContainer from "components/DocumentContainer/FilesContainer";
import Dropdown from "components/common/Dropdown";
import { changeOverlayContent } from "events/OverlayEvents";
import ItemLightbox from "components/Backend/DocumentContainer/ItemLightbox";
import "./Section.scss";

const Section = (props) => {
    const { section, isEdit, type } = props;
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const getOrderedList = (category, key) => {
        let folders = category.folders;
        let files = category.files;
        let lines = [];
        folders.map((folder, key) => {
            lines[folder.priority - 1] = folder;
        });
        files.map((file, key) => {
            lines[file.priority - 1] = file;
        });

        return (
            <FilesContainer
                categoryId={category.id}
                title={category.label}
                files={lines}
                key={key}
                isEdit={isEdit}
            />
        );
    };

    return (
        <div className="section-container">
            <div className="section-title">
                <div className="section-title-label">{section.label}</div>
                {isEdit ? (
                    <Dropdown
                        isDropdownVisible={isDropdownVisible}
                        setDropdownVisible={setDropdownVisible}
                    >
                        <ul className="dropdown-list">
                            <li
                                onClick={() => {
                                    setDropdownVisible(false);
                                    changeOverlayContent(
                                        <ItemLightbox
                                            sectionId={section.id}
                                            mode="create"
                                        />
                                    );
                                }}
                            >
                                Ajouter une catégorie
                            </li>
                        </ul>
                    </Dropdown>
                ) : (
                    type !== "social" && (
                        <img
                            className="section-title-image"
                            src={section.iconUrl}
                            alt={section.label}
                        />
                    )
                )}
            </div>
            {section.categories?.map((category, key) => {
                return getOrderedList(category, key);
            })}
        </div>
    );
};

export default Section;
