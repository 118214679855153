import React from "react";
import useClickOutside from "hooks/useClickOutside";
import "./Dropdown.scss";

const Dropdown = (props) => {
    const { isDropdownVisible, setDropdownVisible } = props;

    const ref = useClickOutside(() => {
        setDropdownVisible(false);
    });

    return (
        <div
            className={"dropdown" + (isDropdownVisible ? " dropdown-open" : "")}
            ref={ref}
        >
            <div
                className="dropdown-btn"
                onClick={(e) => {
                    e.stopPropagation();
                    setDropdownVisible(!isDropdownVisible);
                }}
            >
                <i className="fa fa-ellipsis-h"></i>
            </div>
            {props.children}
        </div>
    );
};

export default Dropdown;
