import React, {useEffect, useState} from "react";
import {monthInLetter} from "../../../utils/Utils";
import CheckBox from "./CheckBox";
import SpecificCRAContainer from "./SpecificCRAContainer";
import {Field} from "formik";
import RadioButton from "../../common/RadioButton";
import Overtime from "../../../entities/Overtime";
import moment from "moment";
import "moment/locale/fr";
import {useSelector} from "react-redux";

const Step1SendMonth = (props) => {
    const {
        handleCommentChange,
        comment,
        files,
        setFiles,
        disabled,
        setDisabled,
        personType,
        special,
        setSpecial,
        certified,
        setCertified,
        touched,
        setTouched,
        noPrestationChecked,
        setNoPrestationChecked,
        month,
        hasEvents,
        hasExtraActivityPaymentChoice,
        extraActivities,
        extraActivityWeeks,
        extraActivityPayment,
        changeExtraActivityPayment,
        overtimes,
        previousMonth
    } = props

    const [totalHoursCount, setTotalHoursCount] = useState(0)

    const monthEntity = useSelector((state) => state.timesheet.entities.month);
    const eventCodes = useSelector((state) => state.timesheet.entities.eventCodes);
    const me = useSelector((state) => state.user.me);

    let workedHalfdays = 0

    useEffect(() => {
        monthEntity.days.map(day => {
            const halfdays = [day.am, day.pm]
            halfdays.map(halfdayId => {
                const halfday = monthEntity.halfdays.find(data => data.id === halfdayId)
                const event = halfday && monthEntity.events.find(event => event.id === halfday.event)
                const eventCode = event ? eventCodes.find(code => code.id === event.eventCode) : false
                if (eventCode?.type === 'activity' || (!eventCode && monthEntity.autofillable && day.worked)) {
                    workedHalfdays++;
                }
            })
        })

        let halfdayDuration = 0
        switch (me.modality) {
            case "1":
                halfdayDuration = 210
                break;
            case "2":
                halfdayDuration = 231
                break;
        }

        const duration = workedHalfdays * halfdayDuration

        setTotalHoursCount(duration)
    }, [monthEntity, eventCodes])

    const getMonthPreposition = () => {
        let firstLetterOfMonth = monthInLetter(month).charAt(0);
        if (firstLetterOfMonth === "A" || firstLetterOfMonth === "O") {
            return "d'";
        }

        return "de";
    };

    const durationInHours = (durationInMinutes) => {
        let hoursDuration = "";

        let hour = Math.floor(durationInMinutes / 60);
        let minutes = durationInMinutes - hour * 60;

        hoursDuration = hour + "h" + (minutes < 10 ? "0" : "") + minutes;

        return hoursDuration;
    };


    const DurationTotal = (props) => {
        const {total} = props;

        return <div className={"duration-total"}>{durationInHours(total)}</div>
    }

    return <>
        <div className="lb-confirm-send-header">ENVOYER MON CRA</div>
        {/*{totalHoursCount ?*/}
        {/*    <p className={"font-15"}><strong>Ce mois ci vous avez*/}
        {/*        travaillé {durationInHours(totalHoursCount + durationForMonth())}</strong>*/}
        {/*    </p> : ''}*/}
        <p className={"font-15"}>
            Je confirme l'exactitude des informations saisies pour le mois {getMonthPreposition()}{" "}
            <strong>{monthInLetter(month)}</strong>
        </p>
        {
            personType === "contractor" && !hasEvents && (
                <CheckBox
                    label="Aucune prestation ce mois-ci"
                    name="no-prestation"
                    value={noPrestationChecked}
                    onClick={() =>
                        setNoPrestationChecked(!noPrestationChecked)
                    }
                />
            )
        }

        {
            (personType === "consultant" ||
                personType === "contractor") && (
                <SpecificCRAContainer
                    files={files}
                    setFiles={setFiles}
                    disabled={disabled}
                    setDisabled={setDisabled}
                    special={special}
                    setSpecial={setSpecial}
                    certified={certified}
                    setCertified={setCertified}
                    afterSubmit={false}
                    touched={touched}
                    setTouched={setTouched}
                />
            )
        }

        {hasExtraActivityPaymentChoice() &&
            <div className={"extra-activities-payment-choice-container font-15"}>
                <div className="extra-activities-payment-choice-title font-15">Activité complémentaire ce mois-ci :
                </div>
                {personType !== "contractor" && monthEntity.extraActivitySummary.weeks.map((week, key) => {
                    return <div className="extra-activities-payment-week font-15" key={key}>
                        <div className="payment-week-label font-15">Semaine {week.number}</div>
                        <DurationTotal total={week.duration}/></div>
                })}

                <div className="extra-activities-payment-total font-15">Vous avez réalisé <DurationTotal
                    total={monthEntity.extraActivitySummary.duration}/> d'activités complémentaires ce
                    mois{personType !== "contractor" ? ", qui peuvent être payées ou récupérées" : ""}
                </div>

                {personType !== "contractor" && (<div>
                        <div className="payment-choices-container">
                            <span className="bold">Je souhaite :</span>

                            <div className="input-type-radio">
                                <input
                                    name="extraActivityPayment"
                                    type="radio"
                                    value={"money"}
                                    checked={extraActivityPayment === "money"}
                                    onChange={() => changeExtraActivityPayment("money")}
                                    className="radio-button"
                                    id="money"
                                />
                                <div className="fake-radio"></div>
                                <label htmlFor="money">paiement</label>
                            </div>

                            <div className="input-type-radio">
                                <input
                                    name="extraActivityPayment"
                                    type="radio"
                                    value={"rest"}
                                    checked={extraActivityPayment === "rest"}
                                    onChange={() => changeExtraActivityPayment("rest")}
                                    className="radio-button"
                                    id="rest"
                                />

                                <div className="fake-radio"></div>
                                <label htmlFor="rest">récupération</label>
                            </div>
                        </div>

                        {/*{extraActivityPayment === 'money' &&*/}
                        {/*    <div className="extra-activity-payment-explanation">Le paiement de vos heures*/}
                        {/*        supplémentaires*/}
                        {/*        augmentera votre contingent d’heures*/}
                        {/*        supplémentaires de {durationInHours(durationForMonth())}</div>}*/}
                        {/*{extraActivityPayment === 'rest' &&*/}
                        {/*    <div className="extra-activity-payment-explanation">Les activités complémentaires déclarées*/}
                        {/*        sur*/}
                        {/*        la dernière semaine du mois lorsqu’elle est à*/}
                        {/*        cheval sur 2 mois, seront comptabilisées le mois suivant et payées le mois*/}
                        {/*        d’après</div>}*/}
                        {extraActivityPayment === 'money' &&
                            <div className="extra-activity-payment-explanation">Toute saisie fera l'objet d'une
                                validation
                                managériale et du service paie. Aussi, toute heure débutée dans le cadre d'une
                                intervention
                                sur
                                astreinte est due dans son intégralité.
                            </div>}
                    </div>
                )}
            </div>
        }

        {personType !== "contractor" ? (<div className="testify-send-cra">
            <div>En envoyant mon CRA j’atteste que :</div>
            <div>• J’ai bien bénéficié de mon temps de repos quotidien de 11 heures</div>
            <div>• J’ai bien bénéficié de mon temps de repos hebdomadaire de 24 heures</div>
            <div>• J’ai bien respecté mon temps de déconnexion</div>
        </div>) : ""}

        <div className="lb-confirm-send-comment-label">
            <i className="far fa-comment-alt"></i> Commentaire
        </div>
        <textarea
            name="comment"
            value={comment}
            onChange={(e) => handleCommentChange(e)}
            className="lb-confirm-send-comment"
        ></textarea>
    </>
}

export default Step1SendMonth
