import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import * as ValidationActions from "actions/Validation";
import { changeOverlayContent } from "events/OverlayEvents";

import "./RealTimeVacationBlock.scss";
import AbsenceHistory from "components/TimesheetContainer/Calendar/AbsenceHistory";

const RealTimeVacationBlock = (props) => {
    const { me, user } = props;
    const dispatch = useDispatch();

    const canViewAbsenceHistory = () => {
        if (me.hasRole("ROLE_ABSENCE_FULL_HISTORY")) {
            return true;
        }
        return false;
    };

    const fetchAbsenceHistory = () => {
        dispatch(
            ValidationActions.fetchAbsenceHistoryForValidation(user.matricule)
        ).then(() => {
            dispatch(
                ValidationActions.fetchEventCodesForValidation({
                    person: user.matricule,
                })
            ).then(() => {
                changeOverlayContent(<AbsenceHistory type="validation" />);
            });
        });
    };

    const Counts = () => {
        const CounterLine = (props) => {
            const { role } = props;

            let header, cpCount, rttCount, remainderCount, headerClass;

            if (role === "manager") {
                header = "Manager";
                headerClass = "manager-header";
                cpCount = user.cpManager;
                rttCount = user.rttManager;
                remainderCount = user.remainderManager;
            } else if (role === "pay-manager") {
                header = "Valideur paie";
                headerClass = "pay-manager-header";
                cpCount = user.cpPayManager;
                rttCount = user.rttPayManager;
                remainderCount = user.remainderPayManager;
            }

            return (
                <div className="counter-line counter-block">
                    <div className={headerClass}>{header}</div>
                    <span className="real-time-cp-count">
                        CP <span className="purple">{cpCount}</span>
                    </span>
                    <span className="real-time-rtt-count">
                        RTT <span className="purple">{rttCount}</span>
                    </span>
                    {remainderCount > 0 && user.displayRemainderCounter && (
                        <span className="real-time-remainder-count">
                            Reliquat{" "}
                            <span className="purple">{remainderCount}</span>
                        </span>
                    )}
                </div>
            );
        };

        let countsContainer;
        if (me.hasRole("ROLE_MANAGER") && me.hasRole("ROLE_PAY_MANAGER")) {
            countsContainer = (
                <Fragment>
                    <CounterLine role="manager" />
                    <CounterLine role="pay-manager" />
                </Fragment>
            );
        } else if (
            me.hasRole("ROLE_PAY_MANAGER") ||
            me.hasRole("ROLE_MANAGER")
        ) {
            countsContainer = (
                <div className="counter-line">
                    <span className="real-time-cp-count">
                        CP{" "}
                        <span className="purple">
                            {me.hasRole("ROLE_MANAGER")
                                ? user.cpManager
                                : user.cpPayManager}
                        </span>
                    </span>
                    <span className="real-time-rtt-count">
                        RTT{" "}
                        <span className="purple">
                            {me.hasRole("ROLE_MANAGER")
                                ? user.rttManager
                                : user.rttPayManager}
                        </span>
                    </span>
                    {user.displayRemainderCounter &&
                        ((me.hasRole("ROLE_MANAGER") &&
                            user.remainderManager > 0) ||
                            (me.hasRole("ROLE_PAY_MANAGER") &&
                                user.remainderPayManager > 0)) && (
                            <span className="real-time-remainder-count">
                                Reliquat{" "}
                                <span className="purple">
                                    {me.hasRole("ROLE_MANAGER")
                                        ? user.remainderManager
                                        : user.remainderPayManager}
                                </span>
                            </span>
                        )}
                </div>
            );
        }

        return <div className="counts">{countsContainer}</div>;
    };

    return (
        <div className="real-time-vacation-block">
            <Counts />
            {/* <div className="counts">
                
                {me.hasRole("ROLE_MANAGER") && <Counts role="manager" />}
                {me.hasRole("ROLE_PAY_MANAGER") && (
                    <Counts role="pay-manager" />
                )}
            </div> */}

            <div className="actions">
                {canViewAbsenceHistory() && (
                    <span className="absence-history">
                        <i
                            className="far fa-stopwatch"
                            onClick={() => fetchAbsenceHistory()}
                            title="Historique détaillé"
                        ></i>
                    </span>
                )}
            </div>
            {/* <span className="cegid-vacation-count-btn">
                <i className="fal fa-copyright" title="Solde CEGID"></i>
            </span> */}
        </div>
    );
};

export default RealTimeVacationBlock;
