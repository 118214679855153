class Period {
    id;
    year;
    month;
    nonSubmitted;
    submitted;
    validated1;
    validated2;
    total;
    remoteWorkers;
    remoteDays;
    remoteFileUrl;
    author;
    closed;
    closedAt;
    comment;
    createdAt;
    updatedAt;

    constructor(json) {
        this.id = json.id;
        this.year = json.year;
        this.month = json.month;
        this.nonSubmitted = json.non_submitted;
        this.submitted = json.submitted;
        this.validated1 = json.validated1;
        this.validated2 = json.validated2;
        this.total = json.total;
        this.remoteWorkers = json.remote_workers;
        this.remoteDays = json.remote_days;
        this.remoteFileUrl = json.remote_file_url;
        this.author = json.author;
        this.closed = json.closed;
        this.closedAt = json.closed_at;
        this.comment = json.comment;
        this.createdAt = json.created_at;
        this.updatedAt = json.updated_at;
    }
}

export default Period;
