import store from "store/ConfigureStore";
import { isEmpty } from "utils/Utils";

import { useSelector } from "react-redux";

class EventCodeRepository {
    // findForCurrentMonth = () => {
    //     let state = store.getState().timesheet.entities.eventCodes;

    //     if (!isEmpty(state)) {
    //         const events = [];
    //         let array = Object.values(state);
    //         array.forEach(element => {
    //             events.push(new Event(element));
    //         });

    //         return events;
    //     }

    //     return;
    // };
    find = (id, type) => {
        return useSelector((state) => {
            if (id) {
                let eventCodes;
                if (type === "validation") {
                    eventCodes = state.validation.eventCodes;
                } else if (type === "view") {
                    eventCodes = state.validation.craEventCodes;
                } else {
                    eventCodes = state.timesheet.entities.eventCodes;
                }
                if (!isEmpty(eventCodes)) {
                    let result = null;
                    let array = Object.values(eventCodes);
                    array.forEach((element) => {
                        if (element.id === id) {
                            result = element;
                        }
                    });
                    return result;
                }
            }

            return;
        });
    };

    findWithoutHook = (id, type) => {
        let eventCodes;
        if (type === "view") {
            eventCodes = store.getState().validation.craEventCodes;
        } else {
            eventCodes = store.getState().timesheet.entities.eventCodes;
        }

        if (!isEmpty(eventCodes)) {
            if (id) {
                let result = null;
                let array = Object.values(eventCodes);
                array.forEach((element) => {
                    if (element.id === id) {
                        result = element;
                    }
                });
                return result;
            }
        }

        return;
    };

    findForEvent = (id) => {
        return useSelector((state) => {
            if (id) {
                let eventCodes = state.timesheet.entities.eventCodes;
                if (!isEmpty(eventCodes)) {
                    let result = null;
                    let array = Object.values(eventCodes);
                    array.forEach((element) => {
                        if (element.id === id.eventCode) {
                            result = element;
                        }
                    });
                    return result;
                }
            }

            return;
        });
    };

    findForAffectationCode = (code) => {
        if (code) {
            let eventCodes = store.getState().timesheet.entities.eventCodes;
            if (!isEmpty(eventCodes)) {
                let result = null;
                let array = Object.values(eventCodes);
                array.forEach((element) => {
                    if (element.code === code) {
                        result = element;
                    }
                });
                return result;
            }
        }

        return;
    };
}

export default EventCodeRepository;
