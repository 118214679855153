import DoctorItem from "entities/Social/DoctorItem";
import SsctItem from "entities/Social/SsctItem";
import PsychoItem from "entities/Social/PsychoItem";
import DocumentItem from "entities/Social/DocumentItem";
import HarassmentItem from "entities/Social/HarassmentItem";
import InsuranceItem from "entities/Social/InsuranceItem";
import CseItem from "entities/Social/CseItem";

class SocialFolder {
    label;
    items;

    constructor(json) {
        this.label = json.label;
        let itemsArray = [];
        json.items.map((file) => {
            let socialItem;
            switch (file["@type"]) {
                case "doctor":
                    socialItem = new DoctorItem(file, file["@type"]);
                    break;
                case "ssct":
                    socialItem = new SsctItem(file, file["@type"]);
                    break;
                case "psycho":
                    socialItem = new PsychoItem(file, file["@type"]);
                    break;
                case "document":
                    socialItem = new DocumentItem(file, file["@type"]);
                    break;
                case "harassment":
                    socialItem = new HarassmentItem(file, file["@type"]);
                    break;
                case "insurance":
                    socialItem = new InsuranceItem(file, file["@type"]);
                    break;
                case "cse":
                    socialItem = new CseItem(file, file["@type"]);
                    break;
            }
            itemsArray.push(socialItem);
        });
        this.items = itemsArray;
    }
}

export default SocialFolder;
