import React, { useEffect } from "react";
import moment from "moment";
import "moment/locale/fr";
import { useSelector, useDispatch } from "react-redux";
import { changeOverlayContent } from "events/OverlayEvents";
import * as ValidationActions from "actions/Validation";
import Lightbox from "components/common/Lightbox";

import "./CommentBox.scss";

const CommentBox = (props) => {
    const { event } = props;
    const dispatch = useDispatch();
    const eventActions = useSelector((state) => state.validation.eventActions);
    const users = useSelector((state) => state.user.listUsers);

    useEffect(() => {
        dispatch(ValidationActions.fetchEventAction(event.id));
    }, []);

    const close = () => {
        changeOverlayContent(null);
    };

    const CommentRow = (props) => {
        const { eventAction } = props;

        return (
            <div className="comment-row">
                <div className="comment-author">
                    {eventAction.author ? (
                        eventAction.author.firstname +
                        " " +
                        eventAction.author.lastname
                    ) : (
                        <span className="italic-label">Système</span>
                    )}
                </div>
                <div className="comment-created-at">
                    {moment
                        .parseZone(eventAction.createdAt)
                        .format("DD/MM/YYYY [à] HH[h]mm")}
                </div>
                <div className="comment-comment">{eventAction.comment}</div>
            </div>
        );
    };

    return (
        <Lightbox className="lb-centered comment-absence-lb">
            <div className="comment-absence-title">Commentaires</div>
            <div className="close-lb" onClick={close}>
                <i className="fal fa-times"></i>
            </div>
            {Object.values(eventActions)
                .sort((a, b) => {
                    return (
                        moment.parseZone(b.createdAt) -
                        moment.parseZone(a.createdAt)
                    );
                })
                .map((element, key) => {
                    if (element.comment) {
                        return <CommentRow eventAction={element} key={key} />;
                    }
                    return false;
                })}
        </Lightbox>
    );
};

export default CommentBox;
