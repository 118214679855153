import React, { useState, useEffect, useRef, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import moment from "moment";
import "moment/locale/fr";

import * as NewsActions from "actions/News";
import { isEmpty } from "utils/Utils";
import Post from "entities/Post";
import RexView from "components/NewsContainer/View/RexView";
import PressView from "components/NewsContainer/View/PressView";
import ZoomView from "components/NewsContainer/View/ZoomView";
import InterviewView from "components/NewsContainer/View/InterviewView";
import ShareBlock from "components/NewsContainer/ShareBlock";
import PressContactBlock from "./PressContactBlock";

import "./PostView.scss";
import NewsFeed from "./NewsFeed";

const PostView = (props) => {
    const container = useRef(null);
    const [post, setPost] = useState();
    const [postLoaded, setPostLoaded] = useState(false);
    const dispatch = useDispatch();
    const REX = 1;
    const PRESS_RELEASE = 2;
    const EVENT = 3;
    const ZOOM = 4;
    const INTERVIEW = 5;
    const TWITTER = 6;
    const FACEBOOK = 7;
    const INSTAGRAM = 8;
    const LINKEDIN = 9;

    const resetScroll = () => {
        document.documentElement.scrollTop = 0;
    };

    useEffect(() => {
        let isCancelled = false;
        resetScroll();
        Promise.resolve(
            dispatch(NewsActions.fetchPost(props.postId)).then((response) => {
                const posts = response.response.entities.posts;
                let postEntity = new Post(Object.values(posts)[0]);
                if (!isCancelled) {
                    setPost(postEntity);
                }
                setPostLoaded(true);
            })
        );

        return () => {
            isCancelled = true;
        };
    }, [props.postId]);

    return (
        <Fragment>
            <Link className="back-news" to="/actus">
                <i className="far fa-newspaper"></i> Retour à l'accueil des
                actualités
            </Link>
            <div className="container" ref={container}>
                {post && (
                    <div className="post-container">
                        <div className="article">
                            {
                                {
                                    1: <RexView post={post} />,
                                    2: <PressView post={post} />,
                                    4: <ZoomView post={post} />,
                                    5: <InterviewView post={post} />,
                                }[post.type]
                            }
                        </div>
                        <div className="published-at">
                            Publié le{" "}
                            {moment.parseZone(post.publishedAt).format("DD/MM/YYYY")}
                        </div>
                        {post.type !== 2 ? (
                            <div className="published-by">
                                Contacter l’auteur de l’article :{" "}
                                <a
                                    href={`mailto: ${
                                        post.contact ||
                                        "communication@synchrone.fr"
                                    }`}
                                >
                                    {post.contact ||
                                        "communication@synchrone.fr"}
                                </a>
                            </div>
                        ) : (
                            <PressContactBlock />
                        )}
                        <ShareBlock post={post} containerRef={container} />
                    </div>
                )}
            </div>
            {postLoaded && (
                <div className="container">
                    <NewsFeed recommandation={true} />
                </div>
            )}
        </Fragment>
    );
};

export default PostView;
