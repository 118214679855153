import React, { useState, useEffect } from "react";
import "./ContactList.scss";
import ContactBlock from "components/ContactContainer/ContactBlock";
import * as ContactActions from "actions/Contact";
import { EntityRepository, CONTACT_REPOSITORY } from "store/EntityRepository";
import { useDispatch } from "react-redux";
import { setLoading } from "actions/Common";

const ConsultantContactList = (props) => {
    const contactRepo = EntityRepository().getRepository(CONTACT_REPOSITORY);
    const [groups, setGroups] = useState(contactRepo.findMyContacts());
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ContactActions.fetchAll()).then(() => {
            dispatch(ContactActions.fetchMyContacts()).then(() => {
                setGroups(contactRepo.findMyContacts());
                dispatch(setLoading(false));
            });
        });
    }, []);

    let leftColumn = [];
    let rightColumn = [];

    groups.forEach((group) => {
        if (group.contacts.length > 1) {
            rightColumn.push(group);
        } else if (group.contacts.length > 0) {
            leftColumn.push(group);
        }
    });

    return (
        <div>
            <div className="contact-list">
                <div className="contact-col">
                    {leftColumn.map((group) => (
                        <ContactBlock group={group} key={group.id} />
                    ))}
                </div>
                <div className="contact-col">
                    {rightColumn.map((group) => (
                        <ContactBlock group={group} key={group.id} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ConsultantContactList;
