import React from "react";
import "./ContactContainer.scss";
import ContactMenu from "components/ContactContainer/ContactMenu";
import ContactList from "components/ContactContainer/ContactList";
import { Route } from "react-router";
import ConsultantContactList from "components/ContactContainer/ConsultantContactList";
import ConsultingTeamList from "components/ContactContainer/ConsultingTeamList";
import { CONTACTS, CONSULTING_TEAM, CONSULTANT_CONTACT } from "config/Routes";

const ContactContainer = () => {
    return (
        <div id="page-container">
            <div className="container">
                <ContactMenu />
                <Route
                    path={CONSULTANT_CONTACT}
                    exact
                    strict
                    component={() => <ConsultantContactList />}
                ></Route>
                <Route
                    path={CONSULTING_TEAM}
                    exact
                    strict
                    component={() => <ConsultingTeamList />}
                ></Route>
                <Route
                    path={CONTACTS}
                    exact
                    strict
                    component={() => <ContactList />}
                ></Route>
            </div>
        </div>
    );
};

export default ContactContainer;
