import { combineReducers } from "redux";
import common from "reducers/Common";
import timesheet from "reducers/Timesheet";
import contact from "reducers/Contact";
import user from "reducers/User";
import news from "reducers/News";
import validation from "reducers/Validation";
import tutorial from "reducers/Tutorial";
import teamPlanning from "reducers/TeamPlanning";
import period from "reducers/Period";
import document from "reducers/Document";
import settings from "reducers/Settings";

export default combineReducers({
    common,
    timesheet,
    contact,
    user,
    news,
    validation,
    tutorial,
    teamPlanning,
    period,
    document,
    settings
});
