import React, {useEffect} from "react";
import CheckBox from "../TimesheetContainer/Calendar/CheckBox";
import {Formik} from "formik";
import ParametersSubCategoryContainer from "./ParametersSubCategoryContainer";

const ParametersCategoryContainer = (props) => {
    const {category} = props;

    return <div className="parameters-category-container">
        <div className="parameters-category-title">
            {category.categoryTitle}
        </div>
        {category.subCategories.map((subcategory, key) => {
            return <ParametersSubCategoryContainer subcategory={subcategory} key={key}/>
        })}

    </div>
}

export default ParametersCategoryContainer