import { MY_SYNCHRONE_URL as baseUrl } from "config/Url.js";

class InsuranceItem {
    label;
    phone;
    email;
    url;
    address;
    itemType;

    constructor(json, itemType) {
        this.itemType = itemType;
        this.label = json.label;
        this.phone = json.phone;
        this.email = json.email;
        this.url = json.url;
        this.address = json.address;
    }

    get downloadUrl() {
        return baseUrl + this.url;
    }
}

export default InsuranceItem;
