import { MY_SYNCHRONE_URL as baseUrl } from "config/Url.js";

class DocumentItem {
    label;
    mimeType;
    url;
    itemType;

    constructor(json, itemType) {
        this.itemType = itemType;
        this.label = json.label;
        this.mimeType = json.mime_type;
        this.url = json.url;
    }

    get downloadUrl() {
        return baseUrl + this.url;
    }
}

export default DocumentItem;
