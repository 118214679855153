import React from "react";
import {useDispatch} from "react-redux";
import {Form, Formik, useFormikContext, Field, yupToFormErrors} from "formik";
import {changeOverlayContent} from "events/OverlayEvents";
import Lightbox from "components/common/Lightbox";

import * as TimesheetActions from "actions/Timesheet";
import { setLoading } from "actions/Common";

import "./ConfirmAskForExtraActivity.scss";

const ConfirmAskForExtraActivity = (props) => {
    const {monthEntity} = props;
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(setLoading(true));
        dispatch(TimesheetActions.requestExtraActivities(monthEntity.month, monthEntity.year))
            .then(() => {
                Promise.all([
                        dispatch(TimesheetActions.fetchEntireMonth(monthEntity.month, monthEntity.year)),
                        dispatch(TimesheetActions.notifySuccess("Votre demande a été envoyée")),
                        dispatch(setLoading(false))
                    ]);
                    changeOverlayContent(null);
                }
            );
    }

    return <Lightbox className="lb-w1200">
        <div className="lb-ask-extra-activity">
            <div className="lb-ask-extra-activity-header">
                <div className="ask-extra-activity-header-title">
                    Demander l'autorisation de saisir une activité complémentaire
                </div>
            </div>
            <div className={"lb-ask-extra-activity-content"}>
                <div>
                    Demander à mon manager l'autorisation de saisir exceptionnellement
                    <br/>des activités complémentaires ce mois-ci
                </div>
            </div>
        </div>
        <div className="lb-confirm-send-actions">
            <div
                onClick={() => changeOverlayContent(null)}
                className="lb-confirm-send-cancel"
            >
                Annuler
            </div>
            <div
                onClick={() => handleClick()}
                className="lb-confirm-send-confirm"
            >
                Envoyer
            </div>
        </div>
    </Lightbox>;
}

export default ConfirmAskForExtraActivity