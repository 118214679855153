import React from "react";

import { EntityRepository, MANAGER_REPOSITORY } from "store/EntityRepository";

import IdentityBlock from "components/Backend/Block/IdentityBlock";
import ActivityBlock from "components/Backend/Block/ActivityBlock";
import OvertimeBlock from "components/Backend/Block/OvertimeBlock";
import ActivityDaysBlock from "components/Backend/Block/ActivityDaysBlock";
import ActivityActionsBlock from "../Block/ActivityActionsBlock";

import {
    CRA_NOT_SENT,
    CRA_SUBMITTED,
    CRA_VALIDATED_BY_MANAGER,
} from "config/CRAStateColors";

const PersonRow = (props) => {
    const getStylesForState = () => {
        let tempStyles = {};
        if (month) {
            switch (month.state) {
                case "non_submitted":
                    tempStyles.borderLeft = "10px solid " + CRA_NOT_SENT;
                    break;
                case "submitted":
                    tempStyles.borderLeft = "10px solid " + CRA_SUBMITTED;
                    break;
                case "validated1":
                    tempStyles.borderLeft =
                        "10px solid " + CRA_VALIDATED_BY_MANAGER;
                    break;
                default:
                    break;
            }
        }
        return tempStyles;
    };

    const { user, month, me, period, loadList } = props;
    const managerRepo = EntityRepository().getRepository(MANAGER_REPOSITORY);

    const getManager = () => {
        if (managerRepo.findByMatricule(user.manager)) {
            return managerRepo.findByMatricule(user.manager);
        } else if (user.manager === me.matricule) {
            return me;
        }
        return;
    };
    const manager = getManager();

    return (
        <div className="person-row" style={getStylesForState()}>
            <IdentityBlock user={user} manager={manager} month={month} />
            <ActivityBlock month={month} user={user} />
            <ActivityDaysBlock month={month} user={user} />
            <OvertimeBlock
                month={month}
                user={user}
                manager={manager}
                period={period}
                me={me}
            />
            <ActivityActionsBlock
                me={me}
                month={month}
                user={user}
                manager={manager}
                period={period}
                loadList={loadList}
            />
        </div>
    );
};

export default PersonRow;
