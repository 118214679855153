import { MY_SYNCHRONE_URL as baseUrl } from "config/Url.js";

class File {
    id;
    label;
    priority;
    mimeType;
    url;
    createdAt;
    position;

    constructor(json) {
        this.id = json.id;
        this.label = json.label;
        this.priority = json.priority;
        this.mimeType = json.mime_type;
        this.url = json.url;
        this.createdAt = json.created_at;
        this.position = json.priority;
    }

    get downloadUrl() {
        return baseUrl + this.url;
    }

    // set position(newPosition) {
    //     // this.position = newPosition;
    // }
}

export default File;
