import store from "store/ConfigureStore";
import Day from "entities/Day";
import { isEmpty } from "utils/Utils";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/fr";

class DayRepository {
    findForCurrentMonth = () => {
        let state = store.getState().timesheet.entities.month.days;

        if (!isEmpty(state)) {
            const days = [];
            let array = Object.values(state);
            array.forEach((element) => {
                days.push(element);
            });

            return days;
        }

        return;
    };

    findForDateWithoutHook = (date) => {
        let state = store.getState().timesheet.entities.month.days;

        if (!isEmpty(state)) {
            let result = null;
            let array = Object.values(state);
            array.forEach((element) => {
                if (
                    moment.parseZone(element.dateAt).format("YYYY-MM-DD") ===
                    moment.parseZone(date).format("YYYY-MM-DD")
                ) {
                    result = element;
                }
            });

            return result;
        }

        return;
    };

    findDaysForRange = (startAt, endAt, type) => {
        let state;
        if (type === "view") {
            state = store.getState().validation.craDisplayMonth.days;
        } else {
            state = store.getState().timesheet.entities.month.days;
        }

        if (!isEmpty(state)) {
            const result = [];
            let array = Object.values(state);
            array.forEach((element) => {
                const day = element;
                if (
                    moment.parseZone(day.dateAt) >= startAt &&
                    moment.parseZone(day.dateAt) <= endAt
                ) {
                    result.push(day);
                }
            });

            return result;
        }

        return [];
    };

    findWorkingDaysForRange = (startAt, endAt, type) => {
        let state;
        if (type === "view") {
            state = store.getState().validation.craDisplayMonth.days;
        } else {
            state = store.getState().timesheet.entities.month.days;
        }

        if (!isEmpty(state)) {
            const result = [];
            let array = Object.values(state);
            array.forEach((element) => {
                const day = element;
                if (day.worked) {
                    if (
                        moment.parseZone(day.dateAt) >= startAt &&
                        moment.parseZone(day.dateAt) <= endAt
                    ) {
                        result.push(day);
                    }
                }
            });

            return result;
        }

        return [];
    };

    findSelectedDays = () => {
        let days = store.getState().timesheet.entities.month.days;

        let selectedDays = store.getState().timesheet.selectedDays;

        if (!isEmpty(days) && !isEmpty(selectedDays)) {
            const result = [];
            let array = Object.values(selectedDays);
            array.forEach((selectedDay) => {
                for (let index = 0; index < days.length; index++) {
                    const day = days[index];
                    if (day.id === selectedDay) {
                        result.push(day);
                    }
                }
            });
            result.sort((a, b) => {
                return moment.parseZone(a.dateAt) - moment.parseZone(b.dateAt);
            });
            return result;
        }

        return [];
    };

    findForDate = (date) => {
        return useSelector((state) => {
            if (date) {
                let days = state.timesheet.entities.month.days;
                if (!isEmpty(days)) {
                    let result = null;
                    let array = Object.values(days);
                    array.forEach((element) => {
                        if (
                            moment
                                .parseZone(element.dateAt)
                                .format("YYYY-MM-DD") ===
                            moment.parseZone(date).format("YYYY-MM-DD")
                        ) {
                            result = element;
                        }
                    });
                    return result;
                }
            }
        });
    };

    findNextDay = (day) => {
        return this.findAdjacentDay(day, "next");
    };

    findPreviousDay = (day) => {
        return this.findAdjacentDay(day, "previous");
    };

    findAdjacentDay = (day, modifier) => {
        return useSelector((state) => {
            if (day) {
                let days = state.timesheet.entities.month.days;
                if (!isEmpty(days)) {
                    let result = null;
                    let dayMoment;
                    if (modifier === "previous") {
                        dayMoment = moment
                            .parseZone(day.dateAt)
                            .subtract(1, "d")
                            .format("YYYY-MM-DD");
                    } else {
                        dayMoment = moment
                            .parseZone(day.dateAt)
                            .add(1, "d")
                            .format("YYYY-MM-DD");
                    }
                    let array = Object.values(days);
                    array.forEach((element) => {
                        if (
                            moment
                                .parseZone(element.dateAt)
                                .format("YYYY-MM-DD") === dayMoment
                        ) {
                            result = element;
                        }
                    });
                    return result;
                }
            }

            return;
        });
    };
}

export default DayRepository;
