import User from "./User";

class CRA {
    constructor(json) {
        this.id = json.id;
        this.events = json.events;
        this.editable = json.editable;
        this.constraint = json.constraint;
        this.comment = json.comment;
        this.commentCount = json.comment_count;
        this.certified = json.certified;
        this.absenceValidated1 = json.absence_validated1;
        this.absenceValidated2 = json.absence_validated2;
        this.files = json.files;
        this.intervention = json.intervention;
        this.locked = json.locked;
        this.noPrestation = json.no_prestation;
        this.month = json.month;
        this.partialTime = json.partial_time;
        this.remoteWorking = json.remote_working;
        this.backwarded = json.backwarded;
        this.specialTime = json.special_time;
        this.submitted = json.submitted;
        this.submittable = json.submittable;
        this.submittedAt = json.submitted_at;
        this.state = json.state;
        this.user = json.user;
        this.validated1At = json.validated1_at;
        this.validated1By = json.validated1_by;
        this.validated2At = json.validated2_at;
        this.validated2By = json.validated2_by;
        this.year = json.year;
        this.updatedAt = json.updated_at;
        this.extraActivitiesStatus = json.extra_activities_status;
        this.user = new User(json.user);
        this.remoteDaysCount = json.remote_days_count;
    }

    get validated2() {
        return this.state === 'validated2'
    }

    hasExtraActivitiesPending = () => {
        return this.extraActivitiesStatus === 'pending'
    }
}

export default CRA;
