import React from "react";
import "./ContactGroup.scss";
import { useDispatch } from "react-redux";
import ContactGroupLightbox from "components/ContactContainer/ContactGroupLightbox";
import { changeOverlayContent } from "events/OverlayEvents";

const ContactGroup = (props) => {
    const handleClick = () => {
        changeOverlayContent(<ContactGroupLightbox {...props} />);
    };

    return (
        <div>
            <div className="contact-group" onClick={handleClick}>
                <div className="contact-images">
                    {props.group.contacts.map((contact, key) => {
                        if (contact) {
                            return (
                                <img
                                    alt={contact.identity}
                                    src={contact.picture}
                                    key={key}
                                />
                            );
                        }
                    })}
                </div>
                {props.group.displayName && (<div className="contact-identities">
                    {props.group.contacts.map((contact, key) => {
                        if (contact) {
                            return (
                                <div className="contact-identity" key={key}>
                                    {contact.identity}
                                </div>
                            );
                        }
                    })}
                </div>)}

            </div>
            <div className="contact-help">{props.group.text}</div>
        </div>
    );
};

export default ContactGroup;
