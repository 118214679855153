import React from "react";
import Contact from "components/ContactContainer/Contact";
import ContactHeader from "components/ContactContainer/ContactHeader";
import "./ContactBlock.scss";
import ContactGroup from "components/ContactContainer/ContactGroup";

const ContactBlock = props => {
    let contacts;

    if (props.group.contacts.length > 1) {
        contacts = <ContactGroup {...props} />;
    } else if (props.group.contacts.length > 0) {
        let contact = props.group.contacts[0];
        contact.title = props.group.title;
        contacts = <Contact contact={contact} />;
    }

    return (
        <div className="contact-block">
            <ContactHeader title={props.group.title} />
            {contacts}
        </div>
    );
};

export default ContactBlock;
