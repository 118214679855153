import React from "react";

import PersonTypeBadge from "components/Backend/common/PersonTypeBadge";
import ModalityBadge from "components/Backend/common/ModalityBadge";

import "./IdentityBlock.scss";

const IdentityBlock = (props) => {
    const { user, manager, month } = props;

    const Particularities = (props) => {
        const { targetMonth } = props;

        return (
            <div className="particularities-block">
                {targetMonth.backwarded && (
                    <i
                        className="fal fa-repeat particularity-icon backwarded"
                        title="A subi un retour arrière"
                    ></i>
                )}
                {targetMonth.remoteWorking && (
                    <i
                        className="far fa-home-lg-alt particularity-icon"
                        title="Télétravail"
                    ></i>
                )}
                {targetMonth.partialTime && user.type !== "contractor" && (
                    <i
                        className="fal fa-percent particularity-icon"
                        title="Temps partiel"
                    ></i>
                )}
                {targetMonth.specialTime && (
                    <i
                        className="fal fa-hourglass particularity-icon"
                        title="Temps spécial"
                    ></i>
                )}
                {user.type === "contractor" && (
                    <i
                        className="far fa-address-book particularity-icon"
                        title="Prestataire"
                    ></i>
                )}
            </div>
        );
    };

    return (
        <div className="identity-block">
            <div className="identity-block-identity">{user.identity}</div>
            <div className="badges">
                <PersonTypeBadge personType={user.personType} />
                {user.modality > 0 && <ModalityBadge modality={user.modality} />}
            </div>
            <div className="identity-block-matricule">Matricule : {user.matricule}</div>
            {manager && (
                <div className="identity-block-manager">
                    Responsable : {manager.identity}
                </div>
            )}
            {month && <Particularities targetMonth={month} />}
        </div>
    );
};

export default IdentityBlock;
