class PsychoItem {
    subtitle;
    label;
    phone;
    itemType;

    constructor(json, itemType) {
        this.itemType = itemType;
        this.subtitle = json.subtitle;
        this.label = json.label;
        this.phone = json.phone;
    }
}

export default PsychoItem;
