import React, { useState } from "react";
import "./PsychoItem.scss";

const PsychoItem = (props) => {
    const { item } = props;

    return (
        <>
            <div className="psycho-subtitle">{item.subtitle}</div>
            <div className="psycho-item">
                <div className="label-item">{item.label}</div>
                <div className="phone-item">
                    <i className="fal fa-phone"></i>
                    {item.phone}
                </div>
            </div>
        </>
    );
};

export default PsychoItem;
