import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import useClickOutside from "hooks/useClickOutside";
import usePressEscapeKey from "hooks/usePressEscapeKey";
import Lightbox from "components/common/Lightbox";

import { changeOverlayContent } from "events/OverlayEvents";

import "./AbsenceHistory.scss";
import AbsenceHistoryEvent from "components/TimesheetContainer/Calendar/AbsenceHistoryEvent";
import { useEffect } from "react";

const AbsenceHistory = (props) => {
    const { type } = props;

    const absenceHistory = useSelector((state) => {
        if (type === "validation") {
            return state.validation.absenceHistory;
        }
        return state.timesheet.entities.absenceHistory;
    });

    const splitHistory = () => {
        let result = {};
        absenceHistory.forEach((element) => {
            let key = moment.parseZone(element.startAt).format("YYYY");
            if (!result[key]) {
                result[key] = [];
            }
            result[key].push(element);
        });

        return result;
    };

    const [activeYear, setActiveYear] = useState("");

    useEffect(() => {
        if (!activeYear) {
            setActiveYear(Object.keys(splitHistory()).reverse()[0]);
        }
    }, [absenceHistory]);

    const close = () => {
        changeOverlayContent(null);
    };

    usePressEscapeKey(() => {
        close();
    });

    const ref = useClickOutside(() => {
        close();
    });

    const AbsenceByYear = (props) => {
        const events = props.events;

        return (
            <div className="absence-by-year-container">
                <span className="year" id={"year-" + events[0]}>
                    {events[0]}
                </span>
                {events[1].map((event, key) => {
                    return (
                        <AbsenceHistoryEvent
                            key={key}
                            event={event}
                            type={type}
                        />
                    );
                })}
            </div>
        );
    };

    const AbsenceTimeline = (props) => {
        const previousYear = () => {
            let years = Object.keys(splitHistory());
            setActiveYear(years[years.indexOf(activeYear) - 1]);
        };

        const nextYear = () => {
            let years = Object.keys(splitHistory());
            setActiveYear(years[years.indexOf(activeYear) + 1]);
        };

        const Year = (props) => {
            let active = false;
            if (parseInt(activeYear) === parseInt(props.year)) {
                active = true;
            }

            return (
                <a
                    className={"timeline-year" + (active ? " active" : "")}
                    href={"#year-" + props.year}
                    onClick={() => setActiveYear(props.year)}
                >
                    {props.year}
                </a>
            );
        };

        return (
            <div className="lb-absence-history-timeline">
                <a
                    className={
                        "switch-year next-year" +
                        (parseInt(activeYear) ===
                        parseInt(Object.keys(splitHistory()).reverse()[0])
                            ? " disabled"
                            : "")
                    }
                    onClick={() => nextYear()}
                    href={
                        "#year-" +
                        Object.keys(splitHistory())[
                            Object.keys(splitHistory()).indexOf(activeYear) + 1
                        ]
                    }
                >
                    <i className="far fa-chevron-left"></i>
                </a>
                {Object.entries(splitHistory())
                    .reverse()
                    .map((year, key) => {
                        return <Year year={year[0]} key={key} />;
                    })}
                <a
                    className={
                        "switch-year previous-year" +
                        (parseInt(activeYear) ===
                        parseInt(Object.keys(splitHistory())[0])
                            ? " disabled"
                            : "")
                    }
                    onClick={() => previousYear()}
                    href={
                        "#year-" +
                        Object.keys(splitHistory())[
                            Object.keys(splitHistory()).indexOf(activeYear) - 1
                        ]
                    }
                >
                    <i className="far fa-chevron-right"></i>
                </a>
            </div>
        );
    };

    return (
        <Lightbox ref={ref} className="lb-w1200">
            <div className="lb-absence-history">
                <div className="lb-absence-history-header">
                    {"Historique d'absences détaillé".toUpperCase()}
                    <i className="close-lb fal fa-times" onClick={close} />
                </div>
                {absenceHistory.length > 0 ? (
                    <Fragment>
                        <AbsenceTimeline setActiveYear={setActiveYear} />
                        <div className="lb-absence-history-table-header">
                            <div className="table-header-year"></div>
                            <div className="table-header-status">
                                Statut de validation
                            </div>
                            <div className="table-header-dates">Date</div>
                            <div className="table-header-total">Total</div>
                            <div className="table-header-type">Type</div>
                            <div className="table-header-created-at">
                                Date de la demande
                            </div>
                            <div className="table-header-validated-at">
                                Date de validation
                            </div>
                            <div className="table-header-validated-by">
                                Manager
                            </div>
                        </div>
                        <div className="lb-absence-history-table">
                            {Object.entries(splitHistory())
                                .reverse()
                                .map((year, key) => {
                                    return (
                                        <AbsenceByYear
                                            events={year}
                                            key={key}
                                        />
                                    );
                                })}
                        </div>
                    </Fragment>
                ) : (
                    <div className="no-absence">Aucune absence à afficher</div>
                )}
            </div>
        </Lightbox>
    );
};

export default AbsenceHistory;
