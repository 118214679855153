import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as DocumentActions from "actions/Document";
import "./UnionHome.scss";

const UnionHome = (props) => {
    const { me, isEdit } = props;
    const dispatch = useDispatch();
    const unions = useSelector((state) => state.document.entities.unionList);

    useEffect(() => {
        dispatch(DocumentActions.fetchUnions());
    }, []);

    return (
        <>
            <div className="unions-headline">Les syndicats</div>
            <div className="unions-infos">
                Cliquez sur le logo du syndicat pour accéder à ses
                communications
            </div>
            <div className="union-links-container">
                {Object.values(unions).map((union, key) => {
                    if (isEdit) {
                        if (
                            me.hasRole(union.role) ||
                            me.hasRole("ROLE_SYNDIC_ADMIN")
                        ) {
                            return (
                                <Link
                                    key={key}
                                    className="union-link"
                                    style={{
                                        backgroundImage: `url(${union.logoUrl})`,
                                    }}
                                    to={`/backend/documents/syndicats/${union.id}`}
                                ></Link>
                            );
                        }
                    } else {
                        return (
                            <Link
                                key={key}
                                className="union-link"
                                style={{
                                    backgroundImage: `url(${union.logoUrl})`,
                                }}
                                to={`/documents/syndicats/${union.id}`}
                            ></Link>
                        );
                    }
                })}
            </div>
        </>
    );
};

export default UnionHome;
