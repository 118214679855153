class Post {
    id;
    title;
    summary;
    content;
    dateAt;
    type;
    place;
    publishedAt;
    url;
    fileUrl;
    secondaryFile;
    iframeUrl;
    shareUrl;
    facebookShareUrl;
    linkedinShareUrl;
    twitterShareUrl;
    viadeoShareUrl;
    contact;
    homepageImageUrl;
    homepageContent;
    homepage;

    constructor(json) {
        this.id = json.id;
        this.title = json.title;
        this.summary = json.summary;
        this.content = json.content;
        this.dateAt = json.date_at;
        this.type = json.type;
        this.place = json.place;
        this.publishedAt = json.published_at;
        this.url = json.url;
        this.fileUrl = json.file_url;
        this.secondaryFile = json.secondary_file;
        this.iframeUrl = json.iframe_url;
        this.shareUrl = json.share_url;
        this.facebookShareUrl = json.facebook_share_url;
        this.linkedinShareUrl = json.linkedin_share_url;
        this.twitterShareUrl = json.twitter_share_url;
        this.viadeoShareUrl = json.viadeo_share_url;
        this.contact = json.contact;
        this.homepageImageUrl = json.homepage_image_url;
        this.homepageContent = json.homepage_content;
        this.homepage = json.homepage;
    }

    get picture() {
        return "https://www.synchrone.fr/" + this.fileUrl;
    }

    get homepagePicture() {
        return "https://www.synchrone.fr/" + this.homepageImageUrl;
    }

    get secondaryPicture() {
        return "https://www.synchrone.fr/" + this.secondaryFile;
    }
}

export default Post;
