import { Schemas } from "middleware/Schemas";
import * as Params from "config/Parameters";

import { apiOptions } from "utils/Api";

export const FETCH_REMOTE_DAYS_CONFIG_REQUEST = "FETCH_REMOTE_DAYS_CONFIG_REQUEST";
export const FETCH_REMOTE_DAYS_CONFIG_SUCCESS = "FETCH_REMOTE_DAYS_CONFIG_SUCCESS";
export const FETCH_REMOTE_DAYS_CONFIG_FAILURE = "FETCH_REMOTE_DAYS_CONFIG_FAILURE";
export const fetchRemoteDaysConfig = () => {
    return {
        types: [
            FETCH_REMOTE_DAYS_CONFIG_REQUEST,
            FETCH_REMOTE_DAYS_CONFIG_SUCCESS,
            FETCH_REMOTE_DAYS_CONFIG_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}settings/activity/remote-days`,
                apiOptions("GET")
            );
        },
        schema: Schemas.REMOTE_DAYS_CONFIG,
    };
};

export const PATCH_REMOTE_DAYS_CONFIG_REQUEST = "PATCH_REMOTE_DAYS_CONFIG_REQUEST";
export const PATCH_REMOTE_DAYS_CONFIG_SUCCESS = "PATCH_REMOTE_DAYS_CONFIG_SUCCESS";
export const PATCH_REMOTE_DAYS_CONFIG_FAILURE = "PATCH_REMOTE_DAYS_CONFIG_FAILURE";
export const patchRemoteDaysConfig = (json) => {
    return {
        types: [
            PATCH_REMOTE_DAYS_CONFIG_REQUEST,
            PATCH_REMOTE_DAYS_CONFIG_SUCCESS,
            PATCH_REMOTE_DAYS_CONFIG_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}settings/activity/remote-days`,
                apiOptions("PATCH", json)
            );
        },
        schema: Schemas.REMOTE_DAYS_CONFIG,
    };
};

export const PATCH_REMOTE_DAYS_FOR_MONTH_REQUEST = "PATCH_REMOTE_DAYS_FOR_MONTH_REQUEST";
export const PATCH_REMOTE_DAYS_FOR_MONTH_SUCCESS = "PATCH_REMOTE_DAYS_FOR_MONTH_SUCCESS";
export const PATCH_REMOTE_DAYS_FOR_MONTH_FAILURE = "PATCH_REMOTE_DAYS_FOR_MONTH_FAILURE";
export const patchRemoteDaysForMonth = (month, year, json) => {
    return {
        types: [
            PATCH_REMOTE_DAYS_FOR_MONTH_REQUEST,
            PATCH_REMOTE_DAYS_FOR_MONTH_SUCCESS,
            PATCH_REMOTE_DAYS_FOR_MONTH_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}settings/activity/remote-days/month/${month}/${year}`,
                apiOptions("PATCH", json)
            );
        },
        schema: Schemas.REMOTE_DAYS_CONFIG,
    };
};