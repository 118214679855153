import moment from "moment";
import "moment/locale/fr";
import unorm from "unorm";

import * as Params from "config/Parameters";

export function monthInLetter(month) {
    let year = [];
    year[1] = "Janvier";
    year[2] = "Février";
    year[3] = "Mars";
    year[4] = "Avril";
    year[5] = "Mai";
    year[6] = "Juin";
    year[7] = "Juillet";
    year[8] = "Août";
    year[9] = "Septembre";
    year[10] = "Octobre";
    year[11] = "Novembre";
    year[12] = "Décembre";

    return year[month];
}

export function getPersonType(type) {
    let personType;

    switch (type) {
        case "internal":
            personType = "Structure Interne";
            break;
        case "contractor":
            personType = "Prestataire";
            break;
        case "consultant":
            personType = "Consultant";
            break;

        default:
            break;
    }

    return personType;
}

export function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

export function isAuthenticated() {
    return !!localStorage.getItem("synchroneTokenExpiration");
}

export function isSwitching() {
    return !!localStorage.getItem("switchUser");
}

export function needTokenRefresh() {
    let expirationDate = moment
        .unix(localStorage.getItem("synchroneTokenExpiration"))
        .subtract(Params.REFRESH_DELAY, "s");
    if (moment.parseZone() >= expirationDate) {
        return true;
    }
    return false;
}

export function checkLocalStorageExpirationDateForMobileOverlay() {
    let expirationDate = moment.unix(
        localStorage.getItem("acceptBrowserNavOnMobileExpiration")
    );
    if (moment.parseZone() >= expirationDate) {
        localStorage.removeItem("acceptBrowserNavOnMobileExpiration");
        localStorage.removeItem("acceptBrowserNavOnMobile");
    }
}

export function logout() {
    // localStorage.removeItem("synchroneToken");
    // localStorage.removeItem("synchroneRefreshToken");
    localStorage.removeItem("switchUser");
    localStorage.removeItem("synchroneTokenExpiration");

    window.location = "/login";
}

export function acceptBrowserNavOnMobile() {
    localStorage.setItem("acceptBrowserNavOnMobile", true);
    localStorage.setItem(
        "acceptBrowserNavOnMobileExpiration",
        moment.parseZone().add(1, "d").unix()
    );
}

export function hasAcceptedBrowserNavOnMobile() {
    checkLocalStorageExpirationDateForMobileOverlay();
    return !!localStorage.getItem("acceptBrowserNavOnMobile");
}

export function getInitials(string) {
    let array = string.split(" ");
    switch (array.length) {
        case 1:
            return array[0].charAt(0).toUpperCase();
        default:
            return (
                array[0].charAt(0).toUpperCase() +
                array[array.length - 1].charAt(0).toUpperCase()
            );
    }
}

// Remove accents and replaces hyphens with spaces
export function sanitizeForSearch(string) {
    return unorm
        .nfd(string.toLowerCase())
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/-/g, " ");
}

export function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
}

export function b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
}

export function addSynchroneUrlToPictures(content) {
    const regex = /src="(\/[^"]*)"/gi;
    return content.replace(regex, 'src="https://www.synchrone.fr/$1"');
}

export function buildUrl(baseUrl, params) {
    if (params) {
        let searchParams = new URLSearchParams();
        Object.entries(params).forEach((param) => {
            if (param[1]) {
                if (typeof param[1] === "object") {
                    param[1].forEach((item) => {
                        if (param[0] !== "person") {
                            searchParams.append(`${param[0]}[]`, item);
                        } else {
                            searchParams.append(`matricules[]`, item);
                        }
                    });
                } else {
                    if (param[0] !== "person") {
                        searchParams.append(`${param[0]}`, param[1]);
                    } else {
                        searchParams.append(`matricules[]`, param[1]);
                    }
                }
            }
        });
        return baseUrl + "?" + searchParams.toString();
    }

    return baseUrl;
}

export function daysForOvertimeList(overtimeValues, overtimeType) {
    if (overtimeType === "constraint") {
        let startAt, endAt;
        if (overtimeValues.startAt) {
            startAt = overtimeValues.startAt;
            endAt = overtimeValues.endAt;
        } else {
            startAt = overtimeValues.start_at;
            endAt = overtimeValues.end_at;
        }

        let daysArray = [];
        let startDay = moment.parseZone(startAt);
        if (startAt !== endAt) {
            let currentDay = startDay;
            daysArray.push({ date: currentDay.format("YYYY-MM-DD") });
            while (
                currentDay.format("YYYY-MM-DD") !==
                moment.parseZone(endAt).format("YYYY-MM-DD")
            ) {
                currentDay = moment.parseZone(currentDay).add(1, "days");
                daysArray.push({ date: currentDay.format("YYYY-MM-DD") });
            }
        } else {
            daysArray.push({ date: startDay.format("YYYY-MM-DD") });
        }

        return daysArray;
    }

    return overtimeValues.days;
}

export const getDevice = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        return "android";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios";
    }

    return "unknown";
};

export const isMobileOrTablet = () => {
    return window.matchMedia("only screen and (max-width: 1050px)").matches;
};

export const isDocumentsManager = (me) => {
    let canManageDocuments = me.roles.some((role) => {
        return role.match("ROLE_GED_") || role.match("ROLE_SYNDIC_");
    });

    return canManageDocuments;
};

export const isGedManager = (me) => {
    let canManageGed = me.roles.some((role) => {
        return role.match("ROLE_GED_");
    });

    return canManageGed;
};

export const isUnionManager = (me) => {
    let canManageUnion = me.roles.some((role) => {
        return role.match("ROLE_SYNDIC_");
    });

    return canManageUnion;
};
export const isoFormat = (date) => {
    if (Object.prototype.toString.call(date) !== "[object Date]") {
        date = new Date(date);
    }

    return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0")
    );
};

export const getWeekday = (date) => {
    if (Object.prototype.toString.call(date) !== "[object Date]") {
        date = new Date(date);
    }
    return date.getDay();
};
