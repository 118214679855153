import React, {Component} from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {registerLocale} from "react-datepicker";
import fr from "date-fns/locale/fr";

import moment from "moment";
import "moment/locale/fr";

import Button from "components/TimesheetContainer/Forms/Event/Button";
import Slider from "components/TimesheetContainer/Forms/Event/Slider";

const Step3Standard = (props) => {
    registerLocale("fr", fr);

    // Obligatoire de le déclarer en classe, react-datepicker ne prend pas les sfc (problème de passage de ref)
    class CustomDateInput extends Component {
        render() {
            const {onClick, value} = this.props;

            return (
                <div className="custom-date-input" onClick={onClick}>
                    {formattedDate(value, "DD MMMM YYYY")}
                </div>
            );
        }
    }

    const formattedDate = (date, format) => {
        if (format) {
            return moment.parseZone(date, format).format("ddd D MMMM");
        }

        return moment.parseZone(date).format("ddd D MMMM");
    };

    function canNextStep() {
        return (props.selectedEventCode.shortCode !== "CPR" && props.hasEnoughVacations) || (props.selectedEventCode.shortCode === "RTS" && props.hasEnoughVacations)
    }

    return (
        <div className="step3-contents">
            <div className="moments">
                <div className="moment-start">
                    <div className="title">Début :</div>
                    {props.isEdit ? (
                        <DatePicker
                            selected={new Date(props.startAt)}
                            onChange={(date) => props.setStartAt(date)}
                            locale="fr"
                            dateFormat="dd MMMM yyyy"
                            todayButton="Aujourd'hui"
                            filterDate={props.isAllowed}
                            customInput={<CustomDateInput/>}
                        />
                    ) : (
                        <div className="date">
                            {formattedDate(props.startAt)}
                        </div>
                    )}

                    <Slider
                        defaultValue="am"
                        selectedMoment={props.selectedStartMoment}
                        setSelectedMoment={props.setSelectedStartMoment}
                        value={null}
                        leftLabel="Matin"
                        rightLabel="Midi"
                        type={props.selectedEventClass}
                        allowedToSwitch={props.allowedToSwitchStart}
                        selectedDate={props.startAt}
                        otherDate={props.endAt}
                    />
                </div>
                <div className="moment-end">
                    <div className="title">Fin :</div>
                    {props.isEdit ? (
                        <DatePicker
                            selected={new Date(props.endAt)}
                            onChange={(date) => props.setEndAt(date)}
                            locale="fr"
                            dateFormat="dd MMMM yyyy"
                            todayButton="Aujourd'hui"
                            filterDate={props.isAllowed}
                            customInput={<CustomDateInput/>}
                        />
                    ) : (
                        <div className="date">{formattedDate(props.endAt)}</div>
                    )}

                    <Slider
                        defaultValue="pm"
                        selectedMoment={props.selectedEndMoment}
                        setSelectedMoment={props.setSelectedEndMoment}
                        value={null}
                        leftLabel="Midi"
                        rightLabel="Soir"
                        type={props.selectedEventClass}
                        allowedToSwitch={props.allowedToSwitchEnd}
                        selectedDate={props.endAt}
                        otherDate={props.startAt}
                    />
                </div>
            </div>
            <div>
                {props.children}
            </div>
            <div className="btn-container">
                <Button
                    text={"Suivant"}
                    type={
                        canNextStep()
                            ? "next"
                            : "disabled"
                    }
                    onClick={() => {
                        return canNextStep()
                            ? props.nextStep()
                            : "";
                    }}
                />
            </div>
        </div>
    );
};

export default Step3Standard;
