import React from "react";

import "./AbsenceDates.scss";

import moment from "moment";
import "moment/locale/fr";

const AbsenceDates = (props) => {
    const event = props.event;
    const format = props.format;

    moment.updateLocale("fr", {
        monthsShort: [
            "Janv",
            "Févr",
            "Mars",
            "Avri",
            "Mai",
            "Juin",
            "Juil",
            "Août",
            "Sept",
            "Oct",
            "Nov",
            "Déc",
        ],
    });

    const formattedDate = (date) => {
        if (format) {
            return moment.parseZone(date).format(format);
        }

        return moment.parseZone(date).format("ddd D MMMM");
    };

    const StartAt = () => {
        return (
            <span className="absence-startAt">
                {formattedDate(event.startAt)}{" "}
                {event.startMoment !== "am" && (
                    <span className="absence-start-moment"> • PM</span>
                )}
                {event.daysCount === 0.5 && event.startMoment === "am" && (
                    <span className="absence-start-moment"> • AM</span>
                )}
            </span>
        );
    };

    const EndAt = () => {
        return (
            <span className="absence-endAt">
                {formattedDate(event.endAt)}
                {event.endMoment !== "pm" && (
                    <span className="absence-end-moment"> • AM</span>
                )}
            </span>
        );
    };

    if (event.startAt !== event.endAt) {
        return (
            <span className="absence-dates">
                <StartAt />
                <i className="far fa-chevron-right"></i>
                <EndAt />
            </span>
        );
    }

    return (
        <div className="absence-dates">
            <StartAt />
        </div>
    );
};

export default AbsenceDates;
