import React, { useState } from "react";

import { getInitials } from "utils/Utils";

import Button from "components/TimesheetContainer/Forms/Event/Button";

const ButtonList = (props) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const listStyle = {
        height: Math.min(50 * props.eventCodes.length + 60, 300) + "px",
    };

    return (
        <div
            className={
                "lb-event-action-btn button-list" + (open ? " open" : "")
            }
            style={open ? listStyle : {}}
        >
            <div onClick={() => toggleOpen(true)}>
                <div className="label">Autre</div>
                <i className="fal fa-chevron-down"></i>
            </div>
            <div className={"list" + (open ? "" : " hidden ")}>
                {props.eventCodes
                    ? props.eventCodes.map((item, key) => (
                          <Button
                              key={key}
                              text={item.description}
                              type={item.type}
                              shortname={item.shortCode}
                              onClick={() => {
                                  setOpen(false);
                                  props.postEventCode(item);
                              }}
                              // additionnalStyle={{
                              //       lineHeight: "22.5px"
                              //     // lineHeight:
                              //     //     item.description.length > 43
                              //     //         ? "22.5px"
                              //     //         : "45px",
                              // }}
                          />
                      ))
                    : ""}
            </div>
        </div>
    );
};

export default ButtonList;
