import React from "react";
import "./Contact.scss";
import { useDispatch } from "react-redux";
import ContactLightbox from "components/ContactContainer/ContactLightbox";
import { changeOverlayContent } from "events/OverlayEvents";

const ConsultantContact = (props) => {
    const contact = props.contact;

    const handleClick = () => {
        changeOverlayContent(<ContactLightbox contact={contact} />);
    };

    return (
        <div className="contact consultant-contact" onClick={handleClick}>
            <div>{contact.identity}</div>
            <div>{contact.job}</div>
            {contact.birthday ? (
                <div className="contact-birthdate">
                    <i className="fal fa-birthday-cake"></i> {contact.birthday}
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default ConsultantContact;
