import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import moment from "moment";
import "moment/locale/fr";
import {isoFormat, getWeekday} from "utils/Utils";

import PersonTypeBadge from "components/Backend/common/PersonTypeBadge";
import "./ManageeLine.scss";

const ManageeLine = (props) => {
    const {
        user,
        hasManagees,
        manager,
        // isManagerOpen,
        level,
        me,
        holidays,
        // openManagers,
        openManager,
        closeManager,
        daysArray,
    } = props;
    const openManagers = useSelector(
        (state) => state.teamPlanning.openManagers
    );
    const isFirstLevel = user.manager === me.matricule;
    const isManagerOpen = openManagers.includes(manager.matricule);
    const [isVisible, setVisible] = useState(
        isManagerOpen || isFirstLevel ? true : false
    );

    useEffect(() => {
        setVisible(isManagerOpen || isFirstLevel);
    }, [isManagerOpen, isFirstLevel]);

    const getAbsencesForDay = (day) => {
        let events = [];

        user.absences.map((element) => {
            if (
                isoFormat(element.startAt) <= isoFormat(day) &&
                isoFormat(element.endAt) >= isoFormat(day)
            ) {
                events.push(element);
            }
        });

        return events;
    };

    const getStyles = (element) => {
        let styles = {};
        let absencesForDay = getAbsencesForDay(element);
        if (
            getWeekday(element) === 6 || // Saturday
            getWeekday(element) === 0 || // Sunday
            holidays.includes(isoFormat(element)) ||
            (user.endAt && isoFormat(element) > isoFormat(user.endAt))
        ) {
            styles.background = "#878787";
        } else if (absencesForDay.length > 0) {
            if (absencesForDay.length > 1) {
                styles.background = "#a51160";
            } else {
                if (
                    isoFormat(absencesForDay[0].startAt) ===
                    isoFormat(element) &&
                    absencesForDay[0].startMoment === "pm"
                ) {
                    if (absencesForDay[0].state === "submitted") {
                        styles.background =
                            "linear-gradient(to right bottom, white 50%, rgba(165, 17, 96, 0.5) 50%, rgba(165, 17, 96, 0.5) 52%) no-repeat";
                    } else {
                        styles.background =
                            "linear-gradient(to right bottom, white 50%, #a51160 50%, #a51160 52%) no-repeat";
                    }
                } else if (
                    isoFormat(absencesForDay[0].endAt) === isoFormat(element) &&
                    absencesForDay[0].endMoment === "am"
                ) {
                    if (absencesForDay[0].state === "submitted") {
                        styles.background =
                            "linear-gradient(to right bottom, rgba(165, 17, 96, 0.5) 50%, rgba(165, 17, 96, 0.5) 50%, white 52%) no-repeat";
                    } else {
                        styles.background =
                            "linear-gradient(to right bottom, #a51160 50%, #a51160 50%, white 52%) no-repeat";
                    }
                } else {
                    if (absencesForDay[0].state === "submitted") {
                        styles.background = "rgba(165, 17, 96, 0.5)";
                    } else {
                        styles.background = "#a51160";
                    }
                }
            }
        }
        return styles;
    };

    const getPadding = () => {
        let styles = {};
        styles.borderLeft = 10 + level * 40 + "px solid #f2f2f2";
        styles.borderRight = "2px solid #f0f0f0";
        return styles;
    };

    return (
        <tr className={isVisible ? "visible-row" : "invisible-row"}>
            <td
                className="managee-identity-cell"
                style={getPadding()}
                title={user.identity}
            >
                <div className="managee-identity-container">
                    {/* <IdentityBlock user={user} /> */}
                    <PersonTypeBadge personType={user.personType}/>
                    <div className="managee-identity-label">
                        {user.identity}
                    </div>
                </div>
                {hasManagees &&
                    (openManagers.includes(user.matricule) ? (
                        <span
                            onClick={() => closeManager(user.matricule)}
                            className="open-manager-indicator fal fa-chevron-down fa-fw open-group"
                        ></span>
                    ) : (
                        <span
                            onClick={() => openManager(user.matricule)}
                            className="open-manager-indicator fal fa-chevron-down fa-fw closed-group"
                        ></span>
                    ))}
                {!user.isContractor() && <div className="absence-counters-line">
                    <span className="absence-counter-first-block">
                        CP : <span className="absence-counter">{(user.cp + user.beforeJune).toFixed(2)}</span>{" "}
                        dont{" "}
                        <span className="before-june-counter">
                            {user.beforeJune.toFixed(2)}
                        </span>{" "}
                        avant le 31/05
                    </span>
                    <span className="absence-counter-second-block">
                        RTT :{" "}
                        <span className="absence-counter">{user.rtt.toFixed(2)}</span>
                    </span>
                </div>}
                {user.displayWorkedDays &&
                    <div className="worked-days" title="Projection de jours travaillés au 31/12">
                        <i className="fal fa-calendar-exclamation"></i> {user.workedDays} J</div>}
            </td>
            {daysArray.map((element, key) => {
                return (
                    <td
                        key={key}
                        className="day-cell"
                        style={getStyles(element)}
                        // title={element.event_code.description}
                    ></td>
                );
            })}
        </tr>
    );
};

export default ManageeLine;
