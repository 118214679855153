import { Schemas } from "middleware/Schemas";
import * as Params from "config/Parameters";
import { apiOptions } from "utils/Api";

export const GET_TUTORIALS_REQUEST = "GET_TUTORIALS_REQUEST";
export const GET_TUTORIALS_SUCCESS = "GET_TUTORIALS_SUCCESS";
export const GET_TUTORIALS_FAILURE = "GET_TUTORIALS_FAILURE";
export const fetchTutorials = () => {
    return {
        types: [
            GET_TUTORIALS_REQUEST,
            GET_TUTORIALS_SUCCESS,
            GET_TUTORIALS_FAILURE,
        ],
        needLogin: true,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}tutorial?tutorialType=desktop`,
                apiOptions("GET")
            );
        },
        payload: {},
        schema: Schemas.TUTORIAL_ARRAY,
    };
};

export const GET_PUBLIC_TUTORIALS_REQUEST = "GET_PUBLIC_TUTORIALS_REQUEST";
export const GET_PUBLIC_TUTORIALS_SUCCESS = "GET_PUBLIC_TUTORIALS_SUCCESS";
export const GET_PUBLIC_TUTORIALS_FAILURE = "GET_PUBLIC_TUTORIALS_FAILURE";
export const fetchPublicTutorials = () => {
    return {
        types: [
            GET_PUBLIC_TUTORIALS_REQUEST,
            GET_PUBLIC_TUTORIALS_SUCCESS,
            GET_PUBLIC_TUTORIALS_FAILURE,
        ],
        needLogin: false,
        callAPI: () => {
            return fetch(
                `${Params.API_ROOT}public/tutorial?tutorialType=desktop`,
                apiOptions("GET")
            );
        },
        payload: {},
        schema: Schemas.TUTORIAL_ARRAY,
    };
};
