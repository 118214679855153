import React, {useState} from "react";
import {useDispatch} from "react-redux";
import moment from "moment";
import "moment/locale/fr";

import Lightbox from "components/common/Lightbox";
import PersonTypeBadge from "components/Backend/common/PersonTypeBadge";

import * as ValidationActions from "actions/Validation";

import {changeOverlayContent} from "events/OverlayEvents";

import "./ActivityValidationForm.scss";

const ActivityValidationForm = (props) => {
    const {
        action,
        user,
        manager,
        month,
        validatedState,
        period,
        loadList,
    } = props;
    const [comment, setComment] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();

    let activityCount = 0,
        absenceCount = 0;

    Object.values(month.events).map((event, key) => {
        if (event.days > 0) {
            if (event.type === "absence") {
                absenceCount += event.days;
            } else {
                activityCount += event.days;
            }
        }
    });

    const lightboxTitle = () => {
        let title = "";
        switch (action) {
            case "validate":
                title = "Valider le CRA";
                break;
            case "backward":
                title = "Redonner la main au collaborateur";
                break;
            default:
                break;
        }

        return title;
    };

    const close = () => {
        changeOverlayContent(null);
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handlePost = () => {
        if (!submitting) {
            setSubmitting(true);
            let json = {
                month: month.id,
                comment: comment,
                updated_at: moment.parseZone(month.updatedAt).format(
                    "YYYY-MM-DD[T]HH:mm:ss"
                ),
            };

            if (action === "validate") {
                json.action = validatedState;
            } else if (action === "backward") {
                json.action = "non_submitted";
            }

            let body = JSON.stringify(json);
            dispatch(ValidationActions.postMonthAction(body)).then(() => {
                changeOverlayContent(null);
                setSubmitting(false);
                loadList();
            });
        }
    };

    return (
        <Lightbox className="lb-centered validate-activity-lb">
            <div className="validate-activity-header">
                <div className="validate-activity-title">{lightboxTitle()}</div>
                <div className="validate-activity-identity-block">
                    <div className="validate-activity-identity">
                        {user.identity}
                    </div>
                    <PersonTypeBadge personType={user.personType}/>
                    <div className="validate-activity-matricule">
                        {user.matricule}
                    </div>
                    <div className="validate-activity-manager">
                        Responsable : {manager && manager.identity}
                    </div>
                </div>
            </div>


            <div className="validate-flex-container">
                <div className="validate-activity-period">
                    <span className="validate-activity-period-month">{period.format("MMMM")}</span>
                    &nbsp;
                    <span className="validate-activity-period-year">{period.format("YYYY")}</span>
                </div>

                <div className="validate-activity-counts-titles-container">
                    <div className="validate-activity-counts-title">CRA Mensuel</div>
                    <div className="validate-activity-counts-title">{(user.displayWorkedDays || user.displayQuota) ? "Compteurs prévisionnels" : ""}</div>
                </div>

                <div className="validate-activity-counts-container">

                    <div className="validate-activity-counts-subcontainer">
                        <div className="validate-activity-counts">
                            <div className="activity-count">
                                Activités : <span className="count">{activityCount} J</span>
                            </div>
                            <div className="absence-count">
                                Absences : <span className="count">{absenceCount} J</span>
                            </div>
                            <div className="total-count-information">
                                Total :{" "}
                                <div className="total-count">
                                    {absenceCount + activityCount} J
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="validate-activity-particularities-container">
                        {(month.constraint || month.intervention) && (
                            <div className="validate-activity-particularity">
                                <i className="fal fa-fw fa-watch icon hours-not-worked-color"></i>
                                <div className="label">Activités complémentaires</div>
                            </div>
                        )}
                        {month.remoteWorking && (
                            <div className="validate-activity-particularity">
                                <i className="fal fa-fw fa-home-lg-alt icon duck-color"></i>
                                <div className="label">Télétravail ({month.remoteDaysCount} J)</div>
                            </div>
                        )}
                        {month.specialTime && (
                            <div className="validate-activity-particularity">
                                <i className="fal fa-fw fa-hourglass icon special-time-color"></i>
                                <div className="label">Temps spécial</div>
                            </div>
                        )}
                        {month.partialTime && user.type !== "contractor" && (
                            <div className="validate-activity-particularity">
                                <i className="fal fa-fw fa-percent icon partial-time-color"></i>
                                <div className="label">Temps partiel</div>
                            </div>
                        )}
                        {user.type === "contractor" && (
                            <div className="validate-activity-particularity">
                                <i className="far fa-fw fa-address-book icon contractor-color"></i>
                                <div className="label">Prestataire</div>
                            </div>
                        )}
                    </div>
                </div>

                <div className={(user.displayWorkedDays || user.displayQuota) ? "validate-activity-counts-container" : "validate-activity-counts-container no-background"}>
                    {(user.displayWorkedDays || user.displayQuota) && <div className="validate-activity-counts-subcontainer">
                        <div className="validate-activity-counts flex-space-evenly">
                            {user.displayWorkedDays &&
                                <div className="worked-days-counter" title="Projection de jours travaillés au 31/12"><i
                                    className="fal fa-calendar-exclamation"></i> {user.workedDays}
                                </div>}
                            {user.displayQuota &&
                                <div className="quota-counter" title="Projection du contingent au 31/12"><i
                                    className="fal fa-calendar-exclamation"></i> {user.quota} h
                                </div>}
                        </div>
                    </div>}
                </div>

            </div>

            {month.noPrestation && user.isContractor && (
                <div className="validate-form-no-prestation">
                    Aucune prestation ce mois-ci
                </div>
            )}

            {action === "validate" && month.comment && (
                <div className="validate-activity-user-comment-container">
                    <i className="fal fa-quote-left"></i>
                    <div className="validate-activity-user-comment">
                        {month.comment}
                    </div>
                    <i className="fal fa-quote-right"></i>
                </div>
            )}

            <div className="validate-activity-comment">
                <div className="validate-activity-comment-label">
                    <i className="far fa-comment-alt"></i>
                    Commentaire {action === "validate" && "de validation "}:
                </div>
                <textarea
                    className="validate-activity-comment-input"
                    onChange={(e) => handleCommentChange(e)}
                ></textarea>
            </div>

            <div className="validate-activity-actions">
                <div className="cancel-btn" onClick={close}>
                    Annuler
                </div>
                {(action === "validate" || action === "backward") && (
                    <div className="next-btn" onClick={handlePost}>
                        Confirmer
                    </div>
                )}
            </div>
        </Lightbox>
    );
};

export default ActivityValidationForm;
