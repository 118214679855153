import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Form, Formik, Field, yupToFormErrors } from "formik";
import FilesContainer from "components/DocumentContainer/FilesContainer";
import MiniLoader from "components/common/MiniLoader";
import { changeOverlayContent } from "events/OverlayEvents";
import * as DocumentActions from "actions/Document";
import "./UnionDocuments.scss";

const UnionDocuments = (props) => {
    const { me, isEdit } = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    const [editingDescription, setEditingDescription] = useState(false);
    const union = useSelector((state) => {
        return state.document.entities.union;
    });

    useEffect(() => {
        if (id) {
            dispatch(DocumentActions.resetSelectedUnion());
            dispatch(DocumentActions.fetchUnion(id));
        }
    }, [id]);

    useEffect(() => {
        if (isEdit && union.role && !me.hasRole(union.role)) {
            window.location = "/backend/documents/syndicats";
        }
    }, [union]);

    return (
        <>
            <div className="union-infos">
                {union.logoUrl ? (
                    <div
                        className="union-logo"
                        style={{
                            backgroundImage: `url(${union?.logoUrl})`,
                        }}
                    ></div>
                ) : (
                    <MiniLoader
                        color={"#00c1de"}
                        borderWidth="3px"
                        containerWidth="100px"
                        containerHeight="100px"
                    />
                )}

                <div className="union-speech">
                    <div className="union-word">Le mot de votre syndicat</div>
                    <div className="union-description">
                        {isEdit && editingDescription ? (
                            <Formik
                                initialValues={{
                                    description: union.description,
                                }}
                                validateOnChange={false}
                                validateOnBlur={false}
                                // validate={(values, props) => {
                                //     return schema
                                //         .validate(values, { abortEarly: false })
                                //         .then(() => {
                                //             return true;
                                //         })
                                //         .catch((err) => {
                                //             return yupToFormErrors(err);
                                //         });
                                // }}
                                onSubmit={(values, { setSubmitting }) => {
                                    dispatch(
                                        DocumentActions.updateUnion(
                                            id,
                                            JSON.stringify(values)
                                        )
                                    ).then(() => {
                                        dispatch(
                                            DocumentActions.fetchUnion(id)
                                        );
                                        setEditingDescription(false);
                                    });
                                    setTimeout(() => {
                                        setSubmitting(false);
                                    }, 1000);
                                }}
                            >
                                {({
                                    handleChange,
                                    errors,
                                    isSubmitting,
                                    setValues,
                                    submitForm,
                                }) => (
                                    <Form className="update-union-description-form">
                                        <Field
                                            as="textarea"
                                            name="description"
                                            className="union-description-textarea"
                                        />
                                        {errors?.label && (
                                            <div className="error-warning">
                                                {errors.label}
                                            </div>
                                        )}
                                        <div className="union-description-actions">
                                            <i
                                                onClick={() =>
                                                    setEditingDescription(false)
                                                }
                                                className="fas fa-times-circle union-description-actions-item cancel-action"
                                                title="Annuler"
                                            ></i>
                                            <i
                                                onClick={submitForm}
                                                className="fas fa-check-circle union-description-actions-item confirm-action"
                                                title="Valider"
                                            ></i>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        ) : (
                            <>
                                <div
                                    className={
                                        isEdit ? "shorter-description" : ""
                                    }
                                >
                                    {union?.description}
                                </div>
                                {isEdit && (
                                    <div className="union-description-actions">
                                        <i
                                            onClick={() =>
                                                setEditingDescription(true)
                                            }
                                            className="far fa-edit union-description-actions-item"
                                            title="Modifier le mot du syndicat"
                                        ></i>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="files-container">
                {union && (
                    <FilesContainer
                        title="Communications syndicales"
                        files={union.files}
                        isEdit={isEdit}
                        type="union"
                        unionId={id}
                    />
                )}
            </div>
        </>
    );
};

export default UnionDocuments;
