import React, {Fragment, useEffect} from "react";
import {Switch, Route} from "react-router";
import {isGedManager, isUnionManager} from "utils/Utils";
import BackendHome from "components/Backend/BackendHome";
import Header from "components/common/Header/Header";
import AbsenceContainer from "components/Backend/AbsenceContainer/AbsenceContainer";
import PeriodContainer from "components/Backend/PeriodContainer/PeriodContainer";
import Remote from "components/Backend/Remote/Remote";
import CRAContainer from "components/Backend/CRAContainer/CRAContainer";
import CRADisplay from "components/Backend/CRADisplay/CRADisplay";
import TeamPlanningContainer from "components/Backend/TeamPlanningContainer/TeamPlanningContainer";
import ExtraActivitiesRequestsContainer from "components/Backend/ExtraActivitiesRequestsContainer/ExtraActivitiesRequestsContainer";
import DocumentContainer from "components/Backend/DocumentContainer/DocumentContainer";
import UnionHome from "components/DocumentContainer/UnionHome";
import UnionDocuments from "components/DocumentContainer/UnionDocuments";
import * as loadDynamicScript from "utils/LoadDynamicScript.js";
import moment from "moment";
import "moment/locale/fr";

const BackendRoutes = (props) => {
    const {me} = props;

    useEffect(() => {
        // if (
        //     moment() >= moment("2021-12-01", "YYYY-MM-DD") &&
        //     moment() <= moment("2021-12-31", "YYYY-MM-DD")
        // ) {
        loadDynamicScript.unload("aventScript");
        // }
    }, []);

    return (
        <Fragment>
            <Header backend={true}/>
            <Switch>
                {me.hasRole("ROLE_ABSENCE_SHOW") && (
                    <Route
                        path="/backend/absences"
                        component={() => <AbsenceContainer/>}
                    />
                )}

                {me.hasRole("ROLE_PAY_MANAGER") && (
                    <Route
                        path="/backend/remote-work"
                        component={() => <Remote/>}
                    />
                )}
                {me.hasRole("ROLE_BILLING_SUPERVISOR") && (
                    <Route
                        path="/backend/periods"
                        component={() => <PeriodContainer/>}
                    />
                )}
                {me.hasRole("ROLE_ACTIVITY_SHOW") && (
                    <Route
                        path="/backend/activity"
                        component={() => <CRAContainer/>}
                    />
                )}
                {me.hasRole("ROLE_CRA_SHOW") && (
                    <Route
                        path="/backend/cra/:monthId/:month/:year/:userMatricule"
                        component={() => <CRADisplay/>}
                    />
                )}

                {me.hasRole("ROLE_ABSENCE_ACCEPT_FIRST_LEVEL") && (
                    <Route
                        path="/backend/team-planning/:month?/:year?"
                        component={() => <TeamPlanningContainer/>}
                    />
                )}

                {isGedManager(me) && (
                    <Route
                        path="/backend/documents/ged"
                        component={() => <DocumentContainer/>}
                    />
                )}

                {isUnionManager(me) && (
                    <Route
                        path="/backend/documents/syndicats/:id"
                        component={() => (
                            <div id="union-container" className="backend">
                                <UnionDocuments me={me} isEdit={true}/>
                            </div>
                        )}
                    />
                )}

                {isUnionManager(me) && (
                    <Route
                        path="/backend/documents/syndicats"
                        component={() => (
                            <div id="union-container" className="backend">
                                <UnionHome me={me} isEdit={true}/>
                            </div>
                        )}
                    />
                )}

                <Route path="/backend/extra-activities"
                       component={() => (
                           <ExtraActivitiesRequestsContainer me={me}/>
                       )}
                />

                <Route path="/backend/" component={() => <BackendHome/>}/>
            </Switch>
        </Fragment>
    );
};

export default BackendRoutes;
