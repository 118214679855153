import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeOverlayContent } from "events/OverlayEvents";
import Dropdown from "components/common/Dropdown";
import FileRow from "components/DocumentContainer/FileRow";
import ConfirmLightbox from "components/common/ConfirmLightbox";
import FileDepositLightbox from "components/Backend/DocumentContainer/FileDepositLightbox";
import ItemLightbox from "components/Backend/DocumentContainer/ItemLightbox";
import * as DocumentActions from "actions/Document";

const Folder = (props) => {
    const { folder, isEdit, categoryId, type } = props;
    const dispatch = useDispatch();
    const [hovered, setHovered] = useState(false);
    const [open, setOpen] = useState(false);
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    return (
        <div className="line-container first-level-dnd" elementid={folder.id}>
            <div
                className={"folder-row" + (hovered ? " hovered" : "")}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={() => setOpen(!open)}
            >
                {isEdit && (
                    <i
                        className={
                            "far fa-chevron-right expand-icon expand-icon-before" +
                            (open ? " rotated-icon" : "")
                        }
                    ></i>
                )}
                <div className="folder-row-label">{folder.label}</div>
                {isEdit && (
                    <Dropdown
                        isDropdownVisible={isDropdownVisible}
                        setDropdownVisible={setDropdownVisible}
                    >
                        <ul className="dropdown-list">
                            <li
                                onClick={() => {
                                    setDropdownVisible(false);
                                    changeOverlayContent(
                                        <FileDepositLightbox
                                            folderId={folder.id}
                                        />
                                    );
                                }}
                            >
                                Ajouter un fichier au dossier
                            </li>
                            <li
                                onClick={() => {
                                    setDropdownVisible(false);
                                    changeOverlayContent(
                                        <ItemLightbox
                                            mode="update"
                                            folderId={folder.id}
                                            itemLabel={folder.label}
                                        />
                                    );
                                }}
                            >
                                Renommer le dossier
                            </li>
                            <li
                                className={
                                    folder.files.length > 0 ? "disabled" : ""
                                }
                                onClick={() => {
                                    if (folder.files.length === 0) {
                                        setDropdownVisible(false);
                                        changeOverlayContent(
                                            <ConfirmLightbox
                                                title={"Supprimer un dossier"}
                                                message={`Êtes-vous sûr de vouloir supprimer le dossier "${folder.label}" ?`}
                                                handleSubmit={() =>
                                                    dispatch(
                                                        DocumentActions.deleteFolder(
                                                            folder.id
                                                        )
                                                    ).then(() => {
                                                        changeOverlayContent(
                                                            null
                                                        );
                                                        dispatch(
                                                            DocumentActions.fetchDocuments()
                                                        );
                                                        dispatch(
                                                            DocumentActions.fetchUnions()
                                                        );
                                                    })
                                                }
                                            />
                                        );
                                    }
                                }}
                            >
                                Supprimer le dossier
                            </li>
                        </ul>
                    </Dropdown>
                )}
                {!isEdit && (
                    <i
                        className={
                            "far fa-chevron-right expand-icon" +
                            (open ? " rotated-icon" : "")
                        }
                    ></i>
                )}
            </div>
            <div className="nested-files-container-content">
                {folder.files.map((file, key) => {
                    return (
                        <FileRow
                            categoryId={categoryId}
                            file={file}
                            key={key}
                            visible={open}
                            nested={true}
                            isEdit={isEdit}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Folder;
