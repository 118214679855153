import React, { useState } from "react";
import DoctorItem from "components/DocumentContainer/Social/DoctorItem";
import InsuranceItem from "components/DocumentContainer/Social/InsuranceItem";
import SsctItem from "components/DocumentContainer/Social/SsctItem";
import PsychoItem from "components/DocumentContainer/Social/PsychoItem";
import HarassmentItem from "components/DocumentContainer/Social/HarassmentItem";
import LinkItem from "components/DocumentContainer/Social/LinkItem";
import FileRow from "components/DocumentContainer/FileRow";
import CseItem from "components/DocumentContainer/Social/CseItem";
import "./SocialItem.scss";

const SocialItem = (props) => {
    const { item } = props;

    return item.itemType !== "link" ? (
        <div
            className={
                "social-item-container" + ` ${item.itemType}-item-container`
                // (item.itemType === "cse" ? " cse-item-container" : "") +
                // (item.itemType === "insurance"
                //     ? " insurance-item-container"
                //     : "") +
                // (item.itemType === "document" ? " file-item-container" : "")
            }
        >
            {
                {
                    doctor: <DoctorItem item={item} />,
                    insurance: <InsuranceItem item={item} />,
                    ssct: <SsctItem item={item} />,
                    harassment: <HarassmentItem item={item} />,
                    psycho: <PsychoItem item={item} />,
                    document: <FileRow file={item} visible={true} />,
                    cse: <CseItem file={item} visible={true} />,
                }[item.itemType]
            }
        </div>
    ) : (
        <LinkItem item={item} />
    );
};

export default SocialItem;
