import React, { useState, useEffect } from "react";
import SocialFolder from "components/DocumentContainer/Social/SocialFolder";
import "./SocialCategory.scss";

const SocialCategory = (props) => {
    const { category, type } = props;

    return (
        <div className="category-container">
            <div className="category-label">{category.label}</div>
            {category.folders instanceof Array &&
                category.folders.map((folder, key) => {
                    return (
                        <SocialFolder folder={folder} key={key} type={type} />
                    );
                })}
        </div>
    );
};

export default SocialCategory;
