import React, {Fragment, useState} from "react";

import moment from "moment";
import "moment/locale/fr";

import {
    HOUR_INPUT,
    TICKET_INPUT,
} from "components/TimesheetContainer/Forms/Event/StepActions/Step3Overtime";
import {Field} from "formik";

const TotalsContainer = (props) => {
    const {
        overtimeType,
        onChange,
        onBlur,
        values,
        ignoreMission,
        isSubmitting
    } = props;

    const [isDisabled, setDisabled] = useState(ignoreMission)

    const TotalHours = () => {
        let duration = 0;
        let durationString;
        values.forEach((hours) => {
            let array = hours.hours;
            array.forEach((element) => {
                if (element) {
                    let startTime = moment.parseZone(element.start, "HH:mm");
                    let endTime = moment.parseZone(element.end, "HH:mm");

                    if (!isNaN(endTime.diff(startTime))) {
                        if (endTime.format("HH:mm") === "00:00") {
                            duration +=
                                24 * 60 * 60 * 1000 + endTime.diff(startTime);
                        } else {
                            duration += endTime.diff(startTime);
                        }
                    }
                }
            });
        });

        let hours = parseInt(moment.duration(duration).asHours());
        let minutes = parseInt(moment.duration(duration).asMinutes()) % 60;

        if (!isNaN(hours) && !isNaN(minutes) && duration > 0) {
            if (minutes < 10) {
                minutes = "0" + minutes;
            }

            durationString = hours + ":" + minutes;
        } else {
            durationString = "0:00";
        }

        return <div className="input">{durationString}</div>;
    };

    const TotalTickets = () => {
        let ticketsCount = 0;
        values.forEach((tickets) => {
            if (!isNaN(tickets.tickets)) {
                ticketsCount += tickets.tickets;
            }
        });

        return <div className="input">{ticketsCount}</div>;
    };

    return (
        <div className="overtime-days-container-right">
            <div
                className={
                    "constraint-total" +
                    (props.constraintCategory === "intervention"
                        ? " hidden"
                        : "")
                }
            >
                {overtimeType !== HOUR_INPUT && (
                    <Fragment>
                        <div className="label">Total d'astreintes :</div>
                        <div className="input-container">
                            {ignoreMission ?
                                <Field
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    className="total-constraint-input"
                                    type="number"
                                    name="constraint_count"
                                    id="constraintCount"
                                    min={1}
                                    disabled={!isSubmitting}
                                />
                                :
                                <Field
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    className="total-constraint-input"
                                    type="number"
                                    name="constraint_count"
                                    id="constraintCount"
                                    min={1}
                                />
                            }
                        </div>
                    </Fragment>
                )}
            </div>
            {overtimeType === HOUR_INPUT ? (
                <div className="hours-total">
                    <div className="label">Total d'heures :</div>
                    <div className="input-container">
                        <TotalHours/>
                    </div>
                </div>
            ) : (
                ""
            )}
            {overtimeType === TICKET_INPUT ? (
                <div className="ticket-total">
                    <div className="label">Total de tickets :</div>
                    <div className="input-container">
                        <TotalTickets/>
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default TotalsContainer;
