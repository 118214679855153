import React, { useState } from "react";
import "./VacationCount.scss";
import VacationCountHeader from "components/TimesheetContainer/VacationCount/VacationCountHeader";
import VacationCountContent from "components/TimesheetContainer/VacationCount/VacationCountContent";

const VacationCount = (props) => {
    const [open, setOpen] = useState(false);

    function toggleOpen() {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }

    return (
        <div
            className={(open ? " vacation-count-open" : "") + " vacation-count"}
        >
            <VacationCountHeader toggleOpen={toggleOpen} />
            <VacationCountContent
                modality={props.modality}
                cp={props.cp}
                rtt={props.rtt}
                remainder={props.remainder}
                rttRemainder={props.rttRemainder}
                rest={props.rest}
                beforeJune={props.beforeJune}
                sickChildren={props.sickChildrenDays}
                displayRemainderCounter={props.displayRemainderCounter}
            />
        </div>
    );
};

export default VacationCount;
