const suffix = "__ExpireAt";
const CACHE_EXPIRATION_DATE = "__cacheExpirationDate";

export const getCache = (key) => {
    if (!localStorage.getItem(key)) {
        return false;
    } else {
        return JSON.parse(localStorage.getItem(key));
    }
};

export const setCache = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
};

export const removeCache = (key) => {
    localStorage.removeItem(key);
};

export const getExpirableCache = (key) => {
    const timestamp = getTimestamp();
    const lifetime = getCache(key + suffix);
    if (timestamp > parseInt(lifetime)) {
        localStorage.removeItem(key);
    }
    return getCache(key);
};

export const setExpirableCache = (key, data, lifetime) => {
    let timestamp;
    if (lifetime instanceof Date) {
        timestamp = getTimestamp(lifetime);
    } else {
        timestamp = getTimestamp() + lifetime;
    }
    localStorage.setItem(key + suffix, timestamp);
    setCache(key, data);
};

export const removeExpirableCache = (key) => {
    removeCache(key);
    removeCache(key + suffix);
};

export const setCacheExpirationDate = (date) => {
    setCache(CACHE_EXPIRATION_DATE, getTimestamp(date));
};

export const getCacheExpirationDate = () => {
    return new Date(getCache(CACHE_EXPIRATION_DATE) * 1000);
};

const getTimestamp = (date = null) => {
    if (!(date instanceof Date)) {
        date = new Date();
    }
    return Math.round(date.getTime() / 1000);
};
