import React, { useEffect } from "react";

import "./CalendarNotAvailable.scss";

const CalendarNotAvailable = (props) => {
    return (
        <div className="calendar-not-available">
            Votre profil utilisateur ne vous permet pas d'accéder au contenu de
            cette fonctionnalité. Pour plus d'informations merci de contacter
            votre Manager.
        </div>
    );
};

export default CalendarNotAvailable;
