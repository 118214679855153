import MonthRepository from "store/repositories/MonthRepository";
import DayRepository from "store/repositories/DayRepository";
import HalfDayRepository from "store/repositories/HalfDayRepository";
import EventRepository from "store/repositories/EventRepository";
import EventCodeRepository from "store/repositories/EventCodeRepository";
import ContactRepository from "store/repositories/ContactRepository";
import OvertimeRepository from "store/repositories/OvertimeRepository";
import OvertimeCodeRepository from "store/repositories/OvertimeCodeRepository";
import PostRepository from "store/repositories/PostRepository";
import HighlightRepository from "store/repositories/HighlightRepository";
import UserRepository from "store/repositories/UserRepository";
import ManagerRepository from "store/repositories/ManagerRepository";

export const MONTH_REPOSITORY = "MONTH_REPOSITORY";
export const DAY_REPOSITORY = "DAY_REPOSITORY";
export const HALFDAY_REPOSITORY = "HALFDAY_REPOSITORY";
export const OVERTIME_REPOSITORY = "OVERTIME_REPOSITORY";
export const OVERTIME_CODE_REPOSITORY = "OVERTIME_CODE_REPOSITORY";
export const EVENT_REPOSITORY = "EVENT_REPOSITORY";
export const EVENT_CODE_REPOSITORY = "EVENT_CODE_REPOSITORY";
export const CONTACT_REPOSITORY = "CONTACT_REPOSITORY";
export const POST_REPOSITORY = "POST_REPOSITORY";
export const HIGHLIGHT_REPOSITORY = "HIGHLIGHT_REPOSITORY";
export const USER_REPOSITORY = "USER_REPOSITORY";
export const MANAGER_REPOSITORY = "MANAGER_REPOSITORY";

export const EntityRepository = () => ({
    getRepository(classname) {
        let repository = undefined;
        switch (classname) {
            case MONTH_REPOSITORY:
                repository = new MonthRepository();
                break;
            case DAY_REPOSITORY:
                repository = new DayRepository();
                break;
            case HALFDAY_REPOSITORY:
                repository = new HalfDayRepository();
                break;
            case OVERTIME_CODE_REPOSITORY:
                repository = new OvertimeCodeRepository();
                break;
            case OVERTIME_REPOSITORY:
                repository = new OvertimeRepository();
                break;
            case EVENT_REPOSITORY:
                repository = new EventRepository();
                break;
            case EVENT_CODE_REPOSITORY:
                repository = new EventCodeRepository();
                break;
            case CONTACT_REPOSITORY:
                repository = new ContactRepository();
                break;
            case POST_REPOSITORY:
                repository = new PostRepository();
                break;
            case HIGHLIGHT_REPOSITORY:
                repository = new HighlightRepository();
                break;
            case USER_REPOSITORY:
                repository = new UserRepository();
                break;
            case MANAGER_REPOSITORY:
                repository = new ManagerRepository();
                break;
            default:
                break;
        }

        return repository;
    },
});
