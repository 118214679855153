import React, {useState} from "react";
import "./VacationCountTotals.scss";
import moment from "moment";
import "moment/locale/fr";

const VacationCountTotals = (props) => {
    const {
        beforeJune,
        rtt,
        cp,
        displayRemainderCounter,
        remainder,
        rttRemainder,
        rest,
        modality
    } = props;

    const [hovered, setHovered] = useState(false);

    const toggleInfos = () => {
        setHovered(!hovered)
    }

    return (
        <div className="vacation-totals">
            <div className="vacation-container">
                <div className="container-header">
                    <div className="total-name">Solde CP</div>
                    <div className="total-count">{(cp + remainder + beforeJune).toFixed(2)}</div>
                </div>
                <div className="container-line">
                    <div className="line-count">{cp.toFixed(2)}</div>
                    <div className="line-description">CP N</div>
                </div>
                <div className="container-line">
                    <div className="line-count">{beforeJune.toFixed(2)}</div>
                    <div className="line-description">CP N-1 <div className={"vacation-before"}>
                        <i className="fal fa-exclamation-square"></i> à prendre avant le 31/05</div>
                    </div>
                </div>
                {remainder > 0 && displayRemainderCounter && <div className="container-line">
                    <div className="line-count">{remainder.toFixed(2)}</div>
                    <div className="line-description">CP reliquats</div>
                </div>}
            </div>

            <div className="vacation-container">
                <div className="container-header">
                    <div className="total-name">Solde JRS
                        <i onMouseEnter={toggleInfos} onMouseLeave={toggleInfos} className="fal fa-info-circle toggle-infos-indicator"></i>
                        <div className={(hovered ? "" : "hidden ") + "jrs-infos"}>
                            Pour rappel, votre compteur de JRS est incrémenté intégralement, à chaque début d'année, sur
                            la base d'une année de présence à taux plein.<br/>
                            {(modality == "2" ? "Pour une année pleine d'acquisition (du 01/01/N au 31/12/N) : 11 JRS (4 JRS Employeur / 7 JRS Salarié). Pour toute acquisition partielle, le compteur de JRS Employeur est acquis prioritairement." : "")}
                        </div>
                    </div>
                    <div className="total-count">{(rtt + rttRemainder).toFixed(2)}</div>
                </div>
                <div className="container-line">
                    <div className="line-count">{rtt.toFixed(2)}</div>
                    <div className="line-description">JRS N</div>
                </div>
                <div className="container-line">
                    <div className="line-count">{rttRemainder.toFixed(2)}</div>
                    <div className="line-description">JRS N-1 <div className={"vacation-before"}>
                        <i className="fal fa-exclamation-square"></i> à prendre avant le 31/03</div>
                    </div>
                </div>
            </div>

            {rest > 0 && (<div className="vacation-container">
                <div className="container-header">
                    <div className="total-name two-line-total">Repos compensateur</div>
                    <div className="total-count">{rest.toFixed(2)}</div>
                </div>
            </div>)}

        </div>
    );
};

export default VacationCountTotals;
