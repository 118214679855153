import React from "react";
import "./PressContactBlock.scss";

const PressContactBlock = () => {
    return (
        <div className="press-contact">
            <div className="content">
                <strong>Contact Presse</strong>
                <br />
                Vincent Dinah
                <br />
                Chargé des Relations Presse
                <br />
                01.44.94.78.76
                <br />
                <a href="mailto:dinah@synchrone.fr">dinah@synchrone.fr</a>
                <div className="share-media">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://fr.linkedin.com/in/vincent-dinah-26186411a"
                        id="share-linkedin"
                    >
                        <i className="fab fa-linkedin"></i>
                    </a>
                    {/* <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://fr.viadeo.com/fr/profile/vincent.dinah"
                        id="share-viadeo"
                    >
                        <i className="fab fa-viadeo"></i>
                    </a> */}
                </div>
            </div>
        </div>
    );
};

export default PressContactBlock;
