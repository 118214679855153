import User from "./User";
import ExtraActivityDay from "./ExtraActivityDay";
import "moment/locale/fr";
import moment from "moment";

class ExtraActivityWeek {
    id;
    week;
    month;
    year;
    comment;
    user;
    complementaries;
    constraints;
    duration;

    constructor(json) {
        this.id = json.id;
        this.week = json.week;
        this.month = json.month;
        this.year = json.year;
        this.comment = json.comment;
        this.user = new User(json.user)
        this.complementaries = [];
        this.constraints = [];
        this.duration = json.duration;

        json.complementaries.map((day) => {
            this.complementaries.push(new ExtraActivityDay(day))
        })

        json.constraints.map((day) => {
            this.constraints.push(new ExtraActivityDay(day))
        })
    }
}

export default ExtraActivityWeek;
