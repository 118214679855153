import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import * as UserActions from "actions/User";
import RunAsUser from "./RunAsUser";
import usePressEnterKey from "hooks/usePressEnterKey";
import usePressNumPadEnterKey from "hooks/usePressNumPadEnterKey";

import SearchBar from "components/ContactContainer/SearchBar";
import {sanitizeForSearch} from "utils/Utils";
import {setAppLoading} from "actions/Common";

import "./RunAsContainer.scss";
import {removeCache, removeExpirableCache} from "utils/LocalStorage";
import User from "../../entities/User";

const RunAsContainer = () => {
    const [filter, setFilter] = useState("");
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [favorites, setFavorites] = useState([])
    const [favoriteIds, setFavoriteIds] = useState([])
    const me = useSelector((state) => state.user.me);
    const users = useSelector((state) => state.user.runAsListUsers);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(UserActions.fetchRunAsList());
    }, []);

    useEffect(() => {
        const data = localStorage.getItem('runAsFavorites')
        if (data) {
            const favoriteUsers = []

            JSON.parse(data).map(id => {
                const favoriteUser = users.find(target => target.id === id)
                if (favoriteUser) {
                    favoriteUsers.push(favoriteUser)
                }
            })

            setFavorites(favoriteUsers)
        }
    }, [users]);

    useEffect(() => {
        setFilteredUsers(filterUsers());
    }, [filter, users]);

    useEffect(() => {
        const favoriteIds = []
        favorites.map(user => {
            favoriteIds.push(user.id)
        })
        setFavoriteIds(favoriteIds);
    }, [favorites])

    const runAsUser = (username) => {
        localStorage.setItem("switchUser", username);
        removeCache("userForAbsenceList");
        removeCache("userForCRAList");
        removeCache("userForAbsenceListFetchedAt");
        removeCache("userForCRAListFetchedAt");
        removeExpirableCache("overtimeCodes");
        removeExpirableCache("eventCodes");
        dispatch(setAppLoading(true));
        me.loaded = false;
        dispatch(UserActions.fetchCurrentUser());
        window.location = "/";
    };

    usePressEnterKey(() => {
        if (filteredUsers.length === 1) {
            runAsUser(filteredUsers[0].username);
        }
    });

    usePressNumPadEnterKey(() => {
        if (filteredUsers.length === 1) {
            runAsUser(filteredUsers[0].username);
        }
    });

    const isFavorite = (user) => {
        return favoriteIds.includes(user.id)
    }

    const handleToggleFavorite = (user) => {
        const id = user.id
        let mustRemove = false
        const favoriteUsers = []
        favorites.map(fav => {
            if (fav.id !== user.id) {
                favoriteUsers.push(users.find(target => target.id === fav.id))
            } else {
                mustRemove = true
            }
        })
        if (!mustRemove) {
            favoriteUsers.push(users.find(target => {
                return target.id === id
            }))
        }
        setFavorites(favoriteUsers)
        const userIds = []
        favoriteUsers.map(user => {
            return userIds.push(user.id)
        })
        localStorage.setItem('runAsFavorites', JSON.stringify(userIds))
    }

    const filterUsers = () => {
        let filtered = users;

        if (filter) {
            return filtered.filter((element) => {
                let lowercasedFilter = sanitizeForSearch(filter);
                if (
                    sanitizeForSearch(element.firstname).includes(
                        lowercasedFilter
                    ) ||
                    sanitizeForSearch(element.lastname).includes(
                        lowercasedFilter
                    ) ||
                    sanitizeForSearch(
                        element.firstname + " " + element.lastname
                    ).includes(lowercasedFilter)
                ) {
                    return element;
                }
            });
        }

        return filtered;
    };

    const CaptionEntry = (props) => {
        const {color, title} = props;

        return (
            <div className="caption">
                <div
                    className="caption-color"
                    style={{
                        backgroundColor: color,
                    }}
                ></div>
                <div className="caption-title">{title}</div>
            </div>
        );
    };

    return (
        <div id="page-container">
            <h1>
                Se connecter en tant que
            </h1>
            <div className="container">
                <div className="caption-container">
                    <CaptionEntry
                        color="#eb5961"
                        title="Interne"
                    />
                    <CaptionEntry
                        color="#00c1de"
                        title="Prestataire"
                    />
                    <CaptionEntry
                        color="#8a1d81"
                        title="Consultant salarié"
                    />
                </div>

                {favorites.length > 0 &&
                <div className="run-as-favorites">
                    <div className="run-as-header"><i className={"fa fa-star"}></i></div>
                    <div>
                        {favorites.map((element, key) => {
                            return (
                                <RunAsUser
                                    user={element}
                                    key={key}
                                    handleClick={runAsUser}
                                    handleRightClick={handleToggleFavorite}
                                    favorite={isFavorite(element)}
                                />
                            );
                        })}
                    </div>
                </div>}

                <SearchBar
                    setFilter={setFilter}
                    placeholder="Rechercher un collaborateur"
                />

                {filter.length > 2 || filteredUsers.length <= 100 ? (
                    filteredUsers.map((element, key) => {
                        return (
                            <RunAsUser
                                user={element}
                                key={key}
                                handleClick={runAsUser}
                                handleRightClick={handleToggleFavorite}
                                favorite={isFavorite(element)}
                            />
                        );
                    })
                ) : (
                    <div className="empty-runas">
                        Commencez à taper pour rechercher un utilisateur
                    </div>
                )}
                {/* {users.map((element, key) => {
                    return <RunAsUser user={element} key={key} />;
                })} */}
            </div>
        </div>
    );
};

export default RunAsContainer;
