import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";

import "./PersonRow.scss";
import { EntityRepository, MANAGER_REPOSITORY } from "store/EntityRepository";
import { useSelector } from "react-redux";
import { isEmpty } from "utils/Utils";
import AbsenceBlock from "components/Backend/Block/AbsenceBlock";
import IdentityBlock from "components/Backend/Block/IdentityBlock";
import DaysBlock from "components/Backend/Block/DaysBlock";
import RealTimeVacationBlock from "components/Backend/Block/RealTimeVacationBlock";
import ActionsBlock from "components/Backend/Block/ActionsBlock";

const PersonRow = (props) => {
    const { user, events, loadList } = props;
    const me = useSelector((state) => state.user.me);
    const managerRepo = EntityRepository().getRepository(MANAGER_REPOSITORY);
    const getManager = () => {
        if (managerRepo.findByMatricule(user.manager)) {
            return managerRepo.findByMatricule(user.manager);
        } else if (user.manager === me.matricule) {
            return me;
        }
        return;
    };
    const manager = getManager();

    const groupAbsencesByEventCode = () => {
        if (events.length > 0) {
            return events.reduce((acc, obj) => {
                const key = obj.eventCode.description;
                if (!acc[key]) {
                    acc[key] = [];
                }
                // Add object to list for given key's value
                acc[key].push(obj);
                return acc;
            }, {});
        }

        return {};
    };

    const [absenceGroups, setAbsenceGroups] = useState({});

    useEffect(() => {
        setAbsenceGroups(groupAbsencesByEventCode());
    }, [events]);

    return (
        <div className="person-row">
            <IdentityBlock user={user} manager={manager} />
            <AbsenceBlock absenceGroups={absenceGroups} user={user} />
            <DaysBlock absenceGroups={absenceGroups} />
            <ActionsBlock
                absenceGroups={absenceGroups}
                me={me}
                user={user}
                manager={manager}
                loadList={loadList}
            />
            <RealTimeVacationBlock user={user} me={me} />
        </div>
    );
};

export default PersonRow;
