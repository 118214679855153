class MonthAction {
    id;
    month;
    action;
    author;
    comment;
    createdAt;
    label;

    constructor(json) {
        this.id = json.id;
        this.month = json.month;
        this.action = json.action;
        this.author = json.author;
        this.comment = json.comment;
        this.createdAt = json.created_at;
        this.label = json.label;
    }
}

export default MonthAction;
