import React from "react";
import Lightbox from "components/common/Lightbox";
import useClickOutside from "hooks/useClickOutside";
import usePressEscapeKey from "hooks/usePressEscapeKey";
import "./ContactLightbox.scss";
import { changeOverlayContent } from "events/OverlayEvents";

const ContactLightbox = (props) => {
    const contact = props.contact;

    const close = () => {
        changeOverlayContent(null);
    };

    usePressEscapeKey(() => {
        close();
    });

    const ref = useClickOutside(() => {
        close();
    });

    const title = () => {
        let title;
        if (contact.title) {
            title = contact.title;
        } else {
            title = contact.identity;
        }

        return <div className="contact-title">{title}</div>;
    };
    const job = () => {
        let job;
        if (contact.job) {
            job = contact.job;
        } else if (contact.title) {
            job = contact.identity;
        } else {
            return "";
        }

        return <div className="contact-identity">{job}</div>;
    };

    const picture = () =>
        contact.picture ? (
            <img src={contact.picture} alt={contact.identity} />
        ) : null;

    const line = (value, icon, type) => {
        let label = value;

        if (type) {
            label = <a href={type + ":" + value}>{value}</a>;
        }

        return (
            <div className="contact-line">
                <i className={"fal fa-" + icon}></i> {label}
            </div>
        );
    };

    const lines = () => {
        const lines = [];
        if (contact.mobile) {
            lines.push(line(contact.mobile, "mobile", "tel"));
        }
        if (contact.phone) {
            lines.push(line(contact.phone, "phone-office", "tel"));
        }
        if (contact.email) {
            lines.push(line(contact.email, "envelope-open", "mailto"));
        }
        if (contact.birthday) {
            lines.push(line(contact.birthday, "birthday-cake", null));
        }

        return (
            <div className="contact-lines">
                {lines.map((item) => {
                    return item;
                })}
            </div>
        );
    };

    const place = () =>
        contact.place ? (
            <div className="contact-place">
                <i className="fal fa-map-marker-alt"></i>
                {contact.place}
            </div>
        ) : null;

    return (
        <Lightbox ref={ref} className="contact-lb">
            <div className="close-lb" onClick={close} />
            {title()}
            {picture()}
            {job()}
            {lines()}
            {place()}
        </Lightbox>
    );
};

export default ContactLightbox;
