import { MY_SYNCHRONE_URL as baseUrl } from "config/Url.js";

class CseItem {
    label;
    url;
    logo;
    itemType;

    constructor(json, itemType) {
        this.label = json.label;
        this.url = json.url;
        this.logo = json.logo;
        this.itemType = itemType;
    }

    get iconUrl() {
        return baseUrl + this.logo;
    }
}

export default CseItem;
