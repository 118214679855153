import { MY_SYNCHRONE_URL as baseUrl } from "config/Url.js";
import Folder from "entities/Folder";
import File from "entities/File";

class Category {
    id;
    label;
    priority;
    folders;
    files;

    constructor(json) {
        this.id = json.id;
        this.label = json.label;
        this.priority = json.priority;
        let foldersArray = [];
        json.folders.map((folder) => {
            foldersArray.push(new Folder(folder));
        });
        this.folders = foldersArray;
        let filesArray = [];
        json.files.map((file) => {
            filesArray.push(new File(file));
        });
        this.files = filesArray;
    }
}

export default Category;
