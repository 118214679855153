import SocialFolder from "entities/SocialFolder";

class SocialCategory {
    label;
    folders;

    constructor(json) {
        this.label = json.label;
        let foldersArray = [];
        json.folders.map((folder) => {
            foldersArray.push(new SocialFolder(folder));
        });
        this.folders = foldersArray;
    }
}

export default SocialCategory;
