import { MY_SYNCHRONE_URL } from "config/Parameters";
import moment from "moment";
import "moment/locale/fr";

class Contact {
    constructor(json) {
        this.id = json.id;
        this.firstname = json.firstname;
        this.lastname = json.lastname;
        this.phone = json.phone;
        this.mobile = json.mobile;
        this.email = json.email;
        this.place = json.place;
        this.matricule = json.matricule;
        this.birthday = json.birthday
            ? moment.parseZone(json.birthday).format("DD/MM/YYYY")
            : null;
        if (typeof json.picture_url !== "undefined") {
            this.picture = json.picture_url
                ? MY_SYNCHRONE_URL + json.picture_url
                : "/img/user.png";
        }
    }

    get identity() {
        return [this.firstname, this.lastname].join(" ");
    }
}

export default Contact;
