import SocialCategory from "entities/SocialCategory";

class SocialSection {
    label;
    categories;

    constructor(json) {
        this.label = json.label;
        let categoriesArray = [];
        json.categories.map((category, key) => {
            categoriesArray.push(new SocialCategory(category));
        });
        this.categories = categoriesArray;
    }
}

export default SocialSection;
