import React, { useEffect, useState } from "react";
import Lightbox from "components/common/Lightbox";
import { useDispatch, useSelector } from "react-redux";

import { changeOverlayContent } from "events/OverlayEvents";
import * as TimesheetActions from "actions/Timesheet";
import * as CommonActions from "actions/Common";
import * as ValidationActions from "actions/Validation";
import { monthInLetter } from "utils/Utils";

import "./ForceSubmit.scss";

const ForceSubmit = (props) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.common.isLoading);
    const [submitting, setSubmitting] = useState(false);
    const [targetUsers, setTargetUsers] = useState([]);
    const { period, reload } = props;

    const close = () => {
        if (!submitting && !isLoading) {
            changeOverlayContent(null);
        }
    };

    const handleSubmit = () => {
        if (
            !submitting &&
            !isLoading &&
            targetUsers?.response?.entities?.user
        ) {
            setSubmitting(true);
            dispatch(CommonActions.setLoading(true));
            dispatch(ValidationActions.forceSubmit(period.id, true)).then(
                (json) => {
                    dispatch(CommonActions.setLoading(false));
                    setSubmitting(false);
                    changeOverlayContent(null);
                    reload();
                    dispatch(
                        TimesheetActions.notifySuccess(
                            json.response.entities.message[0].message
                        )
                    );
                }
            );
        }
    };

    useEffect(() => {
        dispatch(CommonActions.setLoading(true));
        dispatch(ValidationActions.forceSubmit(period.id, false)).then(
            (json) => {
                setTargetUsers(json);
                dispatch(CommonActions.setLoading(false));
            }
        );
    }, []);

    return (
        <Lightbox className="lb-centered force-submit-lb">
            <div className="force-submit-title">Envoyer les CRAs complets</div>
            <div className="force-submit-to-be-locked">
                Période concernée : {monthInLetter(period.month)} {period.year}
            </div>
            <div className="force-submit-recap">
                <div className="recap-row">
                    <div className="recap-label">Nombre de CRAs concernés</div>
                    <div className="recap-amount">
                        {targetUsers?.response?.entities?.user
                            ? Object.values(targetUsers.response.entities.user)
                                  .length
                            : 0}
                    </div>
                </div>
            </div>
            <div className="force-submit-recap">
                <div className="recap-row">
                    <div className="recap-label users-recap-label">
                        Utilisateurs concernés
                    </div>
                    <ul className="users-recap-list">
                        {targetUsers?.response?.entities?.user
                            ? Object.values(
                                  targetUsers.response.entities.user
                              ).map((element, index) => {
                                  return (
                                      <li key={index}>
                                          {element.firstname +
                                              " " +
                                              element.lastname}
                                      </li>
                                  );
                              })
                            : "Aucun"}
                    </ul>
                </div>
            </div>
            <div className="force-submit-actions">
                <div
                    className={"cancel-btn " + (isLoading ? "disabled" : "")}
                    onClick={close}
                >
                    Annuler
                </div>
                <div
                    className={
                        "next-btn " +
                        (isLoading || !targetUsers?.response?.entities?.user
                            ? "disabled"
                            : "")
                    }
                    onClick={handleSubmit}
                >
                    Confirmer
                </div>
            </div>
        </Lightbox>
    );
};

export default ForceSubmit;
