import React from "react"
import * as SettingsActions from "actions/Settings";
import * as TimesheetActions from "actions/Timesheet";
import {setLoading} from "actions/Common";

import "./RemoteWorkButton.scss";
import {useDispatch} from "react-redux";

const RemoteWorkButton = (props) => {
    const dispatch = useDispatch();
    const {setRemoteEditing, personType, isRemoteEditing, remoteDaysForSend, month, year} = props

    const handleClick = () => {
        if (!isRemoteEditing) {
            dispatch(TimesheetActions.clearDaysSelection());
            dispatch(TimesheetActions.clearDaysHoveredSelection());
            setRemoteEditing(true)
        } else {
            dispatch(setLoading(true));
            dispatch(SettingsActions.patchRemoteDaysForMonth(month, year, JSON.stringify(remoteDaysForSend))).then(() => {
                setRemoteEditing(false)
                dispatch(TimesheetActions.notifySuccess('Vos jours de télétravail ont été enregistrés avec succès'))
                dispatch(setLoading(false))
                dispatch(TimesheetActions.fetchEntireMonth(month, year));
            })
        }
    }

    return <div onClick={handleClick} className={"remote-working-btn " + (isRemoteEditing ? ' remote-work-editing-btn' : '')}>
        {isRemoteEditing ? '' : <i className="far fa-home"></i>}
        {isRemoteEditing ? 'Enregistrer' : 'Saisir mon ' + (personType === "contractor" ? "travail à distance" : "télétravail")}
    </div>
}

export default RemoteWorkButton