import { Schemas } from "middleware/Schemas";
import * as Params from "config/Parameters";
import { setLoading } from "actions/Common";
import { apiOptions } from "utils/Api";

export const CONTACT_REQUEST = "CONTACT_REQUEST";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_FAILURE = "CONTACT_FAILURE";
export const fetchAll = () => {
    return (dispatch) => {
        dispatch(setLoading(true));
        return dispatch({
            // Types of actions to emit before and after
            types: [CONTACT_REQUEST, CONTACT_SUCCESS, CONTACT_FAILURE],
            needLogin: true,
            // Perform the fetching:
            callAPI: () => {
                return fetch(
                    `${Params.API_ROOT}contact/contact`,
                    apiOptions("GET")
                );
            },
            name: "contact.entities.contacts",
            alwaysFetch: false,
            // Arguments to inject in begin/end actions
            schema: Schemas.CONTACT_ARRAY,
        });
    };
};

export const CONSULTING_TEAM_REQUEST = "CONSULTING_TEAM_REQUEST";
export const CONSULTING_TEAM_SUCCESS = "CONSULTING_TEAM_SUCCESS";
export const CONSULTING_TEAM_FAILURE = "CONSULTING_TEAM_FAILURE";
export const fetchConsultingTeam = () => {
    return (dispatch) => {
        dispatch(setLoading(true));
        return dispatch({
            // Types of actions to emit before and after
            types: [
                CONSULTING_TEAM_REQUEST,
                CONSULTING_TEAM_SUCCESS,
                CONSULTING_TEAM_FAILURE,
            ],
            needLogin: true,
            // Perform the fetching:
            callAPI: () => {
                return fetch(
                    `${Params.API_ROOT}contact/consulting-team`,
                    apiOptions("GET")
                );
            },
            name: "contact.entities.consultingTeam",
            alwaysFetch: false,
            // Arguments to inject in begin/end actions
            schema: Schemas.CONSULTING_TEAM,
        });
    };
};

export const MY_CONTACT_REQUEST = "MY_CONTACT_REQUEST";
export const MY_CONTACT_SUCCESS = "MY_CONTACT_SUCCESS";
export const MY_CONTACT_FAILURE = "MY_CONTACT_FAILURE";
export const fetchMyContacts = () => {
    return (dispatch) => {
        dispatch(setLoading(true));
        return dispatch({
            // Types of actions to emit before and after
            types: [MY_CONTACT_REQUEST, MY_CONTACT_SUCCESS, MY_CONTACT_FAILURE],
            needLogin: true,
            // Perform the fetching:
            callAPI: () => {
                return fetch(
                    `${Params.API_ROOT}contact/my-contact`,
                    apiOptions("GET")
                );
            },
            name: "contact.entities.mycontacts",
            alwaysFetch: false,
            // Arguments to inject in begin/end actions
            schema: Schemas.MY_CONTACT_ARRAY,
        });
    };
};

export const RESET_CONTACT = "RESET_CONTACT";
export const resetContact = () => ({
    type: RESET_CONTACT,
});
