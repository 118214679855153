import React from "react";

import "./Button.scss";

const Button = (props) => {
    const getClassnames = () => {
        let classes = "lb-event-action-btn ";
        switch (props.type) {
            case "activity":
                classes += " activity-type";
                break;
            case "absence":
                classes += " absence-type";
                break;
            case "hours-not-worked":
                classes += " hours-not-worked-type";
                break;
            case "next":
                classes += " next-btn";
                break;
            case "cancel":
                classes += " cancel-btn";
                break;
            case "disabled":
                classes += " disabled-btn";
                break;
            default:
                break;
        }

        return classes;
    };

    return (
        <div
            onClick={props.onClick}
            className={getClassnames()}
            style={props.additionnalStyle}
        >
            <div className="label">{props.text}</div>
            {props.shortname ? (
                <span className="event-shortname">{props.shortname}</span>
            ) : (
                ""
            )}
        </div>
    );
};

export default Button;
