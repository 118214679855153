import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { callMySynchroneAPIMiddleware } from "middleware/MySynchroneApi";
import rootReducer from "reducers";

const configureStore = preloadedState => {
    const store = createStore(
        rootReducer,
        preloadedState,
        applyMiddleware(thunk, callMySynchroneAPIMiddleware)
    );

    return store;
};

const store = configureStore();

export default store;
